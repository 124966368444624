import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Input,
  Snackbar,
  Alert,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  useContext,
  Fragment,
  useLayoutEffect,
} from "react";
import { StateAPI } from "../../../service/stateList.service";
import { PutYearListAPI, YearListAPI } from "../../../service/year.service";
import DeleteIcon from "@mui/icons-material/Delete";
import { Context as userLoaderContext } from "../../../context/userLoaderContext";
import {
  DeleteStateApi,
  PostStateApi,
} from "../../../service/postState.service";
import "./admin.css";
import { PutSurrenderChargeApi } from "../../../service/putSurrenderCharge_service";
import { PutInterestRateApi } from "../../../service/putInterestRate.service";
import { DeleteYearListApi } from "../../../service/deleteYearList.service";
import { useNavigate } from "react-router-dom";

const Admin1 = (props) => {
  const [yearListRes, setYearListRes] = useState([{}]);
  const [ShowMsg, setShowMsg] = useState({
    status: false,
    error: false,
    msg: "",
  });
  const { state, userType } = useContext(userLoaderContext);
  const { SetUser, SetFlagLoader, SetUserType } = useContext(userLoaderContext);
  const [stateList, setStateList] = useState([{}]);
  const [editableState, setEditableState] = useState("");
  const [editableYear, setEditableYear] = useState(0);
  const [editableInterestRate, setEditableInterestRate] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [editStateRes, setEditStateRes] = useState({});
  const [editYearRes, setEditYearRes] = useState({});
  const [flagAdd, setFlagAdd] = useState(0);
  const [updateSC, setUpdateSC] = useState([]);
  const [flagDelete, setFlagDelete] = useState(0);
  const [tempStateId, setTempStateId] = useState();
  const [deleteMsg, setDeleteMsg] = useState("");
  const [tempSnackbarOpen, setTempSnackbarOpen] = useState(true);
  const [flagDilogBoxForSC, setFlagDilogBoxForSC] = useState(false);
  const [flagDilogBoxForSCOpen, setFlagDilogBoxForSCOpen] = useState(true);
  const [tempArray, setTemparray] = useState([]);
  const [tempArray2, setTemparray2] = useState([]);
  const [data, setData] = useState([]);
  const [surrenderChargeArray, setSurrenderChargeArray] = useState([]);
  const [yearsListResponseFinal, setYearsListResponseFinal] = useState([]);
  const [deletedYearListId, setDeletedYearListId] = useState();
  const [tempAlert, setTempAlert] = useState(false);

  const navigate = useNavigate();
  useLayoutEffect(() => {
    // debugger;
    console.log("role from props", props.role.includes("Admin"));
    if (props.role.includes("Admin") === false) {
      // debugger;
      navigate("/");
    } else {
      // alert("2222");
    }
  }, []);

  useEffect(() => {
    console.log("....................", editableYear);
    let t1 = [];
    for (let i = 0; i < editableYear; i++) {
      t1.push(i);
      console.log("t1", t1);
    }
    console.log("t123", t1);
    setTemparray(t1);
  }, [editableYear]);
  console.log("....................", tempArray);
  console.log("....................", tempArray.length);

  const handleClickState = () => {
    setFlagAdd(1);
    setFlagDelete(0);
    setOpen(true);
  };

  const deletestatefunction = () => {
    //if(deleteState === true)
    SetFlagLoader(true);
    if (flagDelete === 1) {
      PostStateApi(
        { state_id: tempStateId },
        (res) => {
          console.log("resssssssssssssssssss", res);
          if (res.data.status === true) {
            SetFlagLoader(false);
            setOpen(false);
            setStateList(res.data.data);
            setDeleteMsg(res.data.message.message);
            setShowMsg({
              status: false,
              error: false,
              msg: "",
            });
          }
        },
        (err) => {
          console.log("errrrrrrrrrrrrrrrrr", err);
        }
      );
    } else {
      DeleteYearListApi(
        {
          year_list_id: deletedYearListId,
        },
        (res) => {
          console.log("resssss", res);
          SetFlagLoader(false);
          setOpen(false);
          setYearListRes(res.data.data);
          setDeleteMsg(res?.data?.message);
          setShowMsg({
            status: false,
            error: false,
            msg: "",
          });
        },
        (err) => {
          setOpen(false);
          //   console.log("errrrrr",err);
        }
      );
    }
  };
  const handleClickYear = () => {
    setFlagAdd(2);
    setFlagDelete(0);
    setOpen(true);
  };

  const onchangeInput = (e, index) => {
    let temp = data.map((i) => i);
    temp[index] = Number(e.target.value);
    setData(temp);
    console.log(temp);
  };

  const savePeriod = () => {
    // debugger;
    console.log("33333@", data);
    console.log("33333@", editableInterestRate);
    console.log("33333@", editableYear);

    if (data.includes(undefined) != true && data.length !== 0) {
      PutYearListAPI(
        {
          year_name: Number(editableYear),
          interest_rate: Number(editableInterestRate / 100),
          surrender_charge: data,
        },
        (res) => {
          console.log("wwwwwwwwwwwwwwwwwwwww", res);
          SetFlagLoader(true);
          if (res.data.status === true) {
            setFlagDilogBoxForSCOpen(false);
            SetFlagLoader(false);
            setYearListRes(res.data.data);
            setShowMsg({
              status: false,
              error: true,
              msg: res.message,
            });
          } else {
            setShowMsg({
              status: false,
              error: true,
              msg: res.data.message,
            });
          }

          // setEditYearRes(res.data)
        },
        (err) => {
          console.log("errrrrrrrrrrrrrrrrr", err);
          setShowMsg({
            status: false,
            error: true,
            msg: err.data.message,
          });
          SetFlagLoader(false);
        }
      );
    } else {
      setShowMsg({
        status: false,
        error: true,
        msg: "please fill all the feilds",
      });
      // alert("please fill all the feilds");
    }
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const saveState = () => {
    if (
      (editableYear !== 0 && editableInterestRate !== 0) ||
      editableState !== ""
    ) {
      // debugger;
      if (flagAdd === 1) {
        // debugger;
        // let temp = stateList;

        let num = 0;
        stateList.map((i) => {
          if (i.state_name == editableState) {
            num++;
          }
        });

        if (num == 0) {
          // debugger;
          PostStateApi(
            { state: editableState },
            (res) => {
              console.log("wwwwwwwwwwwwwwwwwwwww", res.data);
              setEditStateRes(res.data);
              setOpen(false);
              setShowMsg({
                status: false,
                error: false,
                msg: "",
              });
            },
            (err) => {
              console.log("errrrrrrrrrrrrrrrrr", err);
            }
          );
        } else {
          setShowMsg({
            status: false,
            error: true,
            msg: "State name already exists!",
          });
          // return alert("State name already exists!");
        }
      } else if (flagAdd === 2) {
        // debugger;
        if (
          yearListRes.filter((t) => t.year_list_name == editableYear).length > 0
        ) {
          setTempAlert(true);
          setShowMsg({
            status: false,
            error: true,
            msg: "Year already exists!",
          });
          // return alert("");
        } else if (editableYear == null && editableInterestRate == null) {
          setShowMsg({
            status: false,
            error: true,
            msg: "Required all the fields",
          });
        } else {
          setOpen(false);
          setFlagDilogBoxForSCOpen(true);
          setFlagDilogBoxForSC(true);
        }
      }
    } else {
      setShowMsg({
        status: false,
        error: true,
        msg: "Required all the fields",
      });
      // alert("Required all the field ");
    }
  };
  // else if (editableYear !== "" && editableInterestRate !== "") {
  //   if (flagAdd === 2) {
  //     if (
  //       yearListRes.filter((t) => t.year_list_name == editableYear).length > 0
  //     ) {
  //       setTempAlert(true);
  //       return alert("Year already exists!");
  //     } else {
  //       setFlagDilogBoxForSC(true);
  //     }
  //   } else {
  //     alert("please fill all the feilds");
  //   }
  useEffect(() => {
    // SetFlagLoader(true);
    StateAPI(
      (res) => {
        if (res.data.status === true) {
          console.log("State list", res.data.data);
          console.log("$444444444444444444444", res.data.data);
          setStateList(res.data.data);
          //setStateId(res.data.data.state_id)
        } else {
          // SetFlagLoader(false);
          console.log("res.data.status false");
        }
      },
      (err) => {
        console.log("err", err);
      }
    );

    YearListAPI((res) => {
      console.log("123455678900123456576879879", res.data.data);
      if (res.data.status == true) {
        console.log("$444444444444444444444", res.data.data);

        setYearListRes(res.data.data);
      }
    });
  }, [editStateRes, editYearRes]);

  useEffect(() => {
    if (yearListRes.length > 1) {
      ReturnYersList();
      // YearListAPI()
    }
  }, [yearListRes]);

  useEffect(() => {
    console.log("99999999999999999999999999", yearsListResponseFinal);
    // debugger;
  }, [yearsListResponseFinal]);

  const ReturnYersList = () => {
    console.log(" 44444444444444444444444444", yearListRes);
    let temp = [...yearListRes],
      tempFinal = [];

    let temp1 = [];
    for (let i = 0; i < temp?.length; i++) {
      console.log(
        "Pritning temp[temp.length-1].surrender_charge_data",
        temp[i]
      );
      let t1 = temp[i]?.surrender_charge?.length;
      temp1.push(t1);
    }
    let c1 = Math.max(...temp1);
    console.log("Pritning temp[temp.length-1].surrender_charge_data", c1);

    let count = c1;
    for (let i = 0; i < count; i++) {
      tempFinal.push([{ index: i + 1 }]);
    }
    for (let i = 0; i < count; i++) {
      for (let j = 0; j < temp.length; j++) {
        if (temp[j].surrender_charge?.length > i) {
          tempFinal[i].push({ id: temp[j].surrender_charge[i] });
        } else {
          tempFinal[i].push({ id: {} });
        }
      }
      console.log("Printing temp final", tempFinal);
    }
    setYearsListResponseFinal(tempFinal);
  };
  const decimalNumber = (number) => {
    return Number.isInteger(number) ? number : Number(number.toFixed(2));
  };

  return (
    <>
      {deleteMsg !== "" && (
        <Snackbar
          open={tempSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => {
            setTempSnackbarOpen(false);
          }}
        >
          <Alert
            onClose={() => {
              setTempSnackbarOpen(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            {deleteMsg}
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <span
            style={{
              justifyContent: "end",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen(false);
              setShowMsg({
                status: false,
                error: false,
                msg: "",
              });
            }}
          >
            X
          </span>
          <DialogContentText id="alert-dialog-description">
            {flagAdd === 1 ? (
              <>
                <h5
                  style={{ color: "#D9A22E", margin: "-20px auto 40px auto" }}
                >
                  {" "}
                  Add State
                </h5>

                <Input
                  className="filled-basic"
                  label="Filled"
                  variant="standard"
                  placeholder="Enter State Name"
                  onChange={(e) => {
                    // console.log("44444",e.target.value)
                    setEditableState(e.target.value);
                  }}
                />
              </>
            ) : flagAdd === 2 ? (
              <>
                <h5 style={{ color: "#D9A22E", margin: "-20px auto 0px auto" }}>
                  Add Period
                </h5>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    MinHeight: "min-content",
                  }}
                >
                  {ShowMsg.error && (
                    <Alert className="errAdmin" severity="error">
                      {ShowMsg.msg}
                    </Alert>
                  )}
                  {ShowMsg.status && (
                    <Alert className="msgAdmin" severity="success">
                      {ShowMsg.msg}
                    </Alert>
                  )}
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Input
                    id="filled-basic"
                    required
                    // label="Filled"
                    variant="Standard"
                    type="number"
                    placeholder="Enter your Year"
                    sx={{ margin: "25px 5px" }}
                    onChange={(e) => {
                      // console.log("44444",e.target.value)
                      setEditableYear(e.target.value);
                      setShowMsg({
                        status: false,
                        error: false,
                        msg: "",
                      });
                      // : null;
                    }}
                  />

                  <Input
                    id="filled-basic"
                    required
                    type="number"
                    label="Standard"
                    variant="standard"
                    placeholder="Enter your Interest Rate"
                    sx={{ margin: "5px" }}
                    onChange={(e) => {
                      // console.log("44444",e.target.value)
                      setEditableInterestRate(e.target.value);
                      setShowMsg({
                        status: false,
                        error: false,
                        msg: "",
                      });
                    }}
                  />
                </Box>
              </>
            ) : flagDelete === 1 || flagDelete === 2 ? (
              <h5>Are you sure you want to delete it?</h5>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={saveState}
            sx={{
              display: flagAdd !== 0 && flagDelete === 0 ? "inline" : "none",
              backgroundColor: "#D9A22E",
            }}
          >
            Save
          </Button>
          <Button
            sx={{
              display:
                (flagDelete === 1 || flagDelete === 2) &&
                  flagAdd !== 1 &&
                  flagAdd !== 2
                  ? "inline"
                  : "none",
              backgroundColor: "#D9A22E",
            }}
            onClick={() => {
              deletestatefunction();
            }}
          // onClick={setDeleteState(true)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {flagDilogBoxForSC === true && (
        <Dialog
          open={flagDilogBoxForSCOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="popup">
            <Box className="popup_box">
              <h3 style={{ color: "#D9A22E" }}>Add Surrender Charge</h3>
              {ShowMsg.error && (
                <Alert className="errAdmin" severity="error">
                  {ShowMsg.msg}
                </Alert>
              )}
              {ShowMsg.status && (
                <Alert className="msgAdmin" severity="success">
                  {ShowMsg.msg}
                </Alert>
              )}
              <Grid
                container
                className="item grid_item3 box_height"
                justifyContent="space-evenly"
                md={12}
                sm={10}
                xs={8}
              >
                <Grid item xs={3} alignItems="center" textAlign="center">
                  Index
                </Grid>
                <Grid
                  item
                  className="itemtextcenter"
                  xs={9}
                  sx={{ paddingRight: "2px" }}
                >
                  Surrender Charge value
                </Grid>
              </Grid>

              {tempArray.map((index, i) => {
                // debugger;
                console.log("IIIIIIIIIIII", tempArray, index);
                return (
                  <Grid
                    container
                    className="item grid_item box_height"
                    justifyContent="space-evenly"
                    md={12}
                    sm={10}
                    xs={8}
                  >
                    <Grid item className="itemtextcenter" xs={3}>
                      {i + 1}
                    </Grid>
                    <Grid item className="itemtextcenter" xs={9}>
                      <Input
                        required={true}
                        label="Standard"
                        variant="standard"
                        key={index}
                        sx={{ width: "150px", height: "11px" }}
                        onChange={(e) => {
                          onchangeInput(e, index);
                          // console.log("22222222222222222222",e.target.value, i);
                          // setSurrenderChargeArray(e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                  //setSurrenderChargeArray(tempArray2)
                );
              })}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={savePeriod}
            // sx={{display: flagAdd === 1 || flagAdd ===2 ?"block":null}}
            >
              Save
            </Button>

            <Button
              onClick={() => {
                setFlagDilogBoxForSCOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Box className="page_title_annuity_application page_title">
        <Typography variant="span">Calculator Config</Typography>
      </Box>
      <Box className="btn_div1_admin">
        {/* <Link to="/history"> */}
        <Button
          variant="contained"
          onClick={() => {
            navigate("/history");
          }}
        >
          Back
        </Button>
        {/* </Link> */}
      </Box>
      <Box className="main_container">
        {/* <Box className="first_div">Calculator Config</Box> */}
        <Box className="main_box" sx={{ marginTop: "20px" }}>
          <Box className="first_name" sx={{ Border: "2px solid #D9A22E" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              md={12}
              sm={12}
              xs={12}
              sx={{ alignItems: "baseline" }}
            >
              <Grid
                item
                md={5}
                sm={12}
                className="state_grid"
                sx={{ padding: "20px" }}
              >
                <Box
                  className="state_box"
                  style={{
                    border: "2px solid #D9A22E",
                    padding: "50px",
                    height: "558px",
                  }}
                >
                  <Box className="StateName">State</Box>
                  <Box
                    style={{
                      overflowX: "hidden",
                      overflowY: "scroll",
                      whiteSpace: "nowrap",
                      height: "400px",
                      WebkitScrollSnapTypeWidth: "4px",
                      WebkitScrollSnapTypeThumbBackground: "#D9A22E !important",
                    }}
                  >
                    <Grid
                      container
                      className="item grid_item3 box_height state "
                      justifyContent="space-evenly"
                      md={12}
                      sm={12}
                    // sx={{ position: "sticky", top: "0" }}
                    >
                      <Grid item className="itemtextcenter grid_height" xs={2}>
                        State
                      </Grid>
                      <Grid item className="itemtextcenter grid_height" xs={2}>
                        Action
                      </Grid>
                    </Grid>
                    {console.log("22222222222222222222222", stateList)}
                    {stateList.map((index, i) => {
                      // tempStateId = index.state_id
                      //  console.log("tempStateIdbbbbbbbbbbbbbbbbbbbbbb",temp);
                      return (
                        <>
                          <Grid
                            container
                            className="item grid_item box_height"
                            justifyContent="space-evenly"
                            md={12}
                          >
                            <Grid item className="itemtextcenter" xs={2}>
                              {index.state_name}
                            </Grid>
                            <Grid item className="itemtextcenter" xs={2}>
                              <DeleteIcon
                                className="delete"
                                onClick={(id) => {
                                  setTempStateId(index.state_id);
                                  //temp = index.state_id
                                  // console.log("tempStateId",tempStateId);
                                  setFlagDelete(1);
                                  setFlagAdd(0);
                                  setOpen(true);
                                  //
                                  //  deletestatefunction(index.state_id)

                                  // deleteStateData(tempStateId)
                                }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                  </Box>
                  <Button
                    variant="text"
                    sx={{ color: "#D9A22E" }}
                    onClick={handleClickState}
                  >
                    New state
                  </Button>
                </Box>
              </Grid>

              <Grid className="period_main_grid" item md={5}>
                <Box
                  style={{
                    border: "2px solid #D9A22E",
                    padding: "50px",
                    height: "558px",
                    margin: "5px",
                  }}
                >
                  <Box className="YearName">Period</Box>
                  <div className="YearNameDiv">
                    <Grid
                      container
                      className="item grid_item3 box_height"
                      justifyContent="space-evenly"
                    // sx={{ position: "sticky", top: "0" }}
                    >
                      <Grid item className="itemtextcenter grid_height" xs={2}>
                        Year
                      </Grid>
                      <Grid item className="itemtextcenter grid_height" xs={6}>
                        Interest Rate
                      </Grid>
                      <Grid item className="itemtextcenter grid_height" xs={2}>
                        Action
                      </Grid>
                    </Grid>

                    {yearListRes.map((index, i) => {
                      console.log("22222222222222", index, i);
                      return (
                        <>
                          <Grid
                            container
                            className="item grid_item box_height"
                            justifyContent="space-evenly"
                          >
                            <Grid
                              item
                              className="itemtextcenter grid_height"
                              xs={2}
                            >
                              {index.year_list_name} Year
                            </Grid>

                            <Grid
                              item
                              className="itemtextcenter grid_height"
                              xs={6}
                              contentEditable={true}
                              onBlur={(e) => {
                                let string = e.target.innerHTML;
                                string = string.replace(/%/gi, "");
                                SetFlagLoader(true);
                                PutInterestRateApi(
                                  {
                                    year_list_id: index.year_list_id,
                                    interest_rate: Number(string / 100),
                                  },
                                  (res) => {
                                    SetFlagLoader(false);
                                    setYearListRes(res.data.data);
                                    setShowMsg({
                                      status: false,
                                      error: false,
                                      msg: "",
                                    });
                                  },
                                  (err) => {
                                    // console.log("errr",err);
                                    SetFlagLoader(false);
                                  }
                                );
                                e.target.innerHTML =
                                  Number(string).toFixed(2) + "%";
                              }}
                              onKeyDown={(e) => {
                                console.log(e.key);
                              }}
                            >
                              {Number(index.interest_rate * 100).toFixed(2)}%
                            </Grid>
                            <Grid item className="itemtextcenter" xs={2}>
                              <DeleteIcon
                                className="delete"
                                onClick={() => {
                                  setDeletedYearListId(index.year_list_id);
                                  setFlagDelete(2);
                                  setFlagAdd(0);
                                  setOpen(true);
                                }}
                              ></DeleteIcon>
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                  </div>
                  <Button
                    variant="text"
                    sx={{ color: "#D9A22E" }}
                    onClick={handleClickYear}
                  >
                    New Period
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="con_main_box">
          <Grid item xs={12} sm={12} md={12} style={{ height: "auto" }}>
            <Box
              className="con_main_box_first_box"
              sx={{
                margin: "10px 70px",
                border: " 2px solid #D9A22E",
                padding: " 30px 0",
              }}
            >
              <Box className="YearName">Surrender Charge</Box>
              <Grid
                className="con_main_box_first_grid"
                container
                xs={12}
                md={12}
                sm={12}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  className="item grid_item3 box_height"
                  xs={11}
                  sm={11}
                  md={11}
                >
                  <Grid container justifyContent="space-evenly">
                    <Grid item className="itemtextcenter grid_height" xs={1}>
                      Year
                    </Grid>
                    {yearListRes.map((i, ind) => {
                      console.log("?????????", i, ind);
                      {
                        console.log("@@@@@@@@@@@@@@@@@@@", i.year_list_name);
                      }
                      return (
                        <>
                          <Grid
                            item
                            className="itemtextcenter grid_height"
                            xs={1}
                          >
                            {i.year_list_name} Year
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>

                {yearsListResponseFinal.length > 0 &&
                  yearsListResponseFinal.map((obj, i) => {
                    return (
                      <Fragment>
                        <Grid
                          item
                          className="item grid_item box_height"
                          xs={11}
                          sm={11}
                          md={11}
                          key={i}
                        >
                          <Grid container justifyContent="space-evenly">
                            {obj.map((val, i) => {
                              return (
                                <Grid
                                  item
                                  className="itemtextcenter "
                                  xs={1}
                                  onClick={() => { }}
                                >
                                  <p
                                    className="grid_height"
                                    contentEditable={
                                      val?.index ||
                                        val?.id?.surrender_charge_id === undefined
                                        ? false
                                        : true
                                    }
                                    onBlur={(e) => {
                                      console.log(
                                        "@@@@@@@@@@@@@@@@@@@@@@@@@",
                                        yearsListResponseFinal,
                                        val?.id?.surrender_charge_id
                                      );
                                      let tempobj = obj;
                                      let str = e.target.innerHTML;
                                      str = str.replace(/%/gi, "");
                                      tempobj[i] = Number(str);
                                      SetFlagLoader(true);
                                      PutSurrenderChargeApi(
                                        {
                                          surrender_charge_data: tempobj[i],
                                          surrender_charge_id:
                                            val?.id?.surrender_charge_id,
                                        },
                                        (res) => {
                                          SetFlagLoader(false);
                                          setYearListRes(res.data.data);
                                          setShowMsg({
                                            status: false,
                                            error: false,
                                            msg: "",
                                          });
                                        },
                                        (err) => {
                                          SetFlagLoader(false);
                                        }
                                      );

                                      e.target.innerHTML =
                                        Number(tempobj[i] * 100) + "%";
                                    }}
                                    onKeyDown={(e) => {
                                      console.log(e.key);
                                    }}
                                  >
                                    {/* {val?.index
                                      ? val.index
                                      : (val?.id?.surrender_charge_data * 100)
                                      ? (val?.id?.surrender_charge_data * 100) +
                                        "%"
                                      : ""} */}
                                    {val?.index
                                      ? val.index
                                      : decimalNumber(
                                        val?.id?.surrender_charge_data * 100
                                      )
                                        ? decimalNumber(
                                          val?.id?.surrender_charge_data * 100
                                        ) + "%"
                                        : ""}
                                  </p>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Fragment>
                    );
                  })}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Admin1;
