import React, { Fragment, useContext } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from "@mui/material";
import { Context as ErrorValidationContext } from "../../../context/V1/errorValidationContext";
import CloseIcon from '@mui/icons-material/Close';
import "./errorValidationSubmitdialog.css";

const ErrorValidationSubmitDialog = (props) => {
  const { SetPageIndex, SetApplicationFormFlagSectionData } = useContext(
    ErrorValidationContext
  );

  return (
    <Fragment>
      <Dialog
        open={true}
        onClose={(e, reason) => {
          if (reason === "backdropClick") {
            return;
          }
          props.closeDialog();
        }}
        style={{ overflowX: "hidden" }}
        // disableEscapeKeyDown={true}
        maxWidth={'lg'}
      >
        <DialogContent>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12}>
              <Grid container justifyContent={"center"}>
                <Grid item xs={11.5}>
                  <Grid container justifyContent={"center"}>
                    <Grid item xs={10} sx={{ color: "#D9A22E" }} mb={2}>
                      Below Fields are mandatory to fill / validation
                    </Grid>
                    <Grid item xs={10} sx={{ color: "black" }}>
                      Oops! It looks like you forgot to fill in some required fields. Please make sure to complete the following
                      mandatory fields before submitting the form
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={0.5}>
                  <CloseIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      props.closeDialog();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <TableContainer className="errorValidationTableContainer" style={{ minHeight: "300px", maxHeight: "300px" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="validationPopupV1HeaderRow">
                      <TableCell sx={{ borderRadius: "10px 0px 0px 0px" }}>Application Form</TableCell>
                      <TableCell>Section</TableCell>
                      <TableCell>Error Type</TableCell>
                      <TableCell>Field Name</TableCell>
                      <TableCell sx={{ borderRadius: "0px 10px 0px 0px" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.tableData.map((obj) => {
                      return (
                        <TableRow
                          className="validationPopupV1BodyRow"
                        >
                          <TableCell component={"td"}>{obj?.page}</TableCell>
                          <TableCell component={"td"}>{obj?.section}</TableCell>
                          <TableCell component={"td"}>{obj?.type}</TableCell>
                          <TableCell component={"td"}>{obj?.field}</TableCell>
                          <TableCell component={"td"}>
                            <Button
                              onClick={() => {
                                if (obj.page === "Individual Annuity Application") {
                                  SetPageIndex(1);

                                  SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageFirstSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageSecondSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageThirdSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageFourthSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageFifthSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageSixthSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageSeventhSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageEighthSection",
                                    data: false,
                                  });
                                  switch (obj.section) {
                                    case "1": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Individual Annuity Application",
                                        section: "flagFirstPageFirstSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "2": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Individual Annuity Application",
                                        section: "flagFirstPageSecondSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "3": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Individual Annuity Application",
                                        section: "flagFirstPageThirdSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "4": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Individual Annuity Application",
                                        section: "flagFirstPageFourthSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "5": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Individual Annuity Application",
                                        section: "flagFirstPageFifthSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "6": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Individual Annuity Application",
                                        section: "flagFirstPageSixthSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "7": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Individual Annuity Application",
                                        section: "flagFirstPageSeventhSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "8": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Individual Annuity Application",
                                        section: "flagFirstPageEighthSection",
                                        data: true,
                                      });
                                      break;
                                    }
                                  }
                                  props.closeDialog();
                                } else if (obj.page === "Suitability Analysis") {
                                  SetPageIndex(3);

                                  SetApplicationFormFlagSectionData({
                                    type: "Suitability Analysis",
                                    section: "flagSecondPageFirstSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Suitability Analysis",
                                    section: "flagSecondPageSecondSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Suitability Analysis",
                                    section: "flagSecondPageThirdSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Suitability Analysis",
                                    section: "flagSecondPageFourthSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Suitability Analysis",
                                    section: "flagSecondPageFifthSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Suitability Analysis",
                                    section: "flagSecondPageSixthSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Suitability Analysis",
                                    section: "flagSecondPageSeventhSection",
                                    data: false,
                                  });

                                  switch (obj.section) {
                                    case "1": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Suitability Analysis",
                                        section: "flagSecondPageFirstSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "2": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Suitability Analysis",
                                        section: "flagSecondPageSecondSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "3": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Suitability Analysis",
                                        section: "flagSecondPageThirdSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "4": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Suitability Analysis",
                                        section: "flagSecondPageFourthSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "5": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Suitability Analysis",
                                        section: "flagSecondPageFifthSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "6": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Suitability Analysis",
                                        section: "flagSecondPageSixthSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "7": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Suitability Analysis",
                                        section: "flagSecondPageSeventhSection",
                                        data: true,
                                      });
                                      break;
                                    }
                                  }

                                  props.closeDialog();
                                } else if (obj.page === "Notice of Replacement") {
                                  SetPageIndex(2);

                                  SetApplicationFormFlagSectionData({
                                    type: "Notice of Replacement",
                                    section: "flagReplacementPageFirstSection",
                                    data: false,
                                  });

                                  SetApplicationFormFlagSectionData({
                                    type: "Notice of Replacement",
                                    section: "flagReplacementPageSecondSection",
                                    data: false,
                                  });

                                  switch (obj.section) {
                                    case "1": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Notice of Replacement",
                                        section: "flagReplacementPageFirstSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "2": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Notice of Replacement",
                                        section: "flagReplacementPageSecondSection",
                                        data: true,
                                      });
                                      break;
                                    }
                                  }
                                  props.closeDialog();
                                } else if (
                                  obj.page === "Notice of Replacement For Oklahoma"
                                ) {
                                  SetPageIndex(5);

                                  SetApplicationFormFlagSectionData({
                                    type: "Notice of Replacement For Oklahoma",
                                    section: "flagReplacementPageFirstSection",
                                    data: true,
                                  });
                                  props.closeDialog();
                                } else if (
                                  obj.page === "Transfer or Exchange Request"
                                ) {
                                  SetPageIndex(4);

                                  SetApplicationFormFlagSectionData({
                                    type: "Transfer or Exchange Request",
                                    section: "flagFourthPageFirstSection",
                                    data: false,
                                  });
                                  SetApplicationFormFlagSectionData({
                                    type: "Transfer or Exchange Request",
                                    section: "flagFourthPageSecondSection",
                                    data: false,
                                  });
                                  switch (obj.section) {
                                    case "1": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Transfer or Exchange Request",
                                        section: "flagFourthPageFirstSection",
                                        data: true,
                                      });
                                      break;
                                    }

                                    case "2": {
                                      SetApplicationFormFlagSectionData({
                                        type: "Transfer or Exchange Request",
                                        section: "flagFourthPageSecondSection",
                                        data: true,
                                      });
                                      break;
                                    }
                                  }
                                  props.closeDialog();
                                } else if (
                                  obj.page === "Annuity Disclosure"
                                ) {
                                  SetPageIndex(6);
                                  SetApplicationFormFlagSectionData({
                                    type: "Annuity Disclosure",
                                    section: "flagFirstPageFirstSection",
                                    data: false,
                                  });
                                  props.closeDialog();
                                }
                              }}
                              style={{
                                color: "#fff",
                                textTransform: "capitalize",
                                margin: "0px",
                                whiteSpace: "nowrap"
                              }}
                            >
                              Jump to this Field
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <p style={{ fontSize: "15px" }}>Note: Once you've filled in these fields, you can submit the form successfully</p>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ErrorValidationSubmitDialog;
