import React, { Fragment, useContext, useState } from "react";
import { Typography, Box, Alert } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import "./register.css";
import { RegisterUserAPI } from "../../api/userAPI";
import { Context } from "../../context/userLoaderContext";
import SuccessDdialog from "../../components/successDialog/successDialog";
import MuiPhoneNumber from "material-ui-phone-number";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const Register = () => {
  const { SetFlagLoader } = useContext(Context);
  const [flagSuccessDialog, setFlagSuccessDialog] = useState(false);

  const [userDetail, setUserDetail] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    website: "",
    nationalProducerNumber: "",
  });
  const [ShowMsg, SetshowMsg] = useState({
    status: false,
    error: false,
    msg: "",
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  }

  const RegisterUser = () => {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      phone,
      website,
      nationalProducerNumber,
    } = userDetail;
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      phone === "" ||
      nationalProducerNumber === ""
    ) {
      // alert("Please fill the required fields");
      SetshowMsg({
        status: false,
        error: true,
        msg: "Please fill the required fields",
      });
    } else {
      if (password === confirmPassword) {
        SetFlagLoader(true);
        RegisterUserAPI(
          {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: GetPhoneNumberPayloadFormate(phone),
            website: website,
            password: password,
            national_producer_number: nationalProducerNumber,
          },
          (res) => {
            SetFlagLoader(false);
            console.log("Printing response of register user api", res);

            if (res.data.status === true) {
              console.log("success");
              let domain = window.location.host;
              let protocol = window.location.protocol;
              setFlagSuccessDialog(true);
              // window.location.replace(`${protocol}//${domain}/login`);
            } else {
              if (res.data.error.message == "User already exists") {
                console.log("user is already registered.");
                let domain = window.location.host;
                let protocol = window.location.protocol;
                window.location.replace(`${protocol}//${domain}/login`);
              }
            }
          },
          (err) => {
            SetFlagLoader(false);
            console.log("got error for register user api", err);
            // alert("Something went wrong, Please try again later.!");
            SetshowMsg({
              status: false,
              error: true,
              msg: "Something went wrong, Please try again later.!",
            });
          }
        );
      } else {
        // alert("Password and Confirm Password are not same.");
        SetshowMsg({
          status: false,
          error: true,
          msg: "Password and Confirm Password are not same.",
        });
      }
    }
  };

  const GetPhoneNumberPayloadFormate = (phoneNumber) => {
    let cleanNumber = phoneNumber.replace(/[^\d+]/g, '');
    if (cleanNumber.startsWith('+')) {
      cleanNumber = cleanNumber.substring(1);
    }

    let countryCodeEndIndex = 1;
    if (cleanNumber.length > 4) {
      if (!isNaN(cleanNumber[1]) && cleanNumber.length > 2) {
        countryCodeEndIndex = 2;
      }
      if (!isNaN(cleanNumber[2]) && cleanNumber.length > 3) {
        countryCodeEndIndex = 3;
      }
    }

    const countryCode = cleanNumber.substring(0, countryCodeEndIndex);
    const restOfTheNumber = cleanNumber.substring(countryCodeEndIndex);
    return `${countryCode}-${restOfTheNumber}`;
  }

  return (
    <Fragment>
      {flagSuccessDialog === true && (
        <SuccessDdialog
          firstText="User Account is Registered Successfully!"
          secondText=""
          redirect="/login"
          closeSuccessDialog={() => {
            setFlagSuccessDialog(false);
            let domain = window.location.host;
            let protocol = window.location.protocol;
            window.location.replace(`${protocol}//${domain}/login`);
          }}
        />
      )}
      <Box className="register_page_root">
        <Box className="page_title_register page_title">
          <Typography variant="span">Register</Typography>
        </Box>

        <Box className="register_form">
          <Box className="form_card">
            <Box style={{ width: "100%", marginBottom: "20px" }}>
              {ShowMsg.error && (
                <Alert className="err" severity="error">
                  {ShowMsg.msg}
                </Alert>
              )}
              {ShowMsg.status && (
                <Alert className="msg" severity="success">
                  {ShowMsg.msg}
                </Alert>
              )}
            </Box>
            <Box
              className="first_name_root"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              <Box className="field_name">
                <Typography component="div">
                  First Name <span className="required_field">*</span>
                </Typography>
              </Box>
              <Box>
                <TextField
                  className="registerInput"
                  id="outlined-basic"
                  placeholder="Enter First Name"
                  value={userDetail.firstName}
                  onChange={(e) => {
                    setUserDetail({
                      ...userDetail,
                      firstName: e.target.value,
                    });
                  }}
                />
              </Box>
            </Box>
            <Box className="last_name_root">
              <Box
                className="first_name_root"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <Box className="field_name">
                  <Typography component="div">
                    Last Name <span className="required_field">*</span>
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    className="registerInput"
                    id="outlined-basic"
                    placeholder="Enter Last Name"
                    value={userDetail.lastName}
                    onChange={(e) => {
                      setUserDetail({
                        ...userDetail,
                        lastName: e.target.value,
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="email_root">
              <Box
                className="first_name_root"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <Box className="field_name">
                  <Typography component="div">
                    Email <span className="required_field">*</span>
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    className="registerInput"
                    id="outlined-basic"
                    placeholder="Enter Email"
                    type="email"
                    value={userDetail.email}
                    onChange={(e) => {
                      setUserDetail({
                        ...userDetail,
                        email: e.target.value,
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="password_root">
              <Box
                className="first_name_root"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <Box className="field_name">
                  <Typography component="div">
                    Password <span className="required_field">*</span>
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    className="registerInputPassword"
                    id="outlined-basic"
                    placeholder="Enter Password"
                    type={showPassword ? "text" : "password"}
                    value={userDetail.password}
                    onChange={(e) => {
                      setUserDetail({
                        ...userDetail,
                        password: e.target.value,
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          sx={{ alignItems: "baseline", cursor: "pointer" }}
                        >
                          {
                            showPassword ?
                              <VisibilityOff
                                fontSize="small"
                                sx={{ color: "#d9a22e", }}
                              />
                              :
                              <Visibility
                                fontSize="small"
                                sx={{ color: "#d9a22e" }}
                              />
                          }

                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="confirm_password_root">
              <Box
                className="first_name_root"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <Box className="field_name">
                  <Typography component="div">
                    Confirm Password <span className="required_field">*</span>
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    className="registerInputPassword"
                    id="outlined-basic"
                    placeholder="Enter Password Again"
                    type={showConfirmPassword ? "text" : "password"}
                    value={userDetail.confirmPassword}
                    onChange={(e) => {
                      setUserDetail({
                        ...userDetail,
                        confirmPassword: e.target.value,
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          sx={{ alignItems: "baseline", cursor: "pointer" }}
                        >
                          {
                            showConfirmPassword ?
                              <VisibilityOff
                                fontSize="small"
                                sx={{ color: "#d9a22e", }}
                              />
                              :
                              <Visibility
                                fontSize="small"
                                sx={{ color: "#d9a22e" }}
                              />
                          }
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="phone_root">
              <Box
                className="first_name_root"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <Box className="field_name">
                  <Typography component="div">Phone <span className="required_field">*</span></Typography>
                </Box>
                <Box>

                  <MuiPhoneNumber
                    variant="outlined"
                    name="phone"
                    placeholder="Enter Phone"
                    data-cy="user-phone"
                    sx={{
                      '& .MuiPhoneNumber-flagButton': {
                        pointerEvents: "none"
                      }
                    }}
                    defaultCountry={"us"}
                    onlyCountries={["us"]}
                    autoFormat={true}
                    countryCodeEditable={false}
                    value={userDetail.phone}
                    className="register_Phone"
                    onChange={(value) => {
                      setUserDetail({
                        ...userDetail,
                        phone: value,
                      });

                      // CommonContextUpdate("ownerPhone", value)
                    }}
                  // countryCodeEditable={false}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="website_root">
              <Box
                className="first_name_root"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <Box className="field_name">
                  <Typography component="div">Website</Typography>
                </Box>
                <Box>
                  <TextField
                    className="registerInput"
                    id="outlined-basic"
                    placeholder="Enter Website"
                    value={userDetail.website}
                    onChange={(e) => {
                      setUserDetail({
                        ...userDetail,
                        website: e.target.value,
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="website_root">
              <Box
                className="first_name_root"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <Box className="field_name">
                  <Typography component="div">
                    National Producer Number{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    className="registerInput"
                    id="outlined-basic"
                    placeholder="Enter National Producer Number"
                    value={userDetail.nationalProducerNumber}
                    onChange={(e) => {
                      setUserDetail({
                        ...userDetail,
                        nationalProducerNumber: e.target.value,
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="create_account_button">
              <Button
                onClick={RegisterUser}
                disableElevation
                variant="contained"
              >
                Create Account
              </Button>
            </Box>
            <Box className="login_button">
              <Typography component="div">
                Already have an account?{" "}
                <Link style={{ color: "#000" }} to="/login">
                  Log In
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Register;
