import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Input,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  NativeSelect,
  Divider,
  Autocomplete,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Context as UserContext } from "../../context/userLoaderContext";
import Farmers_img from "../../assets/FarmersLife_Homepage.png";
import "../product/calc_history.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { HistoryAPI, NewHistoryAPI } from "../../service/calculator_history.service";
import { AddAlertRounded } from "@mui/icons-material";
import { UserDetailAPI } from "../../api/userAPI";
import { bottomNavigationClasses } from "@material-ui/core";

const Calc_history = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([{}]);
  const [flagShowAgentName, setFlagShowAgentName] = useState(false);
  const { state, SetFlagLoader } = useContext(UserContext)

  console.log("state", state)
  const addCommas = (num) => {
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return num;
  };

  useEffect(() => {
    SetFlagLoader(true)
    if (state.userType === "Admin") {
      setFlagShowAgentName(true)
    }
    NewHistoryAPI((res) => {
      if (res.data.status === true) {
        let sortedData = res.data?.data.sort(function (a, b) {
          return (a.created_at_date) > (b.created_at_date) ? -1 : 1
        })
        setData(sortedData);
      }
      SetFlagLoader(false)
    }, (err) => {
      SetFlagLoader(false)
    });

  }, []);

  const viewButtonCalculationHistory = (id) => {
    navigate("/View_calculation?calculation_id=" + id);
  };
  return (
    <>
      <Box className="img_div">
        <img src={Farmers_img} className="img" alt="img" />
        <span class="img_text1">Farmers Safeguard Plus®</span>
        <span class="img_text2">Multi-Year Guaranteed Annuity (MYGA)</span>
      </Box>
      <Box className="btn_div1_history">
        {flagShowAgentName === true ? (
          <>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/calculator-config");
              }}
            >
              Calculator Config
            </Button>
          </>
        ) : null}
        <Button
          variant="contained"
          onClick={() => {
            navigate("/Product-Illustration");
          }}
        >
          New Calculation
        </Button>
      </Box>
      <Box className="main_container_history">
        <Box className="first_div page_title">Calculated Data History</Box>

        <Box className="Grid_box">
          <TableContainer sx={{ maxHeight: 710 }}>
            <Table stickyHeader className="calc_history_tbl">
              <TableHead>
                <TableRow className="calc_history_tbl_header">
                  <TableCell align="left" className="header_radius_left">Date</TableCell>
                  <TableCell align="left">Client Name</TableCell>
                  <TableCell align="left">State</TableCell>
                  <TableCell align="left">Amount</TableCell>
                  <TableCell align="left">Period</TableCell>
                  <TableCell align="left">Interest Rate</TableCell>
                  <TableCell align="left">Final Amount</TableCell>
                  {flagShowAgentName === true ? (
                    <TableCell align="left">Agent Name</TableCell>
                  ) : null}
                  <TableCell align="left" className="header_radius_right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length > 0 && data.map((rr, i) => (
                  <TableRow key={i} className="calc_history_tbl_row">
                    <TableCell align="left" className="header_radius_left">{moment(rr.created_at_date).format("MM/DD/YYYY")}</TableCell>
                    <TableCell align="left">{rr.name}</TableCell>
                    <TableCell align="left">{rr.state}</TableCell>
                    <TableCell align="left">${addCommas(rr.amount)}</TableCell>
                    <TableCell align="left">{rr.year}</TableCell>
                    <TableCell align="left">
                      {rr.enhanced_benefit_rider == true &&
                        rr.free_withdrawal_rider == true
                        ? ((rr.interest_rate - 0.002) * 100).toFixed(2) + "%"
                        : rr.enhanced_benefit_rider == true ||
                          rr.free_withdrawal_rider == true
                          ? ((rr.interest_rate - 0.001) * 100).toFixed(2) + "%"
                          : (rr.interest_rate * 100).toFixed(2) + "%"
                      }
                    </TableCell>
                    <TableCell align="left">${addCommas(rr.final_amount)}</TableCell>
                    {flagShowAgentName === true ? (
                      <TableCell align="left">{addCommas(rr.agent_name)}</TableCell>
                    ) : null}
                    <TableCell align="left" className="header_radius_right">
                      <Button
                        className="view_btn"
                        onClick={() => {
                          // navigate(
                          //   "/view-calculator?calculation_id=" +
                          //     rr.calculation_id
                          // );

                          viewButtonCalculationHistory(rr.calculation_id);
                        }}
                      >
                        View
                      </Button>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default Calc_history;
