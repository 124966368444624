import React, { useContext, useEffect, useState } from "react";
import "./formSiteMap.css";
import URLToSendApplicantDialog from "../../urlToSendApplicantDialog/urlToSendApplicantDialog";
import GeneratePublicURLAPI from "../../../api/generatePublicURL";
import { Context } from "../../../context/userLoaderContext";
import { Context as ApplicationFormContext } from "../../../context/V1/newApplicationFormContext";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { Context as ErrorValidationContext } from "../../../context/V1/errorValidationContext";

const FormSiteMap = (props) => {
  const [flagUrlToSendApplicantDialog, setFlagUrlToSendApplicantDialog] =
    useState(false);
  const { SetFlagLoader } = useContext(Context);
  const [uniqueURLId, setUniqueURLId] = useState("");
  const [stopInterval, setStopInterval] = useState(true);
  const [replacementFormName, setReplacementFormName] = useState(null);

  const {
    individualAnnuityApplication,
    suitabilityAnalysis,
    transferOrExchangeRequest,
    noticeOfReplacement,
    // bankDetails,
    applicationId,
    annuityDisclosureForm,
  } = useContext(ApplicationFormContext).state;

  const [annuitantSectionColor, setAnnuitantSectionColor] = useState();
  const [ownerSectionColor, setOwnerSectionColor] = useState();
  const [jointOwnerSectionColor, setJointOwnerSectionColor] = useState();
  const [planRiderSectionColor, setPlanRiderSectionColor] = useState();
  const [beneficiariesSectionColor, setBeneficiariesSectionColor] = useState();
  const [
    premimumTaxQualificationSectionColor,
    setPremiumTaxQualificationSectionColor,
  ] = useState();
  const [ownerReplacementSectionColor, setOwnerReplacementSectionColor] =
    useState(0);
  const [safeguardPlusAnnuityPeriod, setSafeguardPlusAnnuityPeriod] =
    useState();

  const [personalInformationColor, setPersonalInformationColor] = useState();
  const [financialInformationColor, setFinancialInformationColor] = useState();
  const [financialSituationColor, setFinancialSituationColor] = useState();
  const [productPurchaseColor, setProductPurchaseColor] = useState();
  const [existingAccountInformationColor, setExistingAccountInformationColor] =
    useState();
  const [otherSectionColor, setOtherSectionColor] = useState();
  const [agentAcknowledgmentColor, setAgentAcknowledgmentColor] = useState();

  const [basicInformationColor, setBasicInformationColor] = useState();
  const [plansSectionColor, setPlansSectionColor] = useState();
  const [witnessInformationColor, setWitnessInformationColor] = useState();

  const [importantNoticeColor, setImportantNoticeColor] = useState();
  const [brokerColor, setBrokerColor] = useState();

  // const [bankDetailsColor, setBankDetailsColor] = useState();

  const [annuityDisclosureColor, setAnnuityDisclosureColor] = useState();

  const { SetApplicationFormFlagSectionData, SetPageIndex } = useContext(
    ErrorValidationContext
  );

  let domain = window.location.host;
  let protocol = window.location.protocol;

  useEffect(() => {
    console.log("Application id", applicationId);
    // debugger;
    if (
      stopInterval === false &&
      applicationId !== null &&
      applicationId !== undefined &&
      applicationId !== ""
    ) {
      // debugger;
      setStopInterval(true);
      SetFlagLoader(true);
      GeneratePublicURLAPI(
        applicationId,
        (res) => {
          // debugger;
          SetFlagLoader(false);
          console.log("Printing response of generating public url", res);

          if (res.data.status === true) {
            console.log("Success Generate Public URL");
            setUniqueURLId(res.data.data.unique_id_public_access);
            setFlagUrlToSendApplicantDialog(true);
          }
        },
        (err) => {
          SetFlagLoader(false);
          console.log("Got error while generating public url", err);
        }
      );
    }
  }, [applicationId, stopInterval]);

  const GeneratePublicURL = async () => {
    let res = await props.saveAsDraft();
    console.log("Printing res", res);
    // debugger;
    setStopInterval(false);
  };

  const {
    annuitantFullName,
    annuitantSSN,
    annuitantGender,
    annuitantDOB,
    annuitantStreetAddress,
    annuitantZip,
    annuitantCity,
    annuitantState,
    annuitantPhone,
    annuitantEmail,
    ownerFullName,
    ownerSSN,
    ownerGender,
    ownerDOB,
    ownerStreetAddress,
    ownerZip,
    ownerCity,
    ownerState,
    ownerEmail,
    ownerRelationToAnnuitant,
    jointOwnerFullName,
    jointOwnerSSN,
    jointOwnerGender,
    jointOwnerDOB,
    jointOwnerStreetAddress,
    jointOwnerZip,
    jointOwnerCity,
    jointOwnerState,
    jointOwnerEmail,
    jointOwnerRelationToAnnuitant,
    planAppliedFor,
    beneficiariesPrimary,
    beneficiariesContigent,
    selectedNonQualifiedOptions,
    // selectedQualifiedOptions,
    selectedQualifiedOptionsGroup1,
    selectedQualifiedOptionsGroup2,
    otherSelectedQualifiedOptions,
    flagQualifiedOrNonQualifiedSelected,
    amountPaidWithApplication,
    expectedPremiumField,
    flagHaveExistingLifeInsurance,
    reasonOfApplicationToChangeLifeInsurance,
    applicationReplacementFieldsArray,
    flagSafeguardPlusAnnuityPeriod,
  } = individualAnnuityApplication;

  const {
    // ownerFullName,
    ownerCurrentAge,
    // ownerGender,
    ownerEmploymentStatus,
    ownerCurrentOccupation,
    ownerDependents,
    spouseFullName,
    spouseCurrentAge,
    spouseGender,
    spouseEmploymentStatus,
    spouseCurrentOccupation,
    spouseDependents,
    ownerAnnualHouseholdIncome,
    ownerSourceOfIncome,
    ownerIncomeTaxBracket,
    ownerTotalOutstandingDebt,
    ownerTotalAssets,
    ownerTotalLiquidAssets,
    flagOwnerIncomeCoverLivingAndMedicalExpenses,
    explainOwnerIncomeCoverLivingAndMedicalExpenses,
    flagOwnerExpectChangesInLivingExpenses,
    explainOwnerExpectChangesInLivingExpenses,
    flagOwnerAnticipateChangesInMedicalExpenses,
    explainOwnerAnticipateChangesInMedicalExpenses,
    flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
    explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
    flagOwnerHaveEmergencyFundForUnexpectedExpenses,
    explainOwnerHaveEmergencyFundForUnexpectedExpenses,
    ownerOtherInvestments,
    otherOwnerInvestmentField,
    ownerRiskTolerance,
    reasonForPurchasingProduct,
    otherRasonForPurchasingThisProduct,
    flagOwnerPlanToWithdrawFundInSurrenderPeriod,
    explainOwnerPlanToWithdrawFundInSurrenderPeriod,
    ownerAnticipateNeedingAccessToFunds,
    sourceOfFundsToPurchaseThisProduct,
    otherSourceOfFundsToPurchaseThisProduct,
    flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct,
    howLongHasTheLifeInsuranceBeenInForce,
    flagSurrenderChargeOnExistingProduct,
    currentSurrenderChargeOnExistingProduct,
    expirationOfCurrentSurrenderCharge,
    typeOfExistingProduct,
    interestRateForExistingProduct,
    flagTakingRequiredMinimumDistributionsFromExistingProduct,
    flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
    explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
    personsThatAssistedOwnerToPurchaseThisProduct,
    otherPersonsThatAssistedOwnerToPurchaseThisProduct,
    additionalRelaventInformationForSuitabilityAnalysis,
    agentAcknowledgment,
  } = suitabilityAnalysis;

  const {
    // ownerFullName,
    // ownerSSN,
    // ownerStreetAddress,
    // ownerZip,
    // ownerCity,
    // ownerState,
    annuitantOrInsured,
    currentInsuranceCompany,
    currentInsuranceCompanyPhone,
    currentInsuranceCompanyAccountNumber,
    currentInsuranceCompanyStreetAddress,
    currentInsuranceCompanyZip,
    currentInsuranceCompanyCity,
    currentInsuranceCompanyState,
    typeOfAccount,
    otherTypeOfAccount,
    ownerExistingAnnuity,
    plans,
    nonQualifiedTransferOrExchangeExistingAccountAllOrPartial,
    nonQualifiedTransferOrExchangeExistingAccountPartialAmount,
    nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate,
    nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField,
    nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy,
    nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField,
    flagNonQualifiedOwnerWantWithdrawalToExchange,
    explainNonQualifiedOwnerWantWithdrawalToExchange,
    flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal,
    qualifiedTransferOrExchangeExistingAccountAllOrPartial,
    qualifiedTransferOrExchangeExistingAccountPartialAmount,
    qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate,
    qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField,
    qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy,
    qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField,
    qualifiedTypeOfTransfer,
    qualifiedQualifyingEventForDirectRolloverFunds,
    flagQualifiedOwnerReachedAge72OrMoreInThisYear,
    flagQualifiedOwnerSatisfiedRMDFromDistributingPlan,
    qualifiedInternalExchangeOfExistingAnnuityName,
    flagQualifiedOwnerWantRMDBeforeCompletingExchange,
    flagQualifiedOwnerWantToContinueExistingRMDSchedule,
    witnessName,
    witnessEmail,
  } = transferOrExchangeRequest;

  // const {
  //   routingNumber,
  //   accountNumber,
  //   bankName,
  //   bankAddress
  // } = bankDetails;

  const {
    flagDiscontinuingPremiumPayment,
    flagPayForPremiumPolicy,
    insurerPolicyDetailFieldArray,
    reasonForReplacement,
    brokerName,
  } = noticeOfReplacement;

  const { flagAnnuityClosure } = annuityDisclosureForm;

  const ConditionToShowTransferExchangeForm = () => {
    if (1 === 1) {
      return true;
    } else {
      return false;
    }
  };

  const IndividualAnnuityApplicationSuccess = () => {
    if (
      annuitantSectionColor === 3 &&
      (ownerSectionColor === 1 || ownerSectionColor === 3) &&
      (jointOwnerSectionColor === 1 || jointOwnerSectionColor === 3) &&
      planRiderSectionColor === 3 &&
      (beneficiariesSectionColor === 1 || beneficiariesSectionColor === 3) &&
      premimumTaxQualificationSectionColor === 3 &&
      ownerReplacementSectionColor === 3
    ) {
      return true;
    } else {
      return false;
    }
  };

  // const [eftStyle, setEFTStyle] = useState({top:"850px"});
  const [annuityDisclosureStyle, setAnnuityDisclosureStyle] = useState({
    top: "920px",
  });

  useEffect(() => {
    // EFT removed and annuity disclosure is ony need to show
    if (
      ConditionToShowTransferExchangeForm() === false &&
      ConditionToShowReplacementForm() === false
    ) {
      if (searchParams.has("public_url_id") == true) {
        setAnnuityDisclosureStyle({
          top: "680px",
        });
      } else if (searchParams.has("public_url_id") === false) {
        if (ConditionToShowTransferExchangeForm() === true) {
          setAnnuityDisclosureStyle({
            top: "940px",
          });
        } else {
          setAnnuityDisclosureStyle({
            top: "760px",
          });
        }
      }
    } else if (ConditionToShowReplacementForm() === true) {
      if (
        flagHaveExistingLifeInsurance === "Yes" &&
        reasonOfApplicationToChangeLifeInsurance === "Yes"
      ) {
        // replacement and transfer fpr, will be shown
        setAnnuityDisclosureStyle({
          top: "1085px",
        });
      } else {
        // have to show the replacement form
        if (searchParams.has("public_url_id") === true) {
        } else {
          setAnnuityDisclosureStyle({
            top: "1085px",
          });
        }
      }
    } else if (
      ConditionToShowReplacementForm() === false &&
      flagHaveExistingLifeInsurance === "Yes" &&
      reasonOfApplicationToChangeLifeInsurance === "Yes"
    ) {
      //only transfer form will be shown
      setAnnuityDisclosureStyle({
        top: "940px",
      });

      // // have to show the replacement form
      // if(searchParams.has("public_url_id") === true){

      // }
      // else{
      //   setEFTStyle({
      //     top:"880px"
      //   })
      // }
    } else if (ConditionToShowTransferExchangeForm() === true) {
      setAnnuityDisclosureStyle({
        top: "940px",
      });
    }

  }, [
    annuitantState,
    flagHaveExistingLifeInsurance,
    reasonOfApplicationToChangeLifeInsurance,
    flagQualifiedOrNonQualifiedSelected,
    // selectedQualifiedOptions,
    selectedQualifiedOptionsGroup1,
    selectedQualifiedOptionsGroup2,
    selectedNonQualifiedOptions,
  ]);

  // useEffect(()=>{
  //   EFTConditionsForDesigning();
  // },[eftStyle])
  const SuitabilityAnalysisSuccess = () => {
    if (
      personalInformationColor === 3 &&
      financialInformationColor === 3 &&
      financialSituationColor === 3 &&
      productPurchaseColor === 3 &&
      existingAccountInformationColor === 3 &&
      otherSectionColor === 3 &&
      agentAcknowledgmentColor === 3
    ) {
      return true;
    } else {
      return false;
    }
  };

  const NoticeOfReplacementSuccess = () => {
    console.log(
      "Checking important notice color and broker color",
      importantNoticeColor,
      brokerColor
    );
    if (importantNoticeColor === 3 && brokerColor === 3) {
      return true;
    } else {
      return false;
    }
  };
  const NoticeOfReplacementOklahomaSuccess = () => {
    console.log(
      "Checking important notice color and broker color",
      importantNoticeColor,
      brokerColor
    );
    if (importantNoticeColor === 3 && brokerColor === 3) {
      return true;
    } else {
      return false;
    }
  };
  const TransferExchangeRequestSuccess = () => {
    if (basicInformationColor === 3 && plansSectionColor === 3) {
      return true;
    } else {
      return false;
    }
  };

  // const BankDetailsSuccess = ()=>{
  //   if(bankDetailsColor === 3){
  //     return true;
  //   }
  //   else{
  //     return false;
  //   }
  // }

  const AnnuityDisclosureSuccess = () => {
    console.log(
      "running function to check that annuity disclosure form is filled or not",
      annuityDisclosureColor
    );
    if (annuityDisclosureColor === 3) {
      return true;
    } else {
      return false;
    }
  };


  useEffect(() => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const ReplacementQuestionDataArrayValidation = () => {
      let temp = applicationReplacementFieldsArray;

      temp = applicationReplacementFieldsArray.filter((v) => {
        if (v.companyNameOfPolicyField === "" && v.policyNumberField === "") {
          return false;
        } else if (v.companyNameOfPolicyField !== "" && v.policyNumberField !== "") {
          return true;
        } else {
          return false
        }
      });

      if (temp.length > 0) {
        return true;
      } else {
        return false;
      }
    };


    console.log("============================")
    console.log("flagHaveExistingLifeInsurance", flagHaveExistingLifeInsurance)
    console.log("reasonOfApplicationToChangeLifeInsurance", reasonOfApplicationToChangeLifeInsurance)
    console.log("ReplacementQuestionDataArrayValidation()", ReplacementQuestionDataArrayValidation())

    // Annuitant Checking Start

    const annuitantDate = moment(annuitantDOB);

    if (
      annuitantFullName === "" ||
      annuitantSSN === "" ||
      annuitantGender === "" ||
      new Date(annuitantDOB).toDateString() === "Invalid Date" ||
      annuitantStreetAddress === "" ||
      annuitantZip === "" ||
      annuitantCity === "" ||
      annuitantState === "" ||
      annuitantPhone === "" ||
      re.test(annuitantEmail) === false
    ) {
      setAnnuitantSectionColor(0);
    } else if (
      annuitantFullName !== "" &&
      annuitantSSN !== "" &&
      annuitantGender !== "" &&
      annuitantDate.isValid() === true &&
      annuitantStreetAddress !== "" &&
      annuitantZip !== "" &&
      annuitantCity !== "" &&
      annuitantState !== "" &&
      annuitantPhone !== "" &&
      annuitantEmail !== ""
    ) {
      setAnnuitantSectionColor(3);
    }

    // Annuitant Checking End

    // Owner Checking Start

    const ownerDate = moment(ownerDOB);

    if (
      ownerFullName === "" &&
      ownerSSN === "" &&
      ownerGender === "" &&
      (ownerDOB === "" || ownerDOB === null) &&
      ownerStreetAddress === "" &&
      ownerZip === "" &&
      ownerCity === "" &&
      ownerState === "" &&
      ownerRelationToAnnuitant === "" &&
      ownerEmail === ""
    ) {
      setOwnerSectionColor(1);
    } else if (
      ownerFullName !== "" &&
      ownerSSN !== "" &&
      ownerGender !== "" &&
      new Date(ownerDOB).toDateString() !== "Invalid Date" &&
      ownerStreetAddress !== "" &&
      ownerZip !== "" &&
      ownerCity !== "" &&
      ownerState !== "" &&
      ownerRelationToAnnuitant !== "" &&
      ownerEmail !== ""
    ) {
      setOwnerSectionColor(3);
    } else if (
      ownerFullName === "" ||
      ownerSSN === "" ||
      ownerGender === "" ||
      new Date(ownerDOB).toDateString() === "Invalid Date" ||
      ownerStreetAddress === "" ||
      ownerZip === "" ||
      ownerCity === "" ||
      ownerState === "" ||
      ownerRelationToAnnuitant === "" ||
      re.test(ownerEmail) === false
    ) {
      setOwnerSectionColor(0);
    }

    // Owner Checking End

    // Joint Owner Checking Start

    const jointOwnerDate = moment(jointOwnerDOB);

    if (
      jointOwnerFullName === "" &&
      jointOwnerSSN === "" &&
      jointOwnerGender === "" &&
      (jointOwnerDOB === "" || jointOwnerDOB === null) &&
      jointOwnerStreetAddress === "" &&
      jointOwnerZip === "" &&
      jointOwnerCity === "" &&
      jointOwnerState === "" &&
      jointOwnerRelationToAnnuitant === "" &&
      jointOwnerEmail === ""
    ) {
      setJointOwnerSectionColor(1);
    } else if (
      jointOwnerFullName !== "" &&
      jointOwnerSSN !== "" &&
      jointOwnerGender !== "" &&
      new Date(jointOwnerDOB).toDateString() !== "Invalid Date" &&
      jointOwnerStreetAddress !== "" &&
      jointOwnerZip !== "" &&
      jointOwnerCity !== "" &&
      jointOwnerState !== "" &&
      jointOwnerRelationToAnnuitant !== "" &&
      jointOwnerEmail !== ""
    ) {
      setJointOwnerSectionColor(3);
    } else if (
      jointOwnerFullName === "" ||
      jointOwnerSSN === "" ||
      jointOwnerGender === "" ||
      new Date(ownerDOB).toDateString() === "Invalid Date" ||
      jointOwnerStreetAddress === "" ||
      jointOwnerZip === "" ||
      jointOwnerCity === "" ||
      jointOwnerState === "" ||
      jointOwnerRelationToAnnuitant === "" ||
      re.test(jointOwnerEmail === "")
    ) {
      setJointOwnerSectionColor(0);
    }

    // Joint Owner Checking End

    // Plan-Rider Applied For Checking Start
    console.log("Printing plan applied for in form site map", planAppliedFor);
    if (planAppliedFor.length < 1) {
      setPlanRiderSectionColor(0);
    } else {
      setPlanRiderSectionColor(3);
    }

    // Plan-Rider Applied For Checking End

    // Beneficiaries Checking Start

    let beneficiariesArray = beneficiariesPrimary.concat(
      beneficiariesContigent
    );
    let flagValuesEmpty = true;
    let totalSum = 0;

    for (let i = 0; i < beneficiariesArray.length; i++) {
      if (beneficiariesArray[i]?.primaryField !== undefined) {
        totalSum += beneficiariesArray[i].percentageField;
        if (
          beneficiariesArray[i].primaryField === "" &&
          beneficiariesArray[i].percentageField === "" &&
          beneficiariesArray[i].ssnField === "" &&
          beneficiariesArray[i].genderField === "" &&
          beneficiariesArray[i].relationshipField === ""
        ) {
          continue;
        } else {
          flagValuesEmpty = false;
          break;
        }
      } else {
        totalSum += beneficiariesArray[i].percentageField;
        if (
          beneficiariesArray[i].contigentField === "" &&
          beneficiariesArray[i].percentageField === "" &&
          beneficiariesArray[i].ssnField === "" &&
          beneficiariesArray[i].genderField === "" &&
          beneficiariesArray[i].relationshipField === ""
        ) {
          continue;
        } else {
          flagValuesEmpty = false;
          break;
        }
      }
    }

    if (flagValuesEmpty === true) {
      setBeneficiariesSectionColor(1);
    } else if (totalSum <= 200) {
      setBeneficiariesSectionColor(3);
    } else {
      setBeneficiariesSectionColor(0);
    }

    // Beneficiaries Checking End

    // Premium and Tax Qualification Status Checking Start

    if (expectedPremiumField !== "" || amountPaidWithApplication !== "") {
      setPremiumTaxQualificationSectionColor(3);
    } else {
      setPremiumTaxQualificationSectionColor(0);
    }


    // if (amountPaidWithApplication === "") {
    //   setPremiumTaxQualificationSectionColor(0);
    // } else if (
    //   amountPaidWithApplication !== "" &&
    //   (flagQualifiedOrNonQualifiedSelected === "" ||
    //     flagQualifiedOrNonQualifiedSelected !== "") &&
    //   !(
    //     selectedQualifiedOptionsGroup1 === "Transfer" ||
    //     selectedQualifiedOptionsGroup1 === "Rollover" ||
    //     selectedNonQualifiedOptions === "1035 exchange"
    //   )
    // ) {
    //   setPremiumTaxQualificationSectionColor(3);
    // } else if (
    //   amountPaidWithApplication !== "" &&
    //   flagQualifiedOrNonQualifiedSelected !== "" &&
    //   (selectedQualifiedOptionsGroup1 === "Transfer" ||
    //     selectedQualifiedOptionsGroup1 === "Rollover" ||
    //     selectedNonQualifiedOptions === "1035 exchange") &&
    //   expectedPremiumField === ""
    // ) {
    //   setPremiumTaxQualificationSectionColor(0);
    // } else if (
    //   amountPaidWithApplication !== "" &&
    //   flagQualifiedOrNonQualifiedSelected !== "" &&
    //   (selectedQualifiedOptionsGroup1 === "Transfer" ||
    //     selectedQualifiedOptionsGroup1 === "Rollover" ||
    //     selectedNonQualifiedOptions === "1035 exchange") &&
    //   expectedPremiumField !== ""
    // ) {
    //   setPremiumTaxQualificationSectionColor(3);
    // }

    // Premium and Tax Qualification Status Checking End

    // Owner Replacement Question Checking Start
    if (flagHaveExistingLifeInsurance !== "" && reasonOfApplicationToChangeLifeInsurance == "Yes") {
      if (ReplacementQuestionDataArrayValidation() === true) {
        setOwnerReplacementSectionColor(3);
      } else {
        setOwnerReplacementSectionColor(0);
      }
    } else if (flagHaveExistingLifeInsurance !== "" && reasonOfApplicationToChangeLifeInsurance == "No") {
      setOwnerReplacementSectionColor(3);
    } else {
      setOwnerReplacementSectionColor(0);
    }

    // Owner Replacement Question Checking End
  }, [individualAnnuityApplication]);

  useEffect(() => {
    // Owner Personal Information Checking Start

    if (
      suitabilityAnalysis.ownerFullName === "" ||
      ownerCurrentAge === "" ||
      suitabilityAnalysis.ownerGender === "" ||
      ownerEmploymentStatus === "" ||
      ownerCurrentOccupation === "" ||
      ownerDependents === ""
    ) {
      setPersonalInformationColor(0);
    } else if (
      (suitabilityAnalysis.ownerFullName !== "" ||
        ownerCurrentAge !== "" ||
        suitabilityAnalysis.ownerGender !== "" ||
        ownerEmploymentStatus !== "" ||
        ownerCurrentOccupation !== "" ||
        ownerDependents !== "") &&
      ((spouseFullName !== "" &&
        spouseCurrentAge !== "" &&
        spouseGender !== "" &&
        spouseCurrentOccupation !== "" &&
        spouseEmploymentStatus !== "" &&
        spouseDependents !== "") ||
        (spouseFullName === "" &&
          spouseCurrentAge === "" &&
          spouseGender === "" &&
          spouseCurrentOccupation === "" &&
          spouseEmploymentStatus === "" &&
          spouseDependents === ""))
    ) {
      setPersonalInformationColor(3);
    } else {
      setPersonalInformationColor(0);
    }

    // Owner Personal Information Checking End

    // Financial Information Checking Start

    if (
      ownerAnnualHouseholdIncome === "" ||
      ownerSourceOfIncome === "" ||
      ownerIncomeTaxBracket === "" ||
      ownerTotalOutstandingDebt === "" ||
      ownerTotalAssets === "" ||
      ownerTotalLiquidAssets === ""
    ) {
      setFinancialInformationColor(0);
    } else {
      setFinancialInformationColor(3);
    }

    // Financial Information Checking End

    // Financial Situation Checking Start

    if (flagOwnerIncomeCoverLivingAndMedicalExpenses === "") {
      setFinancialSituationColor(0);
    } else if (
      flagOwnerIncomeCoverLivingAndMedicalExpenses === "No" &&
      explainOwnerIncomeCoverLivingAndMedicalExpenses === ""
    ) {
      setFinancialSituationColor(0);
    } else if (flagOwnerExpectChangesInLivingExpenses === "") {
      setFinancialSituationColor(0);
    } else if (
      flagOwnerExpectChangesInLivingExpenses === "Yes" &&
      explainOwnerExpectChangesInLivingExpenses === ""
    ) {
      setFinancialSituationColor(0);
    } else if (flagOwnerAnticipateChangesInMedicalExpenses === "") {
      setFinancialSituationColor(0);
    } else if (
      flagOwnerAnticipateChangesInMedicalExpenses === "Yes" &&
      explainOwnerAnticipateChangesInMedicalExpenses === ""
    ) {
      setFinancialSituationColor(0);
    } else if (
      flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
      ""
    ) {
      setFinancialSituationColor(0);
    } else if (
      flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
      "No" &&
      explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
      ""
    ) {
      setFinancialSituationColor(0);
    } else if (flagOwnerHaveEmergencyFundForUnexpectedExpenses === "") {
      setFinancialSituationColor(0);
    } else if (
      flagOwnerHaveEmergencyFundForUnexpectedExpenses === "No" &&
      explainOwnerHaveEmergencyFundForUnexpectedExpenses === ""
    ) {
      setFinancialSituationColor(0);
    } else if (ownerOtherInvestments.length === 0) {
      setFinancialSituationColor(0);
    } else if (ownerRiskTolerance === "") {
      setFinancialSituationColor(0);
    } else {
      setFinancialSituationColor(3);
    }

    // Financial Situation Checking End

    // Product Purchase Checking Start

    if (reasonForPurchasingProduct.length === 0) {
      setProductPurchaseColor(0);
    } else if (flagOwnerPlanToWithdrawFundInSurrenderPeriod === "") {
      setProductPurchaseColor(0);
    } else if (
      flagOwnerPlanToWithdrawFundInSurrenderPeriod === "Yes" &&
      explainOwnerPlanToWithdrawFundInSurrenderPeriod === ""
    ) {
      setProductPurchaseColor(0);
    } else if (ownerAnticipateNeedingAccessToFunds === "") {
      setProductPurchaseColor(0);
    } else if (sourceOfFundsToPurchaseThisProduct.length === 0) {
      setProductPurchaseColor(0);
    } else {
      setProductPurchaseColor(3);
    }

    // Product Purchase Checking End

    // Existing Account Information Checking Start

    if (
      flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct === ""
    ) {
      setExistingAccountInformationColor(0);
    } else if (
      flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct ===
      "No (go to section 6)"
    ) {
      setExistingAccountInformationColor(3);
    } else {
      if (howLongHasTheLifeInsuranceBeenInForce === "") {
        setExistingAccountInformationColor(0);
      } else if (flagSurrenderChargeOnExistingProduct === "") {
        setExistingAccountInformationColor(0);
      } else if (
        flagSurrenderChargeOnExistingProduct === "Yes" &&
        currentSurrenderChargeOnExistingProduct === "" &&
        expirationOfCurrentSurrenderCharge === ""
      ) {
        setExistingAccountInformationColor(0);
      } else if (typeOfExistingProduct === "") {
        setExistingAccountInformationColor(0);
      } else if (interestRateForExistingProduct === "") {
        setExistingAccountInformationColor(0);
      } else if (
        flagTakingRequiredMinimumDistributionsFromExistingProduct === ""
      ) {
        setExistingAccountInformationColor(0);
      } else if (
        flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution === ""
      ) {
        setExistingAccountInformationColor(0);
      } else if (
        flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution ===
        "Yes" &&
        explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution ===
        ""
      ) {
        setExistingAccountInformationColor(0);
      } else {
        setExistingAccountInformationColor(3);
      }
    }

    // Existing Account Information Checking End

    // Other Section Checking Start

    if (
      personsThatAssistedOwnerToPurchaseThisProduct.length === 0 &&
      additionalRelaventInformationForSuitabilityAnalysis === ""
    ) {
      setOtherSectionColor(1);
    } else {
      setOtherSectionColor(3);
    }

    // Other Section Checking End

    // Agent Acknowledgment Checking Start

    if (agentAcknowledgment !== "" && agentAcknowledgment !== null) {
      setAgentAcknowledgmentColor(3);
    } else {
      setAgentAcknowledgmentColor(0);
    }

    // Agent Acknowledgment Checking End
  }, [suitabilityAnalysis]);

  useEffect(() => {
    // Basic Information Checking Start

    if (
      transferOrExchangeRequest.ownerFullName === "" &&
      transferOrExchangeRequest.ownerSSN === "" &&
      transferOrExchangeRequest.ownerStreetAddress === "" &&
      transferOrExchangeRequest.ownerZip === "" &&
      transferOrExchangeRequest.ownerCity === "" &&
      transferOrExchangeRequest.ownerState === "" &&
      // annuitantOrInsured === "" &&
      currentInsuranceCompany === "" &&
      currentInsuranceCompanyAccountNumber === "" &&
      currentInsuranceCompanyPhone === "" &&
      currentInsuranceCompanyStreetAddress === "" &&
      currentInsuranceCompanyCity === "" &&
      currentInsuranceCompanyZip === "" &&
      currentInsuranceCompanyState === "" &&
      typeOfAccount === "" &&
      ownerExistingAnnuity === ""
    ) {
      setBasicInformationColor(0);
    } else if (
      transferOrExchangeRequest.ownerFullName !== "" &&
      transferOrExchangeRequest.ownerSSN !== "" &&
      transferOrExchangeRequest.ownerStreetAddress !== "" &&
      transferOrExchangeRequest.ownerZip !== "" &&
      transferOrExchangeRequest.ownerCity !== "" &&
      transferOrExchangeRequest.ownerState !== "" &&
      // annuitantOrInsured !== "" &&
      currentInsuranceCompany !== "" &&
      currentInsuranceCompanyAccountNumber !== "" &&
      currentInsuranceCompanyPhone !== "" &&
      currentInsuranceCompanyStreetAddress !== "" &&
      currentInsuranceCompanyCity !== "" &&
      currentInsuranceCompanyZip !== "" &&
      currentInsuranceCompanyState !== "" &&
      typeOfAccount !== "" &&
      ownerExistingAnnuity !== ""
    ) {
      setBasicInformationColor(3);
    } else {
      setBasicInformationColor(0);
    }

    // Basic Information Checking End

    // Plans Section Checking Start

    if (plans === "") {
      setPlansSectionColor(0);
    } else {
      if (plans === "Non-Qualified Plans") {
        if (
          nonQualifiedTransferOrExchangeExistingAccountAllOrPartial !== "" &&
          (nonQualifiedTransferOrExchangeExistingAccountAllOrPartial ===
            "Partial"
            ? nonQualifiedTransferOrExchangeExistingAccountPartialAmount !== ""
            : true) &&
          nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate !==
          "" &&
          (nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate ===
            "Upon the maturity date of"
            ? nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField !==
            ""
            : true) &&
          nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy !==
          "" &&
          (nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy ===
            "To Existing Policy/Account"
            ? nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField !==
            ""
            : true) &&
          flagNonQualifiedOwnerWantWithdrawalToExchange !== "" &&
          (flagNonQualifiedOwnerWantWithdrawalToExchange === "Yes" &&
            explainNonQualifiedOwnerWantWithdrawalToExchange !== ""
            ? true
            : flagNonQualifiedOwnerWantWithdrawalToExchange === "No"
              ? true
              : false) &&
          flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal !== ""
        ) {
          setPlansSectionColor(3);
        } else {
          setPlansSectionColor(0);
        }
      } else {
        console.log(
          "Pritnign condition to check that plans section is valid or not",
          qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField !== ""
        );

        if (
          qualifiedTransferOrExchangeExistingAccountAllOrPartial !== "" &&
          (qualifiedTransferOrExchangeExistingAccountAllOrPartial === "Partial"
            ? qualifiedTransferOrExchangeExistingAccountPartialAmount !== ""
            : true) &&
          qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate !==
          "" &&
          (qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate ===
            "Upon the maturity date of"
            ? qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField !==
            ""
            : true) &&
          qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy !==
          "" &&
          (qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy ===
            "To Existing Policy/Account"
            ? qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField !==
            ""
            : true) &&
          qualifiedTypeOfTransfer !== "" &&
          qualifiedQualifyingEventForDirectRolloverFunds !== "" &&
          flagQualifiedOwnerReachedAge72OrMoreInThisYear !== "" &&
          flagQualifiedOwnerSatisfiedRMDFromDistributingPlan !== "" &&
          (flagQualifiedOwnerReachedAge72OrMoreInThisYear === "Yes" &&
            flagQualifiedOwnerSatisfiedRMDFromDistributingPlan === "No"
            ? qualifiedInternalExchangeOfExistingAnnuityName !== ""
            : true) &&
          flagQualifiedOwnerWantRMDBeforeCompletingExchange !== "" &&
          flagQualifiedOwnerWantToContinueExistingRMDSchedule !== ""
        ) {
          setPlansSectionColor(3);
        } else {
          setPlansSectionColor(0);
        }
      }
    }

    // Plans Section Checking End

    // Witness Information Checking Start
    console.log(
      "Printing witness information in useeffect in formsitemap",
      witnessEmail,
      witnessName
    );
    if (
      witnessName !== "" &&
      witnessName !== undefined &&
      witnessName !== null &&
      witnessEmail !== null &&
      witnessEmail !== null &&
      witnessEmail !== ""
    ) {
      setWitnessInformationColor(3);
    } else {
      setWitnessInformationColor(0);
    }

    // Witness Information Checking End
  }, [transferOrExchangeRequest]);

  useEffect(() => {
    const CheckInsuredPolicyFieldArray = () => {
      let temp = [];

      temp = insurerPolicyDetailFieldArray.map((v) => {
        if (
          v.insurerName === "" &&
          v.contractOrPolicyNumber === "" &&
          v.nameOfInsuredOrAnnuitant === "" &&
          v.replacedOrFinancing === ""
        ) {
          return false;
        } else if (
          v.insurerName !== "" &&
          v.contractOrPolicyNumber !== "" &&
          v.nameOfInsuredOrAnnuitant !== "" &&
          v.replacedOrFinancing !== ""
        ) {
          return true;
        } else {
          return false;
        }
      });

      console.log(
        "Printing temp to check replacement array question",
        temp,
        temp.indexOf(true)
      );

      if (temp.indexOf(true) >= 0) {
        return true;
      } else {
        return false;
      }
    };

    // first section checking start

    if (
      flagDiscontinuingPremiumPayment === "" &&
      flagPayForPremiumPolicy === ""
    ) {
      setImportantNoticeColor(1);
    } else if (
      flagDiscontinuingPremiumPayment === "No" &&
      flagPayForPremiumPolicy === "No"
    ) {
      setImportantNoticeColor(3);
    } else if (
      (flagDiscontinuingPremiumPayment === "Yes" ||
        flagPayForPremiumPolicy === "Yes") &&
      CheckInsuredPolicyFieldArray() === true &&
      reasonForReplacement !== ""
    ) {
      setImportantNoticeColor(3);
    } else {
      setImportantNoticeColor(1);
    }

    // first section checking end

    // second section checking start

    if (brokerName === "") {
      setBrokerColor(1);
    } else {
      setBrokerColor(3);
    }

    // second section checking end
  }, [noticeOfReplacement]);

  // useEffect(()=>{
  //   // Bank Details Checking Start

  //   if(routingNumber === "" && accountNumber === "" && bankAddress === "" && bankName === ""){
  //     setBankDetailsColor(1);
  //   }
  //   else{
  //     setBankDetailsColor(3);
  //   }

  //   // Bank Details Checking End
  // }, [bankDetails])

  useEffect(() => {
    // Checking annuity disclosure form start

    if (flagAnnuityClosure === "") {
      setAnnuityDisclosureColor(1);
    } else {
      setAnnuityDisclosureColor(3);
    }

    // Checking annuity disclosure form send
  }, [annuityDisclosureForm]);

  const [searchParams] = useSearchParams();

  const ConditionToShowReplacementForm = () => {
    if (
      annuitantState !== "Tennessee" &&
      annuitantState !== "" &&
      annuitantState !== "Oklahoma"
    ) {
      if (flagQualifiedOrNonQualifiedSelected === "Non - Qualified") {
        if (selectedNonQualifiedOptions !== "") {
          return true;
        } else {
          return false;
        }
      } else if (flagQualifiedOrNonQualifiedSelected === "Qualified") {
        if (
          selectedQualifiedOptionsGroup1 !== "" &&
          selectedQualifiedOptionsGroup2 !== ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
    else if (
      annuitantState !== "Tennessee" &&
      annuitantState !== "" &&
      annuitantState === "Oklahoma"
    ) {
      if (flagQualifiedOrNonQualifiedSelected === "Non - Qualified") {
        if (selectedNonQualifiedOptions !== "") {
          return true;
        } else {
          return false;
        }
      } else if (flagQualifiedOrNonQualifiedSelected === "Qualified") {
        if (
          selectedQualifiedOptionsGroup1 !== "" &&
          selectedQualifiedOptionsGroup2 !== ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      {flagUrlToSendApplicantDialog === true && (
        <URLToSendApplicantDialog
          value={`${protocol}//${domain}/myga_application?public_url_id=${uniqueURLId}`}
          closeURLToSendApplicantDialog={() => {
            setFlagUrlToSendApplicantDialog(false);
          }}
        />
      )}

      <div className="application_link_button">
        {/* {searchParams.has("public_url_id") === false && (
          <Button
            style={{
              width: "250px",
              color: "#fff",
              textTransform: "capitalize",
              background: "#D9A22E ",
            }}
            className="btn_send_this_link_to_applicant"
            onClick={() => {
              GeneratePublicURL();
            }}
          >
            send this link to applicant
          </Button>
        )} */}
        <div class="treeview">
          <ul
            style={
              IndividualAnnuityApplicationSuccess() === true
                ? {
                  borderLeftColor: "#074a6e",
                  position: "absolute",
                  right: "25px",
                }
                : {
                  borderLeftColor: "rgb(189, 189, 189)",
                  position: "absolute",
                  right: "25px",
                }
            }
          >
            <div
              onClick={() => props.setPage(1)}
              style={
                IndividualAnnuityApplicationSuccess() === true &&
                  props.pageIndex === 1
                  ? { color: "#078a4e", fontWeight: "bold" }
                  : IndividualAnnuityApplicationSuccess() === true
                    ? { color: "#074a6e", fontWeight: "bold" }
                    : props.pageIndex === 1
                      ? { color: "#078a4e" }
                      : { color: "rgb(189, 189, 189)" }
              }
              className={
                props.pageIndex === 1
                  ? "spn right_section_title current_page"
                  : "spn right_section_title"
              }
            >
              Individual Annuity Application
            </div>
          </ul>
          <div className="individual_annuity_application_right_section">
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(1);
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFirstSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSecondSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageThirdSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFourthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFifthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSixthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSeventhSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageEighthSection",
                      data: false,
                    });
                  }}
                  style={
                    annuitantSectionColor === 0
                      ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                      : annuitantSectionColor === 3
                        ? {
                          color: "#074a6e",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }
                        : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Annuitant
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(1);
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFirstSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSecondSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageThirdSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFourthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFifthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSixthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSeventhSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageEighthSection",
                      data: false,
                    });
                  }}
                  style={
                    ownerSectionColor === 0
                      ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                      : ownerSectionColor === 1
                        ? {
                          color: "#1976d2",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }
                        : ownerSectionColor === 3
                          ? {
                            color: "#074a6e",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }
                          : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Owner
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(1);
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFirstSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSecondSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageThirdSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFourthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFifthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSixthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSeventhSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageEighthSection",
                      data: false,
                    });
                  }}
                  style={
                    jointOwnerSectionColor === 0
                      ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                      : jointOwnerSectionColor === 1
                        ? {
                          color: "#1976d2",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }
                        : jointOwnerSectionColor === 3
                          ? {
                            color: "#074a6e",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }
                          : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Joint Spousal Owner, WROS
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(1);
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFirstSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSecondSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageThirdSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFourthSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFifthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSixthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSeventhSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageEighthSection",
                      data: false,
                    });
                  }}
                  style={
                    planRiderSectionColor === 0
                      ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                      : {
                        color: "#074a6e",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Plan/Riders Applied For
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(1);
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFirstSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSecondSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageThirdSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFourthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFifthSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSixthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSeventhSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageEighthSection",
                      data: false,
                    });
                  }}
                  style={
                    beneficiariesSectionColor === 1
                      ? {
                        color: "#1976d2",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }
                      : beneficiariesSectionColor === 3
                        ? {
                          color: "#074a6e",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }
                        : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Beneficiaries
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(1);
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFirstSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSecondSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageThirdSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFourthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFifthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSixthSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSeventhSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageEighthSection",
                      data: false,
                    });
                  }}
                  style={
                    premimumTaxQualificationSectionColor === 0
                      ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                      : {
                        color: "#074a6e",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Premium and Tax Qualification Status
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(1);
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFirstSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSecondSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageThirdSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFourthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageFifthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSixthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageSeventhSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Individual Annuity Application",
                      section: "flagFirstPageEighthSection",
                      data: false,
                    });
                  }}
                  style={
                    ownerReplacementSectionColor === 0
                      ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                      : {
                        color: "#074a6e",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Owner Replacement Questions
                </span>
              </div>
            </li>
          </div>

          {/* Important Notice mapping */}

          {ConditionToShowReplacementForm() === true &&
            annuitantState !== "Tennessee" ? (
            <ul
              style={
                NoticeOfReplacementSuccess() === true
                  ? searchParams.has("public_url_id") === true
                    ? {
                      borderLeftColor: "#074a6e",
                      position: "absolute",
                      right: "25px",
                      top: "380px",
                    }
                    : {
                      borderLeftColor: "#074a6e",
                      position: "absolute",
                      right: "25px",
                      top: "400px",
                    }
                  : searchParams.has("public_url_id") === true
                    ? {
                      borderLeftColor: "rgb(189, 189, 189)",
                      position: "absolute",
                      right: "25px",
                      top: "380px",
                    }
                    : {
                      borderLeftColor: "rgb(189, 189, 189)",
                      position: "absolute",
                      right: "25px",
                      top: "400px",
                    }
              }
            >
              <div
                style={
                  NoticeOfReplacementSuccess() === true && props.pageIndex === 2
                    ? {
                      color: "#078a4e",
                      fontWeight: "bold",
                      marginTop: "-10px",
                    }
                    : NoticeOfReplacementSuccess() === true
                      ? {
                        color: "#074a6e",
                        fontWeight: "bold",
                        marginTop: "-10px",
                      }
                      : props.pageIndex === 2
                        ? { color: "#078a4e", marginTop: "-10px" }
                        : { color: "rgb(189, 189, 189)", marginTop: "-10px" }
                }
                onClick={() => {
                  if (annuitantState === "Oklahoma") {
                    props.setPage(5);
                  } else {
                    // props.pageIndex === 5
                    props.setPage(2);
                  }
                }}
                className={
                  props.pageIndex === 2
                    ? "spn right_section_title current_page"
                    : props.pageIndex === 5
                      ? "spn right_section_title current_page "
                      : "spn right_section_title"
                }
              >
                Notice of Replacement of Life Insurance or Annuities{" "}
              </div>
            </ul>
          ) : null}
          {ConditionToShowReplacementForm() === true &&
            annuitantState !== "" &&
            annuitantState !== "Tennessee" &&
            annuitantState !== "Oklahoma" ? (
            <div className="notice_of_replacement_right_section">
              <li>
                <div style={{ display: "flex" }}>
                  <span className="spn middle_line">___</span>
                  <span
                    onClick={() => {
                      SetPageIndex(2);
                      SetApplicationFormFlagSectionData({
                        type: "Notice of Replacement",
                        section: "flagReplacementPageFirstSection",
                        data: true,
                      });
                      SetApplicationFormFlagSectionData({
                        type: "Notice of Replacement",
                        section: "flagReplacementPageSecondSection",
                        data: false,
                      });
                    }}
                    style={
                      importantNoticeColor === 1
                        ? { color: "rgb(189,189,189)", cursor: "pointer" }
                        : {
                          color: "#074a6e",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }
                    }
                    className="spn application_form_section_name"
                  >
                    Important Notice: Replacement of Life Insurance or Annuities
                  </span>
                </div>
              </li>
            </div>
          ) : null}
          {ConditionToShowReplacementForm() === true &&
            annuitantState !== "" &&
            annuitantState !== "Tennessee" &&
            annuitantState === "Oklahoma" ? (
            <div className="notice_of_replacement_right_section">
              <li>
                <div style={{ display: "flex" }}>
                  <span className="spn middle_line">___</span>
                  <span
                    onClick={() => {
                      SetPageIndex(5);
                      SetApplicationFormFlagSectionData({
                        type: "Notice of Replacement oklahoma",
                        section: "flagReplacementPageFirstSection",
                        data: true,
                      });
                    }}
                    style={
                      importantNoticeColor === 1
                        ? { color: "rgb(189,189,189)", cursor: "pointer" }
                        : {
                          color: "#074a6e",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }
                    }
                    className="spn application_form_section_name"
                  >
                    Important Notice: Replacement of Life Insurance or Annuities
                    for oklahoma
                  </span>
                </div>
              </li>
            </div>
          ) : null}

          {ConditionToShowReplacementForm() === true ? (
            <ul
              style={
                SuitabilityAnalysisSuccess() === true
                  ? searchParams.has("public_url_id") === true
                    ? {
                      borderLeftColor: "#074a6e",
                      position: "absolute",
                      right: "25px",
                      top: "530px",
                    }
                    : {
                      borderLeftColor: "#074a6e",
                      position: "absolute",
                      right: "25px",
                      top: "525px",
                    }
                  : searchParams.has("public_url_id") === true
                    ? {
                      borderLeftColor: "rgb(189, 189, 189)",
                      position: "absolute",
                      right: "25px",
                      top: "530px",
                    }
                    : {
                      borderLeftColor: "rgb(189, 189, 189)",
                      position: "absolute",
                      right: "25px",
                      top: "525px",
                    }
              }
            >
              <div
                style={
                  SuitabilityAnalysisSuccess() === true && props.pageIndex === 3
                    ? { color: "#078a4e", fontWeight: "bold" }
                    : SuitabilityAnalysisSuccess() === true
                      ? { color: "#074a6e", fontWeight: "bold" }
                      : props.pageIndex === 3
                        ? { color: "#078a4e" }
                        : { color: "rgb(189, 189, 189)" }
                }
                onClick={() => props.setPage(3)}
                className={
                  props.pageIndex === 3
                    ? "spn right_section_title current_page"
                    : "spn right_section_title"
                }
              >
                Suitability Analysis
              </div>
            </ul>
          ) : (
            <ul
              style={
                SuitabilityAnalysisSuccess() === true
                  ? searchParams.has("public_url_id") === true
                    ? {
                      borderLeftColor: "#074a6e",
                      position: "absolute",
                      right: "25px",
                      top: "370px",
                    }
                    : {
                      borderLeftColor: "#074a6e",
                      position: "absolute",
                      right: "25px",
                      top: "400px",
                    }
                  : searchParams.has("public_url_id") === true
                    ? {
                      borderLeftColor: "rgb(189, 189, 189)",
                      position: "absolute",
                      right: "25px",
                      top: "370px",
                    }
                    : {
                      borderLeftColor: "rgb(189, 189, 189)",
                      position: "absolute",
                      right: "25px",
                      top: "400px",
                    }
              }
            >
              <div
                style={
                  SuitabilityAnalysisSuccess() === true && props.pageIndex === 3
                    ? { color: "#078a4e", fontWeight: "bold" }
                    : SuitabilityAnalysisSuccess() === true
                      ? { color: "#074a6e", fontWeight: "bold" }
                      : props.pageIndex === 3
                        ? { color: "#078a4e" }
                        : { color: "rgb(189, 189, 189)" }
                }
                onClick={() => props.setPage(3)}
                className={
                  props.pageIndex === 3
                    ? "spn right_section_title current_page"
                    : "spn right_section_title"
                }
              >
                Suitability Analysis
              </div>
            </ul>
          )}
          <div
            className={
              ConditionToShowReplacementForm() === true
                ? "suitability_analysis_right_section_exception"
                : "suitability_analysis_right_section"
            }
          >
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(3);
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFirstSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSecondSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageThirdSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFourthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFifthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSixthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSeventhSection",
                      data: false,
                    });
                  }}
                  style={
                    personalInformationColor === 3
                      ? {
                        color: "#074a6e",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }
                      : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Personal Information
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(3);
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFirstSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSecondSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageThirdSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFourthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFifthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSixthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSeventhSection",
                      data: false,
                    });
                  }}
                  style={
                    financialInformationColor === 3
                      ? {
                        color: "#074a6e",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }
                      : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Financial Information of Owner
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(3);
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFirstSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSecondSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageThirdSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFourthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFifthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSixthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSeventhSection",
                      data: false,
                    });
                  }}
                  style={
                    financialSituationColor === 0
                      ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                      : {
                        color: "#074a6e",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Financial Situation and Needs of Owner
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(3);
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFirstSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSecondSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageThirdSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFourthSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFifthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSixthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSeventhSection",
                      data: false,
                    });
                  }}
                  style={
                    productPurchaseColor === 0
                      ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                      : {
                        color: "#074a6e",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  The Product Purchase
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(3);
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFirstSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSecondSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageThirdSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFourthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFifthSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSixthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSeventhSection",
                      data: false,
                    });
                  }}
                  style={
                    existingAccountInformationColor === 0
                      ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                      : {
                        color: "#074a6e",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Existing Account Information
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(3);
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFirstSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSecondSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageThirdSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFourthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFifthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSixthSection",
                      data: true,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSeventhSection",
                      data: false,
                    });
                  }}
                  style={
                    otherSectionColor === 1
                      ? {
                        color: "#1976d2",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }
                      : {
                        color: "#074a6e",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Other
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="spn middle_line">___</span>
                <span
                  onClick={() => {
                    SetPageIndex(3);
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFirstSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSecondSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageThirdSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFourthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageFifthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSixthSection",
                      data: false,
                    });
                    SetApplicationFormFlagSectionData({
                      type: "Suitability Analysis",
                      section: "flagSecondPageSeventhSection",
                      data: true,
                    });
                  }}
                  style={
                    agentAcknowledgmentColor === 0
                      ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                      : {
                        color: "#074a6e",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }
                  }
                  className="spn application_form_section_name"
                >
                  {" "}
                  Agent's Acknowledgment
                </span>
              </div>
            </li>
          </div>

          {ConditionToShowReplacementForm() === true ? (
            ConditionToShowTransferExchangeForm() ? (
              <ul
                style={
                  TransferExchangeRequestSuccess() === true
                    ? searchParams.has("public_url_id") === true
                      ? {
                        borderLeftColor: "#074a6e",
                        position: "absolute",
                        right: "25px",
                        top: "884px",
                      }
                      : {
                        borderLeftColor: "#074a6e",
                        position: "absolute",
                        right: "25px",
                        top: "920px",
                      }
                    : searchParams.has("public_url_id") === true
                      ? {
                        borderLeftColor: "rgb(189, 189, 189)",
                        position: "absolute",
                        right: "25px",
                        top: "884px",
                      }
                      : {
                        borderLeftColor: "rgb(189, 189, 189)",
                        position: "absolute",
                        right: "25px",
                        top: "920px",
                      }
                }
              >
                <div
                  style={
                    TransferExchangeRequestSuccess() === true &&
                      props.pageIndex === 4
                      ? { color: "#078a4e", fontWeight: "bold" }
                      : TransferExchangeRequestSuccess() === true
                        ? { color: "#074a6e", fontWeight: "bold" }
                        : props.pageIndex === 4
                          ? { color: "#078a4e" }
                          : { color: "rgb(189, 189, 189)" }
                  }
                  onClick={() => props.setPage(4)}
                  className={
                    props.pageIndex === 4
                      ? "spn right_section_title current_page"
                      : "spn right_section_title"
                  }
                >
                  Transfer or Exchange Request
                </div>
              </ul>
            ) : null
          ) : ConditionToShowTransferExchangeForm() ? (
            <ul
              style={
                TransferExchangeRequestSuccess() === true
                  ? searchParams.has("public_url_id") === true
                    ? {
                      borderLeftColor: "#074a6e",
                      position: "absolute",
                      right: "25px",
                      top: "704px",
                    }
                    : {
                      borderLeftColor: "#074a6e",
                      position: "absolute",
                      right: "25px",
                      top: "780px",
                    }
                  : searchParams.has("public_url_id") === true
                    ? {
                      borderLeftColor: "rgb(189, 189, 189)",
                      position: "absolute",
                      right: "25px",
                      top: "704px",
                    }
                    : {
                      borderLeftColor: "rgb(189, 189, 189)",
                      position: "absolute",
                      right: "25px",
                      top: "780px",
                    }
              }
            >
              <div
                style={
                  TransferExchangeRequestSuccess() === true &&
                    props.pageIndex === 4
                    ? { color: "#078a4e", fontWeight: "bold" }
                    : TransferExchangeRequestSuccess() === true
                      ? { color: "#074a6e", fontWeight: "bold" }
                      : props.pageIndex === 4
                        ? { color: "#078a4e" }
                        : { color: "rgb(189, 189, 189)" }
                }
                onClick={() => props.setPage(4)}
                className={
                  props.pageIndex === 4
                    ? "spn right_section_title current_page"
                    : "spn right_section_title"
                }
              >
                Transfer or Exchange Request
              </div>
            </ul>
          ) : null}
          {ConditionToShowTransferExchangeForm() === true ? (
            <div
              className={
                ConditionToShowReplacementForm() === true
                  ? "transfer_exchange_right_section"
                  : "transfer_exchange_right_section_exception"
              }
            //  style={searchParams.has("public_url_id") === true && {marginTop: "655px"}}
            >
              <li>
                <div>
                  <span className="spn middle_line">___</span>
                  <span
                    onClick={() => {
                      SetPageIndex(4);
                      SetApplicationFormFlagSectionData({
                        type: "Transfer or Exchange Request",
                        section: "flagFourthPageFirstSection",
                        data: true,
                      });
                      SetApplicationFormFlagSectionData({
                        type: "Transfer or Exchange Request",
                        section: "flagFourthPageSecondSection",
                        data: false,
                      });
                      SetApplicationFormFlagSectionData({
                        type: "Transfer or Exchange Request",
                        section: "flagFourthPageThirdSection",
                        data: false,
                      });
                    }}
                    style={
                      basicInformationColor === 0
                        ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                        : basicInformationColor === 1
                          ? {
                            color: "#1976d2",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }
                          : {
                            color: "#074a6e",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }
                    }
                    className="spn application_form_section_name"
                  >
                    {" "}
                    Basic Information
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <span className="spn middle_line">___</span>
                  <span
                    onClick={() => {
                      SetPageIndex(4);
                      SetApplicationFormFlagSectionData({
                        type: "Transfer or Exchange Request",
                        section: "flagFourthPageFirstSection",
                        data: false,
                      });
                      SetApplicationFormFlagSectionData({
                        type: "Transfer or Exchange Request",
                        section: "flagFourthPageSecondSection",
                        data: true,
                      });
                      SetApplicationFormFlagSectionData({
                        type: "Transfer or Exchange Request",
                        section: "flagFourthPageThirdSection",
                        data: false,
                      });
                    }}
                    style={
                      plansSectionColor === 0
                        ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                        : {
                          color: "#074a6e",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }
                    }
                    className="spn application_form_section_name"
                  >
                    {" "}
                    Plans
                  </span>
                </div>
              </li>
              {/* <li>
              <div>
                <span className="spn middle_line">___</span>
                <span onClick={()=>{
                  SetPageIndex(4);
                  SetApplicationFormFlagSectionData({
                    type: "Transfer or Exchange Request",
                    section: "flagFourthPageFirstSection",
                    data: false
                  })
                  SetApplicationFormFlagSectionData({
                    type: "Transfer or Exchange Request",
                    section: "flagFourthPageSecondSection",
                    data: false
                  })
                  SetApplicationFormFlagSectionData({
                    type: "Transfer or Exchange Request",
                    section: "flagFourthPageThirdSection",
                    data: true
                  })
                }} style={witnessInformationColor === 0 ? {color: "rgb(189, 189, 189)", cursor: "pointer"} : {color: "#074a6e", fontWeight: "bold", cursor: "pointer"}} className="spn application_form_section_name"> Witness Information</span>
              </div>
            </li> */}
            </div>
          ) : null}

          {/* <ul  style={BankDetailsSuccess() === true ? searchParams.has("public_url_id") === true ? {borderLeftColor: "#074a6e", position: "absolute", right: "25px", top:eftStyle.top} : {borderLeftColor: "#074a6e", position: "absolute", right: "25px", top: eftStyle.top} : searchParams.has("public_url_id") === true ? {borderLeftColor: "rgb(189, 189, 189)", position: "absolute", right: "25px", top: eftStyle.top} : {borderLeftColor: "rgb(189, 189, 189)", position: "absolute", right: "25px", top: eftStyle.top}}>

            <div
              // style={bankDetailsColor === 1 ? {color: "#1976d2", fontWeight: "bold"} : {color: "#074a6e", fontWeight: "bold"}}
              style={BankDetailsSuccess() === true && props.pageIndex === 5 ? {color: "#078a4e", fontWeight: "bold"} : BankDetailsSuccess() === true ? {color: "#074a6e", fontWeight: "bold"} : props.pageIndex === 5 ? {color: "#078a4e"} :  {paddingBottom:"30px",color: "rgb(189, 189, 189)"}}
              onClick={() => props.setPage(5)}
              className={props.pageIndex === 5 ?
                "spn right_section_title current_page" : "spn right_section_title"}>EFT</div>
          </ul> */}

          {/* // disclosure section  */}
          <ul
            style={
              AnnuityDisclosureSuccess() === true
                ? searchParams.has("public_url_id") === true
                  ? {
                    borderLeftColor: "#074a6e",
                    position: "absolute",
                    right: "25px",
                    top: annuityDisclosureStyle.top,
                  }
                  : {
                    borderLeftColor: "#074a6e",
                    position: "absolute",
                    right: "25px",
                    top: annuityDisclosureStyle.top,
                  }
                : searchParams.has("public_url_id") === true
                  ? {
                    borderLeftColor: "rgb(189, 189, 189)",
                    position: "absolute",
                    right: "25px",
                    top: annuityDisclosureStyle.top,
                  }
                  : {
                    borderLeftColor: "rgb(189, 189, 189)",
                    position: "absolute",
                    right: "25px",
                    top: annuityDisclosureStyle.top,
                  }
            }
          >
            <div
              style={
                AnnuityDisclosureSuccess() === true && props.pageIndex === 6
                  ? { color: "#078a4e", fontWeight: "bold" }
                  : AnnuityDisclosureSuccess() === true
                    ? { color: "#074a6e", fontWeight: "bold" }
                    : props.pageIndex === 6
                      ? { color: "#078a4e" }
                      : { paddingBottom: "30px", color: "rgb(189, 189, 189)" }
              }
              onClick={() => props.setPage(6)}
              className={
                props.pageIndex === 6
                  ? "spn right_section_title current_page"
                  : "spn right_section_title"
              }
            >
              Annuity Disclosure
            </div>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FormSiteMap;
