import React, { Fragment, useState, useContext, useEffect } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Select,
  TableHead,
  TableRow,
  Typography,
  Button,
  InputLabel,
  FormControl,
  NativeSelect,
  TextField,
} from "@mui/material";
import "./agents.css";
import { Context } from "../../context/userLoaderContext";
import {
  GetUserDetailAPI,
  UpdateUserStatusAPI,
  GetUserStatusAPI,
} from "../../api/getUserDetailAPI";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import Tooltip from '@mui/material/Tooltip';

const Agents = () => {
  const [openStatusMenu, setOpenStatusMenu] = useState(false);
  // const { SetFlagLoader } = useContext(Context);
  const [flagLoader, SetFlagLoader] = useState(false);
  const { state } = useContext(Context);
  const [agents, setAgents] = useState([]);
  const [defaultAgents, setDefaultAgents] = useState([]);
  const [userStatus, setUserStatus] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    console.log("$$$$$", state.userType === "Admin");
    if (state.userType !== "Admin") {
      navigate("/myga_application_history");
    }
  });

  // useEffect(() => {
  //   if (userStatus == undefined) {
  //     SetFlagLoader(true);
  //   } else {
  //     SetFlagLoader(false);
  //   }
  // }, [userStatus]);

  useEffect(() => {
    SetFlagLoader(true);
    GetUserStatusAPI(
      {},
      (res) => {
        if (res.data.status === true) {
          setUserStatus(res.data.data);
          setTimeout(function () {
          }, 1000);
        }
      },
      (err) => {
        console.log("error while getting user detail", err);
      }
    );
    GetUserDetailAPI(
      {},
      (res) => {
        console.log("hello world", res.data.data);
        if (res.data.status === true) {
          let sortedData = res.data?.data.sort(function (a, b) {
            return (a.first_name) < (b.first_name) ? -1 : 1
          })
          setAgents(sortedData);
          setDefaultAgents(sortedData);
        }
        SetFlagLoader(false);
      },
      (err) => {
        if (err.response.status === 401) {
          window.location.reload();
        }
        SetFlagLoader(false);
      }
    );

  }, []);
  const tableHeader = ["Name", "Email", "Phone", "Registered On", "Status"];
  useEffect(() => {
    agents.sort((a, b) => new Date(b.date) - new Date(a.date));
  }, []);
  const changeStatus = (status, userid) => {
    SetFlagLoader(true);
    UpdateUserStatusAPI(
      {
        status_id: status,
        user_id: userid,
      },
      (res) => {
        // debugger;
        SetFlagLoader(false);
        console.log("Printing response of user update", res);
      },
      (err) => {
        SetFlagLoader(false);
        console.log("got error for of user update", err);
      }
    );
  };

  const searchAgent = (value) => {
    if (!value) {
      setAgents(defaultAgents)
    } else {
      var filteredData = defaultAgents.filter((row) => (row.first_name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        row.last_name.toLowerCase().indexOf(value.toLowerCase()) > -1))
      setAgents(filteredData)
    }
  }

  const getTooltipContent = (firstName, lastName) => {
    return `${firstName} ${lastName}`
  }

  return (
    <Fragment>
      {flagLoader === true && <Loader />}
      <Box className="agents_page_root">
        <Box className="page_title">
          <Typography variant="span page_title">Agents</Typography>
        </Box>
        <Box className="agentsSearchBarBox" >
          <TextField
            className="agentsSearchBarTextField"
            // id="filled-hidden-label-small"
            // variant="filled"
            // size="small"
            placeholder="Search..."
            onChange={(e) => {
              searchAgent(e.target.value);
            }}
          />
        </Box>
        <Box className="agents_table_root">
          <TableContainer sx={{ maxHeight: 720 }}>
            <Table className="agents_table" stickyHeader>
              <TableHead className="agents_table_head">
                <TableRow>
                  {tableHeader.map((v) => {
                    return <TableCell>{v}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {agents.map((row, i) => {
                  return (
                    <TableRow className="agents_table_data">
                      <TableCell>
                        <Tooltip title={getTooltipContent(row.first_name, row.last_name)}>
                          <span>
                            {(row.first_name + row.last_name)?.length > 31 ? (row.first_name + row.last_name).substring(0, 31) + "..." : row.first_name + " " + row.last_name}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {row?.email}
                      </TableCell>
                      <TableCell>
                        {row?.phone}
                      </TableCell>
                      <TableCell>
                        {moment(row.created_at).format("MM-DD-yyyy")}
                      </TableCell>
                      <TableCell>
                        <Select
                          className="agents_status_select"
                          value={row.status}
                          onChange={(e) => {
                            let userid;
                            userStatus?.filter((v) => {
                              if (v.name === e.target.value) {
                                userid = v.id;
                                row.status = e.target.value;
                              }
                            });
                            changeStatus(userid, row.user_id);
                          }}
                          variant="standard"
                        >
                          {userStatus?.length > 0 && userStatus.map((v) => {
                            return <MenuItem value={v.name}>{v.name}</MenuItem>;
                          })}
                        </Select>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Agents;
