import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
} from "react";
import {
  Typography,
  Box,
  Divider,
  TextField,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Input,
  InputAdornment,
  Grid,
  IconButton,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ClearIcon from '@mui/icons-material/Clear';
import "./individualAnnuityApplication.css";
import { Context } from "../../../context/V1/newApplicationFormContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Context as UserLoaderContext } from "../../../context/userLoaderContext";
import { Context as ErrorValidationContext } from "../../../context/V1/errorValidationContext";

const ApplicationForm = (props) => {
  const {
    SetIndividualAnnuityApplication,
    SetWholeAnnuityDisclosureForm,
    updateTransferOrExchangeRequestFlag,
  } = useContext(Context);
  const { state } = useContext(UserLoaderContext);
  const {
    individualAnnuityApplication,
    disableAllInputs,
    annuityDisclosureForm,
  } = useContext(Context).state;

  const [flagNonQualifiedMenu, setFlagNonQualifiedMenu] = useState(false);
  const [flagQualifiedMenu, setFlagQualifiedMenu] = useState(false);
  const [flagTemp, setFlagTemp] = useState(disableAllInputs);

  const otherTypeOfPlanRef = useRef();
  console.log("disableAllInputs", disableAllInputs);
  const { SetApplicationFormFlagSectionData } = useContext(
    ErrorValidationContext
  );
  const { annuitantState } = individualAnnuityApplication;
  const {
    flagFirstPageFirstSection,
    flagFirstPageSecondSection,
    flagFirstPageThirdSection,
    flagFirstPageFourthSection,
    flagFirstPageFifthSection,
    flagFirstPageSixthSection,
    flagFirstPageSeventhSection,
    flagFirstPageEighthSection,
  } = useContext(ErrorValidationContext).state;

  // const [flagFirstPageFirstSection, setFlagFirstSection] = useState(true);
  // const [flagFirstPageSecondSection, setFlagSecondSection] = useState(false);
  // const [flagFirstPageThirdSection, setFlagThirdSection] = useState(false);
  // const [flagFirstPageFourthSection, setFlagFourthSection] = useState(false);
  // const [flagFirstPageFifthSection, setFlagFifthSection] = useState(false);
  // const [flagFirstPageSixthSection, setFlagSixthSection] = useState(false);
  // const [flagFirstPageSeventhSection, setFlagSeventhSection] = useState(false);
  // const [flagFirstPageEighthSection, setFlagEighthSection] = useState(false);

  const [beneficiariesPercentageTotal, setBeneficiariesPercentageTotal] =
    useState(0);
  const [primaryBeneficiariesPercentage, setPrimaryBeneficiariesPercentage] =
    useState(0);
  const [
    contigentBeneficiariesPercentage,
    setContigentBeneficiariesPercentage,
  ] = useState(0);
  const [annuityPlanValue, setAnnuityPlanValue] = useState("");
  const [riderValue, setRiderValue] = useState("");

  const annuityPlansAllCheckboxArray = [
    "Safeguard Plus 3",
    "Safeguard Plus 5",
    "Safeguard Plus 7",
    "Safeguard Plus 10",
  ];

  const riderAllCheckboxArray = [
    "5% Free Withdrawal Rider",
    "Enhanced Benefit Rider",
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Printing disable all inputs value", disableAllInputs);
  }, []);
  useEffect(() => {
    console.log("Printing individualAnnuityApplication.beneficiariesContigent::", individualAnnuityApplication.beneficiariesContigent);
  }, [individualAnnuityApplication.beneficiariesContigent]);

  // useEffect(() => {
  //   var gg = individualAnnuityApplication;
  //   // debugger
  //   if (
  //     1 === 1
  //     // individualAnnuityApplication.flagHaveExistingLifeInsurance === "Yes" ||
  //     // individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance ===
  //     //   "Yes"
  //   ) {
  //     // setFlagFill(true)
  //     updateTransferOrExchangeRequestFlag(true);
  //   } else {
  //     updateTransferOrExchangeRequestFlag(false);
  //     // if(flagFill)
  //     // {
  //     //     setFlagFill(false)
  //     // }
  //   }
  // }, [
  //   individualAnnuityApplication.flagHaveExistingLifeInsurance,
  //   individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance,
  // ]);

  const StatesName = [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Federated States Of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virgin Islands",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  function isValidEmail(email) {
    return /^([a-zA-Z0-9\._]+)@([a-zA-Z)-9])+.([a-z]+)(.[a-z]+)?$/.test(email);
  }

  const [flagDatePopupOpenAnnuitant, setFlagDatePopupOpenAnnuitant] = useState(false);
  const [flagDatePopupOpenOwner, setFlagDatePopupOpenOwner] = useState(false);
  const [flagDatePopupOpenSpousal, setFlagDatePopupOpenSpousal] = useState(false);
  // const [flagClearClicked, setFlagClearClicked] = useState(false);

  const [annuitantValidationError, setAnnuitantValidationError] = useState({
    flagAnnuitantZipValid: false,
    flagAnnuitantPhoneValid: false,
    flagAnnuitantEmailValid: false,
  });
  const [ownerValidationError, setOwnerValidationError] = useState({
    flagOwnerZipValid: false,
    flagOwnerEmailValid: false,
  });
  const [jointOwnerValidationError, setJointOwnerValidationError] = useState({
    flagJointOwnerZipValid: false,
    flagJointOwnerEmailValid: false,
  });

  useEffect(() => {
    console.log("Printing disable all inputs value", disableAllInputs);
    let inputArray = document.querySelectorAll("input");
    console.log(
      "Printing all input array for individual annuity application",
      inputArray
    );
    if (disableAllInputs === true) {
      for (let i = 0; i < inputArray.length; i++) {
        console.log("Printing input array each element", inputArray[i]);
        inputArray[i].disabled = true;
      }
    } else {
      for (let i = 0; i < inputArray.length; i++) {
        console.log("Printing input array each element", inputArray[i]);
        inputArray[i].disabled = false;
      }
    }
  }, [
    disableAllInputs,
    flagFirstPageFirstSection,
    flagFirstPageSecondSection,
    flagFirstPageThirdSection,
    flagFirstPageFourthSection,
    flagFirstPageFifthSection,
    flagFirstPageSixthSection,
    flagFirstPageSeventhSection,
  ]);

  useEffect(() => {
    console.log("context", individualAnnuityApplication);
    individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected === "" ||
      individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
      "Non - Qualified"
      ? setFlagNonQualifiedMenu(true)
      : setFlagNonQualifiedMenu(false);

    individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected === "" ||
      individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
      "Qualified"
      ? setFlagQualifiedMenu(true)
      : setFlagQualifiedMenu(false);
  }, [individualAnnuityApplication]);

  useEffect(() => {
    setFlagNonQualifiedMenu(false);
    setFlagQualifiedMenu(false);
  }, []);

  const CommonContextUpdate = (type, value) => {
    // debugger;
    SetIndividualAnnuityApplication({
      type: type,
      value: value,
    });
  };

  useEffect(() => {
    ValueOfAnnuityPlanSection();
    // ValueOfRiderSection();
  }, [individualAnnuityApplication.planAppliedFor]);

  useEffect(() => {
    let tempPrimary = 0;

    console.log("initially", tempPrimary);

    individualAnnuityApplication.beneficiariesPrimary.map((v) => {
      if (v.percentageField !== "" && v.percentageField !== -1) {
        tempPrimary += parseFloat(v.percentageField);
      }
    });
    console.log("After primary summation", tempPrimary);

    let tempContigent = 0;

    individualAnnuityApplication.beneficiariesContigent.map((v) => {
      // console.log(v."percentageField",v.percentageField);
      // console.log("v.percentageField",v.percentageField);
      if (v.percentageField !== "" && v.percentageField !== -1) {
        tempContigent += parseFloat(v.percentageField);
      }
    });

    console.log("After all summation", tempPrimary, tempContigent);
    // setBeneficiariesPercentageTotal(temp);
    setPrimaryBeneficiariesPercentage(tempPrimary);
    setContigentBeneficiariesPercentage(tempContigent);
    props.beneficiariesPercentageTotal(tempPrimary + tempContigent);
  }, [
    individualAnnuityApplication.beneficiariesPrimary,
    individualAnnuityApplication.beneficiariesContigent,
  ]);

  const ValueOfAnnuityPlanSection = () => {
    if (individualAnnuityApplication.planAppliedFor.length === 0) {
      return "";
    }
    // else{
    //     let tempValue1 = individualAnnuityApplication.planAppliedFor.split(",")[0];
    //     let tempValue2 = individualAnnuityApplication.planAppliedFor.split(",")[1];
    //     switch(tempValue1){
    //         case "Safeguard Plus 3":
    //             setAnnuityPlanValue(tempValue1);
    //             break;

    //         case "Safeguard Plus 5":
    //             setAnnuityPlanValue(tempValue1);
    //             break;
    //         case "Safeguard Plus 7":
    //             setAnnuityPlanValue(tempValue1);
    //             break;
    //         case "Safeguard Plus 10":
    //             setAnnuityPlanValue(tempValue1);
    //             break;
    //     }
    //     if(tempValue2 !== ""){
    //         switch(tempValue2){
    //             case "Safeguard Plus 3":
    //                 setAnnuityPlanValue(tempValue2);
    //                 break;

    //             case "Safeguard Plus 5":
    //                 setAnnuityPlanValue(tempValue2);
    //                 break;

    //             case "Safeguard Plus 7":
    //                 setAnnuityPlanValue(tempValue2);
    //                 break;

    //             case "Safeguard Plus 10":
    //                 setAnnuityPlanValue(tempValue2);
    //                 break;
    //         }
    //     }

    // }
    else {
      if (
        individualAnnuityApplication.planAppliedFor.indexOf(
          "Safeguard Plus 3"
        ) >= 0
      ) {
        setAnnuityPlanValue("Safeguard Plus 3");
      } else if (
        individualAnnuityApplication.planAppliedFor.indexOf(
          "Safeguard Plus 5"
        ) >= 0
      ) {
        setAnnuityPlanValue("Safeguard Plus 5");
      } else if (
        individualAnnuityApplication.planAppliedFor.indexOf(
          "Safeguard Plus 7"
        ) >= 0
      ) {
        setAnnuityPlanValue("Safeguard Plus 7");
      } else if (
        individualAnnuityApplication.planAppliedFor.indexOf(
          "Safeguard Plus 10"
        ) >= 0
      ) {
        setAnnuityPlanValue("Safeguard Plus 10");
      } else {
        setAnnuityPlanValue("");
      }
    }
  };

  const ValueOfRiderSection = () => {
    if (individualAnnuityApplication.planAppliedFor === "") {
      return "";
    }
    // else{
    //     let tempValue1 = individualAnnuityApplication.planAppliedFor.split(",")[0];
    //     let tempValue2 = individualAnnuityApplication.planAppliedFor.split(",")[1];
    //     switch(tempValue1){
    //         case "5%Free Withdrawal Rider":
    //             setRiderValue(tempValue1);
    //             break;

    //         case "Enhanced Benefit Rider":
    //             setRiderValue(tempValue1);
    //             break;
    //     }
    //     if(tempValue2 !== ""){
    //         switch(tempValue2){
    //             case "5%Free Withdrawal Rider":
    //                 setRiderValue(tempValue2);
    //                 break;

    //             case "Enhanced Benefit Rider":
    //                 setRiderValue(tempValue2);
    //                 break;
    //         }
    //     }
    // }
    else {
      if (
        individualAnnuityApplication.planAppliedFor.indexOf(
          "5%Free Withdrawal Rider"
        ) >= 0
      ) {
        return "5%Free Withdrawal Rider";
      } else if (
        individualAnnuityApplication.planAppliedFor.indexOf(
          "Enhanced Benefit Rider"
        ) >= 0
      ) {
        return "Enhanced Benefit Rider";
      } else {
        return "";
      }
    }
  };

  const ChangeValueOfAnnuityPlan = (e) => {
    console.log("Printing value of plan", e.target.value);
    let temp = [];

    if (
      (annuityPlanValue === "" && riderValue === "") ||
      (annuityPlanValue !== "" && riderValue === "")
    ) {
      temp.push(e.target.value);
      CommonContextUpdate("planAppliedFor", temp);
    } else if (annuityPlanValue === "" && riderValue !== "") {
      let temp = individualAnnuityApplication.planAppliedFor;
      console.log("Second condition temp initial value", temp);
      CommonContextUpdate("planAppliedFor", `${temp}${e.target.value}`);
    } else if (annuityPlanValue !== "" && riderValue !== "") {
      let temp1 = individualAnnuityApplication.planAppliedFor.split(",")[0];
      let temp2 = individualAnnuityApplication.planAppliedFor.split(",")[1];
      if (annuityPlansAllCheckboxArray.indexOf(temp1) >= 0) {
        CommonContextUpdate("planAppliedFor", `${e.target.value},${temp2}`);
      } else {
        CommonContextUpdate("planAppliedFor", `${temp1},${e.target.value}`);
      }
    }
  };
  const ChangeValueOfRider = (e) => {
    console.log("Printing value of plan", e.target.value);
    if (
      (annuityPlanValue === "" && riderValue === "") ||
      (riderValue !== "" && annuityPlanValue === "")
    ) {
      CommonContextUpdate("planAppliedFor", `${e.target.value},`);
    } else if (riderValue === "" && annuityPlanValue !== "") {
      let temp = individualAnnuityApplication.planAppliedFor;
      console.log("Second condition temp initial value", temp);
      CommonContextUpdate("planAppliedFor", `${temp}${e.target.value}`);
    } else if (annuityPlanValue !== "" && riderValue !== "") {
      let temp1 = individualAnnuityApplication.planAppliedFor.split(",")[0];
      let temp2 = individualAnnuityApplication.planAppliedFor.split(",")[1];
      if (riderAllCheckboxArray.indexOf(temp1) >= 0) {
        CommonContextUpdate("planAppliedFor", `${e.target.value},${temp2}`);
      } else {
        CommonContextUpdate("planAppliedFor", `${temp1},${e.target.value}`);
      }
    }
  };

  const ConditionToShowReplacementForm = () => {
    if (
      individualAnnuityApplication.annuitantState !== "Tennessee" &&
      individualAnnuityApplication.annuitantState !== ""
    ) {
      if (individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected === "Non - Qualified") {
        if (individualAnnuityApplication.selectedNonQualifiedOptions !== "") {
          return true;
        } else {
          return false;
        }
      } else if (individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected === "Qualified") {
        if (
          individualAnnuityApplication.selectedQualifiedOptionsGroup1 !== "" &&
          individualAnnuityApplication.selectedQualifiedOptionsGroup2 !== ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  // useEffect(() => {
  //   CommonContextUpdate("expectedPremiumField", "");
  //   CommonContextUpdate("amountPaidWithApplication", "");
  // }, [individualAnnuityApplication.selectedNonQualifiedOptions, individualAnnuityApplication.selectedQualifiedOptionsGroup1])

  const IsRowRequired = (row) => {
    let flag = Object.keys(row)?.map((key) => {
      if (key !== 'id' && row[key] !== "" && row[key] !== null) {
        return true
      } else {
        return false
      }
    })
    if (flag.indexOf(true) > -1) {
      return true
    } else {
      return false
    }
  }

  console.log("individualAnnuityApplication.applicationReplacementFieldsArray====>", individualAnnuityApplication.applicationReplacementFieldsArray);
  console.log("---------------------------------------------------------------------------------------------");
  console.log("Premium Select the Tax Qualification status--->flagQualifiedOrNonQualifiedSelected:", individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected);
  console.log("Premium Select the plan qualified--->selectedQualifiedOptionsGroup1:", individualAnnuityApplication.selectedQualifiedOptionsGroup1);
  console.log("Premium Select the plan non-qualified--->selectedNonQualifiedOptions:", individualAnnuityApplication.selectedNonQualifiedOptions);
  console.log("Premium Amount Paid With Application--->amountPaidWithApplication:", individualAnnuityApplication.amountPaidWithApplication);
  console.log("Premium If a 1035 Exchange, Rollover or transfer is occurring, the expected premium Amount is--->expectedPremiumField:", individualAnnuityApplication.expectedPremiumField);
  console.log("Premium Select type of plan--->selectedQualifiedOptionsGroup2:", individualAnnuityApplication.selectedQualifiedOptionsGroup2);
  console.log("Premium Select type of plan othetr textfield--->otherSelectedQualifiedOptions:", individualAnnuityApplication.otherSelectedQualifiedOptions);
  console.log("---------------------------------------------------------------------------------------------");

  return (
    <>
      <Box className="application_page_root">
        <Box className="application_form">
          <Box className="application_form_card">
            <Box className="application_form_head ">
              <Box className="forsitemap"></Box>
              <Box className="application_form_head_1">
                <Typography variant="span">
                  Individual Annuity Application
                </Typography>
              </Box>
              <Box className="application_form_head_bottom_line">
                <Divider />
              </Box>
            </Box>

            <Box
              className="annuitant_head_root header_1"
              onClick={() => {
                // setFlagFirstSection(!flagFirstPageFirstSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageFirstSection",
                  data: !flagFirstPageFirstSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  1. Annuitant (Joint Annuitants are not permitted)
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageFirstSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFirstPageFirstSection === true ? (
              <Box className="annuitant_input_fields">
                <Box className="annuitant_fullname_and_ssn_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width req_fields"
                      id="standard-basic"
                      label="Annuitant full name"
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "annuitantFullName",
                          e.target.value
                        );
                      }}
                      value={individualAnnuityApplication.annuitantFullName}
                      disabled={disableAllInputs === true ? true : false}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className="input_field_width req_fields"
                      label="SSN/TIN"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        let targetLength = e.target.value.length;
                        let contextLength =
                          individualAnnuityApplication.annuitantSSN.length;
                        if (targetLength > contextLength) {
                          if (targetLength === 3 || targetLength === 6) {
                            let val = e.target.value + "-";
                            CommonContextUpdate("annuitantSSN", val);
                          } else if (e.target.value.length <= 11) {
                            CommonContextUpdate("annuitantSSN", e.target.value);
                          }
                          console.log(
                            "Printing value of e.target.value",
                            e.target.value
                          );
                          console.log(
                            "Printing value of annuitantSSN",
                            individualAnnuityApplication.annuitantSSN
                          );
                        } else {
                          if (targetLength === 7 || targetLength === 4) {
                            CommonContextUpdate(
                              "annuitantSSN",
                              e.target.value.slice(0, -1)
                            );
                          } else {
                            CommonContextUpdate("annuitantSSN", e.target.value);
                          }
                        }
                      }}
                      onBlur={(e) => {
                        var regx = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                        if (!regx.test(e.target.value)) {
                          CommonContextUpdate("annuitantSSN", "");
                        }
                      }}
                      value={individualAnnuityApplication.annuitantSSN}
                    />
                  </Box>
                </Box>
                <Box className="annuitant_gender_and_dateofbirth_row">
                  <Box className="annuitant_gender_selection left_section">
                    <FormControl
                      className="input_field_width"
                      variant="standard"
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false} >
                        Gender assigned at birth
                        {/* <span style={{ color: "red" }} color="red">
                          *
                        </span> */}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => {
                          CommonContextUpdate(
                            "annuitantGender",
                            e.target.value
                          );
                        }}
                        value={individualAnnuityApplication.annuitantGender}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className={disableAllInputs === true ? "annuitant_dateofbirth_field dobColorClass" : "annuitant_dateofbirth_field"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={disableAllInputs === true ? true : false}
                        className="input_field_width req_fields"
                        id="date_field"
                        label="Date Of Birth"
                        inputFormat="MM/dd/yyyy"
                        open={flagDatePopupOpenAnnuitant}
                        onClose={() => {
                          // setFlagClearClicked(false);
                          setFlagDatePopupOpenAnnuitant(false);
                        }}
                        onOpen={() => {
                          setFlagDatePopupOpenAnnuitant(true);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(e) => {
                          SetIndividualAnnuityApplication({
                            type: "annuitantDOB",
                            value: e,
                          });
                        }}
                        value={individualAnnuityApplication.annuitantDOB}
                      />
                    </LocalizationProvider>
                    <IconButton
                      onClick={() => {
                        SetIndividualAnnuityApplication({
                          type: "annuitantDOB",
                          value: "",
                        });
                        // setFlagClearClicked(true);
                        setFlagDatePopupOpenAnnuitant(false)
                      }}
                      className="dateCloseIcon"
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className="annuitant_streetaddress_and_zip_row ">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width req_fields"
                      type="text"
                      label="Street Address"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "annuitantStreetAddress",
                          e.target.value
                        );
                      }}
                      value={
                        individualAnnuityApplication.annuitantStreetAddress
                      }
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className="input_field_width req_fields"
                      error={annuitantValidationError?.flagAnnuitantZipValid ? true : false}
                      helperText={annuitantValidationError?.flagAnnuitantZipValid ? "ZIP number only contains 5 digits" : ""}
                      label="ZIP"
                      disabled={disableAllInputs === true ? true : false}
                      type="number"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("annuitantZip", e.target.value);
                        if (e.target.value?.length == 5) {
                          let temp = { ...annuitantValidationError }
                          temp.flagAnnuitantZipValid = false
                          setAnnuitantValidationError(temp)
                        }
                        if (e.target.value?.length > 5 || e.target.value?.length < 5) {
                          let temp = { ...annuitantValidationError }
                          temp.flagAnnuitantZipValid = true
                          setAnnuitantValidationError(temp)
                        }
                      }}
                      onBlur={() => {
                        let temp = { ...annuitantValidationError }
                        if (individualAnnuityApplication.annuitantZip?.length) {
                          temp.flagAnnuitantZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(individualAnnuityApplication.annuitantZip))
                          setAnnuitantValidationError(temp)
                        } else {
                          temp.flagAnnuitantZipValid = false
                          setAnnuitantValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.annuitantZip}
                    />
                  </Box>
                </Box>
                <Box className="annuitant_city_and_state_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width req_fields"
                      label="City"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("annuitantCity", e.target.value);
                      }}
                      value={individualAnnuityApplication.annuitantCity}
                    />
                  </Box>
                  <Box className="right_section">
                    {/* <TextField
                                            className="input_field_width req_fields"
                                            type="text"
                                            label="State "
                                            variant="standard"
                                            onChange={e => { CommonContextUpdate("annuitantState", e.target.value) }}
                                            value={individualAnnuityApplication.annuitantState}
                                        /> */}
                    <FormControl
                      className="input_field_width"
                      variant="standard"
                      style={{ width: "50%" }}
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        State
                      </InputLabel>
                      <Select
                        className="state_dropdown"
                        onChange={(e) => {
                          CommonContextUpdate("annuitantState", e.target.value);
                        }}
                        value={individualAnnuityApplication.annuitantState}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {StatesName.map((stateName) => {
                          return (
                            <MenuItem value={stateName}>{stateName}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="annuitant_phone_and_email_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width req_fields"
                      type="tel"
                      label="Phone"
                      error={annuitantValidationError.flagAnnuitantPhoneValid ? true : false}
                      helperText={annuitantValidationError.flagAnnuitantPhoneValid ? "Invaild Phone Number" : ""}
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                        if (onlyNums.length < 10) {
                          let temp = { ...annuitantValidationError }
                          temp.flagAnnuitantPhoneValid = true
                          setAnnuitantValidationError(temp)
                          CommonContextUpdate("annuitantPhone", onlyNums);
                        } else if (onlyNums.length === 10) {
                          let temp = { ...annuitantValidationError }
                          temp.flagAnnuitantPhoneValid = false
                          setAnnuitantValidationError(temp)
                          const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                          CommonContextUpdate("annuitantPhone", number);
                        }
                      }}
                      onBlur={() => {
                        let temp = { ...annuitantValidationError }
                        if (individualAnnuityApplication.annuitantPhone?.length) {
                          temp.flagAnnuitantPhoneValid = !(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(individualAnnuityApplication.annuitantPhone))
                          setAnnuitantValidationError(temp)
                        } else {
                          temp.flagAnnuitantPhoneValid = false
                          setAnnuitantValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.annuitantPhone}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className="input_field_width req_fields"
                      label="Email"
                      error={annuitantValidationError.flagAnnuitantEmailValid ? true : false}
                      helperText={annuitantValidationError.flagAnnuitantEmailValid ? "Invaild Email" : ""}
                      disabled={disableAllInputs === true ? true : false}
                      type="email"
                      variant="standard"
                      onChange={(e) => {
                        let temp = { ...annuitantValidationError }
                        if (e.target.value?.length) {
                          temp.flagAnnuitantEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))
                          setAnnuitantValidationError(temp)
                        } else {
                          temp.flagAnnuitantEmailValid = false
                          setAnnuitantValidationError(temp)
                        }
                        CommonContextUpdate("annuitantEmail", e.target.value);
                      }}
                      onBlur={() => {
                        let temp = { ...annuitantValidationError }
                        if (individualAnnuityApplication.annuitantEmail?.length) {
                          temp.flagAnnuitantEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(individualAnnuityApplication.annuitantEmail))
                          setAnnuitantValidationError(temp)
                        } else {
                          temp.flagAnnuitantEmailValid = false
                          setAnnuitantValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.annuitantEmail}
                    />
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Box
              className="annuitant_head_root"
              onClick={() => {
                // setFlagSecondSection(!flagFirstPageSecondSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageSecondSection",
                  data: !flagFirstPageSecondSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  2. Owner (If other than Annuitant)
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageSecondSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFirstPageSecondSection === true ? (
              <Box className="annuitant_owner_section">
                <Box className="annuitant_fullname_and_ssn_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width"
                      label="Owner Full Name"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("ownerFullName", e.target.value);
                      }}
                      value={individualAnnuityApplication.ownerFullName}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className="input_field_width"
                      label="SSN/TIN"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      onChange={(e) => {
                        let targetLength = e.target.value.length;
                        let contextLength =
                          individualAnnuityApplication.ownerSSN.length;
                        var regx = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;

                        if (targetLength > contextLength) {
                          if (targetLength === 3 || targetLength === 6) {
                            let val = e.target.value + "-";
                            CommonContextUpdate("ownerSSN", val);
                          } else if (e.target.value.length <= 11) {
                            CommonContextUpdate("ownerSSN", e.target.value);
                          }
                          console.log(
                            "Printing value of e.target.value",
                            e.target.value
                          );
                          console.log(
                            "Printing value of ownerSSN",
                            individualAnnuityApplication.ownerSSN
                          );
                        } else {
                          if (targetLength === 7 || targetLength === 4) {
                            CommonContextUpdate(
                              "ownerSSN",
                              e.target.value.slice(0, -1)
                            );
                          } else {
                            CommonContextUpdate("ownerSSN", e.target.value);
                          }
                        }
                      }}
                      onBlur={(e) => {
                        var regx = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                        if (!regx.test(e.target.value)) {
                          CommonContextUpdate("ownerSSN", "");
                        }
                      }}
                      value={individualAnnuityApplication.ownerSSN}
                      variant="standard"
                    />
                  </Box>
                </Box>
                <Box className="annuitant_gender_and_dateofbirth_row">
                  <Box
                    className="annuitant_gender_selection left_section"
                    style={{ width: "55%", position: "relative" }}
                  >
                    {/* <InputLabel >Gender</InputLabel> */}
                    <FormControl
                      className="input_field_width"
                      variant="standard"
                      style={{ width: "50%" }}
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        Gender assigned at birth
                      </InputLabel>
                      <Select
                        // className="input_field_width"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => {
                          CommonContextUpdate("ownerGender", e.target.value);
                        }}
                        value={individualAnnuityApplication.ownerGender}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Not Applicable"}>Not Applicable</MenuItem>
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className={disableAllInputs === true ? "annuitant_dateofbirth_field right_section dobColorClass" : "annuitant_dateofbirth_field right_section"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={disableAllInputs === true ? true : false}
                        label="Date Of Birth"
                        inputFormat="MM/dd/yyyy"
                        className="input_field_date"
                        renderInput={(params) => <TextField {...params} />}
                        open={flagDatePopupOpenOwner}
                        onClose={() => {
                          // setFlagClearClicked(false);
                          setFlagDatePopupOpenOwner(false);
                        }}
                        onOpen={() => {
                          setFlagDatePopupOpenOwner(true);
                        }}
                        onChange={(e, key) => {
                          SetIndividualAnnuityApplication({
                            type: "ownerDOB",
                            value: e,
                          });
                        }}
                        value={individualAnnuityApplication.ownerDOB}
                      />
                    </LocalizationProvider>
                    <IconButton
                      onClick={() => {
                        SetIndividualAnnuityApplication({
                          type: "ownerDOB",
                          value: "",
                        });
                        // setFlagClearClicked(true);
                        setFlagDatePopupOpenOwner(false)
                      }}
                      className="dateCloseIcon"
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className="annuitant_streetaddress_and_zip_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width"
                      type="text"
                      label="Street Address"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "ownerStreetAddress",
                          e.target.value
                        );
                      }}
                      value={individualAnnuityApplication.ownerStreetAddress}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className="input_field_width"
                      type="number"
                      error={ownerValidationError?.flagOwnerZipValid ? true : false}
                      helperText={ownerValidationError?.flagOwnerZipValid ? "ZIP number only contains 5 digits" : ""}
                      label="ZIP"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("ownerZip", e.target.value);
                        if (e.target.value?.length == 5) {
                          let temp = { ...ownerValidationError }
                          temp.flagOwnerZipValid = false
                          setOwnerValidationError(temp)
                        } else if (e.target.value?.length) {
                          let temp = { ...ownerValidationError }
                          temp.flagOwnerZipValid = true
                          setOwnerValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.ownerZip}
                      onBlur={() => {
                        let temp = { ...ownerValidationError }
                        if (individualAnnuityApplication.ownerZip?.length) {
                          temp.flagOwnerZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(individualAnnuityApplication.ownerZip))
                          setOwnerValidationError(temp)
                        } else {
                          temp.flagOwnerZipValid = false
                          setOwnerValidationError(temp)
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box className="annuitant_city_and_state_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width"
                      type="text"
                      label="City"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("ownerCity", e.target.value);
                      }}
                      value={individualAnnuityApplication.ownerCity}
                    />
                  </Box>
                  <Box className="right_section">
                    {/* <TextField
                                            className="input_field_width"
                                            type="text"
                                            label="State"
                                            variant="standard"
                                            onChange={e => { CommonContextUpdate("ownerState", e.target.value) }}
                                            value={individualAnnuityApplication.ownerState}
                                        /> */}
                    <FormControl
                      className="input_field_width"
                      variant="standard"
                      style={{ width: "50%" }}
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        State
                      </InputLabel>
                      <Select
                        className="state_dropdown"
                        onChange={(e) => {
                          CommonContextUpdate("ownerState", e.target.value);
                        }}
                        value={individualAnnuityApplication.ownerState}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {StatesName.map((stateName) => {
                          return (
                            <MenuItem value={stateName}>{stateName}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="annuitant_phone_and_email_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width"
                      type="email"
                      error={ownerValidationError?.flagOwnerEmailValid ? true : false}
                      helperText={ownerValidationError?.flagOwnerEmailValid ? "Invalid Email Address" : ""}
                      label="Email"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("ownerEmail", e.target.value);
                        let temp = { ...ownerValidationError }
                        if (e.target.value?.length) {
                          temp.flagOwnerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))
                          setOwnerValidationError(temp)
                        } else {
                          temp.flagOwnerEmailValid = false
                          setOwnerValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.ownerEmail}
                      onBlur={() => {
                        let temp = { ...ownerValidationError }
                        if (individualAnnuityApplication.ownerEmail?.length) {
                          temp.flagOwnerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(individualAnnuityApplication.ownerEmail))
                          setOwnerValidationError(temp)
                        } else {
                          temp.flagOwnerEmailValid = false
                          setOwnerValidationError(temp)
                        }
                      }}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className="input_field_width"
                      type="text"
                      label="Relationship To Annuitant"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "ownerRelationToAnnuitant",
                          e.target.value
                        );
                      }}
                      value={
                        individualAnnuityApplication.ownerRelationToAnnuitant
                      }
                    />
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Box
              className="annuitant_head_root"
              onClick={() => {
                // setFlagThirdSection(!flagFirstPageThirdSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageThirdSection",
                  data: !flagFirstPageThirdSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  3. Joint Spousal Owner (If any), WROS (Not available for
                  Qualified Funds)
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageThirdSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFirstPageThirdSection === true ? (
              <Box className="annuitant_owner_section annuitant_owner_section_bottom">
                <Box className="annuitant_fullname_and_ssn_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width"
                      label="Joint Spousal Owner Full Name"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "jointOwnerFullName",
                          e.target.value
                        );
                      }}
                      value={individualAnnuityApplication.jointOwnerFullName}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className="input_field_width"
                      label="SSN/TIN"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        let targetLength = e.target.value.length;
                        let contextLength =
                          individualAnnuityApplication.jointOwnerSSN.length;
                        var regx = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;

                        if (targetLength > contextLength) {
                          if (targetLength === 3 || targetLength === 6) {
                            let val = e.target.value + "-";
                            CommonContextUpdate("jointOwnerSSN", val);
                          } else if (e.target.value.length <= 11) {
                            CommonContextUpdate(
                              "jointOwnerSSN",
                              e.target.value
                            );
                          }
                          console.log(
                            "Printing value of e.target.value",
                            e.target.value
                          );
                          console.log(
                            "Printing value of jointOwnerSSN",
                            individualAnnuityApplication.jointOwnerSSN
                          );
                        } else {
                          if (targetLength === 7 || targetLength === 4) {
                            CommonContextUpdate(
                              "jointOwnerSSN",
                              e.target.value.slice(0, -1)
                            );
                          } else {
                            CommonContextUpdate(
                              "jointOwnerSSN",
                              e.target.value
                            );
                          }
                        }
                      }}
                      onBlur={(e) => {
                        var regx = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                        if (!regx.test(e.target.value)) {
                          CommonContextUpdate("jointOwnerSSN", "");
                        }
                      }}
                      value={individualAnnuityApplication.jointOwnerSSN}
                    />
                  </Box>
                </Box>
                <Box className="annuitant_gender_and_dateofbirth_row">
                  <Box
                    className="annuitant_gender_selection left_section"
                    style={{ width: "55%" }}
                  >
                    {/* <InputLabel >Gender</InputLabel> */}
                    <FormControl
                      className="input_field_width"
                      variant="standard"
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        Gender assigned at birth
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => {
                          CommonContextUpdate(
                            "jointOwnerGender",
                            e.target.value
                          );
                        }}
                        value={individualAnnuityApplication.jointOwnerGender}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Not Applicable"}>Not Applicable</MenuItem>
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className={disableAllInputs === true ? "annuitant_dateofbirth_field right_section dobColorClass" : "annuitant_dateofbirth_field right_section"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={disableAllInputs === true ? true : false}
                        label="Date Of Birth"
                        inputFormat="MM/dd/yyyy"
                        className="input_field_date"
                        renderInput={(params) => <TextField {...params} />}
                        open={flagDatePopupOpenSpousal}
                        onClose={() => {
                          // setFlagClearClicked(false);
                          setFlagDatePopupOpenSpousal(false);
                        }}
                        onOpen={() => {
                          setFlagDatePopupOpenSpousal(true);
                        }}
                        onChange={(e) => {
                          SetIndividualAnnuityApplication({
                            type: "jointOwnerDOB",
                            value: e,
                          });
                        }}
                        value={individualAnnuityApplication.jointOwnerDOB}
                      />
                    </LocalizationProvider>
                    <IconButton
                      onClick={() => {
                        SetIndividualAnnuityApplication({
                          type: "jointOwnerDOB",
                          value: "",
                        });
                        // setFlagClearClicked(true);
                        setFlagDatePopupOpenSpousal(false)
                      }}
                      className="dateCloseIcon"
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className="annuitant_streetaddress_and_zip_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width"
                      label="Street Address"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "jointOwnerStreetAddress",
                          e.target.value
                        );
                      }}
                      value={
                        individualAnnuityApplication.jointOwnerStreetAddress
                      }
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className="input_field_width"
                      label="ZIP"
                      error={jointOwnerValidationError?.flagJointOwnerZipValid ? true : false}
                      helperText={jointOwnerValidationError?.flagJointOwnerZipValid ? "ZIP number only contains 5 digits" : ""}
                      disabled={disableAllInputs === true ? true : false}
                      type="number"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("jointOwnerZip", e.target.value);
                        let temp = { ...jointOwnerValidationError }
                        if (e.target.value?.length) {
                          temp.flagJointOwnerZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(e.target.value))
                          setJointOwnerValidationError(temp)
                        } else {
                          temp.flagJointOwnerZipValid = false
                          setJointOwnerValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.jointOwnerZip}
                      onBlur={() => {
                        let temp = { ...jointOwnerValidationError }
                        if (individualAnnuityApplication.jointOwnerZip?.length) {
                          temp.flagJointOwnerZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(individualAnnuityApplication.jointOwnerZip))
                          setJointOwnerValidationError(temp)
                        } else {
                          temp.flagJointOwnerZipValid = false
                          setJointOwnerValidationError(temp)
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box className="annuitant_city_and_state_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width"
                      label="City"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("jointOwnerCity", e.target.value);
                      }}
                      value={individualAnnuityApplication.jointOwnerCity}
                    />
                  </Box>
                  <Box className="right_section">
                    {/* <TextField
                                            className="input_field_width"
                                            label="State"
                                            type="text"
                                            variant="standard"
                                            onChange={e => { CommonContextUpdate("jointOwnerState", e.target.value) }}
                                            value={individualAnnuityApplication.jointOwnerState}
                                        /> */}
                    <FormControl
                      className="input_field_width"
                      variant="standard"
                      style={{ width: "50%" }}
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        State
                      </InputLabel>
                      <Select
                        className="state_dropdown"
                        onChange={(e) => {
                          CommonContextUpdate(
                            "jointOwnerState",
                            e.target.value
                          );
                        }}
                        value={individualAnnuityApplication.jointOwnerState}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {StatesName.map((stateName) => {
                          return (
                            <MenuItem value={stateName}>{stateName}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="annuitant_phone_and_email_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width"
                      label="Email"
                      error={jointOwnerValidationError?.flagJointOwnerEmailValid ? true : false}
                      helperText={jointOwnerValidationError?.flagJointOwnerEmailValid ? "Invalid Email Address" : ""}
                      disabled={disableAllInputs === true ? true : false}
                      type="email"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("jointOwnerEmail", e.target.value);
                        let temp = { ...jointOwnerValidationError }
                        if (e.target.value?.length) {
                          temp.flagJointOwnerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))
                          setJointOwnerValidationError(temp)
                        } else {
                          temp.flagJointOwnerEmailValid = false
                          setJointOwnerValidationError(temp)
                        }
                      }}
                      onBlur={() => {
                        let temp = { ...jointOwnerValidationError }
                        if (individualAnnuityApplication.jointOwnerEmail?.length) {
                          temp.flagJointOwnerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(individualAnnuityApplication.jointOwnerEmail))
                          setJointOwnerValidationError(temp)
                        } else {
                          temp.flagJointOwnerEmailValid = false
                          setJointOwnerValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.jointOwnerEmail}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className="input_field_width"
                      type="text"
                      label="Relationship To Annuitant"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "jointOwnerRelationToAnnuitant",
                          e.target.value
                        );
                      }}
                      value={
                        individualAnnuityApplication.jointOwnerRelationToAnnuitant
                      }
                    />
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Box
              className="annuitant_head_root"
              onClick={() => {
                // setFlagFourthSection(!flagFirstPageFourthSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageFourthSection",
                  data: !flagFirstPageFourthSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  4. Plan/Riders Applied For (Please check one)
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageFourthSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFirstPageFourthSection === true ? (
              <Box className="plan_rider_section">
                {/* <Box className="plan_rider_checkboxes">
                                    <RadioGroup name="plan_applied_for" value={individualAnnuityApplication.planAppliedFor} onChange={e => CommonContextUpdate("planAppliedFor", e.target.value)} style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row" }}>
                                        <FormControlLabel disabled={disableAllInputs === true ? true : false} control={<Radio value={"5%Free Withdrawal Rider"} icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />} label="5% Free Withdrawal Rider" />
                                        <FormControlLabel disabled={disableAllInputs === true ? true : false} control={<Radio value={"Enhanced Benefit Rider"} icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />} label="Enhanced Benefit Rider" />
                                        <FormControlLabel disabled={disableAllInputs === true ? true : false} control={<Radio value={"Safeguard Plus 3"} icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />} label="Safeguard Plus 3" />
                                        <FormControlLabel disabled={disableAllInputs === true ? true : false} control={<Radio value={"Safeguard Plus 5"} icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />} label="Safeguard Plus 5" />
                                        <FormControlLabel disabled={disableAllInputs === true ? true : false} control={<Radio value={"Safeguard Plus 7"} icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />} label="Safeguard Plus 7" />
                                        <FormControlLabel disabled={disableAllInputs === true ? true : false} control={<Radio value={"Safeguard Plus 10"} icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />} label="Safeguard Plus 10" />
                                    </RadioGroup>
                                </Box> */}
                <Box className="annuity_plan_section">
                  <Typography variant="span">
                    1. Annuity Plans<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <RadioGroup
                    row={true}
                    value={annuityPlanValue}
                    className="radio_group_shift_right"
                    onChange={(e) => {
                      let temp = [
                        ...individualAnnuityApplication.planAppliedFor,
                      ];

                      if (temp.indexOf("Safeguard Plus 3") >= 0) {
                        temp = temp.filter((v) => {
                          if (v !== "Safeguard Plus 3") {
                            return v;
                          }
                        });

                        temp.push(e.target.value);
                      } else if (temp.indexOf("Safeguard Plus 5") >= 0) {
                        temp = temp.filter((v) => {
                          if (v !== "Safeguard Plus 5") {
                            return v;
                          }
                        });

                        temp.push(e.target.value);
                      } else if (temp.indexOf("Safeguard Plus 7") >= 0) {
                        temp = temp.filter((v) => {
                          if (v !== "Safeguard Plus 7") {
                            return v;
                          }
                        });

                        temp.push(e.target.value);
                      } else if (temp.indexOf("Safeguard Plus 10") >= 0) {
                        temp = temp.filter((v) => {
                          if (v !== "Safeguard Plus 10") {
                            return v;
                          }
                        });

                        temp.push(e.target.value);
                      } else {
                        temp.push(e.target.value);
                      }

                      console.log(
                        "Printing temp after updating annuity plan section",
                        temp
                      );
                      CommonContextUpdate("planAppliedFor", temp);
                    }}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Safeguard Plus 3"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Safeguard Plus 3"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Safeguard Plus 5"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Safeguard Plus 5"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Safeguard Plus 7"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Safeguard Plus 7"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Safeguard Plus 10"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Safeguard Plus 10"
                    />
                  </RadioGroup>
                </Box>
                <Box className="rider_section">
                  <Typography variant="span">2. Rider</Typography>
                  <FormGroup row={true} className="radio_group_shift_right">
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Checkbox
                          checked={individualAnnuityApplication.planAppliedFor.includes(
                            "5%Free Withdrawal Rider"
                          )}
                          onChange={(e) => {
                            let temp = [
                              ...individualAnnuityApplication.planAppliedFor,
                            ];
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log(
                              "Printing updated value of planAppliedFor",
                              temp
                            );

                            CommonContextUpdate("planAppliedFor", temp);
                          }}
                          value={"5%Free Withdrawal Rider"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="5% Free Withdrawal Rider"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Checkbox
                          checked={individualAnnuityApplication.planAppliedFor.includes(
                            "Enhanced Benefit Rider"
                          )}
                          onChange={(e) => {
                            let temp = [
                              ...individualAnnuityApplication.planAppliedFor,
                            ];
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log(
                              "Printing updated value of planAppliedFor",
                              temp
                            );

                            CommonContextUpdate("planAppliedFor", temp);
                          }}
                          value={"Enhanced Benefit Rider"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Enhanced Benefit Rider"
                    />
                  </FormGroup>
                </Box>
              </Box>
            ) : null}
            {/* Beneficiaries Section Start */}
            <Box
              className="annuitant_head_root"
              onClick={() => {
                // setFlagFifthSection(!flagFirstPageFifthSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageFifthSection",
                  data: !flagFirstPageFifthSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">5. Beneficiaries</Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageFifthSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFirstPageFifthSection === true ? (
              <Box className="beneficiaries_section">
                <Typography variant="span">
                  If joint owners are named, on the death of either joint owner
                  the surviving joint owner will become the sole primary
                  beneficiary, and the beneficiaries listed below will be
                  considered contingent beneficiaries (unless otherwise
                  requested in the special requests section).
                </Typography>
                <Box className="beneficiaries_section_fields">
                  {individualAnnuityApplication.beneficiariesPrimary.map(
                    (row, i, arr) => {
                      console.log(
                        "Printing row for beneficiaries primary",
                        row
                      );
                      let flagRequired = IsRowRequired(row)
                      // debugger;
                      return (
                        <Box className="beneficiaries_section_row">
                          <Box className="beneficiaries_section_row_1">
                            <TextField
                              label="Primary Full Name"
                              disabled={disableAllInputs === true ? true : false}
                              variant="standard"
                              type="text"
                              className={i == 0 ? "beneficiaries_field_width beneficiaries_field_width_name" : flagRequired ? "beneficiaries_field_width_name beneficiaries_field_width" : "beneficiaries_field_width_name beneficiaries_field_width not_required"}
                              value={row.primaryField}
                              onChange={(e) => {
                                let temp = arr;
                                console.log(temp);
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    v.primaryField = e.target.value;
                                  }
                                  return v;
                                });
                                console.log("context", temp);
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesPrimary",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_2">
                            <TextField
                              label="%"
                              type="number"
                              disabled={disableAllInputs === true ? true : false}
                              className={i == 0 ? "beneficiaries_field_width beneficiaries_field_width_per" : flagRequired ? "beneficiaries_field_width_per beneficiaries_field_width" : "beneficiaries_field_width_per beneficiaries_field_width not_required"}
                              variant="standard"
                              value={
                                row.percentageField == -1
                                  ? ""
                                  : row.percentageField
                              }
                              onChange={(e) => {
                                let temp = arr;
                                console.log(temp);
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    v.percentageField = e.target.value;
                                  }
                                  return v;
                                });
                                console.log("context", temp);
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesPrimary",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_2">
                            <TextField
                              label="Relationship"
                              type="text"
                              disabled={disableAllInputs === true ? true : false}
                              className={i == 0 ? "beneficiaries_field_width beneficiaries_field_width_relation" : flagRequired ? "beneficiaries_field_width_relation beneficiaries_field_width" : "beneficiaries_field_width_relation beneficiaries_field_width not_required"}
                              variant="standard"
                              value={
                                row.relationshipField == -1
                                  ? ""
                                  : row.relationshipField
                              }
                              onChange={(e) => {
                                let temp = arr;
                                console.log(temp);
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    v.relationshipField = e.target.value;
                                  }
                                  return v;
                                });
                                console.log("context", temp);
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesPrimary",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_3">
                            <TextField
                              sx={{ width: 140 }}
                              label="SSN/TIN"
                              type="text"
                              disabled={disableAllInputs === true ? true : false}
                              className={i == 0 ? "beneficiaries_field_width beneficiaries_field_width_ssn" : flagRequired ? "beneficiaries_field_width_ssn beneficiaries_field_width" : "beneficiaries_field_width_ssn beneficiaries_field_width not_required"}
                              variant="standard"
                              value={row.ssnField}
                              onBlur={(e) => {
                                let temp = arr;
                                console.log(arr);
                                var regx =
                                  /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                                temp = temp.filter((v) => {
                                  if (row?.id === v?.id) {
                                    if (!regx.test(e.target.value)) {
                                      v.ssnField = "";
                                    }
                                  }
                                  return v;
                                });
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesPrimary",
                                  value: temp,
                                });
                              }}
                              onChange={(e) => {
                                let temp = arr;
                                console.log(temp);
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    // let targetLength = e.target.value.length;
                                    // let contextLength = individualAnnuityApplication.annuitantSSN.length;
                                    // var regx = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;

                                    // if (targetLength > contextLength) {
                                    //     if (targetLength === 3 || targetLength === 6) {
                                    //         let val = e.target.value + "-";
                                    //         CommonContextUpdate("annuitantSSN", val)
                                    //     }
                                    //     else if (e.target.value.length <= 11) {
                                    //         CommonContextUpdate("annuitantSSN", e.target.value)
                                    //     }
                                    //     console.log("Printing value of e.target.value", e.target.value);
                                    //     console.log("Printing value of annuitantSSN", individualAnnuityApplication.annuitantSSN);
                                    // }
                                    // else {
                                    //     if (targetLength === 7 || targetLength === 4) {
                                    //         CommonContextUpdate("annuitantSSN", e.target.value.slice(0, -1))
                                    //     }
                                    //     else {
                                    //         CommonContextUpdate("annuitantSSN", e.target.value);
                                    //     }
                                    // }
                                    let targetLength = e.target.value.length;
                                    let contextLength = v.ssnField.length;
                                    var regx = /[0-9/-]\d/gi;

                                    if (targetLength > contextLength) {
                                      if (
                                        targetLength === 3 ||
                                        targetLength === 6
                                      ) {
                                        let val = e.target.value + "-";
                                        v.ssnField = val;
                                      } else if (e.target.value.length <= 11) {
                                        v.ssnField = e.target.value;
                                      }
                                      console.log(
                                        "Printing value of e.target.value",
                                        e.target.value
                                      );
                                      console.log(
                                        "Printing value of annuitantSSN",
                                        v.ssnField
                                      );
                                    } else {
                                      if (
                                        targetLength === 7 ||
                                        targetLength === 4
                                      ) {
                                        v.ssnField = e.target.value.slice(
                                          0,
                                          -1
                                        );
                                      } else {
                                        v.ssnField = e.target.value;
                                      }
                                    }
                                  }
                                  return v;
                                });
                                console.log("context", temp);
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesPrimary",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="annuitant_gender_selection annuitant_gender_selection_gender1">
                            <FormControl
                              variant="standard"
                              className={i == 0 ? "annuitant_gender_selection_gender2" : flagRequired ? "annuitant_gender_selection_gender2" : "annuitant_gender_selection_gender2 not_required"}
                            >
                              <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                                Gender assigned at birth
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                className="annuitant_gender_selection_gender3"
                                id="demo-simple-select"
                                sx={{ width: 170 }}
                                value={row.genderField}
                                onChange={(e) => {
                                  let temp = arr;
                                  console.log(temp);
                                  temp = temp.filter((v) => {
                                    if (v?.id === row?.id) {
                                      v.genderField = e.target.value;
                                    }
                                    return v;
                                  });
                                  console.log("context", temp);
                                  SetIndividualAnnuityApplication({
                                    type: "beneficiariesPrimary",
                                    value: temp,
                                  });
                                }}
                                disabled={
                                  disableAllInputs === true ? true : false
                                }
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                      );
                    }
                  )}
                  {individualAnnuityApplication.beneficiariesContigent.map(
                    (row, i, arr) => {
                      console.log(row);
                      let flagRequired = IsRowRequired(row)
                      return (
                        <Box className="beneficiaries_section_row">
                          <Box className="beneficiaries_section_row_1">
                            <TextField
                              label="Contingent Full Name"
                              disabled={disableAllInputs === true ? true : false}
                              type="text"
                              variant="standard"
                              className={i == 0 ? "beneficiaries_field_width beneficiaries_field_width_name" : flagRequired ? "beneficiaries_field_width_name beneficiaries_field_width" : "beneficiaries_field_width_name beneficiaries_field_width not_required"}
                              value={row.contigentField}
                              onChange={(e) => {
                                let temp = arr;
                                console.log(arr);
                                temp = temp.filter((v) => {
                                  if (row?.id === v?.id) {
                                    v.contigentField = e.target.value;
                                  }
                                  return v;
                                });
                                console.log("context", temp);
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesContigent",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_2">
                            <TextField
                              label="%"
                              type="number"
                              variant="standard"
                              disabled={disableAllInputs === true ? true : false}
                              className={i == 0 ? "beneficiaries_field_width beneficiaries_field_width_per" : flagRequired ? "beneficiaries_field_width_per beneficiaries_field_width" : "beneficiaries_field_width_per beneficiaries_field_width not_required"}
                              value={
                                row.percentageField == -1
                                  ? ""
                                  : row.percentageField
                              }
                              onChange={(e) => {
                                let temp = arr;
                                console.log(arr);
                                temp = temp.filter((v) => {
                                  if (row?.id === v?.id) {
                                    v.percentageField = e.target.value;
                                  }
                                  return v;
                                });
                                console.log("context", temp);
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesContigent",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_2">
                            <TextField
                              label="Relationship"
                              type="text"
                              disabled={disableAllInputs === true ? true : false}
                              className={i == 0 ? "beneficiaries_field_width beneficiaries_field_width_relation" : flagRequired ? "beneficiaries_field_width_relation beneficiaries_field_width" : "beneficiaries_field_width_relation beneficiaries_field_width not_required"}
                              variant="standard"
                              value={
                                row.relationshipField == -1
                                  ? ""
                                  : row.relationshipField
                              }
                              onChange={(e) => {
                                let temp = arr;
                                console.log(temp);
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    v.relationshipField = e.target.value;
                                  }
                                  return v;
                                });
                                console.log("context", temp);
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesContigent",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_3">
                            <TextField
                              label="SSN/TIN"
                              type="text"
                              disabled={disableAllInputs === true ? true : false}
                              className={i == 0 ? "beneficiaries_field_width beneficiaries_field_width_ssn" : flagRequired ? "beneficiaries_field_width_ssn beneficiaries_field_width" : "beneficiaries_field_width_ssn beneficiaries_field_width not_required"}
                              variant="standard"
                              value={row.ssnField}
                              onBlur={(e) => {
                                let temp = arr;
                                console.log(arr);
                                var regx =
                                  /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                                temp = temp.filter((v) => {
                                  if (row?.id === v?.id) {
                                    if (!regx.test(e.target.value)) {
                                      v.ssnField = "";
                                    }
                                  }
                                  return v;
                                });

                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesContigent",
                                  value: temp,
                                });
                              }}
                              onChange={(e) => {
                                let temp = arr;
                                console.log(arr);
                                temp = temp.filter((v) => {
                                  if (row?.id === v?.id) {
                                    let targetLength = e.target.value.length;
                                    let contextLength = v.ssnField.length;

                                    if (targetLength > contextLength) {
                                      if (
                                        targetLength === 3 ||
                                        targetLength === 6
                                      ) {
                                        let val = e.target.value + "-";
                                        v.ssnField = val;
                                      } else if (e.target.value.length <= 11) {
                                        v.ssnField = e.target.value;
                                      }
                                      console.log(
                                        "Printing value of e.target.value",
                                        e.target.value
                                      );
                                      console.log(
                                        "Printing value of annuitantSSN",
                                        v.ssnField
                                      );
                                    } else {
                                      if (
                                        targetLength === 7 ||
                                        targetLength === 4
                                      ) {
                                        v.ssnField = e.target.value.slice(
                                          0,
                                          -1
                                        );
                                      } else {
                                        v.ssnField = e.target.value;
                                      }
                                    }
                                  }
                                  return v;
                                });
                                console.log("context", temp);
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesContigent",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="annuitant_gender_selection annuitant_gender_selection_gender1">
                            <FormControl
                              variant="standard"
                              className={i == 0 ? "beneficiaries_field_width annuitant_gender_selection_gender2" : flagRequired ? "annuitant_gender_selection_gender2 beneficiaries_field_width" : "annuitant_gender_selection_gender2 beneficiaries_field_width not_required"}
                            >
                              <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                                Gender assigned at birth
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                className="annuitant_gender_selection_gender3 "
                                id="demo-simple-select"
                                sx={{ width: 170 }}
                                value={row.genderField}
                                onChange={(e) => {
                                  let temp = arr;
                                  console.log(arr);
                                  temp = temp.filter((v) => {
                                    if (row?.id === v?.id) {
                                      v.genderField = e.target.value;
                                    }
                                    return v;
                                  });
                                  console.log("context", temp);
                                  SetIndividualAnnuityApplication({
                                    type: "beneficiariesContigent",
                                    value: temp,
                                  });
                                }}
                                disabled={
                                  disableAllInputs === true ? true : false
                                }
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Box>
                <Box
                  className="total_percentage_beneficiaries"
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {/* <Typography fontWeight={"bold"}>Total: <span style={ primaryBeneficiariesPercentage > 100 ? {color: "red"} : {color: "#000"} 
                                    && contigentBeneficiariesPercentage > 100 ? {color: "red"} : {color: "white"}}>{primaryBeneficiariesPercentage + contigentBeneficiariesPercentage}</span>%</Typography> */}
                  <Typography fontWeight={"bold"}>
                    Primary Total:{" "}
                    <span
                      style={
                        primaryBeneficiariesPercentage !== 100 &&
                          primaryBeneficiariesPercentage !== 0
                          ? { color: "red" }
                          : { color: "#000" }
                      }
                    >
                      {primaryBeneficiariesPercentage}%
                    </span>
                  </Typography>
                  <Typography fontWeight={"bold"}>
                    Contingent Total:{" "}
                    <span
                      style={
                        contigentBeneficiariesPercentage !== 100 &&
                          contigentBeneficiariesPercentage !== 0
                          ? { color: "red" }
                          : { color: "#000" }
                      }
                    >
                      {contigentBeneficiariesPercentage}%
                    </span>
                  </Typography>
                </Box>
              </Box>
            ) : null}
            {/* Beneficiaries Section End */}
            {/* Premium and Tax Qualification Status Section Start */}
            <Box
              className="annuitant_head_root"
              onClick={() => {
                // setFlagSixthSection(!flagFirstPageSixthSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageSixthSection",
                  data: !flagFirstPageSixthSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  6. Premium and Tax Qualification Status
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageSixthSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFirstPageSixthSection === true ? (
              <Box
                className="premium_and_tax_qualification_status_main"
                width={"80%"}
              >
                <Box style={{ margin: "5px 10px 10px 0" }}>
                  <Typography variant="span">
                    <InputLabel style={{ fontSize: "13px" }}>
                      Select the Tax Qualification status
                    </InputLabel>
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                  }}
                  className="radio_group_shift_right"
                >
                  <Button
                    disabled={disableAllInputs === true ? true : false}
                    style={{
                      marginRight: "40px",
                      background:
                        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                          "Non - Qualified"
                          ? "rgb(7, 74, 110)"
                          : "white",
                      color:
                        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                          "Non - Qualified"
                          ? "white"
                          : "rgb(7, 74, 110)",
                      textTransform: "capitalize",
                      border: "1px solid rgb(7, 74, 110)",
                    }}
                    variant={
                      individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                        "Non - Qualified"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={(e) => {
                      if (
                        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                        "Non - Qualified"
                      ) {
                        CommonContextUpdate(
                          "flagQualifiedOrNonQualifiedSelected",
                          ""
                        );
                        SetIndividualAnnuityApplication({
                          type: "selectedNonQualifiedOptions",
                          value: "",
                        })
                      } else {
                        CommonContextUpdate(
                          "flagQualifiedOrNonQualifiedSelected",
                          "Non - Qualified"
                        );
                      }
                      CommonContextUpdate("amountPaidWithApplication", "");
                      CommonContextUpdate("expectedPremiumField", "");
                      SetIndividualAnnuityApplication({
                        type: "selectedQualifiedOptionsGroup1",
                        value: "",
                      });
                      SetIndividualAnnuityApplication({
                        type: "selectedQualifiedOptionsGroup2",
                        value: "",
                      });
                    }}
                  >
                    Non - Qualified
                  </Button>
                  <Button
                    disabled={disableAllInputs === true ? true : false}
                    style={{
                      background:
                        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                          "Qualified"
                          ? "rgb(7, 74, 110)"
                          : "white",
                      color:
                        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                          "Qualified"
                          ? "white"
                          : "rgb(7, 74, 110)",
                      textTransform: "capitalize",

                      border: "1px solid rgb(7, 74, 110)",
                    }}
                    variant={
                      individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                        "Qualified"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={(e) => {
                      if (
                        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                        "Qualified"
                      ) {
                        CommonContextUpdate(
                          "flagQualifiedOrNonQualifiedSelected",
                          ""
                        );
                        CommonContextUpdate("amountPaidWithApplication", "");
                        CommonContextUpdate("expectedPremiumField", "");
                        SetIndividualAnnuityApplication({
                          type: "selectedQualifiedOptionsGroup1",
                          value: "",
                        });
                        SetIndividualAnnuityApplication({
                          type: "selectedQualifiedOptionsGroup2",
                          value: "",
                        });
                      } else {
                        CommonContextUpdate(
                          "flagQualifiedOrNonQualifiedSelected",
                          "Qualified"
                        );
                      }
                      CommonContextUpdate("amountPaidWithApplication", "");
                      CommonContextUpdate("expectedPremiumField", "");
                      SetIndividualAnnuityApplication({
                        type: "selectedNonQualifiedOptions",
                        value: "",
                      })
                    }}
                  >
                    Qualified
                  </Button>
                </Box>
                <Box>
                  {individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                    "Non - Qualified" &&
                    individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected !==
                    "Qualified" ? (
                    <>
                      <Box style={{ margin: "10px 0px 0px 15px" }}>
                        <Typography variant="span">
                          <InputLabel style={{ fontSize: "13px" }}>
                            Select the plan
                          </InputLabel>
                        </Typography>
                      </Box>
                      <Box
                        className="ptqs_non_qualified_options radio_group_shift_right"
                        style={{
                          padding: "5px 0px 5px 10px",
                          background: "#fff",
                          borderRadius: "10px",
                        }}
                      >
                        <RadioGroup
                          name="non_qualified_option"
                          className="radio_group_shift_right"
                          style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                          }}
                          value={
                            individualAnnuityApplication.selectedNonQualifiedOptions
                          }
                          onChange={(e) => {
                            if (e.target.value == "Cash with application") {
                              CommonContextUpdate("expectedPremiumField", "");
                            } else if (e.target.value == "1035 exchange") {
                              CommonContextUpdate("amountPaidWithApplication", "");
                            }
                            SetIndividualAnnuityApplication({
                              type: "selectedNonQualifiedOptions",
                              value: e.target.value,
                            });
                          }}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            style={{ marginRight: 0 }}
                            control={
                              <Radio
                                value={"Cash with application"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Cash with application"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            style={{ marginRight: 0 }}
                            control={
                              <Radio
                                value={"1035 exchange"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="1035 exchange"
                          />
                        </RadioGroup>
                      </Box>
                    </>
                  ) : null}
                  {individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                    "Qualified" &&
                    individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected !==
                    "Non - Qualified" ? (
                    <>
                      <Box style={{ margin: "10px 0px 0px 15px" }}>
                        <Typography variant="span">
                          {" "}
                          <InputLabel style={{ fontSize: "13px" }}>
                            Select the plan
                          </InputLabel>
                        </Typography>
                      </Box>

                      <Box
                        className="ptqs_qualified_options radio_group_shift_right"
                        style={{
                          padding: "5px 5px 5px 10px",
                          background: "#fff",
                          borderRadius: "10px",
                          display: "flex",
                        }}
                      >
                        <RadioGroup
                          name="qualified_option"
                          className="radio_group_shift_right"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                          value={
                            individualAnnuityApplication.selectedQualifiedOptionsGroup1
                          }
                          onChange={(e) => {
                            if (
                              e.target.value !== "Other" &&
                              individualAnnuityApplication.otherSelectedQualifiedOptions !==
                              ""
                            ) {
                              CommonContextUpdate(
                                "otherSelectedQualifiedOptions",
                                ""
                              );
                            }
                            SetIndividualAnnuityApplication({
                              type: "selectedQualifiedOptionsGroup1",
                              value: e.target.value,
                            });
                          }}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            style={{ marginRight: 0 }}
                            control={
                              <Radio
                                value={"Cash with application"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Cash with application"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            style={{ marginRight: 0 }}
                            control={
                              <Radio
                                value={"Transfer"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Transfer"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            style={{ marginRight: 0 }}
                            control={
                              <Radio
                                value={"Rollover"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Rollover"
                          />
                        </RadioGroup>
                      </Box>
                    </>
                  ) : null}
                </Box>
                <Box>
                  {individualAnnuityApplication.selectedQualifiedOptionsGroup1 !==
                    "" &&
                    individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                    "Qualified" && (
                      <>
                        <Box style={{ margin: "10px 10px 0px 15px" }}>
                          <Typography variant="span">
                            <InputLabel style={{ fontSize: "13px" }}>
                              Select type of plan
                            </InputLabel>
                          </Typography>
                        </Box>
                        <RadioGroup
                          value={
                            individualAnnuityApplication.selectedQualifiedOptionsGroup2
                          }
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            marginLeft: "30px"
                          }}
                          onChange={(e) => {
                            // debugger;
                            if (
                              e.target.value !== "Other" &&
                              individualAnnuityApplication.otherSelectedQualifiedOptions !==
                              ""
                            ) {
                              // debugger;
                              CommonContextUpdate(
                                "otherSelectedQualifiedOptions",
                                ""
                              );
                            }
                            SetIndividualAnnuityApplication({
                              type: "selectedQualifiedOptionsGroup2",
                              value: e.target.value,
                            });
                          }}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"IRA"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="IRA"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Roth IRA"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Roth IRA"
                          />
                          <FormControlLabel
                            sx={{ display: "none" }}
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"SEP"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="SEP"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={
                                  "Pension Plan(Profit sharing or defined benefit)"
                                }
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Pension Plan(Profit sharing or defined benefit)"
                          />
                          <br />
                          <Box>
                            <FormControlLabel
                              disabled={
                                disableAllInputs === true ? true : false
                              }
                              control={
                                <Radio
                                  value={"Other"}
                                  icon={<CheckBoxOutlineBlankIcon />}
                                  checkedIcon={<CheckBoxIcon />}
                                />
                              }
                              label="Other"
                            />
                            <TextField
                              variant="standard"
                              type="text"
                              required
                              className="req_fields"
                              placeholder={
                                individualAnnuityApplication.selectedQualifiedOptionsGroup2 ===
                                  "Other"
                                  ? "This field is required"
                                  : ""
                              }
                              value={
                                individualAnnuityApplication.otherSelectedQualifiedOptions
                              }
                              onChange={(e) => {
                                CommonContextUpdate(
                                  "otherSelectedQualifiedOptions",
                                  e.target.value
                                );
                              }}
                              disabled={
                                flagQualifiedMenu === true &&
                                  individualAnnuityApplication.selectedQualifiedOptionsGroup2 ===
                                  "Other"
                                  ? false
                                  : true
                              }
                              style={{
                                border: "none",
                                outline: "none",
                                borderBottom: "1px solid #b3b3b3",
                              }}
                            />
                          </Box>
                        </RadioGroup>
                      </>
                    )}
                </Box>

                {(individualAnnuityApplication.selectedQualifiedOptionsGroup1 ===
                  "Cash with application" &&
                  individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                  "Qualified" &&
                  individualAnnuityApplication.selectedQualifiedOptionsGroup2 !==
                  "") ||
                  (individualAnnuityApplication.selectedNonQualifiedOptions ==
                    "Cash with application" &&
                    individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                    "Non - Qualified") ? (
                  <Box
                    className="amount_to_paid_with_application"
                    style={{ margin: "5px 0px 5px 30px" }}
                  >
                    <InputLabel style={{ fontSize: "13px" }}>
                      Amount Paid With Application
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </InputLabel>
                    <Input
                      style={{ width: "100%" }}
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      onChange={(e) => {
                        console.log(e.target.value);
                        console.log(e.target.value.replaceAll(",", ""));
                        if (isNaN(e.target.value.replaceAll(",", "")) === false)
                          CommonContextUpdate(
                            "amountPaidWithApplication",
                            e.target.value.replaceAll(",", "")
                          );
                        if (
                          individualAnnuityApplication.selectedNonQualifiedOptions ==
                          "Cash with application" ||
                          individualAnnuityApplication.selectedQualifiedOptionsGroup1 ===
                          "Cash with application"
                        ) {
                          // debugger;
                          CommonContextUpdate("expectedPremiumField", "");
                        }
                      }}
                      value={individualAnnuityApplication.amountPaidWithApplication.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </Box>
                ) : null}
                {(individualAnnuityApplication.selectedQualifiedOptionsGroup1 !==
                  "Cash with application" &&
                  individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                  "Qualified" &&
                  individualAnnuityApplication.selectedQualifiedOptionsGroup2 !==
                  "") ||
                  (individualAnnuityApplication.selectedNonQualifiedOptions ===
                    "1035 exchange" &&
                    individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                    "Non - Qualified") ? (
                  <Box className="expected_premium" style={{ margin: "5px 0px 5px 30px" }}>
                    <InputLabel style={{ fontSize: "13px" }}>
                      If a 1035 Exchange, Rollover or transfer is occurring, the
                      expected premium Amount is
                      {/* {individualAnnuityApplication.selectedNonQualifiedOptions ===
                        "1035 exchange" ||
                      individualAnnuityApplication.selectedQualifiedOptionsGroup1 ===
                        "Transfer" ||
                      individualAnnuityApplication.selectedQualifiedOptionsGroup1 ===
                        "Rollover" ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : null} */}
                    </InputLabel>
                    <Input
                      style={{ width: "100%" }}
                      type="text"
                      disabled={disableAllInputs === true ? true : false}
                      onChange={(e) => {
                        console.log(e.target.value);
                        console.log(e.target.value.replaceAll(",", ""));
                        if (isNaN(e.target.value.replaceAll(",", "")) === false)
                          CommonContextUpdate(
                            "expectedPremiumField",
                            e.target.value.replaceAll(",", "")
                          );
                        if (
                          individualAnnuityApplication.selectedNonQualifiedOptions !==
                          "Cash with application" ||
                          individualAnnuityApplication.selectedQualifiedOptionsGroup1 !==
                          "Cash with application"
                        ) {
                          // debugger;
                          CommonContextUpdate("amountPaidWithApplication", "");
                        }
                      }}
                      value={individualAnnuityApplication.expectedPremiumField.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </Box>
                ) : null}
              </Box>
            ) : null}
            {/* Premium and Tax Qualification Status Section End */}

            <Box
              className="annuitant_head_root"
              onClick={() => {
                // setFlagSeventhSection(!flagFirstPageSeventhSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageSeventhSection",
                  data: !flagFirstPageSeventhSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  7. Owner Replacement Questions{" "}
                  <span>(Required for All Applications)</span>
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageSeventhSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            <Box
              className=" owner_replacement_question "
              style={{ width: "80%", left: "0%", position: "relative" }}
            >
              {flagFirstPageSeventhSection === true ? (
                <Box className="owner_replacement_question_main">
                  <Typography variant="span" style={{ margin: "20px 0" }}>
                    If "Yes", please sign the replacement form.
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <Box
                    className="owner_replacement_first_question"
                    style={{ marginTop: "20px" }}
                  >
                    <Typography variant="span">
                      1. Do you have any existing life insurance policies or
                      annuity contracts with Farmers Life Insurance Company or
                      any other insurance company?
                    </Typography>
                    <RadioGroup
                      className="radio_group_shift_right"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                      }}
                      value={
                        individualAnnuityApplication.flagHaveExistingLifeInsurance
                      }
                      onClick={(e) => {
                        if (!e.target.checked) {
                          return;
                        }
                        if (!e.target.value) {
                          CommonContextUpdate(
                            "flagHaveExistingLifeInsurance",
                            ""
                          );

                          return;
                        }
                        if (
                          e.target.value !=
                          individualAnnuityApplication.flagHaveExistingLifeInsurance
                        ) {
                          CommonContextUpdate(
                            "flagHaveExistingLifeInsurance",
                            e.target.value
                          );
                        } else {
                          CommonContextUpdate(
                            "flagHaveExistingLifeInsurance",
                            ""
                          );

                          // CommonContextUpdate("typeOfAccount", "");
                        }
                      }}
                    >
                      <FormControlLabel
                        disabled={disableAllInputs === true ? true : false}
                        control={
                          <Radio
                            value={"Yes"}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        disabled={disableAllInputs === true ? true : false}
                        control={
                          <Radio
                            value={"No"}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              ) : null}
              {flagFirstPageSeventhSection === true && (
                <Fragment>
                  <Box
                    className="owner_replacement_second_question"
                    style={{ marginTop: "20px" }}
                  >
                    <Typography variant="span">
                      2. Is this Application intended to replace or change any
                      life insurance or annuity contract with Farmers Life
                      Insurance Company or any other company? Change means
                      causing a policy or contract to lapse, surrender (in whole
                      or part), forfeit, terminate, convert to reduce paid up or
                      continue as extend term insurance, reduce in value by
                      borrowing against it or change the term or coverage
                      benefits.
                      <span style={{ color: "red" }} color="red">
                        *
                      </span>
                    </Typography>
                    <RadioGroup
                      className="radio_group_shift_right"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                      }}
                      value={
                        individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance
                      }
                      onClick={(e) => {
                        if (!e.target.checked) {
                          return;
                        }
                        if (!e.target.value) {
                          CommonContextUpdate(
                            "reasonOfApplicationToChangeLifeInsurance",
                            ""
                          );

                          return;
                        }
                        if (
                          e.target.value !=
                          individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance
                        ) {
                          CommonContextUpdate(
                            "reasonOfApplicationToChangeLifeInsurance",
                            e.target.value
                          );
                        } else {
                          CommonContextUpdate(
                            "reasonOfApplicationToChangeLifeInsurance",
                            ""
                          );

                          // CommonContextUpdate("typeOfAccount", "");
                        }
                      }}
                    >
                      <FormControlLabel
                        disabled={disableAllInputs === true ? true : false}
                        control={
                          <Radio
                            value={"Yes"}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        disabled={disableAllInputs === true ? true : false}
                        control={
                          <Radio
                            value={"No"}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </Box>
                </Fragment>
              )}
              {/* {individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance ===
                "Yes" &&
                individualAnnuityApplication.flagHaveExistingLifeInsurance ===
                  "Yes" && */}
              {flagFirstPageSeventhSection === true && (
                <Fragment>
                  <TableContainer className="replacementTableContainer">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow className="replacementTableHead">
                          <TableCell>#</TableCell>
                          <TableCell>Company Name of Policy/Contract Being Replaced</TableCell>
                          <TableCell>Policy/Contract Number</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {individualAnnuityApplication.applicationReplacementFieldsArray?.map(
                          (v, i, arr) => {
                            {/* flagFirstPageSeventhSection === true && ( */ }
                            return (
                              <>
                                <TableRow key={i} className="replacementTableRow">
                                  <TableCell align="center" className={disableAllInputs === true ? "replacementTableCell replacementTableCellDisableColor " : "replacementTableCell"} >{i + 1}</TableCell>
                                  <TableCell align="left" className="replacementTableCell">
                                    <TextField
                                      disabled={disableAllInputs === true ? true : false}
                                      className="not_required owner_replacement_not_required"
                                      type="text"
                                      placeholder="Enter Company Name of Policy/Contract Being Replaced"
                                      variant="outlined"
                                      style={{
                                        width: "90%",
                                        fontSize: "1px",
                                      }}
                                      value={v.companyNameOfPolicyField}
                                      onChange={(e) => {
                                        let temp = [...arr];
                                        console.log(temp);
                                        temp = temp.filter((row) => {
                                          if (v.id === row.id) {
                                            row.companyNameOfPolicyField =
                                              e.target.value;
                                          }
                                          return row;
                                        });

                                        console.log("context", temp);
                                        CommonContextUpdate(
                                          "applicationReplacementFieldsArray",
                                          temp
                                        );
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="left" className="replacementTableCell">
                                    <TextField
                                      type="text"
                                      className="not_required owner_replacement_not_required"
                                      disabled={disableAllInputs === true ? true : false}
                                      placeholder="Enter Policy/Contract Number"
                                      variant="outlined"
                                      style={{ width: "90%" }}
                                      value={v.policyNumberField}
                                      onChange={(e) => {
                                        let temp = [...arr];
                                        console.log(temp);
                                        temp = temp.filter((row) => {
                                          if (v.id === row.id) {
                                            row.policyNumberField = e.target.value;
                                          }
                                          return row;
                                        });

                                        console.log("context", temp);
                                        CommonContextUpdate(
                                          "applicationReplacementFieldsArray",
                                          temp
                                        );
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              </>

                            )
                            {/* ); */ }
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>








                  {/* <Box className="policy_company_number_array_fields">
                    {individualAnnuityApplication.applicationReplacementFieldsArray?.map(
                      (v, i, arr) => {
                        flagFirstPageSeventhSection === true && (

                          <Grid container alignItems="center" className="ownerReplacementContainer" >
                            <Grid item lg={0.5} alignSelf="end" paddingBottom="4px" >{i + 1}.</Grid>
                            <Grid item lg={7.5} textAlign="left" className="policy_company_number_array_fields_row_1">
                              <TextField
                                className="not_required"
                                type="text"
                                label="Company Name of Policy/Contract Being Replaced"
                                variant="standard"
                                style={{
                                  width: "90%",
                                  fontSize: "1px",
                                }}
                                value={v.companyNameOfPolicyField}
                                onChange={(e) => {
                                  let temp = [...arr];
                                  console.log(temp);
                                  temp = temp.filter((row) => {
                                    if (v.id === row.id) {
                                      row.companyNameOfPolicyField =
                                        e.target.value;
                                    }
                                    return row;
                                  });

                                  console.log("context", temp);
                                  CommonContextUpdate(
                                    "applicationReplacementFieldsArray",
                                    temp
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item lg={4}>
                              <TextField
                                type="text"
                                className="not_required"
                                label="Policy/Contract Number"
                                variant="standard"
                                style={{ width: "90%" }}
                                value={v.policyNumberField}
                                onChange={(e) => {
                                  let temp = [...arr];
                                  console.log(temp);
                                  temp = temp.filter((row) => {
                                    if (v.id === row.id) {
                                      row.policyNumberField = e.target.value;
                                    }
                                    return row;
                                  });

                                  console.log("context", temp);
                                  CommonContextUpdate(
                                    "applicationReplacementFieldsArray",
                                    temp
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      }
                    )}
                  </Box> */}
                </Fragment>
              )}
            </Box>
            {/* <Box className="annuitant_head_root" onClick={() => { 
                            // setFlagEighthSection(!flagFirstPageEighthSection)
                            SetApplicationFormFlagSectionData({
                                type: "Individual Annuity Application",
                                section: "flagFirstPageEighthSection",
                                data: !flagFirstPageEighthSection
                            })
                        }}>
                            <Box className="section_open_close">
                                <Typography variant='span'>8. Safeguard Plus annuity with the Initial Guarantee Period</Typography>
                            </Box>
                            <Box className="section_open_close" >
                                {flagFirstPageEighthSection === true ?
                                    <KeyboardArrowUpIcon /> :
                                    <KeyboardArrowDownIcon />}
                            </Box>
                        </Box>
                        {flagFirstPageEighthSection === true ?
                            <Box className='safeguard_plus_qnnuity_wth_guarantee_period safeguard_plus_width' style={{ width: "80%" ,marginTop: "10px"}}>
                                <Typography>Guarantee Period indicated below:</Typography>
                                <RadioGroup name="plan_applied_for" value={individualAnnuityApplication.flagSafeguardPlusAnnuityPeriod} onChange={e => CommonContextUpdate("flagSafeguardPlusAnnuityPeriod", e.target.value)} style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row" }}>
                                    <FormControlLabel disabled={disableAllInputs === true ? true : false} control={<Radio value={"Safeguard Plus 3 - 2.60% for 3 yrs."} icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />} label="Safeguard Plus 3 - 2.60% for 3 yrs." />
                                    <FormControlLabel disabled={disableAllInputs === true ? true : false} control={<Radio value={"Safeguard Plus 7 - 3.30% for 7 yrs."} icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />} label="Safeguard Plus 7 - 3.30% for 7 yrs." />
                                    <FormControlLabel disabled={disableAllInputs === true ? true : false} control={<Radio value={"Safeguard Plus 5 - 3.25% for 5 yrs."} icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />} label="Safeguard Plus 5 - 3.25% for 5 yrs." />
                                    <FormControlLabel disabled={disableAllInputs === true ? true : false} control={<Radio value={"Safeguard Plus 10 - 3.35% for 10 yrs."} icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />} label="Safeguard Plus 10 - 3.35% for 10 yrs." />
                                </RadioGroup>
                            </Box> : null} */}

            <Box
              className="next_suitability_analysis_btn"
              style={{ margin: "20px 0 20px 0" }}
            >
              <Button
                endIcon={<DoubleArrowIcon />}
                style={{
                  width: "250px",
                  color: "#fff",
                  textTransform: "capitalize",
                  background: "#074a6e",
                }}
                onClick={() => {
                  ConditionToShowReplacementForm() === true
                    ? annuitantState === "Oklahoma"
                      ? props.changePageIndex(4)
                      : props.changePageIndex(1)
                    : props.changePageIndex(2);
                }}
              >
                Next -{" "}
                {ConditionToShowReplacementForm() === true
                  ? "Notice Of Replacement"
                  : "Suitability Analysis"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ApplicationForm;
