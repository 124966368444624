import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
} from "react";
import {
  Typography,
  Box,
  Divider,
  TextField,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Input,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./individualAnnuityApplication.css";
import { Context } from "../../../context/V3/newApplicationFormContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Context as UserLoaderContext } from "../../../context/userLoaderContext";
import { Context as ErrorValidationContext } from "../../../context/V3/errorValidationContext";
import ClearIcon from '@mui/icons-material/Clear';
import MuiPhoneNumber from "material-ui-phone-number";
import { IllinoisAlert } from "../illinoisAlert";

const ApplicationForm = (props) => {
  const {
    SetIndividualAnnuityApplication,
    SetWholeAnnuityDisclosureForm,
    updateTransferOrExchangeRequestFlag,
    SetWholeIndividualAnnuityApplication
  } = useContext(Context);
  const { state } = useContext(UserLoaderContext);
  const {
    individualAnnuityApplication,
    disableAllInputs,
    annuityDisclosureForm,
  } = useContext(Context).state;

  const [flagNonQualifiedMenu, setFlagNonQualifiedMenu] = useState(false);
  const [flagQualifiedMenu, setFlagQualifiedMenu] = useState(false);
  const [flagJointOwnerRequired, setFlagJointOwnerRequired] = useState(false);
  // const [flagClearAllBtn, setFlagClearAllBtn] = useState({
  //   annuitant:false,
  //   owner:false,
  //   jointOwner:false,
  // });

  console.log("disableAllInputs", disableAllInputs);
  const { SetApplicationFormFlagSectionData } = useContext(
    ErrorValidationContext
  );

  const {
    flagFirstPageFirstSection,
    flagFirstPageSecondSection,
    flagFirstPageThirdSection,
    flagFirstPageFourthSection,
    flagFirstPageFifthSection,
    flagFirstPageSixthSection,
    flagFirstPageSeventhSection,
    flagFirstPageEighthSection,
  } = useContext(ErrorValidationContext).state;

  const [beneficiariesPercentageTotal, setBeneficiariesPercentageTotal] =
    useState(0);
  const [primaryBeneficiariesPercentage, setPrimaryBeneficiariesPercentage] =
    useState(0);
  const [
    contigentBeneficiariesPercentage,
    setContigentBeneficiariesPercentage,
  ] = useState(0);
  const [flagDisableQuestions, setFlagDisableQuestions] = useState(true);
  const [isOwnerDetailReq, setIsOwnerDetailReq] = useState(false);

  const [annuitantValidationError, setAnnuitantValidationError] = useState({
    flagAnnuitantZipValid: false,
    flagAnnuitantPhoneValid: false,
    flagAnnuitantEmailValid: false,
  });
  const [ownerValidationError, setOwnerValidationError] = useState({
    flagOwnerZipValid: false,
    flagOwnerPhoneValid: false,
    flagOwnerEmailValid: false,
  });
  const [jointOwnerValidationError, setJointOwnerValidationError] = useState({
    flagJointOwnerZipValid: false,
    flagJointOwnerPhoneValid: false,
    flagJointOwnerEmailValid: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Printing disable all inputs value", disableAllInputs);
  }, []);

  const StatesName = [
    "Alabama",
    "Alaska",
    // "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    // "Federated States Of Micronesia",
    "Florida",
    "Georgia",
    // "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    // "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    // "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    // "Palau",
    "Pennsylvania",
    // "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    // "Virgin Islands",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const StatesNameNOR = [
    "All Other States",
    "Georgia",
    "Indiana",
    "Massachusetts",
    "Nevada",
    "Oklahoma",
    "Pennsylvania",
    "Tennessee",
    "Wyoming",
    "Illinois"
  ]
  function isValidEmail(email) {
    return /^([a-zA-Z0-9\._]+)@([a-zA-Z)-9])+.([a-z]+)(.[a-z]+)?$/.test(email);
  }

  useEffect(() => {
    console.log("Printing disable all inputs value", disableAllInputs);
    let inputArray = document.querySelectorAll("input");
    console.log(
      "Printing all input array for individual annuity application",
      inputArray
    );
    if (disableAllInputs === true) {
      for (let i = 0; i < inputArray.length; i++) {
        console.log("Printing input array each element", inputArray[i]);
        inputArray[i].disabled = true;
      }
    } else {
      for (let i = 0; i < inputArray.length; i++) {
        console.log("Printing input array each element", inputArray[i]);
        inputArray[i].disabled = false;
      }
    }
  }, [
    disableAllInputs,
    flagFirstPageFirstSection,
    flagFirstPageSecondSection,
    flagFirstPageThirdSection,
    flagFirstPageFourthSection,
    flagFirstPageFifthSection,
    flagFirstPageSixthSection,
    flagFirstPageSeventhSection,
  ]);

  useEffect(() => {
    console.log("context", individualAnnuityApplication);
    individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected === "" ||
      individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
      "non - qualified"
      ? setFlagNonQualifiedMenu(true)
      : setFlagNonQualifiedMenu(false);

    individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected === "" ||
      individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
      "qualified"
      ? setFlagQualifiedMenu(true)
      : setFlagQualifiedMenu(false);
  }, [individualAnnuityApplication]);

  useEffect(() => {
    setFlagNonQualifiedMenu(false);
    setFlagQualifiedMenu(false);
  }, []);

  const CommonContextUpdate = (type, value) => {
    SetIndividualAnnuityApplication({
      type: type,
      value: value,
    });
  };

  const ClearAnnuitantDetails = () => {
    let annuitantFields = [
      "annuitantFullName",
      "annuitantSSN",
      "annuitantGender",
      "annuitantDOB",
      "annuitantStreetAddress",
      "annuitantZip",
      "annuitantCity",
      "annuitantState",
      "annuitantPhone",
      "annuitantEmail",
    ]

    for (let i = 0; i < annuitantFields.length; i++) {
      if (annuitantFields[i] == "annuitantDOB") {
        SetIndividualAnnuityApplication({
          type: annuitantFields[i],
          value: null,
        });
      } else {
        SetIndividualAnnuityApplication({
          type: annuitantFields[i],
          value: "",
        });
      }
    }

    setAnnuitantValidationError({
      flagAnnuitantZipValid: false,
      flagAnnuitantPhoneValid: false,
      flagAnnuitantEmailValid: false,
    });
  }

  const ClearOwnerDetails = () => {
    let ownerFields = [
      "ownerFullName",
      "ownerSSN",
      "ownerGender",
      "ownerDOB",
      "ownerStreetAddress",
      "ownerZip",
      "ownerCity",
      "ownerState",
      "ownerEmail",
      "ownerRelationToAnnuitant",
      "ownerPhone"
    ]

    for (let i = 0; i < ownerFields.length; i++) {
      if (ownerFields[i] == "ownerDOB") {
        SetIndividualAnnuityApplication({
          type: ownerFields[i],
          value: null,
        });
      } else {
        SetIndividualAnnuityApplication({
          type: ownerFields[i],
          value: "",
        });
      }
    }
    setOwnerValidationError({
      flagOwnerZipValid: false,
      flagOwnerEmailValid: false,
    });
  }

  const ClearJointOwnerDetails = () => {
    let jointOwnerFields = [
      "jointOwnerFullName",
      "jointOwnerSSN",
      "jointOwnerGender",
      "jointOwnerDOB",
      "jointOwnerStreetAddress",
      "jointOwnerZip",
      "jointOwnerCity",
      "jointOwnerState",
      "jointOwnerEmail",
      "jointOwnerRelationToAnnuitant",
      "jointOwnerPhone"
    ]

    for (let i = 0; i < jointOwnerFields.length; i++) {
      if (jointOwnerFields[i] == "jointOwnerDOB") {
        SetIndividualAnnuityApplication({
          type: jointOwnerFields[i],
          value: null,
        });
      } else {
        SetIndividualAnnuityApplication({
          type: jointOwnerFields[i],
          value: "",
        });
      }
    }

    setJointOwnerValidationError({
      flagJointOwnerZipValid: false,
      flagJointOwnerEmailValid: false,
    });
  }

  useEffect(() => {
    let tempPrimary = 0;

    let a = individualAnnuityApplication.beneficiariesPrimary
    individualAnnuityApplication.beneficiariesPrimary.map((v) => {
      if (v.percentageField !== "" && v.percentageField !== -1 && v.percentageField !== null) {
        tempPrimary += parseFloat(v.percentageField);
      }
    });

    let tempContigent = 0;

    individualAnnuityApplication.beneficiariesContigent.map((v) => {
      if (v.percentageField !== "" && v.percentageField !== -1 && v.percentageField !== null) {
        tempContigent += parseFloat(v.percentageField);
      }
    });

    setPrimaryBeneficiariesPercentage(tempPrimary);
    setContigentBeneficiariesPercentage(tempContigent);
    props.beneficiariesPercentageTotal(tempPrimary + tempContigent);
  }, [
    individualAnnuityApplication.beneficiariesPrimary,
    individualAnnuityApplication.beneficiariesContigent,
  ]);

  const ConditionToShowReplacementForm = () => {
    if (individualAnnuityApplication.ownerState?.length && individualAnnuityApplication.ownerState !== "" && individualAnnuityApplication.ownerState !== "Tennessee") {
      return true
    } else {
      if (individualAnnuityApplication.annuitantState?.length && individualAnnuityApplication.annuitantState !== "" && individualAnnuityApplication.annuitantState !== "Tennessee") {
        return true
      } else {
        return false
      }
    }
  };

  useEffect(() => {
    if (individualAnnuityApplication.selectedNonQualifiedOptions.length == 0) {
      CommonContextUpdate("amountPaidWithApplication", "");
      SetIndividualAnnuityApplication({
        type: "selectedQualifiedOptionsGroup2",
        value: "",
      });
    } else if (
      individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("transfer") == -1
      && individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("rollover") == -1
      && individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("1035 exchange") == -1
    ) {
      CommonContextUpdate("expectedPremiumField", "");
    }
  }, [individualAnnuityApplication.selectedNonQualifiedOptions])

  useEffect(() => {
    if (individualAnnuityApplication.flagHaveExistingLifeInsurance == "yes" || individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance == "yes") {
      setFlagDisableQuestions(false)
    } else {
      setFlagDisableQuestions(true)
      let emptydata = [
        {
          id: 1,
          companyNameOfPolicyField: "",
          policyNumberField: ""
        },
        {
          id: 2,
          companyNameOfPolicyField: "",
          policyNumberField: ""
        },
        {
          id: 3,
          companyNameOfPolicyField: "",
          policyNumberField: ""
        },
        {
          id: 4,
          companyNameOfPolicyField: "",
          policyNumberField: ""
        }
      ]
      CommonContextUpdate("applicationReplacementFieldsArray", emptydata);
    }
  }, [individualAnnuityApplication.flagHaveExistingLifeInsurance, individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance])

  useEffect(() => {
    let selectedState = individualAnnuityApplication.ownerState
    let norState = StatesNameNOR.filter((obj) => obj == selectedState)
    if (norState?.length) {
      CommonContextUpdate("stateToShowNoticeOfReplacement", norState[0]);
    } else {
      if (individualAnnuityApplication.ownerState == "") {
        norState = StatesNameNOR.filter((obj) => obj == individualAnnuityApplication.annuitantState)
        if (norState?.length) {
          CommonContextUpdate("stateToShowNoticeOfReplacement", norState[0]);
        } else {
          CommonContextUpdate("stateToShowNoticeOfReplacement", "All Other States");
        }
      } else {
        CommonContextUpdate("stateToShowNoticeOfReplacement", "All Other States");
      }
    }
  }, [individualAnnuityApplication.ownerState, individualAnnuityApplication.annuitantState])

  useEffect(() => {
    let jointOwnerFields = [
      "jointOwnerFullName",
      "jointOwnerSSN",
      "jointOwnerGender",
      "jointOwnerDOB",
      "jointOwnerStreetAddress",
      "jointOwnerZip",
      "jointOwnerCity",
      "jointOwnerState",
      "jointOwnerEmail",
      "jointOwnerRelationToAnnuitant",
      "jointOwnerPhone"
    ]
    let ownerFields = [
      "ownerFullName",
      "ownerSSN",
      "ownerGender",
      "ownerDOB",
      "ownerStreetAddress",
      "ownerZip",
      "ownerCity",
      "ownerState",
      "ownerEmail",
      "ownerRelationToAnnuitant",
      "ownerPhone"
    ]

    let isJointOwnerFilled = false
    for (let i = 0; i < jointOwnerFields.length; i++) {
      if (individualAnnuityApplication[jointOwnerFields[i]] !== "" && individualAnnuityApplication[jointOwnerFields[i]] !== null) {
        isJointOwnerFilled = true
      }
    }
    if (isJointOwnerFilled) {
      setFlagJointOwnerRequired(true)
    } else {
      setFlagJointOwnerRequired(false)
    }


    let isOwnerFilled = false
    for (let i = 0; i < ownerFields.length; i++) {
      if (individualAnnuityApplication[ownerFields[i]] !== "" && individualAnnuityApplication[ownerFields[i]] !== null) {
        isOwnerFilled = true
      }
    }
    if (isOwnerFilled) {
      setIsOwnerDetailReq(true)
    } else {
      setIsOwnerDetailReq(false)
    }


  }, [individualAnnuityApplication])

  const IsRowRequired = (row) => {
    let flag = Object.keys(row)?.map((key) => {
      if (key !== 'id' && row[key] !== "" && row[key] !== null) {
        return true
      } else {
        return false
      }
    })
    if (flag.indexOf(true) > -1) {
      return true
    } else {
      return false
    }
  }

  function validatePhoneNumberLatest(input_str) {
    return input_str?.replace(/\D/g, '').length >= 11
  }

  console.log("individualAnnuityApplication.applicationReplacementFieldsArray====>", individualAnnuityApplication.applicationReplacementFieldsArray);
  console.log("---------------------------------------------------------------------------------------------");
  console.log("Premium Select the Tax Qualification status--->flagQualifiedOrNonQualifiedSelected:", individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected);
  console.log("Premium Select the plan non-qualified--->selectedNonQualifiedOptions:", individualAnnuityApplication.selectedNonQualifiedOptions);
  console.log("Premium Amount Paid With Application--->amountPaidWithApplication:", individualAnnuityApplication.amountPaidWithApplication);
  console.log("Premium If a 1035 Exchange, Rollover or transfer is occurring, the expected premium Amount is--->expectedPremiumField:", individualAnnuityApplication.expectedPremiumField);
  console.log("Premium Select type of plan--->selectedQualifiedOptionsGroup2:", individualAnnuityApplication.selectedQualifiedOptionsGroup2);
  console.log("Premium Select type of plan othetr textfield--->otherSelectedQualifiedOptions:", individualAnnuityApplication.otherSelectedQualifiedOptions);
  console.log("---------------------------------------------------------------------------------------------");

  return (
    <>
      <Box className="application_page_root">
        <Box className="application_form">
          <Box className="application_form_card">
            <Box className="application_form_head ">
              <Box className="forsitemap"></Box>
              <Box className="application_form_head_1">
                <Typography variant="span">
                  Individual Annuity Application
                </Typography>
              </Box>
              <Box className="application_form_head_bottom_line">
                <Divider />
              </Box>
            </Box>

            <Box
              className="annuitant_head_root header_1"
              onClick={() => {
                // setFlagFirstSection(!flagFirstPageFirstSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageFirstSection",
                  data: !flagFirstPageFirstSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  1. Annuitant (Joint Annuitants are not permitted)
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageFirstSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFirstPageFirstSection === true ? (
              <Box className="annuitant_input_fields">
                {disableAllInputs === false ?
                  <Box sx={{ width: "100%", textAlign: "end" }}>
                    <Button
                      variant="text"
                      sx={{ textTransform: "capitalize", color: "#d9a22e" }}
                      onClick={() => {
                        ClearAnnuitantDetails()
                      }}
                    >
                      Clear All
                    </Button>
                  </Box>
                  : ""
                }
                <Box className="annuitant_fullname_and_ssn_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width req_fields"
                      id="standard-basic"
                      label="Annuitant full name"
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "annuitantFullName",
                          e.target.value
                        );
                      }}
                      value={individualAnnuityApplication.annuitantFullName}
                      disabled={disableAllInputs === true ? true : false}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className="input_field_width req_fields"
                      label="SSN/TIN"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        let targetLength = e.target.value.length;
                        let contextLength =
                          individualAnnuityApplication.annuitantSSN.length;
                        if (targetLength > contextLength) {
                          if (targetLength === 3 || targetLength === 6) {
                            let val = e.target.value + "-";
                            CommonContextUpdate("annuitantSSN", val);
                          } else if (e.target.value.length <= 11) {
                            CommonContextUpdate("annuitantSSN", e.target.value);
                          }
                          console.log(
                            "Printing value of e.target.value",
                            e.target.value
                          );
                          console.log(
                            "Printing value of annuitantSSN",
                            individualAnnuityApplication.annuitantSSN
                          );
                        } else {
                          if (targetLength === 7 || targetLength === 4) {
                            CommonContextUpdate(
                              "annuitantSSN",
                              e.target.value.slice(0, -1)
                            );
                          } else {
                            CommonContextUpdate("annuitantSSN", e.target.value);
                          }
                        }
                      }}
                      onBlur={(e) => {
                        var regx = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                        if (!regx.test(e.target.value)) {
                          CommonContextUpdate("annuitantSSN", "");
                        }
                      }}
                      value={individualAnnuityApplication.annuitantSSN}
                    />
                  </Box>
                </Box>
                <Box className="annuitant_gender_and_dateofbirth_row">
                  <Box className="annuitant_gender_selection left_section">
                    <FormControl
                      className="input_field_width"
                      variant="standard"
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        Gender Assigned at Birth
                        {/* <span style={{ color: "red" }} color="red">
                          *
                        </span> */}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => {
                          CommonContextUpdate(
                            "annuitantGender",
                            e.target.value
                          );
                        }}
                        value={individualAnnuityApplication.annuitantGender}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"male"}>Male</MenuItem>
                        <MenuItem value={"female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className={disableAllInputs === true ? "annuitant_dateofbirth_field dobColorClass" : "annuitant_dateofbirth_field"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={disableAllInputs === true ? true : false}
                        className="input_field_width req_fields"
                        id="date_field"
                        label="Date Of Birth"
                        inputFormat="MM/dd/yyyy"
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(e) => {
                          SetIndividualAnnuityApplication({
                            type: "annuitantDOB",
                            value: e,
                          });
                        }}
                        maxDate={new Date()}
                        value={individualAnnuityApplication.annuitantDOB}
                      />
                      <IconButton
                        disabled={disableAllInputs === true ? true : false}
                        edge="end"
                        className="DatePickerCrossIcon"
                        size="small"
                        onClick={() => {
                          SetIndividualAnnuityApplication({
                            type: "annuitantDOB",
                            value: null,
                          });
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box className="annuitant_streetaddress_and_zip_row ">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width req_fields"
                      type="text"
                      label="Street Address"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "annuitantStreetAddress",
                          e.target.value
                        );
                      }}
                      value={
                        individualAnnuityApplication.annuitantStreetAddress
                      }
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className="input_field_width req_fields"
                      error={annuitantValidationError?.flagAnnuitantZipValid ? true : false}
                      helperText={annuitantValidationError?.flagAnnuitantZipValid ? "ZIP number only contains 5 digits" : ""}
                      onBlur={() => {
                        let temp = { ...annuitantValidationError }
                        if (individualAnnuityApplication.annuitantZip?.length) {
                          temp.flagAnnuitantZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(individualAnnuityApplication.annuitantZip))
                          setAnnuitantValidationError(temp)
                        } else {
                          temp.flagAnnuitantZipValid = false
                          setAnnuitantValidationError(temp)
                        }
                      }}
                      label="ZIP"
                      disabled={disableAllInputs === true ? true : false}
                      type="number"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("annuitantZip", e.target.value);
                        if (e.target.value?.length == 5) {
                          let temp = { ...annuitantValidationError }
                          temp.flagAnnuitantZipValid = false
                          setAnnuitantValidationError(temp)
                        }
                        if (e.target.value?.length > 5 || e.target.value?.length < 5) {
                          let temp = { ...annuitantValidationError }
                          temp.flagAnnuitantZipValid = true
                          setAnnuitantValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.annuitantZip}
                    />
                  </Box>
                </Box>
                <Box className="annuitant_city_and_state_row">
                  <Box className="left_section">
                    <TextField
                      className="input_field_width req_fields"
                      label="City"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("annuitantCity", e.target.value);
                      }}
                      value={individualAnnuityApplication.annuitantCity}
                    />
                  </Box>
                  <Box className="right_section">
                    <FormControl
                      className="input_field_width"
                      variant="standard"
                      style={{ width: "50%" }}
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        State
                      </InputLabel>
                      <Select
                        className="state_dropdown"
                        onChange={(e) => {
                          CommonContextUpdate("annuitantState", e.target.value);
                        }}
                        value={individualAnnuityApplication.annuitantState}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {StatesName.map((stateName) => {
                          return (
                            <MenuItem value={stateName}>{stateName}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="annuitant_phone_and_email_row">
                  <Box className="left_section">
                    <MuiPhoneNumber
                      sx={{
                        pointerEvents: disableAllInputs === true ? "none" : "auto",
                        '& .MuiPhoneNumber-flagButton': {
                          pointerEvents: "none"
                        }
                      }}
                      name="phone"
                      label="Phone"
                      data-cy="user-phone"
                      defaultCountry={"us"}
                      onlyCountries={["us"]}
                      autoFormat={true}
                      countryCodeEditable={false}
                      value={individualAnnuityApplication.annuitantPhone}
                      className="input_field_width"
                      onChange={(value) => {
                        let temp = { ...annuitantValidationError }
                        temp.flagAnnuitantPhoneValid = !validatePhoneNumberLatest(value)
                        setAnnuitantValidationError(temp)
                        CommonContextUpdate("annuitantPhone", value)
                      }}
                      error={annuitantValidationError.flagAnnuitantPhoneValid ? true : false}
                      helperText={annuitantValidationError.flagAnnuitantPhoneValid ? "Invaild Phone" : ""}
                      disabled={disableAllInputs === true ? true : false}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className="input_field_width req_fields"
                      error={annuitantValidationError.flagAnnuitantEmailValid ? true : false}
                      helperText={annuitantValidationError.flagAnnuitantEmailValid ? "Invaild Email" : ""}
                      label="Email"
                      disabled={disableAllInputs === true ? true : false}
                      type="email"
                      variant="standard"
                      onChange={(e) => {
                        let temp = { ...annuitantValidationError }
                        if (e.target.value?.length) {
                          temp.flagAnnuitantEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))
                          setAnnuitantValidationError(temp)
                        } else {
                          temp.flagAnnuitantEmailValid = false
                          setAnnuitantValidationError(temp)
                        }
                        CommonContextUpdate("annuitantEmail", e.target.value);
                      }}
                      onBlur={() => {
                        let temp = { ...annuitantValidationError }
                        if (individualAnnuityApplication.annuitantEmail?.length) {
                          temp.flagAnnuitantEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(individualAnnuityApplication.annuitantEmail))
                          setAnnuitantValidationError(temp)
                        } else {
                          temp.flagAnnuitantEmailValid = false
                          setAnnuitantValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.annuitantEmail}
                    />
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Box
              className="annuitant_head_root"
              onClick={() => {
                // setFlagSecondSection(!flagFirstPageSecondSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageSecondSection",
                  data: !flagFirstPageSecondSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  2. Owner (If other than Annuitant)
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageSecondSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFirstPageSecondSection === true ? (
              <Box className="annuitant_owner_section">
                {disableAllInputs === false ?
                  <Box sx={{ width: "100%", textAlign: "end" }}>
                    <Button
                      variant="text"
                      sx={{ textTransform: "capitalize", color: "#d9a22e" }}
                      onClick={() => {
                        ClearOwnerDetails()
                      }}
                    >
                      Clear All
                    </Button>
                  </Box>
                  : ""
                }
                <Box className="annuitant_fullname_and_ssn_row">
                  <Box className="left_section">
                    <TextField
                      className={isOwnerDetailReq ? "input_field_width" : "input_field_width not_required"}
                      label="Owner Full Name"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("ownerFullName", e.target.value);
                      }}
                      value={individualAnnuityApplication.ownerFullName}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className={isOwnerDetailReq ? "input_field_width" : "input_field_width not_required"}
                      label="SSN/TIN"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      onChange={(e) => {
                        let targetLength = e.target.value.length;
                        let contextLength =
                          individualAnnuityApplication.ownerSSN.length;
                        var regx = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;

                        if (targetLength > contextLength) {
                          if (targetLength === 3 || targetLength === 6) {
                            let val = e.target.value + "-";
                            CommonContextUpdate("ownerSSN", val);
                          } else if (e.target.value.length <= 11) {
                            CommonContextUpdate("ownerSSN", e.target.value);
                          }
                          console.log(
                            "Printing value of e.target.value",
                            e.target.value
                          );
                          console.log(
                            "Printing value of ownerSSN",
                            individualAnnuityApplication.ownerSSN
                          );
                        } else {
                          if (targetLength === 7 || targetLength === 4) {
                            CommonContextUpdate(
                              "ownerSSN",
                              e.target.value.slice(0, -1)
                            );
                          } else {
                            CommonContextUpdate("ownerSSN", e.target.value);
                          }
                        }
                      }}
                      onBlur={(e) => {
                        var regx = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                        if (!regx.test(e.target.value)) {
                          CommonContextUpdate("ownerSSN", "");
                        }
                      }}
                      value={individualAnnuityApplication.ownerSSN}
                      variant="standard"
                    />
                  </Box>
                </Box>
                <Box className="annuitant_gender_and_dateofbirth_row">
                  <Box
                    className="annuitant_gender_selection left_section"
                    style={{ width: "55%", position: "relative" }}
                  >
                    <FormControl
                      className={isOwnerDetailReq ? "input_field_width" : "input_field_width not_required"}
                      variant="standard"
                      style={{ width: "50%" }}
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        Gender Assigned at Birth
                      </InputLabel>
                      <Select
                        // className="input_field_width"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => {
                          CommonContextUpdate("ownerGender", e.target.value);
                        }}
                        value={individualAnnuityApplication.ownerGender}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"not applicable"}>Not Applicable</MenuItem>
                        <MenuItem value={"male"}>Male</MenuItem>
                        <MenuItem value={"female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className={disableAllInputs === true ? "annuitant_dateofbirth_field right_section dobColorClass" : "annuitant_dateofbirth_field right_section"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={disableAllInputs === true ? true : false}
                        label="Date Of Birth"
                        inputFormat="MM/dd/yyyy"
                        className={isOwnerDetailReq ? "input_field_date" : "input_field_date not_required"}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(e, key) => {
                          SetIndividualAnnuityApplication({
                            type: "ownerDOB",
                            value: e,
                          });
                        }}
                        maxDate={new Date()}
                        value={individualAnnuityApplication.ownerDOB}
                      />
                      <IconButton
                        disabled={disableAllInputs === true ? true : false}
                        edge="end"
                        size="small"
                        className="DatePickerCrossIcon"
                        onClick={() => {
                          SetIndividualAnnuityApplication({
                            type: "ownerDOB",
                            value: null,
                          });
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box className="annuitant_streetaddress_and_zip_row">
                  <Box className="left_section">
                    <TextField
                      className={isOwnerDetailReq ? "input_field_width" : "input_field_width not_required"}
                      type="text"
                      label="Street Address"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "ownerStreetAddress",
                          e.target.value
                        );
                      }}
                      value={individualAnnuityApplication.ownerStreetAddress}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className={isOwnerDetailReq ? "input_field_width" : "input_field_width not_required"}
                      error={ownerValidationError?.flagOwnerZipValid ? true : false}
                      helperText={ownerValidationError?.flagOwnerZipValid ? "ZIP number only contains 5 digits" : ""}
                      type="number"
                      label="ZIP"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("ownerZip", e.target.value);
                        if (e.target.value?.length == 5) {
                          let temp = { ...ownerValidationError }
                          temp.flagOwnerZipValid = false
                          setOwnerValidationError(temp)
                        } else if (e.target.value?.length) {
                          let temp = { ...ownerValidationError }
                          temp.flagOwnerZipValid = true
                          setOwnerValidationError(temp)
                        }
                      }}
                      onBlur={() => {
                        let temp = { ...ownerValidationError }
                        if (individualAnnuityApplication.ownerZip?.length) {
                          temp.flagOwnerZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(individualAnnuityApplication.ownerZip))
                          setOwnerValidationError(temp)
                        } else {
                          temp.flagOwnerZipValid = false
                          setOwnerValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.ownerZip}
                    />
                  </Box>
                </Box>
                <Box className="annuitant_city_and_state_row">
                  <Box className="left_section">
                    <TextField
                      className={isOwnerDetailReq ? "input_field_width" : "input_field_width not_required"}
                      type="text"
                      label="City"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("ownerCity", e.target.value);
                      }}
                      value={individualAnnuityApplication.ownerCity}
                    />
                  </Box>
                  <Box className="right_section">
                    <FormControl
                      className={isOwnerDetailReq ? "input_field_width" : "input_field_width not_required"}
                      variant="standard"
                      style={{ width: "50%" }}
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        State
                      </InputLabel>
                      <Select
                        className="state_dropdown"
                        onChange={(e) => {
                          CommonContextUpdate("ownerState", e.target.value);
                        }}
                        value={individualAnnuityApplication.ownerState}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {StatesName.map((stateName) => {
                          return (
                            <MenuItem value={stateName}>{stateName}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="annuitant_phone_and_email_row">
                  <Box className="left_section">
                    <TextField
                      className={isOwnerDetailReq ? "input_field_width" : "input_field_width not_required"}
                      error={ownerValidationError?.flagOwnerEmailValid ? true : false}
                      helperText={ownerValidationError?.flagOwnerEmailValid ? "Invalid Email Address" : ""}
                      type="email"
                      label="Email"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("ownerEmail", e.target.value);
                        let temp = { ...ownerValidationError }
                        if (e.target.value?.length) {
                          temp.flagOwnerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))
                          setOwnerValidationError(temp)
                        } else {
                          temp.flagOwnerEmailValid = false
                          setOwnerValidationError(temp)
                        }
                      }}
                      onBlur={() => {
                        let temp = { ...ownerValidationError }
                        if (individualAnnuityApplication.ownerEmail?.length) {
                          temp.flagOwnerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(individualAnnuityApplication.ownerEmail))
                          setOwnerValidationError(temp)
                        } else {
                          temp.flagOwnerEmailValid = false
                          setOwnerValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.ownerEmail}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className={isOwnerDetailReq ? "input_field_width" : "input_field_width not_required"}
                      type="text"
                      label="Relationship To Annuitant"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "ownerRelationToAnnuitant",
                          e.target.value
                        );
                      }}
                      value={
                        individualAnnuityApplication.ownerRelationToAnnuitant
                      }
                    />
                  </Box>
                </Box>
                <Box className="annuitant_phone_and_email_row">
                  <Box className="left_section">
                    <MuiPhoneNumber
                      name="phone"
                      sx={{
                        pointerEvents: disableAllInputs === true ? "none" : "auto",
                        '& .MuiPhoneNumber-flagButton': {
                          pointerEvents: "none"
                        }
                      }}
                      label="Phone"
                      data-cy="user-phone"
                      defaultCountry={"us"}
                      onlyCountries={["us"]}
                      autoFormat={true}
                      countryCodeEditable={false}
                      value={individualAnnuityApplication.ownerPhone}
                      className={isOwnerDetailReq ? "input_field_width" : "input_field_width not_required"}
                      onChange={(value) => {
                        let temp = { ...ownerValidationError }
                        temp.flagOwnerPhoneValid = !validatePhoneNumberLatest(value)
                        setOwnerValidationError(temp)
                        CommonContextUpdate("ownerPhone", value)
                      }}
                      disabled={disableAllInputs === true ? true : false}
                    // countryCodeEditable={false}
                    />
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Box
              className="annuitant_head_root"
              onClick={() => {
                // setFlagThirdSection(!flagFirstPageThirdSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageThirdSection",
                  data: !flagFirstPageThirdSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  3. Joint Spousal Owner (If any), WROS (Not available for
                  Qualified Funds)
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageThirdSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFirstPageThirdSection === true ? (
              <Box className="annuitant_owner_section annuitant_owner_section_bottom">
                {disableAllInputs === false ?
                  <Box sx={{ width: "100%", textAlign: "end" }}>
                    <Button
                      variant="text"
                      sx={{ textTransform: "capitalize", color: "#d9a22e" }}
                      onClick={() => {
                        ClearJointOwnerDetails()
                      }}
                    >
                      Clear All
                    </Button>
                  </Box> : ""
                }
                <Box className="annuitant_fullname_and_ssn_row">
                  <Box className="left_section">
                    <TextField
                      className={flagJointOwnerRequired ? "input_field_width" : "input_field_width not_required"}
                      label="Joint Spousal Owner Full Name"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "jointOwnerFullName",
                          e.target.value
                        );
                      }}
                      value={individualAnnuityApplication.jointOwnerFullName}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className={flagJointOwnerRequired ? "input_field_width" : "input_field_width not_required"}
                      label="SSN/TIN"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        let targetLength = e.target.value.length;
                        let contextLength =
                          individualAnnuityApplication.jointOwnerSSN.length;
                        var regx = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;

                        if (targetLength > contextLength) {
                          if (targetLength === 3 || targetLength === 6) {
                            let val = e.target.value + "-";
                            CommonContextUpdate("jointOwnerSSN", val);
                          } else if (e.target.value.length <= 11) {
                            CommonContextUpdate(
                              "jointOwnerSSN",
                              e.target.value
                            );
                          }
                          console.log(
                            "Printing value of e.target.value",
                            e.target.value
                          );
                          console.log(
                            "Printing value of jointOwnerSSN",
                            individualAnnuityApplication.jointOwnerSSN
                          );
                        } else {
                          if (targetLength === 7 || targetLength === 4) {
                            CommonContextUpdate(
                              "jointOwnerSSN",
                              e.target.value.slice(0, -1)
                            );
                          } else {
                            CommonContextUpdate(
                              "jointOwnerSSN",
                              e.target.value
                            );
                          }
                        }
                      }}
                      onBlur={(e) => {
                        var regx = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                        if (!regx.test(e.target.value)) {
                          CommonContextUpdate("jointOwnerSSN", "");
                        }
                      }}
                      value={individualAnnuityApplication.jointOwnerSSN}
                    />
                  </Box>
                </Box>
                <Box className="annuitant_gender_and_dateofbirth_row">
                  <Box
                    className="annuitant_gender_selection left_section"
                    style={{ width: "55%" }}
                  >
                    <FormControl
                      className={flagJointOwnerRequired ? "input_field_width" : "input_field_width not_required"}
                      variant="standard"
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        Gender Assigned at Birth
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => {
                          CommonContextUpdate(
                            "jointOwnerGender",
                            e.target.value
                          );
                        }}
                        value={individualAnnuityApplication.jointOwnerGender}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"not applicable"}>Not Applicable</MenuItem>
                        <MenuItem value={"male"}>Male</MenuItem>
                        <MenuItem value={"female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className={disableAllInputs === true ? "annuitant_dateofbirth_field right_section dobColorClass" : "annuitant_dateofbirth_field right_section"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={disableAllInputs === true ? true : false}
                        label="Date Of Birth"
                        inputFormat="MM/dd/yyyy"
                        className={flagJointOwnerRequired ? "input_field_date" : "input_field_date not_required"}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(e) => {
                          SetIndividualAnnuityApplication({
                            type: "jointOwnerDOB",
                            value: e,
                          });
                        }}
                        maxDate={new Date()}
                        value={individualAnnuityApplication.jointOwnerDOB}
                      />
                      <IconButton
                        disabled={disableAllInputs === true ? true : false}
                        edge="end"
                        size="small"
                        className="DatePickerCrossIcon"
                        onClick={() => {
                          SetIndividualAnnuityApplication({
                            type: "jointOwnerDOB",
                            value: null,
                          });
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box className="annuitant_streetaddress_and_zip_row">
                  <Box className="left_section">
                    <TextField
                      className={flagJointOwnerRequired ? "input_field_width" : "input_field_width not_required"}
                      label="Street Address"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "jointOwnerStreetAddress",
                          e.target.value
                        );
                      }}
                      value={
                        individualAnnuityApplication.jointOwnerStreetAddress
                      }
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className={flagJointOwnerRequired ? "input_field_width" : "input_field_width not_required"}
                      error={jointOwnerValidationError?.flagJointOwnerZipValid ? true : false}
                      helperText={jointOwnerValidationError?.flagJointOwnerZipValid ? "ZIP number only contains 5 digits" : ""}
                      label="ZIP"
                      disabled={disableAllInputs === true ? true : false}
                      type="number"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("jointOwnerZip", e.target.value);
                        let temp = { ...jointOwnerValidationError }
                        if (e.target.value?.length) {
                          temp.flagJointOwnerZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(e.target.value))
                          setJointOwnerValidationError(temp)
                        } else {
                          temp.flagJointOwnerZipValid = false
                          setJointOwnerValidationError(temp)
                        }
                      }}
                      onBlur={() => {
                        let temp = { ...jointOwnerValidationError }
                        if (individualAnnuityApplication.jointOwnerZip?.length) {
                          temp.flagJointOwnerZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(individualAnnuityApplication.jointOwnerZip))
                          setJointOwnerValidationError(temp)
                        } else {
                          temp.flagJointOwnerZipValid = false
                          setJointOwnerValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.jointOwnerZip}
                    />
                  </Box>
                </Box>
                <Box className="annuitant_city_and_state_row">
                  <Box className="left_section">
                    <TextField
                      className={flagJointOwnerRequired ? "input_field_width" : "input_field_width not_required"}
                      label="City"
                      disabled={disableAllInputs === true ? true : false}
                      type="text"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("jointOwnerCity", e.target.value);
                      }}
                      value={individualAnnuityApplication.jointOwnerCity}
                    />
                  </Box>
                  <Box className="right_section">
                    <FormControl
                      className={flagJointOwnerRequired ? "input_field_width" : "input_field_width not_required"}
                      variant="standard"
                      style={{ width: "50%" }}
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        State
                      </InputLabel>
                      <Select
                        className="state_dropdown"
                        onChange={(e) => {
                          CommonContextUpdate(
                            "jointOwnerState",
                            e.target.value
                          );
                        }}
                        value={individualAnnuityApplication.jointOwnerState}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {StatesName.map((stateName) => {
                          return (
                            <MenuItem value={stateName}>{stateName}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="annuitant_phone_and_email_row">
                  <Box className="left_section">
                    <TextField
                      className={flagJointOwnerRequired ? "input_field_width" : "input_field_width not_required"}
                      error={jointOwnerValidationError?.flagJointOwnerEmailValid ? true : false}
                      helperText={jointOwnerValidationError?.flagJointOwnerEmailValid ? "Invalid Email Address" : ""}
                      label="Email"
                      disabled={disableAllInputs === true ? true : false}
                      type="email"
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate("jointOwnerEmail", e.target.value);
                        let temp = { ...jointOwnerValidationError }
                        if (e.target.value?.length) {
                          temp.flagJointOwnerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))
                          setJointOwnerValidationError(temp)
                        } else {
                          temp.flagJointOwnerEmailValid = false
                          setJointOwnerValidationError(temp)
                        }
                      }}
                      onBlur={() => {
                        let temp = { ...jointOwnerValidationError }
                        if (individualAnnuityApplication.jointOwnerEmail?.length) {
                          temp.flagJointOwnerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(individualAnnuityApplication.jointOwnerEmail))
                          setJointOwnerValidationError(temp)
                        } else {
                          temp.flagJointOwnerEmailValid = false
                          setJointOwnerValidationError(temp)
                        }
                      }}
                      value={individualAnnuityApplication.jointOwnerEmail}
                    />
                  </Box>
                  <Box className="right_section">
                    <TextField
                      className={flagJointOwnerRequired ? "input_field_width" : "input_field_width not_required"}
                      type="text"
                      label="Relationship To Annuitant"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        CommonContextUpdate(
                          "jointOwnerRelationToAnnuitant",
                          e.target.value
                        );
                      }}
                      value={
                        individualAnnuityApplication.jointOwnerRelationToAnnuitant
                      }
                    />
                  </Box>
                </Box>
                <Box className="annuitant_phone_and_email_row">
                  <Box className="left_section">
                    <MuiPhoneNumber
                      name="phone"
                      label="Phone"
                      sx={{
                        pointerEvents: disableAllInputs === true ? "none" : "auto",
                        '& .MuiPhoneNumber-flagButton': {
                          pointerEvents: "none"
                        }
                      }}
                      data-cy="user-phone"
                      defaultCountry={"us"}
                      onlyCountries={["us"]}
                      autoFormat={true}
                      countryCodeEditable={false}
                      value={individualAnnuityApplication.jointOwnerPhone}
                      className={flagJointOwnerRequired ? "input_field_width" : "input_field_width not_required"}
                      onChange={(value) => {
                        let temp = { ...jointOwnerValidationError }
                        temp.flagJointOwnerPhoneValid = !validatePhoneNumberLatest(value)
                        setJointOwnerValidationError(temp)
                        CommonContextUpdate("jointOwnerPhone", value)
                      }}
                      disabled={disableAllInputs === true ? true : false}
                    // countryCodeEditable={false}
                    />
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Box
              className="annuitant_head_root"
              onClick={() => {
                // setFlagFourthSection(!flagFirstPageFourthSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageFourthSection",
                  data: !flagFirstPageFourthSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  4. Plan/Riders Applied For (Please check one)
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageFourthSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFirstPageFourthSection === true ? (
              <Box className="plan_rider_section">
                <Box className="annuity_plan_section">
                  <Typography variant="span">
                    1. Annuity Plans<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <RadioGroup
                    row={true}
                    value={individualAnnuityApplication.annuitantPlan}
                    className="radio_group_shift_right"
                    onChange={(e) => {
                      CommonContextUpdate("annuitantPlan", e.target.value);
                    }}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"safeguard plus 3"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Safeguard Plus 3"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"safeguard plus 5"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Safeguard Plus 5"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"safeguard plus 7"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Safeguard Plus 7"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"safeguard plus 10"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="safeguard plus 10"
                    />
                  </RadioGroup>
                </Box>
                <Box className="rider_section">
                  <Typography variant="span">2. Rider</Typography>
                  <FormGroup row={true} className="radio_group_shift_right">
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Checkbox
                          checked={individualAnnuityApplication.planRiders.includes(
                            "5%free withdrawal rider"
                          )}
                          onChange={(e) => {
                            let temp = [...individualAnnuityApplication.planRiders];
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }
                            CommonContextUpdate("planRiders", temp);
                          }}
                          value={"5%free withdrawal rider"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="5% Free Withdrawal Rider"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Checkbox
                          checked={individualAnnuityApplication.planRiders.includes(
                            "enhanced benefit rider"
                          )}
                          onChange={(e) => {
                            let temp = [
                              ...individualAnnuityApplication.planRiders,
                            ];
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }
                            CommonContextUpdate("planRiders", temp);
                          }}
                          value={"enhanced benefit rider"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Enhanced Benefit Rider"
                    />
                  </FormGroup>
                </Box>
              </Box>
            ) : null}
            {/* Beneficiaries Section Start */}
            <Box
              className="annuitant_head_root"
              onClick={() => {
                // setFlagFifthSection(!flagFirstPageFifthSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageFifthSection",
                  data: !flagFirstPageFifthSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">5. Beneficiaries</Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageFifthSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFirstPageFifthSection === true ? (
              <Box className="beneficiaries_section">
                <Typography variant="span">
                  If joint owners are named, on the death of either joint owner
                  the surviving joint owner will become the sole primary
                  beneficiary, and the beneficiaries listed below will be
                  considered contingent beneficiaries (unless otherwise
                  requested in the special requests section).
                </Typography>
                <Box className="beneficiaries_section_fields">
                  {individualAnnuityApplication.beneficiariesPrimary.map(
                    (row, i, arr) => {
                      console.log("Printing row for beneficiaries primary", row);
                      let flagRequired = IsRowRequired(row)
                      {/* debugger; */ }
                      return (
                        <Box className="beneficiaries_section_row">
                          <Box className="beneficiaries_section_row_1">
                            <TextField
                              label="Primary Full Name"
                              variant="standard"
                              type="text"
                              className={i == 0 ? "beneficiaries_field_width_name" : flagRequired ? "beneficiaries_field_width_name" : "beneficiaries_field_width_name not_required"}
                              disabled={disableAllInputs === true ? true : false}
                              value={row.primaryField}
                              onChange={(e) => {
                                let temp = arr;
                                console.log(temp);
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    v.primaryField = e.target.value;
                                  }
                                  return v;
                                });
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesPrimary",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_2">
                            <TextField
                              // sx={{ width: 50 }}
                              label="%"
                              type="number"
                              className={i == 0 ? "beneficiaries_field_width_per" : flagRequired ? "beneficiaries_field_width_per" : "beneficiaries_field_width_per not_required"}
                              disabled={disableAllInputs === true ? true : false}
                              variant="standard"
                              value={
                                row.percentageField == -1
                                  ? ""
                                  : row.percentageField
                              }
                              onChange={(e) => {
                                let temp = arr;
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    v.percentageField = e.target.value;
                                  }
                                  return v;
                                });
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesPrimary",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_2">
                            <TextField
                              // sx={{ width: 120 }}
                              label="Relationship"
                              type="text"
                              className={i == 0 ? "beneficiaries_field_width_relation" : flagRequired ? "beneficiaries_field_width_relation" : "beneficiaries_field_width_relation not_required"}
                              disabled={disableAllInputs === true ? true : false}
                              variant="standard"
                              value={
                                row.relationshipField == -1
                                  ? ""
                                  : row.relationshipField
                              }
                              onChange={(e) => {
                                let temp = arr;
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    v.relationshipField = e.target.value;
                                  }
                                  return v;
                                });
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesPrimary",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_3">
                            <TextField
                              // sx={{ width: 140 }}
                              label="SSN/TIN"
                              type="text"
                              className={i == 0 ? "beneficiaries_field_width_ssn" : flagRequired ? "beneficiaries_field_width_ssn" : "beneficiaries_field_width_ssn not_required"}
                              disabled={disableAllInputs === true ? true : false}
                              variant="standard"
                              value={row.ssnField}
                              onBlur={(e) => {
                                let temp = arr;
                                var regx =
                                  /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                                temp = temp.filter((v) => {
                                  if (row?.id === v?.id) {
                                    if (!regx.test(e.target.value)) {
                                      v.ssnField = "";
                                    }
                                  }
                                  return v;
                                });
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesPrimary",
                                  value: temp,
                                });
                              }}
                              onChange={(e) => {
                                let temp = arr;
                                console.log(temp);
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    let targetLength = e.target.value.length;
                                    let contextLength = v.ssnField.length;
                                    var regx = /[0-9/-]\d/gi;

                                    if (targetLength > contextLength) {
                                      if (targetLength === 3 || targetLength === 6) {
                                        let val = e.target.value + "-";
                                        v.ssnField = val;
                                      } else if (e.target.value.length <= 11) {
                                        v.ssnField = e.target.value;
                                      }
                                    } else {
                                      if (targetLength === 7 || targetLength === 4) {
                                        v.ssnField = e.target.value.slice(0, -1);
                                      } else {
                                        v.ssnField = e.target.value;
                                      }
                                    }
                                  }
                                  return v;
                                });
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesPrimary",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="annuitant_gender_selection annuitant_gender_selection_gender1">
                            <FormControl
                              variant="standard"
                              className={i == 0 ? "annuitant_gender_selection_gender2" : flagRequired ? "annuitant_gender_selection_gender2" : "annuitant_gender_selection_gender2 not_required"}
                            >
                              <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                                Gender Assigned at Birth
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                className="annuitant_gender_selection_gender3"
                                id="demo-simple-select"
                                // sx={{ width: 170 }}
                                value={row.genderField}
                                onChange={(e) => {
                                  let temp = arr;
                                  console.log(temp);
                                  temp = temp.filter((v) => {
                                    if (v?.id === row?.id) {
                                      v.genderField = e.target.value;
                                    }
                                    return v;
                                  });
                                  console.log("context", temp);
                                  SetIndividualAnnuityApplication({
                                    type: "beneficiariesPrimary",
                                    value: temp,
                                  });
                                }}
                                disabled={
                                  disableAllInputs === true ? true : false
                                }
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value={"male"}>Male</MenuItem>
                                <MenuItem value={"female"}>Female</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                      );
                    }
                  )}
                  {individualAnnuityApplication.beneficiariesContigent.map(
                    (row, i, arr) => {
                      console.log(row);
                      let flagRequired = IsRowRequired(row)
                      return (
                        <Box className="beneficiaries_section_row">
                          <Box className="beneficiaries_section_row_1">
                            <TextField
                              label="Contingent Full Name"
                              type="text"
                              variant="standard"
                              className={flagRequired ? "beneficiaries_field_width beneficiaries_field_width_name" : "beneficiaries_field_width not_required beneficiaries_field_width_name"}
                              disabled={disableAllInputs === true ? true : false}
                              value={row.contigentField}
                              onChange={(e) => {
                                let temp = arr;
                                temp = temp.filter((v) => {
                                  if (row?.id === v?.id) {
                                    v.contigentField = e.target.value;
                                  }
                                  return v;
                                });
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesContigent",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_2">
                            <TextField
                              label="%"
                              type="number"
                              variant="standard"
                              disabled={disableAllInputs === true ? true : false}
                              className={flagRequired ? "beneficiaries_field_width beneficiaries_field_width_per" : "beneficiaries_field_width not_required beneficiaries_field_width_per"}
                              value={
                                row.percentageField == -1
                                  ? ""
                                  : row.percentageField
                              }
                              onChange={(e) => {
                                let temp = arr;
                                temp = temp.filter((v) => {
                                  if (row?.id === v?.id) {
                                    v.percentageField = e.target.value;
                                  }
                                  return v;
                                });
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesContigent",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_2">
                            <TextField
                              label="Relationship"
                              type="text"
                              className={flagRequired ? "beneficiaries_field_width beneficiaries_field_width_relation" : "beneficiaries_field_width not_required beneficiaries_field_width_relation"}
                              disabled={disableAllInputs === true ? true : false}
                              variant="standard"
                              value={
                                row.relationshipField == -1
                                  ? ""
                                  : row.relationshipField
                              }
                              onChange={(e) => {
                                let temp = arr;
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    v.relationshipField = e.target.value;
                                  }
                                  return v;
                                });
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesContigent",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_3">
                            <TextField
                              label="SSN/TIN"
                              type="text"
                              disabled={disableAllInputs === true ? true : false}
                              className={flagRequired ? "beneficiaries_field_width beneficiaries_field_width_ssn" : "beneficiaries_field_width not_required beneficiaries_field_width_ssn"}
                              variant="standard"
                              value={row.ssnField}
                              onBlur={(e) => {
                                let temp = arr;
                                var regx =
                                  /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                                temp = temp.filter((v) => {
                                  if (row?.id === v?.id) {
                                    if (!regx.test(e.target.value)) {
                                      v.ssnField = "";
                                    }
                                  }
                                  return v;
                                });
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesContigent",
                                  value: temp,
                                });
                              }}
                              onChange={(e) => {
                                let temp = arr;
                                temp = temp.filter((v) => {
                                  if (row?.id === v?.id) {
                                    let targetLength = e.target.value.length;
                                    let contextLength = v.ssnField.length;

                                    if (targetLength > contextLength) {
                                      if (
                                        targetLength === 3 ||
                                        targetLength === 6
                                      ) {
                                        let val = e.target.value + "-";
                                        v.ssnField = val;
                                      } else if (e.target.value.length <= 11) {
                                        v.ssnField = e.target.value;
                                      }
                                    } else {
                                      if (
                                        targetLength === 7 ||
                                        targetLength === 4
                                      ) {
                                        v.ssnField = e.target.value.slice(
                                          0,
                                          -1
                                        );
                                      } else {
                                        v.ssnField = e.target.value;
                                      }
                                    }
                                  }
                                  return v;
                                });
                                SetIndividualAnnuityApplication({
                                  type: "beneficiariesContigent",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="annuitant_gender_selection annuitant_gender_selection_gender1">
                            <FormControl
                              variant="standard"
                              className={flagRequired ? "annuitant_gender_selection_gender2" : "annuitant_gender_selection_gender2 not_required"}
                            >
                              <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                                Gender Assigned at Birth
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                className="annuitant_gender_selection_gender3 "
                                id="demo-simple-select"
                                sx={{ width: 170 }}
                                value={row.genderField}
                                onChange={(e) => {
                                  let temp = arr;
                                  console.log(arr);
                                  temp = temp.filter((v) => {
                                    if (row?.id === v?.id) {
                                      v.genderField = e.target.value;
                                    }
                                    return v;
                                  });
                                  SetIndividualAnnuityApplication({
                                    type: "beneficiariesContigent",
                                    value: temp,
                                  });
                                }}
                                disabled={
                                  disableAllInputs === true ? true : false
                                }
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value={"male"}>Male</MenuItem>
                                <MenuItem value={"female"}>Female</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Box>
                <Box
                  className="total_percentage_beneficiaries"
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography fontWeight={"bold"}>
                    Primary Total:{" "}
                    <span
                      style={
                        primaryBeneficiariesPercentage !== 100 &&
                          primaryBeneficiariesPercentage !== 0
                          ? { color: "red" }
                          : { color: "#000" }
                      }
                    >
                      {primaryBeneficiariesPercentage}%
                    </span>
                  </Typography>
                  <Typography fontWeight={"bold"}>
                    Contingent Total:{" "}
                    <span
                      style={
                        contigentBeneficiariesPercentage !== 100 &&
                          contigentBeneficiariesPercentage !== 0
                          ? { color: "red" }
                          : { color: "#000" }
                      }
                    >
                      {contigentBeneficiariesPercentage}%
                    </span>
                  </Typography>
                </Box>
              </Box>
            ) : null}
            <Box
              className="annuitant_head_root"
              onClick={() => {
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageSixthSection",
                  data: !flagFirstPageSixthSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  6. Premium and Tax Qualification Status
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageSixthSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFirstPageSixthSection === true ? (
              <Box
                className="premium_and_tax_qualification_status_main"
                width={"80%"}
              >
                <Box style={{ margin: "5px 10px 10px 0" }}>
                  <Typography variant="span">
                    <InputLabel style={{ fontSize: "13px" }}>
                      Select the Tax Qualification status
                    </InputLabel>
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                  }}
                  className="radio_group_shift_right"
                >
                  <Button
                    disabled={disableAllInputs === true ? true : false}
                    style={{
                      marginRight: "40px",
                      background:
                        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                          "non - qualified"
                          ? "rgb(7, 74, 110)"
                          : "white",
                      color:
                        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                          "non - qualified"
                          ? "white"
                          : "rgb(7, 74, 110)",
                      textTransform: "capitalize",
                      border: "1px solid rgb(7, 74, 110)",
                    }}
                    variant={
                      individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                        "non - qualified"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={(e) => {
                      CommonContextUpdate("selectedNonQualifiedOptions", []);
                      SetIndividualAnnuityApplication({
                        type: "selectedQualifiedOptionsGroup2",
                        value: "",
                      });
                      if (
                        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                        "non - qualified"
                      ) {
                        CommonContextUpdate(
                          "flagQualifiedOrNonQualifiedSelected",
                          ""
                        );
                      } else {
                        CommonContextUpdate(
                          "flagQualifiedOrNonQualifiedSelected",
                          "non - qualified"
                        );
                      }
                      CommonContextUpdate("amountPaidWithApplication", "");
                      CommonContextUpdate("expectedPremiumField", "");
                    }}
                  >
                    Non - Qualified
                  </Button>
                  <Button
                    disabled={disableAllInputs === true ? true : false}
                    style={{
                      background:
                        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                          "qualified"
                          ? "rgb(7, 74, 110)"
                          : "white",
                      color:
                        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                          "qualified"
                          ? "white"
                          : "rgb(7, 74, 110)",
                      textTransform: "capitalize",

                      border: "1px solid rgb(7, 74, 110)",
                    }}
                    variant={
                      individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                        "qualified"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={(e) => {
                      CommonContextUpdate("selectedNonQualifiedOptions", []);
                      SetIndividualAnnuityApplication({
                        type: "selectedQualifiedOptionsGroup2",
                        value: "",
                      });
                      if (
                        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                        "qualified"
                      ) {
                        CommonContextUpdate(
                          "flagQualifiedOrNonQualifiedSelected",
                          ""
                        );
                      } else {
                        CommonContextUpdate(
                          "flagQualifiedOrNonQualifiedSelected",
                          "qualified"
                        );
                      }
                      CommonContextUpdate("amountPaidWithApplication", "");
                      CommonContextUpdate("expectedPremiumField", "");
                    }}
                  >
                    Qualified
                  </Button>
                </Box>
                <Box>
                  {individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                    "non - qualified" &&
                    individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected !==
                    "qualified" ? (
                    <>
                      <Box style={{ margin: "10px 0px 0px 15px" }}>
                        <Typography variant="span">
                          <InputLabel style={{ fontSize: "13px" }}>
                            Select the plan
                          </InputLabel>
                        </Typography>
                      </Box>
                      <Box
                        className="ptqs_non_qualified_options radio_group_shift_right"
                        style={{
                          padding: "5px 0px 5px 10px",
                          background: "#fff",
                          borderRadius: "10px",
                        }}
                      >
                        <FormGroup row={true} className="radio_group_shift_right">
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Checkbox
                                checked={individualAnnuityApplication.selectedNonQualifiedOptions.includes(
                                  "cash with application"
                                )}
                                onChange={(e) => {
                                  let temp = [
                                    ...individualAnnuityApplication.selectedNonQualifiedOptions,
                                  ];
                                  if (e.target.checked === false) {
                                    temp = temp.filter((v) => {
                                      if (v !== e.target.value) {
                                        return v;
                                      }
                                    });
                                  } else {
                                    temp.push(e.target.value);
                                  }
                                  CommonContextUpdate("selectedNonQualifiedOptions", temp);
                                }}
                                value={"cash with application"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Cash with application"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Checkbox
                                checked={individualAnnuityApplication.selectedNonQualifiedOptions.includes(
                                  "1035 exchange"
                                )}
                                onChange={(e) => {
                                  let temp = [
                                    ...individualAnnuityApplication.selectedNonQualifiedOptions,
                                  ];
                                  if (e.target.checked === false) {
                                    temp = temp.filter((v) => {
                                      if (v !== e.target.value) {
                                        return v;
                                      }
                                    });
                                  } else {
                                    temp.push(e.target.value);
                                  }

                                  CommonContextUpdate("selectedNonQualifiedOptions", temp);
                                }}
                                value={"1035 exchange"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="1035 exchange"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Checkbox
                                checked={individualAnnuityApplication.selectedNonQualifiedOptions.includes("transfer")}
                                onChange={(e) => {
                                  let temp = [
                                    ...individualAnnuityApplication.selectedNonQualifiedOptions,
                                  ];
                                  if (e.target.checked === false) {
                                    temp = temp.filter((v) => {
                                      if (v !== e.target.value) {
                                        return v;
                                      }
                                    });
                                  } else {
                                    temp.push(e.target.value);
                                  }
                                  CommonContextUpdate("selectedNonQualifiedOptions", temp);
                                }}
                                value={"transfer"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Transfer"
                          />
                        </FormGroup>
                      </Box>
                    </>
                  ) : null}
                  {individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                    "qualified" &&
                    individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected !==
                    "non - qualified" ? (
                    <>
                      <Box style={{ margin: "10px 0px 0px 15px" }}>
                        <Typography variant="span">
                          {" "}
                          <InputLabel style={{ fontSize: "13px" }}>
                            Select the plan
                          </InputLabel>
                        </Typography>
                      </Box>

                      <Box
                        className="ptqs_qualified_options radio_group_shift_right"
                        style={{
                          padding: "5px 5px 5px 10px",
                          background: "#fff",
                          borderRadius: "10px",
                          display: "flex",
                        }}
                      >
                        <FormGroup row={true} className="radio_group_shift_right">
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Checkbox
                                checked={individualAnnuityApplication.selectedNonQualifiedOptions.includes(
                                  "cash with application"
                                )}
                                onChange={(e) => {
                                  let temp = [
                                    ...individualAnnuityApplication.selectedNonQualifiedOptions,
                                  ];
                                  if (e.target.checked === false) {
                                    temp = temp.filter((v) => {
                                      if (v !== e.target.value) {
                                        return v;
                                      }
                                    });
                                  } else {
                                    temp.push(e.target.value);
                                  }
                                  CommonContextUpdate("selectedNonQualifiedOptions", temp);
                                }}
                                value={"cash with application"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Cash with application"
                          />

                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Checkbox
                                checked={individualAnnuityApplication.selectedNonQualifiedOptions.includes("transfer")}
                                onChange={(e) => {
                                  let temp = [
                                    ...individualAnnuityApplication.selectedNonQualifiedOptions,
                                  ];
                                  if (e.target.checked === false) {
                                    temp = temp.filter((v) => {
                                      if (v !== e.target.value) {
                                        return v;
                                      }
                                    });
                                  } else {
                                    temp.push(e.target.value);
                                  }
                                  CommonContextUpdate("selectedNonQualifiedOptions", temp);
                                }}
                                value={"transfer"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Transfer"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Checkbox
                                checked={individualAnnuityApplication.selectedNonQualifiedOptions.includes(
                                  "rollover"
                                )}
                                onChange={(e) => {
                                  let temp = [
                                    ...individualAnnuityApplication.selectedNonQualifiedOptions,
                                  ];
                                  if (e.target.checked === false) {
                                    temp = temp.filter((v) => {
                                      if (v !== e.target.value) {
                                        return v;
                                      }
                                    });
                                  } else {
                                    temp.push(e.target.value);
                                  }

                                  CommonContextUpdate("selectedNonQualifiedOptions", temp);
                                }}
                                value={"rollover"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Rollover"
                          />
                        </FormGroup>
                      </Box>
                    </>
                  ) : null}
                </Box>
                <Box>
                  {individualAnnuityApplication?.selectedNonQualifiedOptions?.length > 0 && (
                    <>
                      <Box style={{ margin: "0px 10px 0px 15px" }}>
                        <Typography variant="span">
                          <InputLabel style={{ fontSize: "13px" }}>
                            Select type of plan
                          </InputLabel>
                        </Typography>
                      </Box>
                      <RadioGroup
                        value={individualAnnuityApplication.selectedQualifiedOptionsGroup2}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          marginLeft: "30px"
                        }}
                        onChange={(e) => {
                          if (
                            e.target.value !== "other" &&
                            individualAnnuityApplication.otherSelectedQualifiedOptions !==
                            ""
                          ) {
                            CommonContextUpdate(
                              "otherSelectedQualifiedOptions",
                              ""
                            );
                          }
                          SetIndividualAnnuityApplication({
                            type: "selectedQualifiedOptionsGroup2",
                            value: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={"ira"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="IRA"
                        />
                        <FormControlLabel
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={"roth ira"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Roth IRA"
                        />
                        <FormControlLabel
                          sx={{ display: "none" }}
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={"sep"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="SEP"
                        />
                        <FormControlLabel
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={
                                "pension plan"
                              }
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Pension Plan(Profit sharing or defined benefit)"
                        />
                        <br />
                        <Box>
                          <FormControlLabel
                            disabled={
                              disableAllInputs === true ? true : false
                            }
                            control={
                              <Radio
                                value={"other"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Other"
                          />
                          <TextField
                            variant="standard"
                            type="text"
                            required
                            className="req_fields"
                            placeholder={
                              individualAnnuityApplication.selectedQualifiedOptionsGroup2 ===
                                "other"
                                ? "This field is required"
                                : ""
                            }
                            value={
                              individualAnnuityApplication.otherSelectedQualifiedOptions
                            }
                            onChange={(e) => {
                              CommonContextUpdate(
                                "otherSelectedQualifiedOptions",
                                e.target.value
                              );
                            }}
                            disabled={
                              individualAnnuityApplication.selectedQualifiedOptionsGroup2 ===
                                "other" && disableAllInputs !== true
                                ? false
                                : true
                            }
                            style={{
                              border: "none",
                              outline: "none",
                              borderBottom: "1px solid #b3b3b3",
                            }}
                          />
                        </Box>
                      </RadioGroup>
                    </>
                  )}
                </Box>
                {(individualAnnuityApplication.selectedNonQualifiedOptions.length &&
                  individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected !== "") ?
                  (
                    <Box
                      className="amount_to_paid_with_application"
                      style={{ margin: "5px 0px 5px 30px" }}
                    >
                      <InputLabel style={{ fontSize: "13px" }}>
                        Amount Paid With Application
                      </InputLabel>
                      <Input
                        style={{ width: "100%" }}
                        disabled={disableAllInputs === true ? true : false}
                        type="text"
                        onChange={(e) => {
                          console.log(e.target.value);
                          var value = e.target.value;
                          value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

                          console.log(value.replaceAll(",", ""));
                          if (isNaN(value.replaceAll(",", "")) === false)
                            // CommonContextUpdate("expectedPremiumField", "");
                            CommonContextUpdate("amountPaidWithApplication", value.replaceAll(",", ""));
                        }}
                        value={individualAnnuityApplication.amountPaidWithApplication.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </Box>
                  ) : null}
                {((individualAnnuityApplication.selectedNonQualifiedOptions?.length > 0 &&
                  (individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("1035 exchange") > -1
                    || individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("transfer") > -1
                    || individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("rollover") > -1
                  ))) ? (
                  <Box className="expected_premium" style={{ margin: "5px 0px 5px 30px" }}>
                    <InputLabel style={{ fontSize: "13px" }}>
                      If a 1035 Exchange, Rollover or transfer is occurring, the
                      expected premium Amount is
                    </InputLabel>
                    <Input
                      style={{ width: "100%" }}
                      type="text"
                      disabled={disableAllInputs === true ? true : false}
                      onChange={(e) => {
                        var value = e.target.value;
                        value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
                        if (isNaN(value.replaceAll(",", "")) === false)
                          // CommonContextUpdate("amountPaidWithApplication", "");
                          CommonContextUpdate("expectedPremiumField", value.replaceAll(",", ""));
                      }}
                      value={individualAnnuityApplication.expectedPremiumField.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </Box>
                ) : null}



              </Box>
            ) : null}
            {/* Premium and Tax Qualification Status Section End */}

            <Box
              className="annuitant_head_root"
              onClick={() => {
                // setFlagSeventhSection(!flagFirstPageSeventhSection)
                SetApplicationFormFlagSectionData({
                  type: "Individual Annuity Application",
                  section: "flagFirstPageSeventhSection",
                  data: !flagFirstPageSeventhSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  7. Owner Replacement Questions{" "}
                  <span>(Required for All Applications)</span>
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagFirstPageSeventhSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            <Box
              className=" owner_replacement_question "
              style={{ width: "80%", left: "0%", position: "relative" }}
            >
              {flagFirstPageSeventhSection === true ? (
                <Box className="owner_replacement_question_main">
                  <Typography variant="span" style={{ margin: "20px 0" }}>
                    If "Yes", please sign the replacement form.
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <Box
                    className="owner_replacement_first_question"
                    style={{ marginTop: "20px" }}
                  >
                    <Typography variant="span">
                      1. Do you have any existing life insurance policies or
                      annuity contracts with Farmers Life Insurance Company or
                      any other insurance company?
                    </Typography>
                    <RadioGroup
                      className="radio_group_shift_right"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                      }}
                      value={individualAnnuityApplication.flagHaveExistingLifeInsurance}
                      onClick={(e) => {
                        if (!e.target.checked) {
                          return;
                        }
                        if (!e.target.value) {
                          CommonContextUpdate("flagHaveExistingLifeInsurance", "");
                          return;
                        }
                        if (e.target.value != individualAnnuityApplication.flagHaveExistingLifeInsurance) {
                          CommonContextUpdate("flagHaveExistingLifeInsurance", e.target.value);
                        } else {
                          CommonContextUpdate("flagHaveExistingLifeInsurance", "");

                        }
                      }}
                    >
                      <FormControlLabel
                        disabled={disableAllInputs === true ? true : false}
                        control={
                          <Radio
                            value={"yes"}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        disabled={disableAllInputs === true ? true : false}
                        control={
                          <Radio
                            value={"no"}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              ) : null}
              {flagFirstPageSeventhSection === true && (
                <Fragment>
                  <Box
                    className="owner_replacement_second_question"
                    style={{ marginTop: "20px" }}
                  >
                    <Typography variant="span">
                      2. Is this Application intended to replace or change any
                      life insurance or annuity contract with Farmers Life
                      Insurance Company or any other company? Change means
                      causing a policy or contract to lapse, surrender (in whole
                      or part), forfeit, terminate, convert to reduce paid up or
                      continue as extend term insurance, reduce in value by
                      borrowing against it or change the term or coverage
                      benefits.
                      <span style={{ color: "red" }} color="red">
                        *
                      </span>
                    </Typography>
                    <RadioGroup
                      className="radio_group_shift_right"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                      }}
                      value={
                        individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance
                      }
                      onClick={(e) => {
                        if (!e.target.checked) {
                          return;
                        }
                        if (!e.target.value) {
                          CommonContextUpdate(
                            "reasonOfApplicationToChangeLifeInsurance",
                            ""
                          );

                          return;
                        }
                        if (
                          e.target.value !=
                          individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance
                        ) {
                          CommonContextUpdate(
                            "reasonOfApplicationToChangeLifeInsurance",
                            e.target.value
                          );
                        } else {
                          CommonContextUpdate(
                            "reasonOfApplicationToChangeLifeInsurance",
                            ""
                          );

                          // CommonContextUpdate("typeOfAccount", "");
                        }
                      }}
                    >
                      <FormControlLabel
                        disabled={disableAllInputs === true ? true : false}
                        control={
                          <Radio
                            value={"yes"}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        disabled={disableAllInputs === true ? true : false}
                        control={
                          <Radio
                            value={"no"}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </Box>
                </Fragment>
              )}
              {/* {(flagFirstPageSeventhSection === true && individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance == "yes")
                && (
                  <>
                    <Box className="nor_states_select">
                      <FormControl
                        className="input_field_width"
                        variant="standard"
                        style={{ width: "50%" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select the state for notice of replacement form
                        </InputLabel>
                        <Select
                          className="state_dropdown"
                          onChange={(e) => {
                            CommonContextUpdate("stateToShowNoticeOfReplacement", e.target.value);
                          }}
                          value={individualAnnuityApplication.stateToShowNoticeOfReplacement}
                          disabled={disableAllInputs === true ? true : false}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {StatesNameNOR.map((stateName) => {
                            return (
                              <MenuItem value={stateName}>{stateName}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </>)
              } */}
              {flagFirstPageSeventhSection === true && (
                <Fragment>
                  <TableContainer className="replacementTableContainer">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow className="replacementTableHead">
                          <TableCell>#</TableCell>
                          <TableCell>Company Name of Policy/Contract Being Replaced</TableCell>
                          <TableCell>Policy/Contract Number</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {individualAnnuityApplication.applicationReplacementFieldsArray?.map(
                          (v, i, arr) => {
                            {/* flagFirstPageSeventhSection === true && ( */ }
                            return (
                              <>
                                <TableRow key={i} className="replacementTableRow">
                                  <TableCell align="center" className={disableAllInputs === true ? "replacementTableCell replacementTableCellDisableColor " : "replacementTableCell"} >{i + 1}</TableCell>
                                  <TableCell align="left" className="replacementTableCell">
                                    <TextField
                                      disabled={disableAllInputs === true || flagDisableQuestions == true ? true : false}
                                      className="not_required owner_replacement_not_required"
                                      type="text"
                                      placeholder="Enter Company Name of Policy/Contract Being Replaced"
                                      variant="outlined"
                                      style={{
                                        width: "90%",
                                        fontSize: "1px",
                                      }}
                                      value={v.companyNameOfPolicyField}
                                      // value="Kaval"
                                      onChange={(e) => {
                                        if (flagDisableQuestions == true) {
                                          return;
                                        }
                                        let temp = [...arr];
                                        console.log(temp);
                                        temp = temp.filter((row) => {
                                          if (v.id === row.id) {
                                            row.companyNameOfPolicyField = e.target.value;
                                          }
                                          return row;
                                        });

                                        console.log("context", temp);
                                        CommonContextUpdate("applicationReplacementFieldsArray", temp);
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="left" className="replacementTableCell">
                                    <TextField
                                      type="text"
                                      className="not_required owner_replacement_not_required"
                                      disabled={disableAllInputs === true || flagDisableQuestions == true ? true : false}
                                      placeholder="Enter Policy/Contract Number"
                                      variant="outlined"
                                      style={{ width: "90%" }}
                                      value={v.policyNumberField}
                                      onChange={(e) => {
                                        if (flagDisableQuestions == true) {
                                          return;
                                        }
                                        let temp = [...arr];
                                        console.log(temp);
                                        temp = temp.filter((row) => {
                                          if (v.id === row.id) {
                                            row.policyNumberField = e.target.value;
                                          }
                                          return row;
                                        });

                                        console.log("context", temp);
                                        CommonContextUpdate("applicationReplacementFieldsArray",
                                          temp
                                        );
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              </>

                            )
                            {/* ); */ }
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Fragment>
              )}
              {individualAnnuityApplication.stateToShowNoticeOfReplacement === "Illinois" ? <IllinoisAlert /> : ""}
            </Box>
            <Box
              className="next_suitability_analysis_btn"
              style={{ margin: "20px 0 20px 0" }}
            >
              <Button
                endIcon={<DoubleArrowIcon />}
                style={{
                  width: "250px",
                  color: "#fff",
                  textTransform: "capitalize",
                  background: "#074a6e",
                }}
                onClick={() => {
                  if (ConditionToShowReplacementForm() === true) {
                    props.changePageIndex(1)
                  } else {
                    props.changePageIndex(2)
                  }
                }}
              >
                Next -{" "}
                {ConditionToShowReplacementForm() === true
                  ? "Notice Of Replacement"
                  : "Suitability Analysis"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ApplicationForm;
