import React, {
    useContext,
    useEffect,
    useRef,
    useState,
    Fragment,
} from "react";
import {
    Typography,
    Box,
    Divider,
    TextField,
    Select,
    MenuItem,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    FormControl,
    InputLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    Input,
    InputAdornment,
    Grid,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Context } from "../../../context/V3/newApplicationFormContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Context as UserLoaderContext } from "../../../context/userLoaderContext";
import { Context as ErrorValidationContext } from "../../../context/V3/errorValidationContext";
import "./fixedIndexAnnuityApplication.css"
import MuiPhoneNumber from "material-ui-phone-number";
import { IllinoisAlert } from "../illinoisAlert";

const FixedIndexAnnuityApplication = (props) => {
    const {
        SetIndividualFixedIndexAnnuityApplication,
        SetWholeAnnuityDisclosureForm,
        updateTransferOrExchangeRequestFlag,
    } = useContext(Context);
    const { state } = useContext(UserLoaderContext);
    const {
        individualFixedIndexAnnuityApplication,
        disableAllInputs,
        annuityDisclosureForm,
        accountAllocationArray
    } = useContext(Context).state;

    const [flagNonQualifiedMenu, setFlagNonQualifiedMenu] = useState(false);
    const [flagQualifiedMenu, setFlagQualifiedMenu] = useState(false);
    const [flagTemp, setFlagTemp] = useState(disableAllInputs);
    const [flagDisableJointAnnuitant, setFlagDisableJointAnnuitant] = useState(false);
    const [flagDisableJointOwner, setFlagDisableJointOwner] = useState(false);

    const otherTypeOfPlanRef = useRef();
    console.log("individualFixedIndexAnnuityApplication++++++++", individualFixedIndexAnnuityApplication);
    const { SetApplicationFormFlagSectionData } = useContext(
        ErrorValidationContext
    );

    const {
        flagFirstPageFirstSection,
        flagFirstPageSecondSection,
        flagFirstPageThirdSection,
        flagFirstPageFourthSection,
        flagFirstPageFifthSection,
        flagFirstPageSixthSection,
        flagFirstPageSeventhSection,
        flagFirstPageEighthSection,
        flagFirstPageNinthSection
    } = useContext(ErrorValidationContext).state;

    const [beneficiariesPercentageTotal, setBeneficiariesPercentageTotal] = useState(0);
    const [initialAccountAllocation, setInitialAccountAllocation] = useState(0);
    const [primaryBeneficiariesPercentage, setPrimaryBeneficiariesPercentage] = useState(0);
    const [contigentBeneficiariesPercentage, setContigentBeneficiariesPercentage] = useState(0);
    const [annuityPlanValue, setAnnuityPlanValue] = useState("");
    const [riderValue, setRiderValue] = useState("");

    const [annuitantReq, setAnnuitantReq] = useState(false);

    const [ownerValidationError, setOwnerValidationError] = useState({
        flagOwnerZipValid: false,
        flagOwnerEmailValid: false,
        flagOwnerPhoneValid: false,
    });

    const [jointOwnerValidationError, setJointOwnerValidationError] = useState({
        flagJointOwnerZipValid: false,
        flagJointOwnerEmailValid: false,
        flagJointOwnerPhoneValid: false,
    });

    const [annuitantValidationError, setAnnuitantValidationError] = useState({
        flagAnnuitantZipValid: false,
        flagAnnuitantPhoneValid: false,
        flagAnnuitantEmailValid: false,
    });

    const [jointAnnuitantValidationError, setJointAnnuitantValidationError] = useState({
        flagJointAnnuitantZipValid: false,
        flagJointAnnuitantPhoneValid: false,
        flagJointAnnuitantEmailValid: false,
    });


    const producerDeclarationsArray = [
        {
            key: "A",
            question: "The applicant has existing life insurance policy(ies) or annuity contract(s) with Farmers Life Insurance Company or any other insurance company.",
            producerState: "producerStateA",
            applicantState: "applicantStateA",
        },
        {
            key: "B",
            question: "The contract applied for will replace or change an existing life insurance policy or annuity contract with Farmers Life Insurance Company or any other insurance company. Change means causing a policy or contract to: lapse; surrender (in whole or part); forfeit; terminate; convert to reduced paid-up or continue as extended term insurance; reduce in value by borrowing against it; or change the term or coverage benefits. <br/> If “yes,” the insurance producer has left all materials used in the sales presentation with the applicant(s). <br/> If “yes,” the insurance producer has gone through the applicable replacement form with the applicant(s), and it has been completed and is enclosed with this application.",
            producerState: "producerStateB",
            applicantState: "applicantStateB",
        },
        {
            key: "C",
            question: "The insurance producer has delivered and the applicant has received The Buyer’s Guide to Deferred Annuities.",
            producerState: "producerStateC",
            applicantState: "applicantStateC",
        },
        {
            key: "D",
            question: "The signatories of this application have read through the applicable product disclosure. The insurance producer has explained and the applicant(s) understands the various product features, including: (a) surrenders and withdrawals; (b) surrender charges and surrender charge period; (c) potential of a 10% early withdrawal tax penalty for withdrawals taken before age 59½; and (d) annuitization. A signed product disclosure is enclosed with this application. ",
            producerState: "producerStateD",
            applicantState: "applicantStateD",
        },
        {
            key: "E",
            question: "The applicant(s) is purchasing an annuity that includes a market value adjustment. If “yes,” the insurance producer has explained and the applicant(s) understands that during the market value adjustment period: (a) any amount surrendered or withdrawn may be subject to a market value adjustment; and (b) the adjustment may increase or decrease amounts payable under the contract. The insurance producer has explained and the applicant(s) understands that: (a) if interest rates rise after the contract effective date, the market value adjustment will generally decrease the surrender value; and (b) if interest rates fall after the contract effective date, the market value adjustment will generally increase the surrender value",
            producerState: "producerStateE",
            applicantState: "applicantStateE",
        },
        {
            key: "F",
            question: "The insurance producer has explained and the applicant(s) understands that Farmers Life Insurance Company does not offer legal, financial, tax, investment or estate-planning advice. The applicant(s) has had the opportunity to seek such advice from the proper sources before applying for this annuity.",
            producerState: "producerStateF",
            applicantState: "applicantStateF",
        },
        {
            key: "G",
            question: "The insurance producer and the applicant(s) agree that the purchase of this annuity is appropriate to the applicant’s particular legal, financial, tax, investment, estate-planning goals and other circumstances. The insurance producer and the applicant(s) have reviewed and completed the suitability form, as applicable. The completed and signed original of that form is enclosed with this application, a copy has been retained by the applicant(s), and a copy has been retained by the insurance producer.",
            producerState: "producerStateG",
            applicantState: "applicantStateG",
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("Printing disable all inputs value", disableAllInputs);
    }, []);

    useEffect(() => {
        let tempPercentage = 0;
        accountAllocationArray?.map((obj) => {
            if (individualFixedIndexAnnuityApplication.initialAccountAllocation[obj.state] != "" && individualFixedIndexAnnuityApplication.initialAccountAllocation[obj.state] != -1) {
                tempPercentage += parseFloat(individualFixedIndexAnnuityApplication.initialAccountAllocation[obj.state])
            }
        })
        setInitialAccountAllocation(tempPercentage)
    }, [individualFixedIndexAnnuityApplication])

    const StatesName = [
        "Alabama",
        "Alaska",
        // "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District Of Columbia",
        // "Federated States Of Micronesia",
        "Florida",
        "Georgia",
        // "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        // "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        // "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        // "Palau",
        "Pennsylvania",
        // "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        // "Virgin Islands",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
    ];

    const StatesNameNOR = [
        "All Other States",
        "Georgia",
        "Indiana",
        "Massachusetts",
        "Nevada",
        "Oklahoma",
        "Pennsylvania",
        "Tennessee",
        "Wyoming",
        "Illinois"
    ]
    function isValidEmail(email) {
        return /^([a-zA-Z0-9\._]+)@([a-zA-Z)-9])+.([a-z]+)(.[a-z]+)?$/.test(email);
    }

    useEffect(() => {
        console.log("Printing disable all inputs value", disableAllInputs);
        let inputArray = document.querySelectorAll("input");
        console.log(
            "Printing all input array for individual annuity application",
            inputArray
        );
        if (disableAllInputs === true) {
            for (let i = 0; i < inputArray.length; i++) {
                console.log("Printing input array each element", inputArray[i]);
                inputArray[i].disabled = true;
            }
        } else {
            for (let i = 0; i < inputArray.length; i++) {
                console.log("Printing input array each element", inputArray[i]);
                inputArray[i].disabled = false;
            }
        }
    }, [
        disableAllInputs,
        flagFirstPageFirstSection,
        flagFirstPageSecondSection,
        flagFirstPageThirdSection,
        flagFirstPageFourthSection,
        flagFirstPageFifthSection,
        flagFirstPageSixthSection,
        flagFirstPageSeventhSection,
    ]);

    useEffect(() => {
        console.log("context", individualFixedIndexAnnuityApplication);
        individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected === "" ||
            individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
            "non - qualified"
            ? setFlagNonQualifiedMenu(true)
            : setFlagNonQualifiedMenu(false);

        individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected === "" ||
            individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
            "qualified"
            ? setFlagQualifiedMenu(true)
            : setFlagQualifiedMenu(false);

        let ownerKeys = [
            "ownerFullName",
            "ownerSSN",
            "ownerGender",
            "ownerDOB",
            "ownerStreetAddress",
            "ownerZip",
            "ownerCity",
            "ownerState",
            "ownerEmail",
            "ownerRelationToAnnuitant",
            "ownerPhone"
        ]

        let flagFillOwnerField = []
        for (let i = 0; i < ownerKeys.length; i++) {
            if (individualFixedIndexAnnuityApplication[ownerKeys[i]] == "" || individualFixedIndexAnnuityApplication[ownerKeys[i]] == null) {
                flagFillOwnerField.push(false)
            } else {
                flagFillOwnerField.push(true)
            }
        }
        if (flagFillOwnerField.indexOf(true) > -1) {
            setFlagDisableJointOwner(false)
        } else {
            setFlagDisableJointOwner(true)
        }


        let annuitantKeys = [
            "annuitantFullName",
            "annuitantSSN",
            "annuitantGender",
            "annuitantDOB",
            "annuitantStreetAddress",
            "annuitantZip",
            "annuitantCity",
            "annuitantState",
            "annuitantEmail",
            "annuitantRelationToOwner",
            "annuitantPhone",
        ]

        let flagFillAnnuitantField = []
        for (let i = 0; i < annuitantKeys.length; i++) {
            if (individualFixedIndexAnnuityApplication[annuitantKeys[i]] == "" || individualFixedIndexAnnuityApplication[annuitantKeys[i]] == null) {
                flagFillAnnuitantField.push(false)
            } else {
                flagFillAnnuitantField.push(true)
            }
        }
        if (flagFillAnnuitantField.indexOf(true) > -1) {
            setFlagDisableJointAnnuitant(false)
            setAnnuitantReq(true)
        } else {
            setFlagDisableJointAnnuitant(true)
            setAnnuitantReq(false)
        }
    }, [individualFixedIndexAnnuityApplication]);

    useEffect(() => {
        setFlagNonQualifiedMenu(false);
        setFlagQualifiedMenu(false);
    }, []);

    const CommonContextUpdate = (type, value) => {
        // debugger;
        SetIndividualFixedIndexAnnuityApplication({
            type: type,
            value: value,
        });
    };


    useEffect(() => {
        let tempPrimary = 0;
        let tempContigent = 0;

        individualFixedIndexAnnuityApplication?.beneficiaries?.map((v) => {
            if (v.percentageField !== "" && v.percentageField !== -1) {
                if (v.primaryOrContingent == "Primary") {
                    tempPrimary += parseFloat(v.percentageField);
                } else if (v.primaryOrContingent == "Contingent") {
                    tempContigent += parseFloat(v.percentageField);
                }
            }
        });
        setPrimaryBeneficiariesPercentage(tempPrimary);
        setContigentBeneficiariesPercentage(tempContigent);
        props.beneficiariesPercentageTotal(tempPrimary + tempContigent);
    }, [individualFixedIndexAnnuityApplication.beneficiaries]);


    const ConditionToShowReplacementForm = () => {
        if (individualFixedIndexAnnuityApplication.ownerState?.length && individualFixedIndexAnnuityApplication.ownerState !== "" && individualFixedIndexAnnuityApplication.ownerState !== "Tennessee") {
            return true
        } else {
            if (individualFixedIndexAnnuityApplication.annuitantState?.length && individualFixedIndexAnnuityApplication.annuitantState !== "" && individualFixedIndexAnnuityApplication.annuitantState !== "Tennessee") {
                return true
            } else {
                return false
            }
        }
    };

    useEffect(() => {
        if (individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.indexOf("cash with application") == -1) {
            // CommonContextUpdate("stateToShowNoticeOfReplacement", "")
            // CommonContextUpdate("amountPaidWithApplication", "");
        } else if (
            individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.indexOf("transfer") == -1
            && individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.indexOf("rollover") == -1
            && individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.indexOf("1035 exchange") == -1
        ) {
            CommonContextUpdate("expectedPremiumField", "");
            // CommonContextUpdate("stateToShowNoticeOfReplacement", "")
        }
    }, [individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions])

    useEffect(() => {
        let selectedState = individualFixedIndexAnnuityApplication.ownerState
        let norState = StatesNameNOR.filter((obj) => obj == selectedState)
        if (norState?.length) {
            CommonContextUpdate("stateToShowNoticeOfReplacement", norState[0]);
        } else {
            if (individualFixedIndexAnnuityApplication.ownerState == "") {
                norState = StatesNameNOR.filter((obj) => obj == individualFixedIndexAnnuityApplication.annuitantState)
                if (norState?.length) {
                    CommonContextUpdate("stateToShowNoticeOfReplacement", norState[0]);
                } else {
                    CommonContextUpdate("stateToShowNoticeOfReplacement", "All Other States");
                }
            } else {
                CommonContextUpdate("stateToShowNoticeOfReplacement", "All Other States");
            }
        }
    }, [individualFixedIndexAnnuityApplication.ownerState, individualFixedIndexAnnuityApplication.annuitantState])

    function validatePhoneNumberLatest(input_str) {
        return input_str?.replace(/\D/g, '').length >= 11
    }

    console.log("individualFixedIndexAnnuityApplication.applicationReplacementFieldsArray====>", individualFixedIndexAnnuityApplication.applicationReplacementFieldsArray);
    console.log("---------------------------------------------------------------------------------------------");
    console.log("Premium Select the Tax Qualification status--->flagQualifiedOrNonQualifiedSelected:", individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected);
    console.log("Premium Select the plan non-qualified--->selectedNonQualifiedOptions:", individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions);
    console.log("Premium Amount Paid With Application--->amountPaidWithApplication:", individualFixedIndexAnnuityApplication.amountPaidWithApplication);
    console.log("Premium If a 1035 Exchange, rollover or transfer is occurring, the expected premium Amount is--->expectedPremiumField:", individualFixedIndexAnnuityApplication.expectedPremiumField);
    console.log("Premium Select type of plan--->selectedQualifiedOptionsGroup2:", individualFixedIndexAnnuityApplication.selectedQualifiedOptionsGroup2);
    console.log("Premium Select type of plan othetr textfield--->otherSelectedQualifiedOptions:", individualFixedIndexAnnuityApplication.otherSelectedQualifiedOptions);
    console.log("---------------------------------------------------------------------------------------------");

    return (
        <>
            <Box className="application_page_root">
                <Box className="application_form">
                    <Box className="application_form_card">
                        <Box className="application_form_head ">
                            <Box className="forsitemap"></Box>
                            <Box className="application_form_head_1">
                                <Typography variant="span">
                                    Individual Fixed Index Annuity Application
                                </Typography>
                            </Box>
                            <Box className="application_form_head_bottom_line">
                                <Divider />
                            </Box>
                        </Box>

                        <Box
                            className="annuitant_head_root"
                            onClick={() => {
                                SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageFirstSection",
                                    data: !flagFirstPageFirstSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">
                                    1. Owner (Either Owner or Annuitant details are mandatory)
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagFirstPageFirstSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagFirstPageFirstSection === true ? (
                            <Box className="annuitant_owner_section">
                                <Box className="annuitant_fullname_and_ssn_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width"
                                            label="Owner Full Name"
                                            disabled={disableAllInputs === true ? true : false}
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("ownerFullName", e.target.value);
                                            }}
                                            value={individualFixedIndexAnnuityApplication.ownerFullName}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width"
                                            label="SSN/TIN"
                                            disabled={disableAllInputs === true ? true : false}
                                            type="text"
                                            onChange={(e) => {
                                                let targetLength = e.target.value.length;
                                                let contextLength =
                                                    individualFixedIndexAnnuityApplication.ownerSSN.length;
                                                var regx = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;

                                                if (targetLength > contextLength) {
                                                    if (targetLength === 3 || targetLength === 6) {
                                                        let val = e.target.value + "-";
                                                        CommonContextUpdate("ownerSSN", val);
                                                    } else if (e.target.value.length <= 11) {
                                                        CommonContextUpdate("ownerSSN", e.target.value);
                                                    }
                                                    console.log(
                                                        "Printing value of e.target.value",
                                                        e.target.value
                                                    );
                                                    console.log(
                                                        "Printing value of ownerSSN",
                                                        individualFixedIndexAnnuityApplication.ownerSSN
                                                    );
                                                } else {
                                                    if (targetLength === 7 || targetLength === 4) {
                                                        CommonContextUpdate(
                                                            "ownerSSN",
                                                            e.target.value.slice(0, -1)
                                                        );
                                                    } else {
                                                        CommonContextUpdate("ownerSSN", e.target.value);
                                                    }
                                                }
                                            }}
                                            onBlur={(e) => {
                                                var regx = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                                                if (!regx.test(e.target.value)) {
                                                    CommonContextUpdate("ownerSSN", "");
                                                }
                                            }}
                                            value={individualFixedIndexAnnuityApplication.ownerSSN}
                                            variant="standard"
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_gender_and_dateofbirth_row">
                                    <Box
                                        className="annuitant_gender_selection left_section"
                                        style={{ width: "55%", position: "relative" }}
                                    >
                                        <FormControl
                                            className="input_field_width"
                                            variant="standard"
                                            style={{ width: "50%" }}
                                        >
                                            <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                                                Gender Assigned at Birth
                                            </InputLabel>
                                            <Select
                                                // className="input_field_width"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                onChange={(e) => {
                                                    CommonContextUpdate("ownerGender", e.target.value);
                                                }}
                                                value={individualFixedIndexAnnuityApplication.ownerGender}
                                                disabled={disableAllInputs === true ? true : false}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={"Not Applicable"}>Not Applicable</MenuItem>
                                                <MenuItem value={"male"}>Male</MenuItem>
                                                <MenuItem value={"female"}>Female</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box className={disableAllInputs === true ? "annuitant_dateofbirth_field right_section dobColorClass" : "annuitant_dateofbirth_field right_section"}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disabled={disableAllInputs === true ? true : false}
                                                label="Date Of Birth"
                                                inputFormat="MM/dd/yyyy"
                                                className="input_field_date"
                                                renderInput={(params) => <TextField {...params} />}
                                                onChange={(e, key) => {
                                                    SetIndividualFixedIndexAnnuityApplication({
                                                        type: "ownerDOB",
                                                        value: e,
                                                    });
                                                }}
                                                maxDate={new Date()}
                                                value={individualFixedIndexAnnuityApplication.ownerDOB}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                                <Box className="annuitant_streetaddress_and_zip_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width"
                                            type="text"
                                            label="Street Address"
                                            disabled={disableAllInputs === true ? true : false}
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "ownerStreetAddress",
                                                    e.target.value
                                                );
                                            }}
                                            value={individualFixedIndexAnnuityApplication.ownerStreetAddress}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width"
                                            error={ownerValidationError?.flagOwnerZipValid ? true : false}
                                            helperText={ownerValidationError?.flagOwnerZipValid ? "ZIP number only contains 5 digits" : ""}
                                            type="number"
                                            label="ZIP"
                                            disabled={disableAllInputs === true ? true : false}
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("ownerZip", e.target.value);
                                                if (e.target.value?.length == 5) {
                                                    let temp = { ...ownerValidationError }
                                                    temp.flagOwnerZipValid = false
                                                    setOwnerValidationError(temp)
                                                } else if (e.target.value?.length) {
                                                    let temp = { ...ownerValidationError }
                                                    temp.flagOwnerZipValid = true
                                                    setOwnerValidationError(temp)
                                                }
                                            }}
                                            onBlur={() => {
                                                let temp = { ...ownerValidationError }
                                                if (individualFixedIndexAnnuityApplication.ownerZip?.length) {
                                                    temp.flagOwnerZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(individualFixedIndexAnnuityApplication.ownerZip))
                                                    setOwnerValidationError(temp)
                                                } else {
                                                    temp.flagOwnerZipValid = false
                                                    setOwnerValidationError(temp)
                                                }
                                            }}
                                            value={individualFixedIndexAnnuityApplication.ownerZip}
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_city_and_state_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width"
                                            type="text"
                                            label="City"
                                            disabled={disableAllInputs === true ? true : false}
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("ownerCity", e.target.value);
                                            }}
                                            value={individualFixedIndexAnnuityApplication.ownerCity}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <FormControl
                                            className="input_field_width"
                                            variant="standard"
                                            style={{ width: "50%" }}
                                        >
                                            <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                                                State
                                            </InputLabel>
                                            <Select
                                                className="state_dropdown"
                                                onChange={(e) => {
                                                    CommonContextUpdate("ownerState", e.target.value);
                                                }}
                                                value={individualFixedIndexAnnuityApplication.ownerState}
                                                disabled={disableAllInputs === true ? true : false}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {StatesName.map((stateName) => {
                                                    return (
                                                        <MenuItem value={stateName}>{stateName}</MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box className="annuitant_phone_and_email_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width"
                                            error={ownerValidationError?.flagOwnerEmailValid ? true : false}
                                            helperText={ownerValidationError?.flagOwnerEmailValid ? "Invalid Email Address" : ""}
                                            type="email"
                                            label="Email"
                                            disabled={disableAllInputs === true ? true : false}
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("ownerEmail", e.target.value);
                                                let temp = { ...ownerValidationError }
                                                if (e.target.value?.length) {
                                                    temp.flagOwnerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))
                                                    setOwnerValidationError(temp)
                                                } else {
                                                    temp.flagOwnerEmailValid = false
                                                    setOwnerValidationError(temp)
                                                }
                                            }}
                                            onBlur={() => {
                                                let temp = { ...ownerValidationError }
                                                if (individualFixedIndexAnnuityApplication.ownerEmail?.length) {
                                                    temp.flagOwnerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(individualFixedIndexAnnuityApplication.ownerEmail))
                                                    setOwnerValidationError(temp)
                                                } else {
                                                    temp.flagOwnerEmailValid = false
                                                    setOwnerValidationError(temp)
                                                }
                                            }}
                                            value={individualFixedIndexAnnuityApplication.ownerEmail}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width"
                                            type="text"
                                            label="Relationship To Annuitant"
                                            disabled={disableAllInputs === true ? true : false}
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "ownerRelationToAnnuitant",
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                individualFixedIndexAnnuityApplication.ownerRelationToAnnuitant
                                            }
                                        />
                                    </Box>
                                </Box>

                                <Box className="annuitant_phone_and_email_row">
                                    <Box className="left_section">
                                        <MuiPhoneNumber
                                            name="phone"
                                            label="Phone"
                                            sx={{
                                                pointerEvents: disableAllInputs === true ? "none" : "auto",
                                                '& .MuiPhoneNumber-flagButton': {
                                                    pointerEvents: "none"
                                                }
                                            }}
                                            data-cy="user-phone"
                                            defaultCountry={"us"}
                                            onlyCountries={["us"]}
                                            anutoFormat={true}
                                            coutryCodeEditable={false}
                                            value={individualFixedIndexAnnuityApplication.ownerPhone}
                                            className="input_field_width not_required"
                                            onChange={(value) => {
                                                let temp = { ...ownerValidationError }
                                                temp.flagOwnerPhoneValid = !validatePhoneNumberLatest(value)
                                                setOwnerValidationError(temp)
                                                CommonContextUpdate("ownerPhone", value)
                                            }}
                                            error={ownerValidationError.flagOwnerPhoneValid ? true : false}
                                            helperText={ownerValidationError.flagOwnerPhoneValid ? "Invaild Phone Number" : ""}
                                            disabled={disableAllInputs === true ? true : false}
                                            inputProps={{ readOnly: disableAllInputs === true ? true : false }}
                                        />
                                    </Box>
                                </Box>



                            </Box>
                        ) : null}


                        <Box
                            className="annuitant_head_root"
                            onClick={() => {
                                SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageSecondSection",
                                    data: !flagFirstPageSecondSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">
                                    2. Joint Spousal Owner (If any), WROS (Not available for
                                    Qualified Funds)
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagFirstPageSecondSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagFirstPageSecondSection === true ? (
                            <Box className="annuitant_owner_section annuitant_owner_section_bottom">
                                <Box className="annuitant_fullname_and_ssn_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            label="Joint Spousal Owner Full Name"
                                            disabled={(disableAllInputs === true || flagDisableJointOwner === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointOwner === true) ? true : false }}
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "jointOwnerFullName",
                                                    e.target.value
                                                );
                                            }}
                                            value={individualFixedIndexAnnuityApplication.jointOwnerFullName}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            label="SSN/TIN"
                                            disabled={(disableAllInputs === true || flagDisableJointOwner === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointOwner === true) ? true : false }}
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                let targetLength = e.target.value.length;
                                                let contextLength =
                                                    individualFixedIndexAnnuityApplication.jointOwnerSSN.length;
                                                var regx = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;

                                                if (targetLength > contextLength) {
                                                    if (targetLength === 3 || targetLength === 6) {
                                                        let val = e.target.value + "-";
                                                        CommonContextUpdate("jointOwnerSSN", val);
                                                    } else if (e.target.value.length <= 11) {
                                                        CommonContextUpdate(
                                                            "jointOwnerSSN",
                                                            e.target.value
                                                        );
                                                    }
                                                    console.log(
                                                        "Printing value of e.target.value",
                                                        e.target.value
                                                    );
                                                    console.log(
                                                        "Printing value of jointOwnerSSN",
                                                        individualFixedIndexAnnuityApplication.jointOwnerSSN
                                                    );
                                                } else {
                                                    if (targetLength === 7 || targetLength === 4) {
                                                        CommonContextUpdate(
                                                            "jointOwnerSSN",
                                                            e.target.value.slice(0, -1)
                                                        );
                                                    } else {
                                                        CommonContextUpdate(
                                                            "jointOwnerSSN",
                                                            e.target.value
                                                        );
                                                    }
                                                }
                                            }}
                                            onBlur={(e) => {
                                                var regx = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                                                if (!regx.test(e.target.value)) {
                                                    CommonContextUpdate("jointOwnerSSN", "");
                                                }
                                            }}
                                            value={individualFixedIndexAnnuityApplication.jointOwnerSSN}
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_gender_and_dateofbirth_row">
                                    <Box
                                        className="annuitant_gender_selection left_section"
                                        style={{ width: "55%" }}
                                    >
                                        <FormControl
                                            className="input_field_width not_required"
                                            variant="standard"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-label"
                                                disabled={(disableAllInputs === true || flagDisableJointOwner === true) ? true : false}
                                            >
                                                Gender Assigned at Birth
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                onChange={(e) => {
                                                    CommonContextUpdate(
                                                        "jointOwnerGender",
                                                        e.target.value
                                                    );
                                                }}
                                                value={individualFixedIndexAnnuityApplication.jointOwnerGender}
                                                disabled={(disableAllInputs === true || flagDisableJointOwner === true) ? true : false}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={"Not Applicable"}>Not Applicable</MenuItem>
                                                <MenuItem value={"male"}>Male</MenuItem>
                                                <MenuItem value={"female"}>Female</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box className={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? "annuitant_dateofbirth_field right_section dobColorClass" : "annuitant_dateofbirth_field right_section"}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disabled={(disableAllInputs === true || flagDisableJointOwner === true) ? true : false}
                                                inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointOwner === true) ? true : false }}
                                                label="Date Of Birth"
                                                inputFormat="MM/dd/yyyy"
                                                className="input_field_date not_required"
                                                renderInput={(params) => <TextField {...params} />}
                                                onChange={(e) => {
                                                    SetIndividualFixedIndexAnnuityApplication({
                                                        type: "jointOwnerDOB",
                                                        value: e,
                                                    });
                                                }}
                                                maxDate={new Date()}
                                                value={individualFixedIndexAnnuityApplication.jointOwnerDOB}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                                <Box className="annuitant_streetaddress_and_zip_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            label="Street Address"
                                            disabled={(disableAllInputs === true || flagDisableJointOwner === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointOwner === true) ? true : false }}
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "jointOwnerStreetAddress",
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                individualFixedIndexAnnuityApplication.jointOwnerStreetAddress
                                            }
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            label="ZIP"
                                            error={jointOwnerValidationError?.flagJointOwnerZipValid ? true : false}
                                            helperText={jointOwnerValidationError?.flagJointOwnerZipValid ? "ZIP number only contains 5 digits" : ""}
                                            disabled={(disableAllInputs === true || flagDisableJointOwner === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointOwner === true) ? true : false }}
                                            type="number"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("jointOwnerZip", e.target.value);
                                                let temp = { ...jointOwnerValidationError }
                                                if (e.target.value?.length) {
                                                    temp.flagJointOwnerZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(e.target.value))
                                                    setJointOwnerValidationError(temp)
                                                } else {
                                                    temp.flagJointOwnerZipValid = false
                                                    setJointOwnerValidationError(temp)
                                                }
                                            }}
                                            onBlur={() => {
                                                let temp = { ...jointOwnerValidationError }
                                                if (individualFixedIndexAnnuityApplication.jointOwnerZip?.length) {
                                                    temp.flagJointOwnerZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(individualFixedIndexAnnuityApplication.jointOwnerZip))
                                                    setJointOwnerValidationError(temp)
                                                } else {
                                                    temp.flagJointOwnerZipValid = false
                                                    setJointOwnerValidationError(temp)
                                                }
                                            }}
                                            value={individualFixedIndexAnnuityApplication.jointOwnerZip}
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_city_and_state_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            label="City"
                                            disabled={(disableAllInputs === true || flagDisableJointOwner === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointOwner === true) ? true : false }}
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("jointOwnerCity", e.target.value);
                                            }}
                                            value={individualFixedIndexAnnuityApplication.jointOwnerCity}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <FormControl
                                            className="input_field_width not_required"
                                            variant="standard"
                                            style={{ width: "50%" }}
                                        >
                                            <InputLabel
                                                id="demo-simple-select-label"
                                                disabled={(disableAllInputs === true || flagDisableJointOwner === true) ? true : false}
                                            >
                                                State
                                            </InputLabel>
                                            <Select
                                                className="state_dropdown"
                                                onChange={(e) => {
                                                    CommonContextUpdate("jointOwnerState", e.target.value);
                                                }}
                                                value={individualFixedIndexAnnuityApplication.jointOwnerState}
                                                disabled={(disableAllInputs === true || flagDisableJointOwner === true) ? true : false}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {StatesName.map((stateName) => {
                                                    return (
                                                        <MenuItem value={stateName}>{stateName}</MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box className="annuitant_phone_and_email_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            label="Email"
                                            error={jointOwnerValidationError?.flagJointOwnerEmailValid ? true : false}
                                            helperText={jointOwnerValidationError?.flagJointOwnerEmailValid ? "Invalid Email Address" : ""}
                                            disabled={(disableAllInputs === true || flagDisableJointOwner === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointOwner === true) ? true : false }}
                                            type="email"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("jointOwnerEmail", e.target.value);
                                                let temp = { ...jointOwnerValidationError }
                                                if (e.target.value?.length) {
                                                    temp.flagJointOwnerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))
                                                    setJointOwnerValidationError(temp)
                                                } else {
                                                    temp.flagJointOwnerEmailValid = false
                                                    setJointOwnerValidationError(temp)
                                                }
                                            }}
                                            onBlur={() => {
                                                let temp = { ...jointOwnerValidationError }
                                                if (individualFixedIndexAnnuityApplication.jointOwnerEmail?.length) {
                                                    temp.flagJointOwnerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(individualFixedIndexAnnuityApplication.jointOwnerEmail))
                                                    setJointOwnerValidationError(temp)
                                                } else {
                                                    temp.flagJointOwnerEmailValid = false
                                                    setJointOwnerValidationError(temp)
                                                }
                                            }}
                                            value={individualFixedIndexAnnuityApplication.jointOwnerEmail}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <MuiPhoneNumber
                                            name="phone"
                                            label="Phone"
                                            sx={{
                                                pointerEvents: (disableAllInputs === true || flagDisableJointOwner === true) ? "none" : "auto",
                                                '& .MuiPhoneNumber-flagButton': {
                                                    pointerEvents: "none"
                                                }
                                            }}
                                            data-cy="user-phone"
                                            defaultCountry={"us"}
                                            onlyCountries={["us"]}
                                            autoFormat={true}
                                            countryCodeEditable={false}
                                            value={individualFixedIndexAnnuityApplication.jointOwnerPhone}
                                            className="input_field_width not_required"
                                            onChange={(value) => {
                                                let temp = { ...jointOwnerValidationError }
                                                temp.flagJointOwnerPhoneValid = !validatePhoneNumberLatest(value)
                                                setJointOwnerValidationError(temp)
                                                CommonContextUpdate("jointOwnerPhone", value)
                                            }}
                                            error={jointOwnerValidationError.flagJointOwnerPhoneValid ? true : false}
                                            helperText={jointOwnerValidationError.flagJointOwnerPhoneValid ? "Invaild Phone Number" : ""}
                                            disabled={(disableAllInputs === true || flagDisableJointOwner === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointOwner === true) ? true : false }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}

                        <Box
                            className="annuitant_head_root header_1"
                            onClick={() => {
                                SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageThirdSection",
                                    data: !flagFirstPageThirdSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">
                                    3. Annuitant (Either Owner or Annuitant details are mandatory)
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagFirstPageThirdSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagFirstPageThirdSection === true ? (
                            <Box className="annuitant_input_fields">
                                <Box className="annuitant_fullname_and_ssn_row">
                                    <Box className="left_section">
                                        <TextField
                                            className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                            id="standard-basic"
                                            label="Annuitant full name"
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("annuitantFullName", e.target.value);
                                            }}
                                            value={individualFixedIndexAnnuityApplication.annuitantFullName}
                                            disabled={disableAllInputs === true ? true : false}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                            label="SSN/TIN"
                                            disabled={disableAllInputs === true ? true : false}
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                let targetLength = e.target.value.length;
                                                let contextLength =
                                                    individualFixedIndexAnnuityApplication.annuitantSSN.length;
                                                if (targetLength > contextLength) {
                                                    if (targetLength === 3 || targetLength === 6) {
                                                        let val = e.target.value + "-";
                                                        CommonContextUpdate("annuitantSSN", val);
                                                    } else if (e.target.value.length <= 11) {
                                                        CommonContextUpdate("annuitantSSN", e.target.value);
                                                    }
                                                    console.log(
                                                        "Printing value of e.target.value",
                                                        e.target.value
                                                    );
                                                    console.log(
                                                        "Printing value of annuitantSSN",
                                                        individualFixedIndexAnnuityApplication.annuitantSSN
                                                    );
                                                } else {
                                                    if (targetLength === 7 || targetLength === 4) {
                                                        CommonContextUpdate(
                                                            "annuitantSSN",
                                                            e.target.value.slice(0, -1)
                                                        );
                                                    } else {
                                                        CommonContextUpdate("annuitantSSN", e.target.value);
                                                    }
                                                }
                                            }}
                                            onBlur={(e) => {
                                                var regx = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                                                if (!regx.test(e.target.value)) {
                                                    CommonContextUpdate("annuitantSSN", "");
                                                }
                                            }}
                                            value={individualFixedIndexAnnuityApplication.annuitantSSN}
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_gender_and_dateofbirth_row">
                                    <Box className="annuitant_gender_selection left_section">
                                        <FormControl
                                            className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                            variant="standard"
                                        >
                                            <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                                                Gender Assigned at Birth
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                onChange={(e) => {
                                                    CommonContextUpdate("annuitantGender", e.target.value);
                                                }}
                                                value={individualFixedIndexAnnuityApplication.annuitantGender}
                                                disabled={disableAllInputs === true ? true : false}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={"male"}>Male</MenuItem>
                                                <MenuItem value={"female"}>Female</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box className={disableAllInputs === true ? "annuitant_dateofbirth_field dobColorClass" : "annuitant_dateofbirth_field"}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disabled={disableAllInputs === true ? true : false}
                                                className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                                id="date_field"
                                                label="Date Of Birth"
                                                inputFormat="MM/dd/yyyy"
                                                renderInput={(params) => <TextField {...params} />}
                                                onChange={(e) => {
                                                    console.log("Annuitant DOB:::::::::::", e);
                                                    // debugger;

                                                    SetIndividualFixedIndexAnnuityApplication({
                                                        type: "annuitantDOB",
                                                        value: e,
                                                    });
                                                }}
                                                maxDate={new Date()}
                                                value={individualFixedIndexAnnuityApplication.annuitantDOB}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                                <Box className="annuitant_streetaddress_and_zip_row ">
                                    <Box className="left_section">
                                        <TextField
                                            className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                            type="text"
                                            label="Street Address"
                                            disabled={disableAllInputs === true ? true : false}
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "annuitantStreetAddress",
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                individualFixedIndexAnnuityApplication.annuitantStreetAddress
                                            }
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                            label="ZIP"
                                            error={annuitantValidationError?.flagAnnuitantZipValid ? true : false}
                                            helperText={annuitantValidationError?.flagAnnuitantZipValid ? "ZIP number only contains 5 digits" : ""}
                                            disabled={disableAllInputs === true ? true : false}
                                            type="number"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("annuitantZip", e.target.value);
                                                if (e.target.value?.length == 5) {
                                                    let temp = { ...annuitantValidationError }
                                                    temp.flagAnnuitantZipValid = false
                                                    setAnnuitantValidationError(temp)
                                                }
                                                if (e.target.value?.length > 5 || e.target.value?.length < 5) {
                                                    let temp = { ...annuitantValidationError }
                                                    temp.flagAnnuitantZipValid = true
                                                    setAnnuitantValidationError(temp)
                                                }
                                            }}
                                            onBlur={() => {
                                                let temp = { ...annuitantValidationError }
                                                if (individualFixedIndexAnnuityApplication.annuitantZip?.length) {
                                                    temp.flagAnnuitantZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(individualFixedIndexAnnuityApplication.annuitantZip))
                                                    setAnnuitantValidationError(temp)
                                                } else {
                                                    temp.flagAnnuitantZipValid = false
                                                    setAnnuitantValidationError(temp)
                                                }
                                            }}
                                            value={individualFixedIndexAnnuityApplication.annuitantZip}
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_city_and_state_row">
                                    <Box className="left_section">
                                        <TextField
                                            className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                            label="City"
                                            disabled={disableAllInputs === true ? true : false}
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("annuitantCity", e.target.value);
                                            }}
                                            value={individualFixedIndexAnnuityApplication.annuitantCity}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <FormControl
                                            className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                            variant="standard"
                                            style={{ width: "50%" }}
                                        >
                                            <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                                                State
                                            </InputLabel>
                                            <Select
                                                className="state_dropdown"
                                                onChange={(e) => {
                                                    CommonContextUpdate("annuitantState", e.target.value);
                                                }}
                                                value={individualFixedIndexAnnuityApplication.annuitantState}
                                                disabled={disableAllInputs === true ? true : false}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {StatesName.map((stateName) => {
                                                    return (
                                                        <MenuItem value={stateName}>{stateName}</MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box className="annuitant_phone_and_email_row">
                                    <Box className="left_section">
                                        <TextField
                                            className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                            label="Email"
                                            error={annuitantValidationError.flagAnnuitantEmailValid ? true : false}
                                            helperText={annuitantValidationError.flagAnnuitantEmailValid ? "Invaild Email" : ""}
                                            disabled={disableAllInputs === true ? true : false}
                                            type="email"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("annuitantEmail", e.target.value);
                                                let temp = { ...annuitantValidationError }
                                                if (e.target.value?.length) {
                                                    temp.flagAnnuitantEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))
                                                    setAnnuitantValidationError(temp)
                                                } else {
                                                    temp.flagAnnuitantEmailValid = false
                                                    setAnnuitantValidationError(temp)
                                                }
                                            }}
                                            onBlur={() => {
                                                let temp = { ...annuitantValidationError }
                                                if (individualFixedIndexAnnuityApplication.annuitantEmail?.length) {
                                                    temp.flagAnnuitantEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(individualFixedIndexAnnuityApplication.annuitantEmail))
                                                    setAnnuitantValidationError(temp)
                                                } else {
                                                    temp.flagAnnuitantEmailValid = false
                                                    setAnnuitantValidationError(temp)
                                                }
                                            }}
                                            value={individualFixedIndexAnnuityApplication.annuitantEmail}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                            type="text"
                                            label="Relationship To Owner"
                                            disabled={disableAllInputs === true ? true : false}
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("annuitantRelationToOwner", e.target.value);
                                            }}
                                            value={
                                                individualFixedIndexAnnuityApplication.annuitantRelationToOwner
                                            }
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_phone_and_email_row">
                                    <Box className="left_section">
                                        <MuiPhoneNumber
                                            name="phone"
                                            label="Phone"
                                            sx={{
                                                pointerEvents: disableAllInputs === true ? "none" : "auto",
                                                '& .MuiPhoneNumber-flagButton': {
                                                    pointerEvents: "none"
                                                }
                                            }}
                                            data-cy="user-phone"
                                            defaultCountry={"us"}
                                            onlyCountries={["us"]}
                                            autoFormat={true}
                                            countryCodeEditable={false}
                                            value={individualFixedIndexAnnuityApplication.annuitantPhone}
                                            className={annuitantReq ? "input_field_width" : "input_field_width not_required"}
                                            onChange={(value) => {
                                                console.log("phoneWithFlag", value)
                                                let temp = { ...annuitantValidationError }
                                                temp.flagAnnuitantPhoneValid = !validatePhoneNumberLatest(value)
                                                setAnnuitantValidationError(temp)
                                                CommonContextUpdate("annuitantPhone", value)
                                            }}
                                            error={annuitantValidationError.flagAnnuitantPhoneValid ? true : false}
                                            helperText={annuitantValidationError.flagAnnuitantPhoneValid ? "Invaild Phone Number" : ""}
                                            disabled={disableAllInputs === true ? true : false}
                                            inputProps={{ readOnly: disableAllInputs === true ? true : false }}
                                        />

                                    </Box>
                                </Box>
                            </Box>
                        ) : null}

                        <Box
                            className="annuitant_head_root header_1"
                            onClick={() => {
                                SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageFourthSection",
                                    data: !flagFirstPageFourthSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">
                                    4. Joint Spousal Annuitant(If any)
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagFirstPageFourthSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagFirstPageFourthSection === true ? (
                            <Box className="annuitant_input_fields">
                                <Box className="annuitant_fullname_and_ssn_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            id="standard-basic"
                                            label="Joint Spousal Annuitant full name"
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "jointAnnuitantFullName",
                                                    e.target.value
                                                );
                                            }}
                                            value={individualFixedIndexAnnuityApplication.jointAnnuitantFullName}
                                            disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false }}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            label="SSN/TIN"
                                            disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false }}
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                let targetLength = e.target.value.length;
                                                let contextLength =
                                                    individualFixedIndexAnnuityApplication.jointAnnuitantSSN.length;
                                                if (targetLength > contextLength) {
                                                    if (targetLength === 3 || targetLength === 6) {
                                                        let val = e.target.value + "-";
                                                        CommonContextUpdate("jointAnnuitantSSN", val);
                                                    } else if (e.target.value.length <= 11) {
                                                        CommonContextUpdate("jointAnnuitantSSN", e.target.value);
                                                    }
                                                } else {
                                                    if (targetLength === 7 || targetLength === 4) {
                                                        CommonContextUpdate(
                                                            "jointAnnuitantSSN",
                                                            e.target.value.slice(0, -1)
                                                        );
                                                    } else {
                                                        CommonContextUpdate("jointAnnuitantSSN", e.target.value);
                                                    }
                                                }
                                            }}
                                            onBlur={(e) => {
                                                var regx = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                                                if (!regx.test(e.target.value)) {
                                                    CommonContextUpdate("jointAnnuitantSSN", "");
                                                }
                                            }}
                                            value={individualFixedIndexAnnuityApplication.jointAnnuitantSSN}
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_gender_and_dateofbirth_row">
                                    <Box className="annuitant_gender_selection left_section">
                                        <FormControl
                                            className="input_field_width not_required"
                                            variant="standard"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-label"
                                                disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                            >
                                                Gender Assigned at Birth
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                onChange={(e) => {
                                                    CommonContextUpdate(
                                                        "jointAnnuitantGender",
                                                        e.target.value
                                                    );
                                                }}
                                                value={individualFixedIndexAnnuityApplication.jointAnnuitantGender}
                                                disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                                inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false }}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={"male"}>Male</MenuItem>
                                                <MenuItem value={"female"}>Female</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box className={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? "annuitant_dateofbirth_field dobColorClass" : "annuitant_dateofbirth_field"}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                                inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false }}
                                                className="input_field_width not_required"
                                                id="date_field"
                                                label="Date Of Birth"
                                                inputFormat="MM/dd/yyyy"
                                                renderInput={(params) => <TextField {...params} />}
                                                onChange={(e) => {
                                                    SetIndividualFixedIndexAnnuityApplication({
                                                        type: "jointAnnuitantDOB",
                                                        value: e,
                                                    });
                                                }}
                                                maxDate={new Date()}
                                                value={individualFixedIndexAnnuityApplication.jointAnnuitantDOB}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                                <Box className="annuitant_streetaddress_and_zip_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            type="text"
                                            label="Street Address"
                                            disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false }}
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "jointAnnuitantStreetAddress",
                                                    e.target.value
                                                );
                                            }}
                                            value={individualFixedIndexAnnuityApplication.jointAnnuitantStreetAddress}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            label="ZIP"
                                            error={jointAnnuitantValidationError?.flagJointAnnuitantZipValid ? true : false}
                                            helperText={jointAnnuitantValidationError?.flagJointAnnuitantZipValid ? "ZIP number only contains 5 digits" : ""}
                                            disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false }}
                                            type="number"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("jointAnnuitantZip", e.target.value);
                                                if (e.target.value?.length == 5) {
                                                    let temp = { ...jointAnnuitantValidationError }
                                                    temp.flagJointAnnuitantZipValid = false
                                                    setJointAnnuitantValidationError(temp)
                                                }
                                                if (e.target.value?.length > 5 || e.target.value?.length < 5) {
                                                    let temp = { ...jointAnnuitantValidationError }
                                                    temp.flagJointAnnuitantZipValid = true
                                                    setJointAnnuitantValidationError(temp)
                                                }
                                            }}
                                            onBlur={() => {
                                                let temp = { ...jointAnnuitantValidationError }
                                                if (individualFixedIndexAnnuityApplication.jointAnnuitantZip?.length) {
                                                    temp.flagJointAnnuitantZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(individualFixedIndexAnnuityApplication.jointAnnuitantZip))
                                                    setJointAnnuitantValidationError(temp)
                                                } else {
                                                    temp.flagJointAnnuitantZipValid = false
                                                    setJointAnnuitantValidationError(temp)
                                                }
                                            }}
                                            value={individualFixedIndexAnnuityApplication.jointAnnuitantZip}
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_city_and_state_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            label="City"
                                            disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false }}
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("jointAnnuitantCity", e.target.value);
                                            }}
                                            value={individualFixedIndexAnnuityApplication.jointAnnuitantCity}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <FormControl
                                            className="input_field_width not_required"
                                            variant="standard"
                                            style={{ width: "50%" }}
                                        >
                                            <InputLabel
                                                id="demo-simple-select-label"
                                                disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                            >
                                                State
                                            </InputLabel>
                                            <Select
                                                className="state_dropdown"
                                                onChange={(e) => {
                                                    CommonContextUpdate("jointAnnuitantState", e.target.value);
                                                }}
                                                value={individualFixedIndexAnnuityApplication.jointAnnuitantState}
                                                disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                                inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false }}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {StatesName.map((stateName) => {
                                                    return (
                                                        <MenuItem value={stateName}>{stateName}</MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box className="annuitant_phone_and_email_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            label="Email"
                                            error={jointAnnuitantValidationError.flagJointAnnuitantEmailValid ? true : false}
                                            helperText={jointAnnuitantValidationError.flagJointAnnuitantEmailValid ? "Invaild Email" : ""}
                                            disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false }}
                                            type="email"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("jointAnnuitantEmail", e.target.value);
                                                let temp = { ...jointAnnuitantValidationError }
                                                if (e.target.value?.length) {
                                                    temp.flagJointAnnuitantEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))
                                                    setJointAnnuitantValidationError(temp)
                                                } else {
                                                    temp.flagJointAnnuitantEmailValid = false
                                                    setJointAnnuitantValidationError(temp)
                                                }
                                            }}
                                            onBlur={() => {
                                                let temp = { ...jointAnnuitantValidationError }
                                                if (individualFixedIndexAnnuityApplication.jointAnnuitantEmail?.length) {
                                                    temp.flagJointAnnuitantEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(individualFixedIndexAnnuityApplication.jointAnnuitantEmail))
                                                    setJointAnnuitantValidationError(temp)
                                                } else {
                                                    temp.flagJointAnnuitantEmailValid = false
                                                    setJointAnnuitantValidationError(temp)
                                                }
                                            }}
                                            value={individualFixedIndexAnnuityApplication.jointAnnuitantEmail}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            type="text"
                                            label="Relationship To Annuitant"
                                            disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false }}
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("jointAnnuitantRelationToAnnuitant", e.target.value);
                                            }}
                                            value={
                                                individualFixedIndexAnnuityApplication.jointAnnuitantRelationToAnnuitant
                                            }
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_phone_and_email_row">
                                    <Box className="left_section">
                                        <MuiPhoneNumber
                                            name="phone"
                                            label="Phone"
                                            sx={{
                                                pointerEvents: (disableAllInputs === true || flagDisableJointAnnuitant === true) ? "none" : "auto",
                                                '& .MuiPhoneNumber-flagButton': {
                                                    pointerEvents: "none"
                                                }
                                            }}
                                            data-cy="user-phone"
                                            defaultCountry={"us"}
                                            onlyCountries={["us"]}
                                            autoFormat={true}
                                            countryCodeEditable={false}
                                            value={individualFixedIndexAnnuityApplication.jointAnnuitantPhone}
                                            className="input_field_width not_required"
                                            onChange={(value) => {
                                                let temp = { ...jointAnnuitantValidationError }
                                                temp.flagJointAnnuitantPhoneValid = !validatePhoneNumberLatest(value)
                                                setJointAnnuitantValidationError(temp)
                                                CommonContextUpdate("jointAnnuitantPhone", value)
                                            }}
                                            error={jointAnnuitantValidationError.flagJointAnnuitantPhoneValid ? true : false}
                                            helperText={jointAnnuitantValidationError.flagJointAnnuitantPhoneValid ? "Invaild Phone Number" : ""}
                                            disabled={(disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false}
                                            inputProps={{ readOnly: (disableAllInputs === true || flagDisableJointAnnuitant === true) ? true : false }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}

                        {/*Plan/Riders  */}

                        <Box
                            className="annuitant_head_root"
                            onClick={() => {
                                SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageFifthSection",
                                    data: !flagFirstPageFifthSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">
                                    5. Plan/Riders Applied For
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagFirstPageFifthSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagFirstPageFifthSection === true ? (
                            <Box className="plan_rider_section">
                                <Box className="annuity_plan_section">
                                    <Typography variant="span">
                                        1. Harvest Plans<span style={{ color: "red" }}>*</span>
                                    </Typography>
                                </Box>
                                <Box className="annuity_plan_section">
                                    <RadioGroup
                                        row={true}
                                        value={individualFixedIndexAnnuityApplication.planHarvest}
                                        className="radio_group_shift_right"
                                        onChange={(e) => {
                                            CommonContextUpdate("planHarvest", e.target.value);
                                        }}
                                    >
                                        <FormControlLabel
                                            disabled={disableAllInputs === true ? true : false}
                                            control={
                                                <Radio
                                                    value={"harvest 5"}
                                                    icon={<CheckBoxOutlineBlankIcon />}
                                                    checkedIcon={<CheckBoxIcon />}
                                                />
                                            }
                                            label="Harvest 5"
                                        />
                                        <FormControlLabel
                                            disabled={disableAllInputs === true ? true : false}
                                            control={
                                                <Radio
                                                    value={"harvest 7"}
                                                    icon={<CheckBoxOutlineBlankIcon />}
                                                    checkedIcon={<CheckBoxIcon />}
                                                />
                                            }
                                            label="Harvest 7"
                                        />
                                        <FormControlLabel
                                            disabled={disableAllInputs === true ? true : false}
                                            control={
                                                <Radio
                                                    value={"harvest 10"}
                                                    icon={<CheckBoxOutlineBlankIcon />}
                                                    checkedIcon={<CheckBoxIcon />}
                                                />
                                            }
                                            label="Harvest 10"
                                        />
                                    </RadioGroup>
                                    {(individualFixedIndexAnnuityApplication?.planHarvest == "harvest 7"
                                        || individualFixedIndexAnnuityApplication?.planHarvest == "harvest 10") &&
                                        <>
                                            {/* <Divider /> */}
                                            <Box className="annuity_plan_section">
                                                <Typography variant="span">
                                                    2. Riders<span style={{ color: "red" }}>*</span>
                                                </Typography>
                                                <RadioGroup
                                                    row={true}
                                                    value={individualFixedIndexAnnuityApplication.ridersPlan}
                                                    className="radio_group_shift_right"
                                                    onChange={(e) => {
                                                        CommonContextUpdate("ridersPlan", e.target.value);
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        disabled={disableAllInputs === true ? true : false}
                                                        control={
                                                            <Radio
                                                                value={"premium bonus"}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label="Premium Bonus Rider (only available if the 7- or 10-year product is selected above)"
                                                    />
                                                    <FormControlLabel
                                                        disabled={disableAllInputs === true ? true : false}
                                                        control={
                                                            <Radio
                                                                value={"enhanced liquidity"}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label="Enhanced Liquidity Package (includes the Enhanced Liquidity Rider and Enhanced Benefit Rider, which cannot be purchased separately)"
                                                    />
                                                    <FormControlLabel
                                                        disabled={disableAllInputs === true ? true : false}
                                                        control={
                                                            <Radio
                                                                value={"none"}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label="None"
                                                    />
                                                </RadioGroup>
                                            </Box>

                                        </>
                                    }
                                    {(individualFixedIndexAnnuityApplication?.planHarvest == "harvest 5") &&
                                        <>
                                            {/* <Divider /> */}
                                            <Box className="annuity_plan_section">
                                                <Typography variant="span">
                                                    2. Riders<span style={{ color: "red" }}>*</span>
                                                </Typography>
                                                <RadioGroup
                                                    row={true}
                                                    value={individualFixedIndexAnnuityApplication.ridersPlan}
                                                    className="radio_group_shift_right"
                                                    onChange={(e) => {
                                                        CommonContextUpdate("ridersPlan", e.target.value);
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        disabled={disableAllInputs === true ? true : false}
                                                        control={
                                                            <Radio
                                                                value={"enhanced liquidity"}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label="Enhanced Liquidity Package (includes the Enhanced Liquidity Rider and Enhanced Benefit Rider, which cannot be purchased separately)"
                                                    />
                                                    <FormControlLabel
                                                        disabled={disableAllInputs === true ? true : false}
                                                        control={
                                                            <Radio
                                                                value={"none"}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label="None"
                                                    />
                                                </RadioGroup>
                                            </Box>

                                        </>
                                    }
                                </Box>
                            </Box>
                        ) : null}

                        {/* Initial Account Allocation(s) */}
                        <Box
                            className="annuitant_head_root"
                            onClick={() => {
                                SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageSixthSection",
                                    data: !flagFirstPageSixthSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">
                                    6. Initial Account Allocation(s)
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagFirstPageSixthSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagFirstPageSixthSection === true ? (
                            <Box className="plan_rider_section" sx={{ margin: "0px" }}>

                                <Box style={{ width: "100%", left: "0%", position: "relative" }}>
                                    <TableContainer className="replacementTableContainer">
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table" className="ma_tbl">
                                            <TableHead>
                                                <TableRow className="replacementTableHead noticeOfReplacementHead">
                                                    <TableCell></TableCell>
                                                    <TableCell align="center" >%</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {accountAllocationArray?.map((row, i, arr) => {
                                                    return (
                                                        <>
                                                            <TableRow key={i} className="replacementTableRow">
                                                                <TableCell width="85%" align="left" className="initialAccountTableCell2 mv_tbl_cell">
                                                                    {row.label}
                                                                </TableCell>
                                                                <TableCell width="15%" align="center" className="initialAccountTableCell mv_tbl_cell">
                                                                    <TextField
                                                                        className="initialAccountTextField"
                                                                        type="number"
                                                                        style={{ width: "80%", backgroundColor: "rgb(220 220 220)" }}
                                                                        id="outlined-basic"
                                                                        variant="outlined"
                                                                        disabled={disableAllInputs === true ? true : false}
                                                                        InputProps={{
                                                                            inputProps: { min: 0, max: 100 },
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">%</InputAdornment>
                                                                            ),
                                                                        }}
                                                                        value={individualFixedIndexAnnuityApplication.initialAccountAllocation[row.state]}
                                                                        onChange={(e) => {
                                                                            if (parseInt(e.target.value) < 0 || parseInt(e.target.value) > 100) {
                                                                                return;
                                                                            }
                                                                            let temp = { ...individualFixedIndexAnnuityApplication }
                                                                            temp.initialAccountAllocation[row.state] = e.target.value
                                                                            CommonContextUpdate("initialAccountAllocation", temp.initialAccountAllocation)
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>

                                                        </>
                                                    )
                                                })}
                                                <TableRow className="replacementTableRow">
                                                    <TableCell align="left" className="initialAccountTableCell2 mv_tbl_cell">
                                                        <Typography fontWeight={"bold"}>
                                                            Total:{" "}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center" className="initialAccountTableCell2 mv_tbl_cell">
                                                        <Typography fontWeight={"bold"}>
                                                            <span
                                                                style={
                                                                    initialAccountAllocation !== 100 &&
                                                                        initialAccountAllocation !== 0
                                                                        ? { color: "red" }
                                                                        : { color: "#000" }
                                                                }
                                                            >
                                                                {initialAccountAllocation}%
                                                            </span>
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>

                            </Box>
                        ) : null}

                        {/* Beneficiaries Section Start */}
                        <Box
                            className="annuitant_head_root"
                            onClick={() => {
                                SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageSeventhSection",
                                    data: !flagFirstPageSeventhSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">7. Beneficiaries</Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagFirstPageSeventhSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagFirstPageSeventhSection === true ? (
                            <Box className="beneficiaries_section">
                                <Typography variant="span">
                                    If joint owners are named, on the death of either joint owner
                                    the surviving joint owner will become the sole primary
                                    beneficiary, and the beneficiaries listed below will be
                                    considered contingent beneficiaries (unless otherwise
                                    requested in the special requests section).
                                </Typography>
                                <Box className="beneficiaries_section_fields">
                                    <TableContainer className="replacementTableContainer">
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table" className="ma_tbl">
                                            <TableHead>
                                                <TableRow className="replacementTableHead noticeOfReplacementHead">
                                                    <TableCell>Primary or Contingent</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align="center">%</TableCell>
                                                    <TableCell>Relationship</TableCell>
                                                    <TableCell>SSN/TIN</TableCell>
                                                    <TableCell>Gender assigned at birth</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {individualFixedIndexAnnuityApplication.beneficiaries?.map((row, i, arr) => {
                                                    return (
                                                        <TableRow key={i} className="replacementTableRow">
                                                            <TableCell align="center" className="initialAccountTableCell mv_tbl_cell beneficiaries_1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    className="annuitant_gender_selection_gender2"
                                                                >
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        className="annuitant_gender_selection_gender3"
                                                                        id="demo-simple-select"
                                                                        value={row.primaryOrContingent}
                                                                        onChange={(e) => {
                                                                            let temp = arr;
                                                                            console.log(temp);
                                                                            temp = temp.filter((v) => {
                                                                                if (v?.id === row?.id) {
                                                                                    v.primaryOrContingent = e.target.value;
                                                                                }
                                                                                return v;
                                                                            });
                                                                            SetIndividualFixedIndexAnnuityApplication({
                                                                                type: "beneficiaries",
                                                                                value: temp,
                                                                            });
                                                                        }}
                                                                        disabled={
                                                                            disableAllInputs === true ? true : false
                                                                        }
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        <MenuItem value={"Primary"}>Primary</MenuItem>
                                                                        <MenuItem value={"Contingent"}>Contingent</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell align="center" className="initialAccountTableCell mv_tbl_cell beneficiaries_2">
                                                                <TextField
                                                                    className="beneficiaries_field_width"
                                                                    disabled={disableAllInputs === true ? true : false}
                                                                    variant="standard"
                                                                    value={row.name}
                                                                    onChange={(e) => {
                                                                        let temp = arr;
                                                                        temp = temp.filter((v) => {
                                                                            if (v?.id === row?.id) {
                                                                                v.name = e.target.value;
                                                                            }
                                                                            return v;
                                                                        });
                                                                        SetIndividualFixedIndexAnnuityApplication({
                                                                            type: "beneficiaries",
                                                                            value: temp,
                                                                        });
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center" className="initialAccountTableCell mv_tbl_cell beneficiaries_3">
                                                                <TextField
                                                                    type="number"
                                                                    className="beneficiaries_field_width"
                                                                    disabled={disableAllInputs === true ? true : false}
                                                                    variant="standard"
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">%</InputAdornment>
                                                                        ),
                                                                    }}
                                                                    value={
                                                                        row.percentageField == -1
                                                                            ? ""
                                                                            : row.percentageField
                                                                    }
                                                                    onChange={(e) => {
                                                                        let temp = arr;
                                                                        temp = temp.filter((v) => {
                                                                            if (v?.id === row?.id) {
                                                                                v.percentageField = e.target.value;
                                                                            }
                                                                            return v;
                                                                        });
                                                                        SetIndividualFixedIndexAnnuityApplication({
                                                                            type: "beneficiaries",
                                                                            value: temp,
                                                                        });
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center" className="initialAccountTableCell mv_tbl_cell beneficiaries_4">
                                                                <TextField
                                                                    type="text"
                                                                    className="beneficiaries_field_width"
                                                                    disabled={disableAllInputs === true ? true : false}
                                                                    variant="standard"
                                                                    value={
                                                                        row.relationshipField == -1
                                                                            ? ""
                                                                            : row.relationshipField
                                                                    }
                                                                    onChange={(e) => {
                                                                        let temp = arr;
                                                                        temp = temp.filter((v) => {
                                                                            if (v?.id === row?.id) {
                                                                                v.relationshipField = e.target.value;
                                                                            }
                                                                            return v;
                                                                        });
                                                                        SetIndividualFixedIndexAnnuityApplication({
                                                                            type: "beneficiaries",
                                                                            value: temp,
                                                                        });
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center" className="initialAccountTableCell mv_tbl_cell beneficiaries_5">
                                                                <TextField
                                                                    type="text"
                                                                    className="beneficiaries_field_width"
                                                                    disabled={disableAllInputs === true ? true : false}
                                                                    variant="standard"
                                                                    value={row.ssnField}
                                                                    onBlur={(e) => {
                                                                        let temp = arr;
                                                                        var regx =
                                                                            /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                                                                        temp = temp.filter((v) => {
                                                                            if (row?.id === v?.id) {
                                                                                if (!regx.test(e.target.value)) {
                                                                                    v.ssnField = "";
                                                                                }
                                                                            }
                                                                            return v;
                                                                        });
                                                                        SetIndividualFixedIndexAnnuityApplication({
                                                                            type: "beneficiaries",
                                                                            value: temp,
                                                                        });
                                                                    }}
                                                                    onChange={(e) => {
                                                                        let temp = arr;
                                                                        console.log(temp);
                                                                        temp = temp.filter((v) => {
                                                                            if (v?.id === row?.id) {
                                                                                let targetLength = e.target.value.length;
                                                                                let contextLength = v.ssnField.length;
                                                                                var regx = /[0-9/-]\d/gi;

                                                                                if (targetLength > contextLength) {
                                                                                    if (
                                                                                        targetLength === 3 ||
                                                                                        targetLength === 6
                                                                                    ) {
                                                                                        let val = e.target.value + "-";
                                                                                        v.ssnField = val;
                                                                                    } else if (e.target.value.length <= 11) {
                                                                                        v.ssnField = e.target.value;
                                                                                    }
                                                                                    console.log(
                                                                                        "Printing value of e.target.value",
                                                                                        e.target.value
                                                                                    );
                                                                                    console.log(
                                                                                        "Printing value of annuitantSSN",
                                                                                        v.ssnField
                                                                                    );
                                                                                } else {
                                                                                    if (
                                                                                        targetLength === 7 ||
                                                                                        targetLength === 4
                                                                                    ) {
                                                                                        v.ssnField = e.target.value.slice(
                                                                                            0,
                                                                                            -1
                                                                                        );
                                                                                    } else {
                                                                                        v.ssnField = e.target.value;
                                                                                    }
                                                                                }
                                                                            }
                                                                            return v;
                                                                        });
                                                                        console.log("context", temp);
                                                                        SetIndividualFixedIndexAnnuityApplication({
                                                                            type: "beneficiaries",
                                                                            value: temp,
                                                                        });
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center" className="initialAccountTableCell mv_tbl_cell beneficiaries_6">
                                                                <FormControl
                                                                    variant="standard"
                                                                    className="annuitant_gender_selection_gender2"
                                                                >
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        className="annuitant_gender_selection_gender3"
                                                                        id="demo-simple-select"
                                                                        value={row.genderField}
                                                                        onChange={(e) => {
                                                                            let temp = arr;
                                                                            console.log(temp);
                                                                            temp = temp.filter((v) => {
                                                                                if (v?.id === row?.id) {
                                                                                    v.genderField = e.target.value;
                                                                                }
                                                                                return v;
                                                                            });
                                                                            console.log("context", temp);
                                                                            SetIndividualFixedIndexAnnuityApplication({
                                                                                type: "beneficiaries",
                                                                                value: temp,
                                                                            });
                                                                        }}
                                                                        disabled={
                                                                            disableAllInputs === true ? true : false
                                                                        }
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        <MenuItem value={"male"}>Male</MenuItem>
                                                                        <MenuItem value={"female"}>Female</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                                <TableRow className="replacementTableRow">
                                                    <TableCell width="20%" align="center" colSpan={3} className="initialAccountTableCell mv_tbl_cell">
                                                        <Typography fontWeight={"bold"}>
                                                            Primary Total:{" "}
                                                            <span
                                                                style={
                                                                    primaryBeneficiariesPercentage !== 100 &&
                                                                        primaryBeneficiariesPercentage !== 0
                                                                        ? { color: "red" }
                                                                        : { color: "#000" }
                                                                }
                                                            >
                                                                {primaryBeneficiariesPercentage}%
                                                            </span>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell width="20%" align="center" colSpan={3} className="initialAccountTableCell mv_tbl_cell">
                                                        <Typography fontWeight={"bold"}>
                                                            Contingent Total:{" "}
                                                            <span
                                                                style={
                                                                    contigentBeneficiariesPercentage !== 100 &&
                                                                        contigentBeneficiariesPercentage !== 0
                                                                        ? { color: "red" }
                                                                        : { color: "#000" }
                                                                }
                                                            >
                                                                {contigentBeneficiariesPercentage}%
                                                            </span>
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                {/* <Box
                                    className="total_percentage_beneficiaries"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        width: "100%",
                                        flexWrap: "wrap",
                                    }}
                                >

                                    <Typography fontWeight={"bold"}>
                                        Primary Total:{" "}
                                        <span
                                            style={
                                                primaryBeneficiariesPercentage !== 100 &&
                                                    primaryBeneficiariesPercentage !== 0
                                                    ? { color: "red" }
                                                    : { color: "#000" }
                                            }
                                        >
                                            {primaryBeneficiariesPercentage}%
                                        </span>
                                    </Typography>
                                    <Typography fontWeight={"bold"}>
                                        Contingent Total:{" "}
                                        <span
                                            style={
                                                contigentBeneficiariesPercentage !== 100 &&
                                                    contigentBeneficiariesPercentage !== 0
                                                    ? { color: "red" }
                                                    : { color: "#000" }
                                            }
                                        >
                                            {contigentBeneficiariesPercentage}%
                                        </span>
                                    </Typography>
                                </Box> */}
                            </Box>
                        ) : null}
                        {/* Beneficiaries Section End */}
                        {/* Premium and Tax Qualification Status Section Start */}
                        <Box
                            className="annuitant_head_root"
                            onClick={() => {
                                SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageEighthSection",
                                    data: !flagFirstPageEighthSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">
                                    8. Premium and Tax Qualification Status
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagFirstPageEighthSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagFirstPageEighthSection === true ? (
                            <Box
                                className="premium_and_tax_qualification_status_main"
                                width={"80%"}
                            >
                                <Box style={{ margin: "5px 10px 10px 0" }}>
                                    <Typography variant="span">
                                        <InputLabel style={{ fontSize: "13px" }}>
                                            Select the Tax Qualification status
                                        </InputLabel>
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        display: "flex",
                                    }}
                                    className="radio_group_shift_right"
                                >
                                    <Button
                                        disabled={disableAllInputs === true ? true : false}
                                        style={{
                                            marginRight: "40px",
                                            background:
                                                individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                                                    "non - qualified"
                                                    ? "rgb(7, 74, 110)"
                                                    : "white",
                                            color:
                                                individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                                                    "non - qualified"
                                                    ? "white"
                                                    : "rgb(7, 74, 110)",
                                            textTransform: "capitalize",
                                            border: "1px solid rgb(7, 74, 110)",
                                        }}
                                        variant={
                                            individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                                                "non - qualified"
                                                ? "contained"
                                                : "outlined"
                                        }
                                        onClick={(e) => {
                                            CommonContextUpdate("selectedNonQualifiedOptions", []);
                                            SetIndividualFixedIndexAnnuityApplication({
                                                type: "selectedQualifiedOptionsGroup2",
                                                value: "",
                                            });
                                            if (
                                                individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                                                "non - qualified"
                                            ) {
                                                CommonContextUpdate(
                                                    "flagQualifiedOrNonQualifiedSelected",
                                                    ""
                                                );
                                            } else {
                                                CommonContextUpdate(
                                                    "flagQualifiedOrNonQualifiedSelected",
                                                    "non - qualified"
                                                );
                                            }
                                            CommonContextUpdate("amountPaidWithApplication", "");
                                            CommonContextUpdate("expectedPremiumField", "");
                                        }}
                                    >
                                        Non - Qualified
                                    </Button>
                                    <Button
                                        disabled={disableAllInputs === true ? true : false}
                                        style={{
                                            background:
                                                individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                                                    "qualified"
                                                    ? "rgb(7, 74, 110)"
                                                    : "white",
                                            color:
                                                individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                                                    "qualified"
                                                    ? "white"
                                                    : "rgb(7, 74, 110)",
                                            textTransform: "capitalize",

                                            border: "1px solid rgb(7, 74, 110)",
                                        }}
                                        variant={
                                            individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                                                "qualified"
                                                ? "contained"
                                                : "outlined"
                                        }
                                        onClick={(e) => {
                                            CommonContextUpdate("selectedNonQualifiedOptions", []);
                                            SetIndividualFixedIndexAnnuityApplication({
                                                type: "selectedQualifiedOptionsGroup2",
                                                value: "",
                                            });
                                            if (
                                                individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                                                "qualified"
                                            ) {
                                                CommonContextUpdate(
                                                    "flagQualifiedOrNonQualifiedSelected",
                                                    ""
                                                );
                                            } else {
                                                CommonContextUpdate(
                                                    "flagQualifiedOrNonQualifiedSelected",
                                                    "qualified"
                                                );
                                            }
                                            CommonContextUpdate("amountPaidWithApplication", "");
                                            CommonContextUpdate("expectedPremiumField", "");
                                        }}
                                    >
                                        Qualified
                                    </Button>
                                </Box>
                                <Box>
                                    {individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                                        "non - qualified" &&
                                        individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected !==
                                        "qualified" ? (
                                        <>
                                            <Box style={{ margin: "10px 0px 0px 15px" }}>
                                                <Typography variant="span">
                                                    <InputLabel style={{ fontSize: "13px" }}>
                                                        Select the plan
                                                    </InputLabel>
                                                </Typography>
                                            </Box>
                                            <Box
                                                className="ptqs_non_qualified_options radio_group_shift_right"
                                                style={{
                                                    padding: "5px 0px 5px 10px",
                                                    background: "#fff",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <FormGroup row={true} className="radio_group_shift_right">
                                                    <FormControlLabel
                                                        disabled={disableAllInputs === true ? true : false}
                                                        control={
                                                            <Checkbox
                                                                checked={individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.includes(
                                                                    "cash with application"
                                                                )}
                                                                onChange={(e) => {
                                                                    let temp = [
                                                                        ...individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions,
                                                                    ];
                                                                    if (e.target.checked === false) {
                                                                        temp = temp.filter((v) => {
                                                                            if (v !== e.target.value) {
                                                                                return v;
                                                                            }
                                                                        });
                                                                    } else {
                                                                        temp.push(e.target.value);
                                                                    }
                                                                    CommonContextUpdate("selectedNonQualifiedOptions", temp);
                                                                }}
                                                                value={"cash with application"}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label="Cash with application"
                                                    />
                                                    <FormControlLabel
                                                        disabled={disableAllInputs === true ? true : false}
                                                        control={
                                                            <Checkbox
                                                                checked={individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.includes(
                                                                    "1035 exchange"
                                                                )}
                                                                onChange={(e) => {
                                                                    let temp = [
                                                                        ...individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions,
                                                                    ];
                                                                    if (e.target.checked === false) {
                                                                        temp = temp.filter((v) => {
                                                                            if (v !== e.target.value) {
                                                                                return v;
                                                                            }
                                                                        });
                                                                    } else {
                                                                        temp.push(e.target.value);
                                                                    }

                                                                    CommonContextUpdate("selectedNonQualifiedOptions", temp);
                                                                }}
                                                                value={"1035 exchange"}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label="1035 exchange"
                                                    />
                                                    <FormControlLabel
                                                        disabled={disableAllInputs === true ? true : false}
                                                        control={
                                                            <Checkbox
                                                                checked={individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.includes("transfer")}
                                                                onChange={(e) => {
                                                                    let temp = [
                                                                        ...individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions,
                                                                    ];
                                                                    if (e.target.checked === false) {
                                                                        temp = temp.filter((v) => {
                                                                            if (v !== e.target.value) {
                                                                                return v;
                                                                            }
                                                                        });
                                                                    } else {
                                                                        temp.push(e.target.value);
                                                                    }
                                                                    CommonContextUpdate("selectedNonQualifiedOptions", temp);
                                                                }}
                                                                value={"transfer"}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label="Transfer"
                                                    />
                                                </FormGroup>
                                            </Box>
                                        </>
                                    ) : null}
                                    {individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
                                        "qualified" &&
                                        individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected !==
                                        "non - qualified" ? (
                                        <>
                                            <Box style={{ margin: "10px 0px 0px 15px" }}>
                                                <Typography variant="span">
                                                    {" "}
                                                    <InputLabel style={{ fontSize: "13px" }}>
                                                        Select the plan
                                                    </InputLabel>
                                                </Typography>
                                            </Box>

                                            <Box
                                                className="ptqs_qualified_options radio_group_shift_right"
                                                style={{
                                                    padding: "5px 5px 5px 10px",
                                                    background: "#fff",
                                                    borderRadius: "10px",
                                                    display: "flex",
                                                }}
                                            >
                                                <FormGroup row={true} className="radio_group_shift_right">
                                                    <FormControlLabel
                                                        disabled={disableAllInputs === true ? true : false}
                                                        control={
                                                            <Checkbox
                                                                checked={individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.includes(
                                                                    "cash with application"
                                                                )}
                                                                onChange={(e) => {
                                                                    let temp = [
                                                                        ...individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions,
                                                                    ];
                                                                    if (e.target.checked === false) {
                                                                        temp = temp.filter((v) => {
                                                                            if (v !== e.target.value) {
                                                                                return v;
                                                                            }
                                                                        });
                                                                    } else {
                                                                        temp.push(e.target.value);
                                                                    }
                                                                    CommonContextUpdate("selectedNonQualifiedOptions", temp);
                                                                }}
                                                                value={"cash with application"}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label="Cash with application"
                                                    />

                                                    <FormControlLabel
                                                        disabled={disableAllInputs === true ? true : false}
                                                        control={
                                                            <Checkbox
                                                                checked={individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.includes("transfer")}
                                                                onChange={(e) => {
                                                                    let temp = [
                                                                        ...individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions,
                                                                    ];
                                                                    if (e.target.checked === false) {
                                                                        temp = temp.filter((v) => {
                                                                            if (v !== e.target.value) {
                                                                                return v;
                                                                            }
                                                                        });
                                                                    } else {
                                                                        temp.push(e.target.value);
                                                                    }
                                                                    CommonContextUpdate("selectedNonQualifiedOptions", temp);
                                                                }}
                                                                value={"transfer"}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label="Transfer"
                                                    />
                                                    <FormControlLabel
                                                        disabled={disableAllInputs === true ? true : false}
                                                        control={
                                                            <Checkbox
                                                                checked={individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.includes(
                                                                    "rollover"
                                                                )}
                                                                onChange={(e) => {
                                                                    let temp = [
                                                                        ...individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions,
                                                                    ];
                                                                    if (e.target.checked === false) {
                                                                        temp = temp.filter((v) => {
                                                                            if (v !== e.target.value) {
                                                                                return v;
                                                                            }
                                                                        });
                                                                    } else {
                                                                        temp.push(e.target.value);
                                                                    }

                                                                    CommonContextUpdate("selectedNonQualifiedOptions", temp);
                                                                }}
                                                                value={"rollover"}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label="Rollover"
                                                    />
                                                </FormGroup>
                                            </Box>
                                        </>
                                    ) : null}
                                </Box>
                                <Box>
                                    {individualFixedIndexAnnuityApplication?.selectedNonQualifiedOptions?.length > 0 && (
                                        <>
                                            <Box style={{ margin: "0px 10px 0px 15px" }}>
                                                <Typography variant="span">
                                                    <InputLabel style={{ fontSize: "13px" }}>
                                                        Select type of plan
                                                    </InputLabel>
                                                </Typography>
                                            </Box>
                                            <RadioGroup
                                                value={
                                                    individualFixedIndexAnnuityApplication.selectedQualifiedOptionsGroup2
                                                }
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    flexDirection: "row",
                                                    marginLeft: "30px"
                                                }}
                                                onChange={(e) => {
                                                    // debugger;
                                                    if (
                                                        e.target.value !== "other" &&
                                                        individualFixedIndexAnnuityApplication.otherSelectedQualifiedOptions !==
                                                        ""
                                                    ) {
                                                        CommonContextUpdate(
                                                            "otherSelectedQualifiedOptions",
                                                            ""
                                                        );
                                                    }
                                                    SetIndividualFixedIndexAnnuityApplication({
                                                        type: "selectedQualifiedOptionsGroup2",
                                                        value: e.target.value,
                                                    });
                                                }}
                                            >
                                                <FormControlLabel
                                                    disabled={disableAllInputs === true ? true : false}
                                                    control={
                                                        <Radio
                                                            value={"ira"}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checkedIcon={<CheckBoxIcon />}
                                                        />
                                                    }
                                                    label="IRA"
                                                />
                                                <FormControlLabel
                                                    disabled={disableAllInputs === true ? true : false}
                                                    control={
                                                        <Radio
                                                            value={"roth ira"}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checkedIcon={<CheckBoxIcon />}
                                                        />
                                                    }
                                                    label="Roth IRA"
                                                />
                                                <FormControlLabel
                                                    sx={{ display: "none" }}
                                                    disabled={disableAllInputs === true ? true : false}
                                                    control={
                                                        <Radio
                                                            value={"sep"}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checkedIcon={<CheckBoxIcon />}
                                                        />
                                                    }
                                                    label="SEP"
                                                />
                                                <FormControlLabel
                                                    disabled={disableAllInputs === true ? true : false}
                                                    control={
                                                        <Radio
                                                            value={"pension plan"}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checkedIcon={<CheckBoxIcon />}
                                                        />
                                                    }
                                                    label="Pension Plan(Profit sharing or defined benefit)"
                                                />
                                                <br />
                                                <Box>
                                                    <FormControlLabel
                                                        disabled={
                                                            disableAllInputs === true ? true : false
                                                        }
                                                        control={
                                                            <Radio
                                                                value={"other"}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                            />
                                                        }
                                                        label="Other"
                                                    />
                                                    <TextField
                                                        variant="standard"
                                                        type="text"
                                                        required
                                                        className="req_fields"
                                                        placeholder={
                                                            individualFixedIndexAnnuityApplication.selectedQualifiedOptionsGroup2 === "other"
                                                                ? "This field is required"
                                                                : ""
                                                        }
                                                        value={
                                                            individualFixedIndexAnnuityApplication.otherSelectedQualifiedOptions
                                                        }
                                                        onChange={(e) => {
                                                            CommonContextUpdate(
                                                                "otherSelectedQualifiedOptions",
                                                                e.target.value
                                                            );
                                                        }}
                                                        disabled={
                                                            individualFixedIndexAnnuityApplication.selectedQualifiedOptionsGroup2 ===
                                                                "other" && disableAllInputs !== true
                                                                ? false
                                                                : true
                                                        }
                                                        style={{
                                                            border: "none",
                                                            outline: "none",
                                                            borderBottom: "1px solid #b3b3b3",
                                                        }}
                                                    />
                                                </Box>
                                            </RadioGroup>
                                        </>
                                    )}
                                </Box>

                                {(individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.length &&
                                    individualFixedIndexAnnuityApplication.flagQualifiedOrNonQualifiedSelected !== "") ?
                                    (
                                        <Box
                                            className="amount_to_paid_with_application"
                                            style={{ margin: "5px 0px 5px 30px" }}
                                        >
                                            <InputLabel style={{ fontSize: "13px" }}>
                                                Amount Paid With Application
                                            </InputLabel>
                                            <Input
                                                style={{ width: "100%" }}
                                                disabled={disableAllInputs === true ? true : false}
                                                type="text"
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    console.log(e.target.value.replaceAll(",", ""));
                                                    if (isNaN(e.target.value.replaceAll(",", "")) === false)
                                                        CommonContextUpdate(
                                                            "amountPaidWithApplication",
                                                            e.target.value.replaceAll(",", "")
                                                        );
                                                }}
                                                value={individualFixedIndexAnnuityApplication.amountPaidWithApplication.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )}
                                                startAdornment={
                                                    <InputAdornment position="start">$</InputAdornment>
                                                }
                                            />
                                        </Box>
                                    ) : null}
                                {((individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions?.length > 0 &&
                                    (individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.indexOf("1035 exchange") > -1
                                        || individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.indexOf("transfer") > -1
                                        || individualFixedIndexAnnuityApplication.selectedNonQualifiedOptions.indexOf("rollover") > -1
                                    ))) ? (
                                    <Box className="expected_premium" style={{ margin: "5px 0px 5px 30px" }}>
                                        <InputLabel style={{ fontSize: "13px" }}>
                                            If a 1035 Exchange, Rollover or transfer is occurring, the
                                            expected premium Amount is
                                        </InputLabel>
                                        <Input
                                            style={{ width: "100%" }}
                                            type="text"
                                            disabled={disableAllInputs === true ? true : false}
                                            onChange={(e) => {
                                                if (isNaN(e.target.value.replaceAll(",", "")) === false)
                                                    CommonContextUpdate(
                                                        "expectedPremiumField",
                                                        e.target.value.replaceAll(",", "")
                                                    );
                                            }}
                                            value={individualFixedIndexAnnuityApplication.expectedPremiumField.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                            startAdornment={
                                                <InputAdornment position="start">$</InputAdornment>
                                            }
                                        />
                                    </Box>
                                ) : null}



                            </Box>
                        ) : null}
                        {/* Premium and Tax Qualification Status Section End */}

                        <Box
                            className="annuitant_head_root"
                            onClick={() => {
                                // setFlagSeventhSection(!flagFirstPageSeventhSection)
                                SetApplicationFormFlagSectionData({
                                    type: "Individual Annuity Application",
                                    section: "flagFirstPageNinthSection",
                                    data: !flagFirstPageNinthSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">
                                    9. Applicant and Insurance Producer Declarations.
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagFirstPageNinthSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagFirstPageNinthSection === true ? (
                            <Box className=" owner_replacement_question " style={{ width: "80%", left: "0%", position: "relative" }}>
                                <Box className="owner_replacement_question_main">
                                    <Box className="owner_replacement_first_question" style={{ marginTop: "20px" }}>
                                        {producerDeclarationsArray?.map((obj, i) => {
                                            return (
                                                <>
                                                    <Grid container sx={{ marginTop: i > 0 ? "25px" : "0px" }}>
                                                        <Grid item xl={0.3}>
                                                            <Typography>{obj.key}.</Typography>
                                                        </Grid>
                                                        <Grid item xl={11}>
                                                            <Typography textAlign="justify" dangerouslySetInnerHTML={{ __html: obj.question }} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container className="radio_group_shift_right_fixed">
                                                                <Grid item sm={12} md={6} lg={12} xl={6}>
                                                                    <Grid container justifyContent={{ xl: "start", lg: "start" }} alignItems="center" >
                                                                        <Grid item sm={3.8} md={6} lg={4} xl={6}> <Typography variant="span">Insurance Producer:</Typography></Grid>
                                                                        <Grid item sm={6} md={6} lg={6} xl={6}>
                                                                            <RadioGroup
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "flex-start",
                                                                                    flexDirection: "row",
                                                                                }}
                                                                                value={individualFixedIndexAnnuityApplication[obj.producerState]}
                                                                                onClick={(e) => {
                                                                                    if (!e.target.checked) {
                                                                                        return;
                                                                                    }
                                                                                    if (!e.target.value) {
                                                                                        CommonContextUpdate([obj.producerState], "");

                                                                                        return;
                                                                                    }
                                                                                    if (e.target.value != individualFixedIndexAnnuityApplication[obj.producerState]) {
                                                                                        CommonContextUpdate([obj.producerState], e.target.value);
                                                                                    } else {
                                                                                        CommonContextUpdate([obj.producerState], "");

                                                                                        // CommonContextUpdate("typeOfAccount", "");
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <FormControlLabel
                                                                                    disabled={disableAllInputs === true ? true : false}
                                                                                    control={
                                                                                        <Radio
                                                                                            value={"yes"}
                                                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                                                            checkedIcon={<CheckBoxIcon />}
                                                                                        />
                                                                                    }
                                                                                    label="Yes"
                                                                                />
                                                                                <FormControlLabel
                                                                                    disabled={disableAllInputs === true ? true : false}
                                                                                    control={
                                                                                        <Radio
                                                                                            value={"no"}
                                                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                                                            checkedIcon={<CheckBoxIcon />}
                                                                                        />
                                                                                    }
                                                                                    label="No"
                                                                                />
                                                                            </RadioGroup>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item sm={12} md={6} lg={12} xl={6}>
                                                                    <Grid container justifyContent={{ xl: "start", lg: "start" }} alignItems="center" >
                                                                        <Grid item sm={3.8} md={4} lg={4} xl={3.8}> <Typography variant="span">Applicant(s):</Typography></Grid>
                                                                        <Grid item sm={6} md={6} lg={6} xl={6}>
                                                                            <RadioGroup
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "flex-start",
                                                                                    flexDirection: "row"
                                                                                }}
                                                                                value={individualFixedIndexAnnuityApplication[obj.applicantState]}
                                                                                onClick={(e) => {
                                                                                    if (!e.target.checked) {
                                                                                        return;
                                                                                    }
                                                                                    if (!e.target.value) {
                                                                                        CommonContextUpdate([obj.applicantState], "");

                                                                                        return;
                                                                                    }
                                                                                    if (
                                                                                        e.target.value !=
                                                                                        individualFixedIndexAnnuityApplication[obj.applicantState]
                                                                                    ) {
                                                                                        CommonContextUpdate(
                                                                                            [obj.applicantState],
                                                                                            e.target.value
                                                                                        );
                                                                                    } else {
                                                                                        CommonContextUpdate(
                                                                                            [obj.applicantState],
                                                                                            ""
                                                                                        );

                                                                                        // CommonContextUpdate("typeOfAccount", "");
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <FormControlLabel
                                                                                    disabled={disableAllInputs === true ? true : false}
                                                                                    control={
                                                                                        <Radio
                                                                                            value={"yes"}
                                                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                                                            checkedIcon={<CheckBoxIcon />}
                                                                                        />
                                                                                    }
                                                                                    label="Yes"
                                                                                />
                                                                                <FormControlLabel
                                                                                    disabled={disableAllInputs === true ? true : false}
                                                                                    control={
                                                                                        <Radio
                                                                                            value={"no"}
                                                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                                                            checkedIcon={<CheckBoxIcon />}
                                                                                        />
                                                                                    }
                                                                                    label="No"
                                                                                />
                                                                            </RadioGroup>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>


                                                </>
                                            )
                                        })
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}
                        {individualFixedIndexAnnuityApplication.stateToShowNoticeOfReplacement === "Illinois" ?
                            <Box className=" owner_replacement_question " style={{ width: "80%", left: "0%", position: "relative" }}>
                                <IllinoisAlert />
                            </Box>
                            : ""}
                        <Box
                            className="next_suitability_analysis_btn"
                            style={{ margin: "20px 0 20px 0" }}
                        >
                            <Button
                                endIcon={<DoubleArrowIcon />}
                                style={{
                                    width: "250px",
                                    color: "#fff",
                                    textTransform: "capitalize",
                                    background: "#074a6e",
                                }}
                                onClick={() => {
                                    if (ConditionToShowReplacementForm() === true) {
                                        props.changePageIndex(1)
                                    } else {
                                        props.changePageIndex(2)
                                    }
                                }}
                            >
                                Next -{" "}
                                {ConditionToShowReplacementForm() === true
                                    ? "Notice Of Replacement"
                                    : "Suitability Analysis"}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default FixedIndexAnnuityApplication;

