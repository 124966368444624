import React, { Fragment, useContext, useState, useEffect } from "react";
import {
    Typography,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Grid,
    TextField,
    DialogActions,
    DialogContent,
} from "@mui/material";
import "./fiaAnnuityApplication.css";
import { Context as UserLoaderContext } from "../../../context/userLoaderContext";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
    CopyApplicationFormAPI, CopyApplicationFormV3API
} from "../../../api/getAnnuityApplicationAPI";
import { Context as ApplicationFormContext } from "../../../context/V3/newApplicationFormContext";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Popup from "../../product/pdfCondfig/popup";
import { GetFiaAppListAPI } from "../../../api/V3/fiaApplicationAPI";

const FiaApplicationList = (props) => {
    const navigate = useNavigate();
    const [applications, setApplications] = useState([]);
    const [defaultApplications, setDefaultApplications] = useState([]);
    const { SetFlagLoader, SetUserType } = useContext(UserLoaderContext);
    const { state } = useContext(UserLoaderContext);
    const [userType, setUserType] = useState();
    const [openCopyAlert, setOpenCopyAlert] = useState({
        flag: false,
        application_id: ""
    });
    useEffect(() => {
        SetFlagLoader(false);
    }, [applications]);

    useEffect(() => {
        SetFlagLoader(true);
    }, []);

    useEffect(() => {
        SetFlagLoader(true);
        GetFiaAppListAPI(
            (res) => {
                if (res.data.status === true) {
                    let sortedData = res.data?.data?.sort((a, b) => new Date(b.insurance_updated_at) - new Date(a.insurance_updated_at));
                    setApplications(sortedData);
                    setDefaultApplications(sortedData);
                    SetFlagLoader(false);
                    setUserType(state.userType);
                } else {
                    SetFlagLoader(false);
                }
            },
            (err) => {
                SetFlagLoader(false);
                if (err.response.status === 401) {
                    window.location.reload();
                }
            }
        );
    }, []);

    const ViewApplication = (id) => {
        let domain = window.location.host;
        let protocol = window.location.protocol;
        navigate(`/fia_application?id=${id}`);
    };

    useEffect(() => {
        console.log("Printing user type in annuity application", userType);
    }, [userType]);

    const searchAnnuitant = (value) => {
        if (!value) {
            setApplications(defaultApplications);
        } else {
            var filteredData = defaultApplications.filter((row) => row?.annuitant_name.toLowerCase().indexOf(value.toLowerCase()) > -1 || row?.application_submitted_by_fullname.toLowerCase().indexOf(value.toLowerCase()) > -1)
            setApplications(filteredData);
        }
    }

    function convertDateFormat(inputDateString) {
        const inputDate = new Date(inputDateString);
        const year = inputDate.getUTCFullYear();
        const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const day = inputDate.getUTCDate().toString().padStart(2, '0');
        const formattedDate = `${month}-${day}-${year}`;
        return formattedDate;
    }

    const CopyApplicationPopUp = () => {
        return (
            <>
                <DialogContent className="edit_pdf_popup2">
                    <>
                        <Grid item xs={12}>
                            <h4
                                style={{
                                    color: "#D9A22E",
                                }}
                            >
                                Confirmation
                            </h4>

                        </Grid>
                        <Grid item xs={12}>
                            <WarningRoundedIcon sx={{ fontSize: "80px", color: "#d9a22e" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <span>Are you sure you want to copy the application?</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className="hint_span">
                                This action will create a new draft application based on the current one. Please confirm your selection.
                            </span>
                        </Grid>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            SetFlagLoader(true);
                            let obj = {
                                application_id: openCopyAlert.application_id
                            }
                            CopyApplicationFormV3API(obj, (res) => {
                                if (res?.data?.status) {
                                    console.log("copy res", res?.data?.data[0].application_id)
                                    let application_id = res?.data?.data[0]?.application_id
                                    navigate(`/fia_application?id=${application_id}`);
                                } else {
                                    setOpenCopyAlert({
                                        flag: false,
                                        application_id: "",
                                        flag_new_application: true
                                    });
                                    SetFlagLoader(false);
                                    return;
                                }
                            }, (err) => {
                                setOpenCopyAlert({
                                    flag: false,
                                    application_id: "",
                                    flag_new_application: true
                                });
                                console.log("copy err", err)
                                SetFlagLoader(false);
                            })
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenCopyAlert({
                                flag: false,
                                application_id: ""
                            });
                        }}
                    >
                        No
                    </Button>
                </DialogActions>
            </>
        );
    };

    return (
        <Fragment>
            {openCopyAlert.flag && <Popup open={openCopyAlert.flag} data={CopyApplicationPopUp()} />}
            <Box className="annuity_application_page">
                <Box className="page_title_annuity_application page_title">
                    <Typography variant="span">E-App History</Typography>
                </Box>
                <Box
                    className="annuity_application_table_root"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                    }}
                >
                    <Grid container marginTop="20px" alignItems="end">
                        <Grid item xs={6}>
                            <TextField
                                className="annuitySearchBarTextField"
                                placeholder="Search..."
                                onChange={(e) => {
                                    searchAnnuitant(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} textAlign="end">
                            <Button
                                onClick={() => {
                                    let domain = window.location.host;
                                    let protocol = window.location.protocol;
                                    window.location.replace(`${protocol}//${domain}/fia_application`);
                                }}
                                style={{
                                    textTransform: "capitalize",
                                    background: "#D9A22E ",
                                    color: "#fff",
                                }}
                            >
                                New Application
                            </Button>
                        </Grid>
                    </Grid>
                    <TableContainer sx={{ maxHeight: 700, marginTop: "10px" }} >
                        <Table className="annuity_application_table" stickyHeader>
                            <TableHead className="annuity_application_table_head">
                                <TableRow>
                                    <TableCell>Action</TableCell>
                                    <TableCell>Annuitant Name</TableCell>
                                    {state.userType === "Admin" && (
                                        <TableCell>Submitted By</TableCell>
                                    )}
                                    {state.userType === "Admin" && (
                                        <TableCell>Agent Email</TableCell>
                                    )}
                                    <TableCell>Submitted Date</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Copy Application</TableCell>
                                    <TableCell>SSN/TIN</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {applications.length > 0 ?
                                    applications.map((row, i) => {
                                        return (
                                            <TableRow key={i} className="annuity_application_table_data">
                                                <TableCell className="annuity_application_view_button">
                                                    <Button
                                                        sx={{ minWidth: "84px" }}
                                                        onClick={() => { ViewApplication(row.applications_id) }}
                                                    >
                                                        {row?.application_status_name === "Submitted for Signature"
                                                            ? "View/Edit"
                                                            : "View"}
                                                    </Button>
                                                </TableCell>
                                                <TableCell>{row?.annuitant_name}</TableCell>
                                                {state.userType === "Admin" && (
                                                    <TableCell>
                                                        {row?.application_submitted_by_fullname}
                                                    </TableCell>
                                                )}
                                                {state.userType === "Admin" && (
                                                    <TableCell>
                                                        {row?.application_submitted_by_email}
                                                    </TableCell>
                                                )}
                                                <TableCell>
                                                    {row?.insurance_updated_at ?
                                                        convertDateFormat(row?.insurance_updated_at)
                                                        :
                                                        convertDateFormat(row?.insurance_created_at)
                                                    }
                                                </TableCell>
                                                <TableCell>{row?.application_status_name}</TableCell>
                                                <TableCell className="annuity_application_view_button">
                                                    {
                                                        row?.application_status_name === "Submitted for Signature"
                                                            ?
                                                            <Button
                                                                sx={{ minWidth: "80px" }}
                                                                onClick={() => {
                                                                    // debugger;
                                                                    setOpenCopyAlert({
                                                                        flag: true,
                                                                        application_id: row.applications_id
                                                                    })
                                                                }}
                                                            >
                                                                Copy Application
                                                            </Button>
                                                            :
                                                            ""
                                                    }
                                                </TableCell>
                                                <TableCell>{row?.ssn_num}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow colspan={5}
                                        className="annuity_application_table_data"
                                    >
                                        {state.flagLoader == false && <TableCell colSpan={state.userType === "Admin" ? 8 : 6} sx={{ textAlign: "center" }}>No Data Available</TableCell>}
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Fragment>
    );
};

export default FiaApplicationList;
