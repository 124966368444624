import { Dialog, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import "./style.css";

const SomethingWentWrongDialog = (props)=>{
    const {setClosePopupState,popupState}= props
    return (
        <React.Fragment>
            <Dialog
                open={popupState}
                onClose={()=>{
                    setClosePopupState(false);
                }}
            >
                <Typography>Something Went Wrong. Please try again later.!</Typography>
                <Button onClick={()=>{setClosePopupState(false)}}>Ok</Button>
            </Dialog>
        </React.Fragment>
    )
};

export default SomethingWentWrongDialog;