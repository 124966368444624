import React from "react";
import {
  Box,
  Button,
  Grid,
  Divider,
  FormControl, FormControlLabel, Checkbox
} from "@mui/material";
import Farmers_img from "../../assets/FarmersLife_Homepage.png";
import "./calculator_view.css";
import { useState, useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ViewCalcIdDataAPI, NewViewCalcIdDataAPI } from "../../service/viewcalc_data_id.service";
import { DownloadPdfAPI } from "../../service/download_pdf.service";
import { Context } from "../../context/userLoaderContext";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import { Footer } from "../../components/footer/footer";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Chip from '@mui/material/Chip';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const View_Calculation = (props) => {
  const [surrenderCharge, setSurrenderCharge] = useState([[]]);
  const [finalChartData, setFinalChartData] = useState();
  const [ShowMsg, setShowMsg] = useState({
    status: false,
    error: false,
    msg: "",
  });
  const [creditSum, setIntCreditSum] = useState(0);
  const [wdSum, setWdSum] = useState(0);
  const navigate = useNavigate();
  const { state, SetFlagLoader } = useContext(Context);
  const queryParams = new URLSearchParams(window.location.search);
  const calculation_id = queryParams.get("calculation_id");
  const addCommas = (num) => {
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return num;
  };
  const downloadBase64File = (contentBase64, fileName) => {
    const linkSource = `data:application/pdf;base64,${contentBase64}`;
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = "_self";
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const [finalMonthlyChartData, setFinalMonthlyChartData] = useState();
  const [monthlyData, setMonthlyData] = useState([]);
  const [calculatorDetails, setCalculatorDetails] = useState()

  console.log("monthlyData", monthlyData)
  function formatDate(date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
  }

  function getFirstDate() {
    const today = new Date();
    let firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    if (today.getDate() > 7) {
      firstDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    }
    return formatDate(firstDate);
  }

  const getIssuedate = () => {
    const today = new Date();
    let firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    if (today.getDate() > 7) {
      firstDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    }

    const month = firstDate.getMonth() + 1;
    const day = firstDate.getDate();
    const year = firstDate.getFullYear();
    return `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
  }

  const GetNextDate = (inputDate) => {
    const parts = inputDate.split('/');
    const month = parseInt(parts[0], 10) - 1;
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    const date = new Date(year, month, day);
    date.setMonth(date.getMonth() + 1);
    return formatDate(date);
  }

  function daysBetweenDates(date1, date2) {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);
    const timeDiff = Math.abs(secondDate - firstDate);
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  }

  const GetAvBeforeWd = (month, date, prevDate, preAvAfterWd, interestRate) => {
    if (month == 0) {
      return Number(calculatorDetails?.amount?.replace(/,/gi, ""))
    } else {
      let diff = (preAvAfterWd * (1 + ((interestRate) / 365) * daysBetweenDates(prevDate, date)))
      return diff
    }
  }

  useEffect(() => {
    SetFlagLoader(false);
    let url = new URLSearchParams(window.location.search);
    let getCalc_id = url.get("calculation_id");
    if (getCalc_id) {
      NewViewCalcIdDataAPI(getCalc_id, (res) => {
        console.log("resData", res);
        if (res.data.status === true) {
          let responseData = res?.data?.data[0]
          setCalculatorDetails(responseData)

          let FinalArr = [];
          let chartDataArr = [];
          let tempInterestRate = responseData?.interest_rate ? responseData?.interest_rate : 0.055;
          if (responseData.free_withdrawal_rider && responseData.enhanced_benefit_rider) {
            tempInterestRate -= 0.002;
          } else if (responseData.free_withdrawal_rider) {
            tempInterestRate -= 0.001;
          } else if (responseData.enhanced_benefit_rider) {
            tempInterestRate -= 0.001;
          }

          if (responseData?.is_withdraw_interest == 0) {
            chartDataArr.push({
              name: `Y ${0}`,
              amount: Number(responseData.amount?.replace(/,/gi, ""))
            });
          }
          let sumCredit = 0;
          let sumWd = 0;
          for (let i = 0; i < responseData?.year; i++) {
            const amountTextNumber = responseData.amount?.replace(/,/gi, "")
            if (responseData?.is_withdraw_interest != 0) {
              sumCredit += parseInt(Number(amountTextNumber) * (Number(tempInterestRate) / 12) * 12);
              sumWd += parseInt(Number(amountTextNumber) * (Number(tempInterestRate) / 12) * 12);
              FinalArr.push([
                tempInterestRate,
                (Number(amountTextNumber) * (Number(tempInterestRate) / 12) * 12),
                (Number(amountTextNumber) * (Number(tempInterestRate) / 12) * 12),
                Number(amountTextNumber),
                Number(amountTextNumber) * (1 - responseData.surrender_charge[i].surrender_charge_value)
              ]);
              chartDataArr.push({
                name: `Y ${i}`,
                amount: Number(amountTextNumber)
              });
            } else {
              const prevIntCreditValue = i == 0 ? amountTextNumber : FinalArr[i - 1][3]
              const currentIntCredit = Number(prevIntCreditValue) * (Math.pow((1 + tempInterestRate / 365), 365) - 1);
              const currentAccountValue = currentIntCredit + Number(prevIntCreditValue)
              const currentSurrenderValue = currentAccountValue - (currentAccountValue * responseData.surrender_charge[i].surrender_charge_value)
              sumCredit += currentIntCredit
              sumWd += 0
              FinalArr.push([
                tempInterestRate,
                currentIntCredit,
                0,
                currentAccountValue,
                currentSurrenderValue
              ]);
              chartDataArr.push({
                name: `Y ${i + 1}`,
                amount: Number(currentAccountValue.toFixed(2))
              });
            }
          }
          setFinalChartData(chartDataArr);
          setSurrenderCharge(FinalArr);
          setIntCreditSum(sumCredit);
          setWdSum(sumWd);

          const amountTextNumber = responseData?.amount.replace(/,/gi, "")
          let finalMonthArr = [{
            month: 0,
            date: getFirstDate(),
            avBeforeWd: Number(amountTextNumber),
            intCredit: 0,
            wd: 0,
            avAfterWd: Number(amountTextNumber),
            cashFlow: 0 - Number(amountTextNumber),
            flagMax: false,
            flagMin: false
          }]
          let crrentDate;
          let currentAvBeforeWd;
          let currentIntCredit;
          let currentCash;
          let monthlyLabels = ["year 0"];
          let graphPoints = [0];
          let monthlyGraphDataArr = []
          monthlyGraphDataArr.push({
            name: 0,
            amount: 0
          })
          for (let i = 1; i <= (responseData?.year * 12); i++) {
            const prevRowData = finalMonthArr[i - 1]
            crrentDate = GetNextDate(prevRowData.date)
            currentAvBeforeWd = GetAvBeforeWd(i, crrentDate, prevRowData.date, prevRowData.avAfterWd, responseData.interest_rate)
            currentIntCredit = currentAvBeforeWd - prevRowData.avAfterWd
            currentCash = responseData.is_withdraw_interest ? currentIntCredit : 0
            if (i == (responseData?.year * 12)) {
              currentCash = Number(amountTextNumber) + currentIntCredit
            }
            finalMonthArr.push({
              month: i,
              date: crrentDate,
              avBeforeWd: addCommas(currentAvBeforeWd.toFixed(2)),
              intCredit: addCommas(currentIntCredit?.toFixed(2)),
              wd: addCommas(currentIntCredit?.toFixed(2)),
              avAfterWd: Number(amountTextNumber),
              cashFlow: addCommas(currentCash?.toFixed(2)),
              flagMax: false,
              flagMin: false
            })
            monthlyGraphDataArr.push({
              name: `${i}`,
              amount: addCommas(currentIntCredit?.toFixed(2))
            })
            monthlyLabels.push(`year ${i / 10}`)
            graphPoints.push(addCommas(currentIntCredit?.toFixed(2)))
          }
          let max = Math.max.apply(Math, finalMonthArr.map(function (o) { return o.intCredit }))
          let maxIndx = finalMonthArr.findIndex((obj) => obj.intCredit == max)
          let min = Math.min.apply(Math, finalMonthArr.slice(1, finalMonthArr?.length).map(function (o) { return o.intCredit }))
          let minIndx = finalMonthArr.findIndex((obj, i) => obj.intCredit == min)
          finalMonthArr[maxIndx].flagMax = true
          finalMonthArr[minIndx].flagMin = true
          setFinalMonthlyChartData(monthlyGraphDataArr);
          setMonthlyData(finalMonthArr)
        }
      }, (err) => {
        console.log("errData", err);
      })
    }
  }, []);

  function isThisDecimal(val) {
    return val % 1 !== 0
  }

  const getMonthlySum = (row_name) => {
    let sum = monthlyData?.reduce((accumulator, currentObject) => {
      return accumulator + Number(currentObject[row_name])
    }, 0)
    return sum
  }


  return (
    <>
      <Box className="img_div">
        <img src={Farmers_img} className="img" alt="img" />
        <span class="img_text1">Farmers Safeguard Plus®</span>
        <span class="img_text2">Multi-Year Guaranteed Annuity (MYGA)</span>
      </Box>
      <Box className="btn_div1_view">
        {/* <Link to="/history"> */}
        <Button
          variant="contained"
          onClick={() => {
            navigate("/history");
          }}
        >
          Back
        </Button>
        {/* </Link> */}
      </Box>
      <Box className="main_container main_container_view">
        <Box className="first_div">Calculator View</Box>

        <Box className="third_box">
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            className="download_btn"
            onClick={() => {
              SetFlagLoader(true);
              const d = new Date();
              const xValues = finalChartData.map((obj) => obj.name)
              const yValues = finalChartData.map((obj) => obj.amount)
              const xValuesMonthly = finalMonthlyChartData.map((obj) => obj.name)
              const yValuesMonthly = finalMonthlyChartData.map((obj) => obj.amount)
              DownloadPdfAPI(
                {
                  client: calculatorDetails?.name,
                  preparedBy:
                    calculatorDetails?.prepared_by !== ""
                      ? calculatorDetails?.prepared_by
                      : state.user.first_name + " " + state.user.last_name,
                  age: Number(calculatorDetails?.age),
                  state: calculatorDetails?.state,
                  Graph: { x: xValues, y: yValues },
                  amount: calculatorDetails?.amount,
                  final_amount: surrenderCharge[surrenderCharge?.length - 1][3].toFixed(2),
                  period: calculatorDetails.year,
                  interest_rate: calculatorDetails.interest_rate,
                  pdf_array: surrenderCharge,
                  prepared: moment(d).format("DD/MM/YYYY h:mm"),
                  surrender_Charge: calculatorDetails?.surrender_charge?.map((obj) => obj.surrender_charge_value),
                  free_withdrawal_rider: calculatorDetails?.free_withdrawal_rider,
                  enhanced_benefit_rider: calculatorDetails?.enhanced_benefit_rider,
                  withdrawal_graph: { x: xValuesMonthly, y: yValuesMonthly },
                  total_withdrawal_amount: getMonthlySum('wd')?.toFixed(2),
                  withdrawal_data: monthlyData,
                  is_withdraw_interest: calculatorDetails?.is_withdraw_interest ? true : false,
                },
                (res) => {
                  SetFlagLoader(false);
                  console.log(res);
                  if (res.data.status)
                    downloadBase64File(res.data.data, "Farmers_Safeguard_Plus_calculator.pdf");
                  return setShowMsg({
                    status: false,
                    error: true,
                    msg: "Something Went Wrong!",
                  });
                },
                (err) => {
                  SetFlagLoader(false);
                  console.log(err);
                }
              );
            }}
            style={{ borderColor: "2px solid red" }}
          >
            Download
          </Button>
        </Box>

        <Box className="first_part">
          <Box className="first_box_view">
            <Box>
              <Box className="name">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Client Name:</div>
                  <div className="third">{calculatorDetails?.name}</div>
                </Box>
              </Box>

              <Box className="age">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Client Age:</div>
                  <div className="third">{calculatorDetails?.age}</div>
                </Box>
              </Box>

              <Box className="amount">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Amount:</div>
                  <div className="third">${addCommas(calculatorDetails?.amount)}</div>
                </Box>
              </Box>

              <Box className="period">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Period:</div>
                  <div className="third">{calculatorDetails?.year}</div>
                </Box>
              </Box>
              <Box className="state state_view">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">State:</div>
                  <div className="third">{calculatorDetails?.state}</div>
                </Box>
              </Box>
              <Box className="rider">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Rider:</div>
                  <div className="third third_rider">
                    {calculatorDetails?.free_withdrawal_rider === true &&
                      calculatorDetails?.enhanced_benefit_rider === false
                      ? "Free Withdrawal Rider"
                      : calculatorDetails?.enhanced_benefit_rider === true &&
                        calculatorDetails?.free_withdrawal_rider === false
                        ? "Enhanced Benefit Rider"
                        : calculatorDetails?.enhanced_benefit_rider === true &&
                          calculatorDetails?.free_withdrawal_rider === true
                          ? "Free Withdrawal Rider,Enhanced Benefit Rider"
                          : "None"}
                  </div>
                </Box>
              </Box>
              <Box className="state">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Created At:</div>
                  <div className="third">
                    {moment(calculatorDetails?.created_at).format("MM-DD-YYYY")}
                  </div>
                </Box>
              </Box>
              <Box className="state">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Prepared By:</div>
                  <div className="third">{calculatorDetails?.prepared_by}</div>
                </Box>
              </Box>
              <Box className="state">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Withdraw Interest?</div>
                  <div className="third">
                    {calculatorDetails?.is_withdraw_interest == 1 ? "Yes" : "No"}
                    {/* <Checkbox
                      disabled={true}
                      checked={calculatorDetails?.is_withdraw_interest == 1 ? true : false}
                      value={calculatorDetails?.is_withdraw_interest}
                      sx={{ padding: 0 }}
                    /> */}
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="rightbox rightbox_view">
            <Grid container justifyContent={"center"} rowGap={1.5} columnGap={1.1}>
              <Grid item xs={11.5} sx={{ backgroundColor: "#D9A22E", color: "white", textAlign: "center", padding: "10px 0", borderRadius: "5px", marginTop: "10px" }}>
                Annual Interest Rate: {(calculatorDetails?.interest_rate * 100).toFixed(2)}%
              </Grid>
              <Grid item xs={5.6} className="grayBox">
                <Grid container className="grayBoxContainer" alignItems={"center"} justifyContent={"center"} direction={"column"}>
                  <Grid item xs={6}>
                    <h2 style={{ color: "#D9A22E" }}>
                      ${surrenderCharge?.length == 0 ? 0 :
                        surrenderCharge[surrenderCharge?.length - 1][3] ? addCommas(surrenderCharge[surrenderCharge?.length - 1][3].toFixed(2)) : 0
                      }
                    </h2>
                  </Grid>
                  <Grid item xs={6} mt={1.3} textAlign={"center"}>
                    <b>Amount After {calculatorDetails?.year} Years</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5.6} className="grayBox">
                <Grid container className="grayBoxContainer" alignItems={"center"} justifyContent={"center"} direction={"column"}>
                  <Grid item xs={6}>
                    <h2 style={{ color: "#D9A22E" }}>
                      $
                      {calculatorDetails?.total_monthly_withdrawal_amount !== NaN && calculatorDetails?.total_monthly_withdrawal_amount !== undefined
                        ? addCommas(Number(calculatorDetails?.total_monthly_withdrawal_amount)?.toFixed(2))
                        : 0}
                    </h2>
                  </Grid>
                  <Grid item xs={6} mt={1.3} textAlign={"center"}>
                    <b>Total Withdrawal</b>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {(calculatorDetails?.surrender_charge_change_flag === true ||
              calculatorDetails?.interest_rate_change_flag === true) && (
                <Box
                  className="note"
                  style={{ color: "red", textAlign: "justify" }}
                >
                  <span>
                    <b>Note: </b>The interest rate or surrender charges have
                    changed since this calculator was run. You may need to create
                    a new calculator to get the latest data.
                  </span>
                </Box>
              )}
          </Box>
        </Box>

        <Divider
          component="div"
          role="presentation"
          style={{
            width: "90%",
            color: "rgb(217, 162, 46) !important",
            margin: "0 auto !important",
          }}
        ></Divider>
        <h4 style={{ textAlign: "center" }}>Account Value Over the Years</h4>
        <div className="chart" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {finalChartData?.length ?
            <LineChart
              width={800}
              height={300}
              data={finalChartData}
            >
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis dataKey="name" />
              <YAxis
                tickFormatter={tick => {
                  return tick.toLocaleString();
                }}
                domain={([dataMin, dataMax]) => { const absMax = Math.max(Math.abs(dataMin), Math.ceil(dataMax * 1.2)); return [0, absMax]; }}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="amount"
                stroke="#D9A22E"
              // activeDot={{ r: 0 }}
              />
            </LineChart>
            : ""}

        </div>

        <Box className="con_main_box">
          <Grid
            container
            justifyContent="center"
          >
            <Grid item xs={10}>
              <TableContainer>
                <Table className="guaranteedTable">
                  <TableHead>
                    <TableRow className="guaranteedTableRow">

                      <TableCell className="blankCell" align="center" colSpan={4}></TableCell>
                      <TableCell className="guaranteedTableCell" sx={{ borderRadius: "10px" }} align="center" colSpan={5}>Guaranteed</TableCell>

                    </TableRow>
                    <TableRow className="guaranteedTableRow">
                      <TableCell className="guaranteedTableCell firstCell" align="center">Year</TableCell>
                      <TableCell className="guaranteedTableCell" align="center">Surrender Charge</TableCell>
                      <TableCell className="guaranteedTableCell lastCell" align="center">Age</TableCell>
                      <TableCell className="blankCell" align="center"></TableCell>
                      <TableCell className="guaranteedTableCell firstCell" align="center">Interest</TableCell>
                      <TableCell className="guaranteedTableCell" align="center">Interest Credit</TableCell>
                      <TableCell className="guaranteedTableCell" align="center">Interest WD</TableCell>
                      <TableCell className="guaranteedTableCell" align="center">Account Value</TableCell>
                      <TableCell className="guaranteedTableCell lastCell" align="center">Surrender Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {surrenderCharge.map((row, i) => (
                      <TableRow className="guaranteedTableBodyRow">
                        <TableCell align="center" className="firstCell">{i + 1}</TableCell>
                        <TableCell align="center">
                          {Number((Number(calculatorDetails?.surrender_charge[i].surrender_charge_value) * 100).toFixed(2))}%
                        </TableCell>
                        <TableCell className="lastCell" align="center">{Number(calculatorDetails?.age) + i + 1}</TableCell>
                        <TableCell className="blankCell" align="center"></TableCell>
                        <TableCell className="firstCell" align="center">{(row[0] * 100).toFixed(2)}%</TableCell>
                        <TableCell align="center">${addCommas(row[1]?.toFixed(2))}</TableCell>
                        <TableCell align="center">${addCommas(row[2]?.toFixed(2))}</TableCell>
                        <TableCell align="center">${addCommas(row[3]?.toFixed(2))}</TableCell>
                        <TableCell className="lastCell" align="center">${addCommas(row[4]?.toFixed(2))}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow className="guaranteedTableBodyRow">
                      <TableCell sx={{ borderRadius: "10px" }} align="center" colSpan={3}><b>Total / Ending</b></TableCell>
                      <TableCell className="blankCell" align="center"></TableCell>
                      <TableCell className="firstCell" align="center"></TableCell>
                      <TableCell align="center"><b>${addCommas(creditSum.toFixed(2))}</b></TableCell>
                      <TableCell align="center"><b>${addCommas(wdSum.toFixed(2))}</b></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center" className="lastCell"></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {calculatorDetails?.is_withdraw_interest == 1 ?
              <>
                <Grid item xs={12} textAlign={"center"}>
                  <h4>Monthly interest cash flow</h4>
                  <div className="chart" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {finalMonthlyChartData?.length ?
                      <LineChart
                        width={800}
                        height={300}
                        data={finalMonthlyChartData}
                      >
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis dataKey="name" />
                        <YAxis
                          tickFormatter={tick => {
                            return tick.toLocaleString();
                          }}
                          domain={([dataMin, dataMax]) => { const absMax = Math.max(Math.abs(dataMin), Math.ceil(dataMax * 1.2)); return [0, absMax]; }}
                        />
                        <Tooltip />
                        <Line
                          type="monotone"
                          dataKey="amount"
                          stroke="#D9A22E"
                        />
                      </LineChart>
                      : ""}

                  </div>
                </Grid>

                <Grid item xs={10} mb={2}>
                  <TableContainer>
                    <Table className="guaranteedTable">
                      <TableHead>
                        <TableRow className="guaranteedTableRow">
                          <TableCell className="guaranteedTableCell" sx={{ borderRadius: "10px" }} align="center" colSpan={7}>Monthly Cash Flow</TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  <TableContainer sx={{ maxHeight: "65vh" }}>
                    <Table className="guaranteedTable" stickyHeader>
                      <TableHead>
                        <TableRow className="guaranteedTableRow">
                          <TableCell className="guaranteedTableCell firstCell" align="center">Month</TableCell>
                          <TableCell className="guaranteedTableCell" align="center">Date</TableCell>
                          <TableCell className="guaranteedTableCell" align="center">AV Before WD</TableCell>
                          <TableCell className="guaranteedTableCell" align="center">Interest Credit</TableCell>
                          <TableCell className="guaranteedTableCell" align="center">Withdrawal</TableCell>
                          <TableCell className="guaranteedTableCell" align="center">AV After WD</TableCell>
                          <TableCell className="guaranteedTableCell lastCell" align="center">Cash Flow</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {monthlyData.map((row, i) => (
                          <TableRow className="guaranteedTableBodyRow">
                            <TableCell align="center" className="firstCell">{row.month}</TableCell>
                            <TableCell align="center">{row.date}</TableCell>
                            <TableCell align="center">${row.avBeforeWd}</TableCell>
                            <TableCell align="center" sx={{ color: row.flagMin ? "#FFA500" : (row.flagMax ? "#078A4E" : "black") }} >
                              ${row.intCredit}
                              {row.flagMin ?
                                <Chip label="Min" size="small" className="minLabel" />
                                :
                                (row.flagMax ?
                                  <Chip label="Max" size="small" className="maxLabel" />
                                  : "")}
                            </TableCell>
                            <TableCell align="center">${row.wd}</TableCell>
                            <TableCell align="center">${addCommas(row.avAfterWd)}</TableCell>
                            <TableCell align="center" className="lastCell">${addCommas(row.cashFlow)}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow className="guaranteedTableBodyRow" sx={{ position: "sticky", bottom: 0 }}>
                          <TableCell className="firstCell" align="center"><b>Total</b></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"><b>${addCommas(getMonthlySum('intCredit')?.toFixed(2))}</b></TableCell>
                          <TableCell align="center"><b>${addCommas(getMonthlySum('wd')?.toFixed(2))}</b></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center" className="lastCell"></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </> : ""}
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default View_Calculation;
