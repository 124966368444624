import Axios from "./axios";

export const DownloadPdfAPI = async (data, onSuccess, onError) => {
    try {
        const responce = await Axios.post('/api/calculator/download-myga-illustration', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(responce);
    }
    catch (err) {
        onError && onError(err)
    }
}