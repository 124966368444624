import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Input,
  InputAdornment,
  RadioGroup,
  Checkbox,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Divider,
  Radio,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { Context } from "../../../context/V1/newApplicationFormContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./suitabilityAnalysis.css";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Context as ErrorValidationContext } from "../../../context/V1/errorValidationContext";

const SuitabilityAnalysis = (props) => {
  const { SetSuitabilityAnalysis } = useContext(Context);
  const {
    suitabilityAnalysis,
    disableAllInputs,
    individualAnnuityApplication,
  } = useContext(Context).state;
  const { annuitantState } = individualAnnuityApplication;

  const { SetApplicationFormFlagSectionData } = useContext(
    ErrorValidationContext
  );
  const {
    flagSecondPageFirstSection,
    flagSecondPageSecondSection,
    flagSecondPageThirdSection,
    flagSecondPageFourthSection,
    flagSecondPageFifthSection,
    flagSecondPageSixthSection,
    flagSecondPageSeventhSection,
  } = useContext(ErrorValidationContext).state;

  // const [flagSecondPageFirstSection, setFlagFirstSection] = useState(true);
  // const [flagSecondPageSecondSection, setFlagSecondSection] = useState(false);
  // const [flagSecondPageThirdSection, setFlagThirdSection] = useState(false);
  // const [flagSecondPageFourthSection, setFlagFourthSection] = useState(false);
  // const [flagSecondPageFifthSection, setFlagFifthSection] = useState(false);
  // const [flagSecondPageSixthSection, setFlagSixthSection] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("context 11", suitabilityAnalysis);
  }, [suitabilityAnalysis]);

  const CommonContextUpdate = (type, value) => {
    SetSuitabilityAnalysis({
      type: type,
      value: value,
    });
  };

  const ConditionToShowTransferExchangeForm = () => {
    if (
      1 === 1
      // individualAnnuityApplication.flagHaveExistingLifeInsurance === "Yes" ||
      // individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance ===
      //   "Yes" ||
      // individualAnnuityApplication.flagHaveExistingLifeInsurance === "Yes"
      //  && individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance === "No")||(individualAnnuityApplication.flagHaveExistingLifeInsurance === "No")||(individualAnnuityApplication.flagHaveExistingLifeInsurance === "")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const ConditionToShowReplacementForm = () => {
    if (
      individualAnnuityApplication.annuitantState !== "Tennessee" &&
      individualAnnuityApplication.annuitantState !== ""
    ) {
      if (
        individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected ===
        "Non - Qualified"
      ) {
        if (individualAnnuityApplication.selectedNonQualifiedOptions !== "") {
          return true;
        } else {
          return false;
        }
      } else if (individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected === "Qualified") {
        if (
          individualAnnuityApplication.selectedQualifiedOptionsGroup1 !== "" &&
          individualAnnuityApplication.selectedQualifiedOptionsGroup2 !== ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    let inputArray = document.querySelectorAll("input");
    if (disableAllInputs === true) {
      for (let i = 0; i < inputArray.length; i++) {
        console.log(
          "Printing each element in suitability analysis for disabe input array elements",
          inputArray[i]
        );
        inputArray[i].disabled = true;
      }
    }

    console.log(
      "Error Validation Context for Suitability Analysis",
      flagSecondPageFirstSection,
      flagSecondPageSecondSection,
      flagSecondPageThirdSection,
      flagSecondPageFourthSection,
      flagSecondPageFifthSection,
      flagSecondPageSixthSection,
      flagSecondPageSeventhSection
    );

    // else{
    //     for(let i=0;i<inputArray.length;i++){
    //         console.log("Printing each element in suitability analysis for disabe input array elements", inputArray[i]);
    //         inputArray[i].disabled = false;
    //     }
    // }
  }, [
    disableAllInputs,
    flagSecondPageFirstSection,
    flagSecondPageSecondSection,
    flagSecondPageThirdSection,
    flagSecondPageFourthSection,
    flagSecondPageFifthSection,
    flagSecondPageSixthSection,
    flagSecondPageSeventhSection,
  ]);

  return (
    <Fragment>
      <Box className="suitability_analysis_page_root">
        <Box className="application_form">
          <Box className="application_form_card">
            <Box className="application_form_head">
              <Box>
                <Typography variant="span">Suitability Analysis</Typography>
              </Box>
              <Box className="application_form_head_bottom_line">
                <Divider />
              </Box>
            </Box>
            <Box
              className="suitability_section_head_root"
              onClick={() => {
                // setFlagFirstSection(!flagSecondPageFirstSection)
                SetApplicationFormFlagSectionData({
                  type: "Suitability Analysis",
                  section: "flagSecondPageFirstSection",
                  data: !flagSecondPageFirstSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  1. Personal Information (All sections of this form must be
                  fully completed.)
                </Typography>
              </Box>
              <Box
                className="section_open_close"
              //  onClick={() => { setFlagFirstSection(!flagSecondPageFirstSection) }}
              >
                {flagSecondPageFirstSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagSecondPageFirstSection === true ? (
              <Box
                className="personal_information_main suitability_section_width"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Box className="suitability_full_name">
                  <Box className="suitability_left_part">
                    <Typography variant="span" className="full_name_label">
                      Full Name
                    </Typography>
                  </Box>
                  <Box className="suitability_middle_part">
                    <TextField
                      className="suitability_middle_part_input_field_width"
                      type="text"
                      label="Owner"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      value={suitabilityAnalysis.ownerFullName}
                      onChange={(e) => {
                        CommonContextUpdate("ownerFullName", e.target.value);
                      }}
                    />
                  </Box>
                  <Box className="suitability_right_part">
                    <TextField
                      className="suitability_right_part_input_field_width not_required"
                      type="text"
                      label="Spouse (If Any)"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      value={suitabilityAnalysis.spouseFullName}
                      onChange={(e) => {
                        CommonContextUpdate("spouseFullName", e.target.value);
                      }}
                    />
                  </Box>
                </Box>
                <Box className="current_age">
                  <Box className="suitability_left_part">
                    <Typography variant="span" className="current_age_label">
                      Current Age
                      {/* <span style={{ color: "red" }} color="red">
                        *
                      </span> */}
                    </Typography>
                  </Box>
                  <Box className="suitability_middle_part">
                    <TextField
                      className="suitability_middle_part_input_field_width"
                      type="number"
                      label="Owner"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      value={suitabilityAnalysis.ownerCurrentAge}
                      onChange={(e) => {
                        CommonContextUpdate("ownerCurrentAge", e.target.value);
                      }}
                    />
                  </Box>
                  <Box className="suitability_right_part">
                    <TextField
                      className="suitability_right_part_input_field_width not_required"
                      type="number"
                      label="Spouse (If Any)"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      value={suitabilityAnalysis.spouseCurrentAge}
                      onChange={(e) => {
                        CommonContextUpdate("spouseCurrentAge", e.target.value);
                      }}
                    />
                  </Box>
                </Box>
                <Box className="gender">
                  <Box className="suitability_left_part">
                    <Typography variant="span" className="gender_label">
                      Gender assignd at birth
                      {/* <span style={{ color: "red" }} color="red">
                        *
                      </span> */}
                    </Typography>
                  </Box>
                  <Box className="suitability_middle_part">
                    <FormControl
                      className="suitability_middle_part_input_field_width"
                      variant="standard"
                      placeholder="Gender"
                    >
                      <InputLabel id="demo-simple-select-label">
                        Owner
                      </InputLabel>
                      <Select
                        onChange={(e) => {
                          CommonContextUpdate("ownerGender", e.target.value);
                        }}
                        disabled={disableAllInputs === true ? true : false}
                        value={suitabilityAnalysis.ownerGender}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="suitability_right_part">
                    <FormControl
                      className="suitability_right_part_input_field_width not_required"
                      variant="standard"
                      label="Spouse (If Any)"
                      placeholder="Spouse (If Any)"
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        Spouse (If Any)
                      </InputLabel>
                      <Select
                        onChange={(e) => {
                          CommonContextUpdate("spouseGender", e.target.value);
                        }}
                        value={suitabilityAnalysis.spouseGender}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="employment_status">
                  <Box className="suitability_left_part">
                    <Typography
                      variant="span"
                      className="employment_status_label"
                    >
                      Employment Status
                      {/* <span style={{ color: "red" }} color="red">
                        *
                      </span> */}
                    </Typography>
                  </Box>
                  <Box className="suitability_middle_part">
                    <FormControl
                      className="suitability_middle_part_input_field_width"
                      variant="standard"
                    >
                      <InputLabel id="demo-simple-select-label">
                        Owner
                      </InputLabel>
                      <Select
                        onChange={(e) => {
                          CommonContextUpdate(
                            "ownerEmploymentStatus",
                            e.target.value
                          );
                        }}
                        value={suitabilityAnalysis.ownerEmploymentStatus}
                        disabled={disableAllInputs === true ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Retired"}>Retired</MenuItem>
                        <MenuItem value={"Full - Time"}>Full - Time</MenuItem>
                        <MenuItem value={"Part - Time"}>Part - Time</MenuItem>
                        {/* <MenuItem value="Not - Employed">Not - Employed</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="suitability_right_part">
                    <FormControl
                      className="suitability_right_part_input_field_width not_required"
                      label="Employment status"
                      placeholder="Employment status"
                      variant="standard"
                    >
                      <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                        Spouse (If Any)
                      </InputLabel>
                      <Select
                        onChange={(e) => {
                          CommonContextUpdate(
                            "spouseEmploymentStatus",
                            e.target.value
                          );
                        }}
                        disabled={disableAllInputs === true ? true : false}
                        value={suitabilityAnalysis.spouseEmploymentStatus}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Retired"}>Retired</MenuItem>
                        <MenuItem value={"Full - Time"}>Full - Time</MenuItem>
                        <MenuItem value={"Part - Time"}>Part - Time</MenuItem>
                        {/* <MenuItem value="Not - Employed">Not - Employed</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="current_occupation">
                  <Box className="suitability_left_part">
                    <Typography
                      variant="span"
                      className="current_occupation_label"
                    >
                      Current Occupation
                      {/* <span style={{ color: "red" }} color="red">
                        *
                      </span> */}
                    </Typography>
                  </Box>
                  <Box className="suitability_middle_part">
                    <TextField
                      className="suitability_middle_part_input_field_width"
                      type="text"
                      label="Owner"
                      disabled={disableAllInputs === true ? true : false}
                      placeholder="Owner"
                      variant="standard"
                      value={suitabilityAnalysis.ownerCurrentOccupation}
                      onChange={(e) => {
                        CommonContextUpdate(
                          "ownerCurrentOccupation",
                          e.target.value
                        );
                      }}
                    />
                  </Box>
                  <Box className="suitability_right_part">
                    <TextField
                      className="suitability_right_part_input_field_width not_required"
                      type="text"
                      label="Spouse (If Any)"
                      disabled={disableAllInputs === true ? true : false}
                      placeholder="Spouse (If Any)"
                      variant="standard"
                      value={suitabilityAnalysis.spouseCurrentOccupation}
                      onChange={(e) => {
                        CommonContextUpdate(
                          "spouseCurrentOccupation",
                          e.target.value
                        );
                      }}
                    />
                  </Box>
                </Box>
                <Box className="dependents">
                  <Box className="suitability_left_part">
                    <Typography variant="span" className="dependents_label">
                      Dependents
                      <br />
                      (Number & Ages)
                      {/* <span style={{ color: "red" }} color="red">
                        *
                      </span> */}
                    </Typography>
                  </Box>
                  <Box className="suitability_middle_part">
                    <TextField
                      className="suitability_middle_part_input_field_width"
                      type="number"
                      label="Owner"
                      disabled={disableAllInputs === true ? true : false}
                      placeholder="Owner"
                      variant="standard"
                      value={suitabilityAnalysis.ownerDependents}
                      onChange={(e) => {
                        CommonContextUpdate("ownerDependents", e.target.value);
                      }}
                    />
                  </Box>
                  <Box className="suitability_right_part">
                    <TextField
                      className="suitability_right_part_input_field_width not_required"
                      type="number"
                      label="Spouse (If Any)"
                      disabled={disableAllInputs === true ? true : false}
                      placeholder="Spouse (If Any)"
                      variant="standard"
                      value={suitabilityAnalysis.spouseDependents}
                      onChange={(e) => {
                        CommonContextUpdate("spouseDependents", e.target.value);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            ) : null}

            <Box
              className="suitability_section_head_root"
              onClick={() => {
                // setFlagSecondSection(!flagSecondPageSecondSection)
                SetApplicationFormFlagSectionData({
                  type: "Suitability Analysis",
                  section: "flagSecondPageSecondSection",
                  data: !flagSecondPageSecondSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  2. Financial Information Of Owner
                </Typography>
              </Box>
              <Box
                className="section_open_close"
              // onClick={() => { setFlagSecondSection(!flagSecondPageSecondSection) }}
              >
                {flagSecondPageSecondSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagSecondPageSecondSection === true ? (
              <Box
                className="financial_information_of_owner_main suitability_section_width "
                style={{ width: "80%" }}
              >
                <Box
                  className="Financial_Information_Of_Owner"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 0 20px 0",
                  }}
                >
                  <Box
                    className="annual_household_income left_section"
                    style={{ width: "70%", position: "relative" }}
                  >
                    <TextField
                      className="req_fields financial_section_labels"
                      style={{ width: "95%" }}
                      type="text"
                      label="Annual Household Income"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      onChange={(e) => {
                        console.log(e.target.value);
                        console.log(e.target.value.replaceAll(",", ""));
                        if (isNaN(e.target.value.replaceAll(",", "")) === false)
                          CommonContextUpdate(
                            "ownerAnnualHouseholdIncome",
                            e.target.value.replaceAll(",", "")
                          );
                        // if(isNaN(e.target.value.replaceAll(",", "")) === false)
                        //     CommonContextUpdate("ownerAnnualHouseholdIncome", e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                      }}
                      value={suitabilityAnalysis.ownerAnnualHouseholdIncome.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className="right_space"
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box
                    className="source_of_income right_section"
                    style={{ width: "70%", position: "relative" }}
                  >
                    <TextField
                      className="req_fields financial_section_labels"
                      style={{ width: "95%" }}
                      type="text"
                      label="Source of Income"
                      disabled={disableAllInputs === true ? true : false}
                      variant="standard"
                      // placeholder='Source of Income *'
                      onChange={(e) => {
                        CommonContextUpdate(
                          "ownerSourceOfIncome",
                          e.target.value
                        );
                      }}
                      value={suitabilityAnalysis.ownerSourceOfIncome}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  className="Financial_Information_Of_Owner"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "20px 0",
                  }}
                >
                  <Box
                    className="income_tax_bracket left_section"
                    style={{ width: "70%", position: "relative" }}
                  >
                    <TextField
                      className="req_fields financial_section_labels"
                      type="number"
                      style={{ width: "95%" }}
                      variant="standard"
                      label="Income Tax Bracket (Federal &amp; State)"
                      disabled={disableAllInputs === true ? true : false}
                      // placeholder='Income Tax Bracket (Federal &amp; State)*'
                      onChange={(e) => {
                        CommonContextUpdate(
                          "ownerIncomeTaxBracket",
                          e.target.value
                        );
                      }}
                      value={suitabilityAnalysis.ownerIncomeTaxBracket}
                      // endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box
                    className="total_outstanding_debt right_section"
                    style={{ width: "70%", position: "relative" }}
                  >
                    <TextField
                      className="req_fields financial_section_labels"
                      type="text"
                      style={{ width: "95%" }}
                      variant="standard"
                      label="Total Outstanding Debt"
                      disabled={disableAllInputs === true ? true : false}
                      // placeholder='Total Outstanding Debt  *'

                      onChange={(e) => {
                        console.log(e.target.value);
                        console.log(e.target.value.replaceAll(",", ""));
                        if (isNaN(e.target.value.replaceAll(",", "")) === false)
                          CommonContextUpdate(
                            "ownerTotalOutstandingDebt",
                            e.target.value.replaceAll(",", "")
                          );
                      }}
                      value={suitabilityAnalysis.ownerTotalOutstandingDebt.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                      // startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  className="total_assets"
                  style={{ width: "100%", margin: "20px 0" }}
                >
                  <TextField
                    className="req_fields financial_section_labels"
                    variant="standard"
                    style={{ width: "100%" }}
                    type="text"
                    label="Total Assets (total liquid and non-liquid assets prior to purchase of this annuity)"
                    disabled={disableAllInputs === true ? true : false}
                    // placeholder='Total Assets (total liquid and non-liquid assets prior to purchase of this annuity) *'
                    onChange={(e) => {
                      console.log(e.target.value);
                      console.log(e.target.value.replaceAll(",", ""));
                      if (isNaN(e.target.value.replaceAll(",", "")) === false)
                        CommonContextUpdate(
                          "ownerTotalAssets",
                          e.target.value.replaceAll(",", "")
                        );
                    }}
                    value={suitabilityAnalysis.ownerTotalAssets.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}
                    // startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  className="total_liquid_assets"
                  style={{ margin: "5px 0" }}
                >
                  <TextField
                    className="req_fields financial_section_labels"
                    variant="standard"
                    style={{ width: "100%" }}
                    type="text"
                    disabled={disableAllInputs === true ? true : false}
                    label="Total Liquid Assets (all assets that are readily convertible to cash after the purchase of this annuity)"
                    onChange={(e) => {
                      console.log(e.target.value);
                      console.log(e.target.value.replaceAll(",", ""));
                      if (isNaN(e.target.value.replaceAll(",", "")) === false)
                        CommonContextUpdate(
                          "ownerTotalLiquidAssets",
                          e.target.value.replaceAll(",", "")
                        );
                    }}
                    value={suitabilityAnalysis.ownerTotalLiquidAssets.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}
                    // startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            ) : null}
            <Box
              className="suitability_section_head_root"
              onClick={() => {
                // setFlagThirdSection(!flagSecondPageThirdSection)
                SetApplicationFormFlagSectionData({
                  type: "Suitability Analysis",
                  section: "flagSecondPageThirdSection",
                  data: !flagSecondPageThirdSection,
                });
              }}
              sx={{ marginTop: 0 }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  3. Financial Situation And Needs Of Owner
                </Typography>
              </Box>
              <Box
                className="section_open_close"
              // onClick={() => { setFlagThirdSection(!flagSecondPageThirdSection) }}
              >
                {flagSecondPageThirdSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagSecondPageThirdSection === true ? (
              <Box
                className="financial_situation_and_need_of_owner"
                style={{ width: "80%" }}
              >
                <Box
                  className=" first_question_sutability"
                  style={{ margin: "10px 0" }}
                >
                  <Typography variant="span">
                    1. Does your income cover all of your living and medical
                    expenses?
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <RadioGroup
                    className="radio_group_shift_right"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                    }}
                    name="first_question"
                    value={
                      suitabilityAnalysis.flagOwnerIncomeCoverLivingAndMedicalExpenses
                    }
                    onClick={(e) => {
                      if (!e.target.checked) {
                        return;
                      }
                      if (!e.target.value) {
                        CommonContextUpdate(
                          "explainOwnerIncomeCoverLivingAndMedicalExpenses",
                          ""
                        );
                        CommonContextUpdate(
                          "flagOwnerIncomeCoverLivingAndMedicalExpenses",
                          ""
                        );
                        return;
                      }
                      if (
                        e.target.value !=
                        suitabilityAnalysis.flagOwnerIncomeCoverLivingAndMedicalExpenses
                      ) {
                        if (
                          e.target.value === "Yes" &&
                          suitabilityAnalysis.explainOwnerIncomeCoverLivingAndMedicalExpenses !==
                          ""
                        ) {
                          CommonContextUpdate(
                            "explainOwnerIncomeCoverLivingAndMedicalExpenses",
                            ""
                          );
                        }
                        CommonContextUpdate(
                          "flagOwnerIncomeCoverLivingAndMedicalExpenses",
                          e.target.value
                        );
                      } else {
                        CommonContextUpdate(
                          "explainOwnerIncomeCoverLivingAndMedicalExpenses",
                          ""
                        );
                        CommonContextUpdate(
                          "flagOwnerIncomeCoverLivingAndMedicalExpenses",
                          ""
                        );
                        // CommonContextUpdate("typeOfAccount", "");
                      }
                    }}
                  // onChange={(e) => {
                  //   if (
                  //     e.target.value === "Yes" &&
                  //     suitabilityAnalysis.explainOwnerIncomeCoverLivingAndMedicalExpenses !==
                  //       ""
                  //   ) {
                  //     CommonContextUpdate(
                  //       "explainOwnerIncomeCoverLivingAndMedicalExpenses",
                  //       ""
                  //     );
                  //   }
                  //   CommonContextUpdate(
                  //     "flagOwnerIncomeCoverLivingAndMedicalExpenses",
                  //     e.target.value
                  //   );
                  // }}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Yes"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"No"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                  <TextField
                    variant="standard"
                    className="radio_group_shift_right"
                    type="text"
                    label="If No, Please Explain"
                    value={
                      suitabilityAnalysis.explainOwnerIncomeCoverLivingAndMedicalExpenses
                    }
                    onChange={(e) => {
                      CommonContextUpdate(
                        "explainOwnerIncomeCoverLivingAndMedicalExpenses",
                        e.target.value
                      );
                    }}
                    disabled={
                      suitabilityAnalysis.flagOwnerIncomeCoverLivingAndMedicalExpenses ===
                        "No"
                        ? false
                        : true
                    }
                  />
                </Box>
                <Box className="second_question" style={{ margin: "10px 0" }}>
                  <Typography variant="span">
                    2. Do you expect changes in your living expenses?
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <RadioGroup
                    className="radio_group_shift_right"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                    }}
                    name="first_question"
                    value={
                      suitabilityAnalysis.flagOwnerExpectChangesInLivingExpenses
                    }
                    onClick={(e) => {
                      if (!e.target.checked) {
                        return;
                      }
                      if (!e.target.value) {
                        CommonContextUpdate(
                          "explainOwnerExpectChangesInLivingExpenses",
                          ""
                        );
                        CommonContextUpdate(
                          "flagOwnerExpectChangesInLivingExpenses",
                          ""
                        );
                        return;
                      }
                      if (
                        e.target.value !=
                        suitabilityAnalysis.flagOwnerExpectChangesInLivingExpenses
                      ) {
                        if (
                          e.target.value === "No" &&
                          suitabilityAnalysis.explainOwnerExpectChangesInLivingExpenses !==
                          ""
                        ) {
                          CommonContextUpdate(
                            "explainOwnerExpectChangesInLivingExpenses",
                            ""
                          );
                        }
                        CommonContextUpdate(
                          "flagOwnerExpectChangesInLivingExpenses",
                          e.target.value
                        );
                      } else {
                        CommonContextUpdate(
                          "flagOwnerExpectChangesInLivingExpenses",
                          ""
                        );
                        CommonContextUpdate(
                          "explainOwnerExpectChangesInLivingExpenses",
                          ""
                        );
                        // CommonContextUpdate("typeOfAccount", "");
                      }
                    }}
                  // onChange={(e) => {
                  //   if (
                  //     e.target.value === "No" &&
                  //     suitabilityAnalysis.explainOwnerExpectChangesInLivingExpenses !==
                  //       ""
                  //   ) {
                  //     CommonContextUpdate(
                  //       "explainOwnerExpectChangesInLivingExpenses",
                  //       ""
                  //     );
                  //   }
                  //   CommonContextUpdate(
                  //     "flagOwnerExpectChangesInLivingExpenses",
                  //     e.target.value
                  //   );
                  // }}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Yes"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"No"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                  <TextField
                    className="radio_group_shift_right"
                    variant="standard"
                    type="text"
                    label="If Yes, Please Explain"
                    value={
                      suitabilityAnalysis.explainOwnerExpectChangesInLivingExpenses
                    }
                    onChange={(e) => {
                      CommonContextUpdate(
                        "explainOwnerExpectChangesInLivingExpenses",
                        e.target.value
                      );
                    }}
                    disabled={
                      suitabilityAnalysis.flagOwnerExpectChangesInLivingExpenses ===
                        "Yes"
                        ? false
                        : true
                    }
                  />
                </Box>
                <Box className="third_question" style={{ margin: "10px 0" }}>
                  <Typography variant="span">
                    3. Do you anticipate changes in your out-of-pocket medical
                    expenses?
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <RadioGroup
                    className="radio_group_shift_right"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                    }}
                    name="first_question"
                    value={
                      suitabilityAnalysis.flagOwnerAnticipateChangesInMedicalExpenses
                    }
                    onClick={(e) => {
                      if (!e.target.checked) {
                        return;
                      }
                      if (!e.target.value) {
                        CommonContextUpdate(
                          "flagOwnerAnticipateChangesInMedicalExpenses",
                          ""
                        );
                        CommonContextUpdate(
                          "explainOwnerAnticipateChangesInMedicalExpenses",
                          ""
                        );
                        return;
                      }
                      if (
                        e.target.value !=
                        suitabilityAnalysis.flagOwnerAnticipateChangesInMedicalExpenses
                      ) {
                        if (
                          e.target.value === "No" &&
                          suitabilityAnalysis.explainOwnerAnticipateChangesInMedicalExpenses !==
                          ""
                        ) {
                          CommonContextUpdate(
                            "explainOwnerAnticipateChangesInMedicalExpenses",
                            ""
                          );
                        }
                        CommonContextUpdate(
                          "flagOwnerAnticipateChangesInMedicalExpenses",
                          e.target.value
                        );
                      } else {
                        CommonContextUpdate(
                          "flagOwnerAnticipateChangesInMedicalExpenses",
                          ""
                        );
                        CommonContextUpdate(
                          "explainOwnerAnticipateChangesInMedicalExpenses",
                          ""
                        );
                        // CommonContextUpdate("typeOfAccount", "");
                      }
                    }}
                  // onChange={(e) => {
                  //   if (
                  //     e.target.value === "No" &&
                  //     suitabilityAnalysis.explainOwnerAnticipateChangesInMedicalExpenses !==
                  //       ""
                  //   ) {
                  //     CommonContextUpdate(
                  //       "explainOwnerAnticipateChangesInMedicalExpenses",
                  //       ""
                  //     );
                  //   }
                  //   CommonContextUpdate(
                  //     "flagOwnerAnticipateChangesInMedicalExpenses",
                  //     e.target.value
                  //   );
                  // }}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Yes"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"No"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                  <TextField
                    className="radio_group_shift_right"
                    variant="standard"
                    type="text"
                    label="If Yes, Please Explain"
                    value={
                      suitabilityAnalysis.explainOwnerAnticipateChangesInMedicalExpenses
                    }
                    onChange={(e) => {
                      CommonContextUpdate(
                        "explainOwnerAnticipateChangesInMedicalExpenses",
                        e.target.value
                      );
                    }}
                    disabled={
                      suitabilityAnalysis.flagOwnerAnticipateChangesInMedicalExpenses ===
                        "Yes"
                        ? false
                        : true
                    }
                  />
                </Box>
                <Box
                  className=" fourth_question_top fourth_question"
                  style={{ margin: "10px 0" }}
                >
                  <Typography variant="span">
                    4. Is your income sufficient to cover future changes in your
                    living and out-of-pocket medical expenses during the
                    surrender charge period?
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <RadioGroup
                    className="radio_group_shift_right"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                    }}
                    name="first_question"
                    value={
                      suitabilityAnalysis.flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod
                    }
                    onClick={(e) => {
                      if (!e.target.checked) {
                        return;
                      }
                      if (!e.target.value) {
                        CommonContextUpdate(
                          "flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod",
                          ""
                        );
                        CommonContextUpdate(
                          "explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod",
                          ""
                        );
                        return;
                      }
                      if (
                        e.target.value !=
                        suitabilityAnalysis.flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod
                      ) {
                        if (
                          e.target.value === "Yes" &&
                          suitabilityAnalysis.explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod !==
                          ""
                        ) {
                          CommonContextUpdate(
                            "explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod",
                            ""
                          );
                        }
                        CommonContextUpdate(
                          "flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod",
                          e.target.value
                        );
                      } else {
                        CommonContextUpdate(
                          "flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod",
                          ""
                        );
                        CommonContextUpdate(
                          "explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod",
                          ""
                        );
                        // CommonContextUpdate("typeOfAccount", "");
                      }
                    }}
                  // onChange={(e) => {
                  //   if (
                  //     e.target.value === "Yes" &&
                  //     suitabilityAnalysis.explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod !==
                  //       ""
                  //   ) {
                  //     CommonContextUpdate(
                  //       "explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod",
                  //       ""
                  //     );
                  //   }
                  //   CommonContextUpdate(
                  //     "flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod",
                  //     e.target.value
                  //   );
                  // }}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Yes"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"No"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                  <TextField
                    className="radio_group_shift_right"
                    variant="standard"
                    type="text"
                    label="If No, Please Explain"
                    value={
                      suitabilityAnalysis.explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod
                    }
                    onChange={(e) => {
                      CommonContextUpdate(
                        "explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod",
                        e.target.value
                      );
                    }}
                    disabled={
                      suitabilityAnalysis.flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
                        "No"
                        ? false
                        : true
                    }
                  />
                </Box>
                <Box className="fifth_question" style={{ margin: "10px 0" }}>
                  <Typography variant="span">
                    5. Do you have an emergency fund for unexpected expenses?
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <RadioGroup
                    className="radio_group_shift_right"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                    }}
                    name="first_question"
                    value={
                      suitabilityAnalysis.flagOwnerHaveEmergencyFundForUnexpectedExpenses
                    }
                    onClick={(e) => {
                      if (!e.target.checked) {
                        return;
                      }
                      if (!e.target.value) {
                        CommonContextUpdate(
                          "flagOwnerHaveEmergencyFundForUnexpectedExpenses",
                          ""
                        );
                        CommonContextUpdate(
                          "explainOwnerHaveEmergencyFundForUnexpectedExpenses",
                          ""
                        );
                        return;
                      }
                      if (
                        e.target.value !=
                        suitabilityAnalysis.flagOwnerHaveEmergencyFundForUnexpectedExpenses
                      ) {
                        if (
                          e.target.value === "Yes" &&
                          suitabilityAnalysis.explainOwnerHaveEmergencyFundForUnexpectedExpenses !==
                          ""
                        ) {
                          CommonContextUpdate(
                            "explainOwnerHaveEmergencyFundForUnexpectedExpenses",
                            ""
                          );
                        }
                        CommonContextUpdate(
                          "flagOwnerHaveEmergencyFundForUnexpectedExpenses",
                          e.target.value
                        );
                      } else {
                        CommonContextUpdate(
                          "flagOwnerHaveEmergencyFundForUnexpectedExpenses",
                          ""
                        );
                        CommonContextUpdate(
                          "explainOwnerHaveEmergencyFundForUnexpectedExpenses",
                          ""
                        );
                        // CommonContextUpdate("typeOfAccount", "");
                      }
                    }}
                  // onChange={(e) => {
                  //   if (
                  //     e.target.value === "Yes" &&
                  //     suitabilityAnalysis.explainOwnerHaveEmergencyFundForUnexpectedExpenses !==
                  //       ""
                  //   ) {
                  //     CommonContextUpdate(
                  //       "explainOwnerHaveEmergencyFundForUnexpectedExpenses",
                  //       ""
                  //     );
                  //   }
                  //   CommonContextUpdate(
                  //     "flagOwnerHaveEmergencyFundForUnexpectedExpenses",
                  //     e.target.value
                  //   );
                  // }}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Yes"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"No"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                  <TextField
                    className="radio_group_shift_right"
                    variant="standard"
                    type="text"
                    label="If No, Please Explain"
                    value={
                      suitabilityAnalysis.explainOwnerHaveEmergencyFundForUnexpectedExpenses
                    }
                    onChange={(e) => {
                      CommonContextUpdate(
                        "explainOwnerHaveEmergencyFundForUnexpectedExpenses",
                        e.target.value
                      );
                    }}
                    disabled={
                      suitabilityAnalysis.flagOwnerHaveEmergencyFundForUnexpectedExpenses ===
                        "No"
                        ? false
                        : true
                    }
                  />
                </Box>
                <Box className="sixth_question" style={{ margin: "10px 0" }}>
                  <Typography variant="span">
                    6. What other investments do you currently own? (Check all
                    that apply)
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <FormGroup row={true} className="radio_group_shift_right">
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Stocks"}
                      control={
                        <Checkbox
                          value={"Stocks"}
                          checked={
                            suitabilityAnalysis.ownerOtherInvestments.indexOf(
                              "Stocks"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.ownerOtherInvestments;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate("ownerOtherInvestments", temp);
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Bonds"}
                      control={
                        <Checkbox
                          value={"Bonds"}
                          checked={
                            suitabilityAnalysis.ownerOtherInvestments.indexOf(
                              "Bonds"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.ownerOtherInvestments;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate("ownerOtherInvestments", temp);
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Mutual Funds"}
                      control={
                        <Checkbox
                          value={"Mutual Funds"}
                          checked={
                            suitabilityAnalysis.ownerOtherInvestments.indexOf(
                              "Mutual Funds"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.ownerOtherInvestments;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate("ownerOtherInvestments", temp);
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Personal Business"}
                      control={
                        <Checkbox
                          value={"Personal Business"}
                          checked={
                            suitabilityAnalysis.ownerOtherInvestments.indexOf(
                              "Personal Business"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.ownerOtherInvestments;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate("ownerOtherInvestments", temp);
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Options"}
                      control={
                        <Checkbox
                          value={"Options"}
                          checked={
                            suitabilityAnalysis.ownerOtherInvestments.indexOf(
                              "Options"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.ownerOtherInvestments;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate("ownerOtherInvestments", temp);
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Real Estate"}
                      control={
                        <Checkbox
                          value={"Real Estate"}
                          checked={
                            suitabilityAnalysis.ownerOtherInvestments.indexOf(
                              "Real Estate"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.ownerOtherInvestments;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate("ownerOtherInvestments", temp);
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Partnerships"}
                      control={
                        <Checkbox
                          value={"Partnerships"}
                          checked={
                            suitabilityAnalysis.ownerOtherInvestments.indexOf(
                              "Partnerships"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.ownerOtherInvestments;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate("ownerOtherInvestments", temp);
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Other"}
                      control={
                        <Checkbox
                          value={"Other"}
                          checked={
                            suitabilityAnalysis.ownerOtherInvestments.indexOf(
                              "Other"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.ownerOtherInvestments;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                              CommonContextUpdate(
                                "otherOwnerInvestmentField",
                                ""
                              );
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate("ownerOtherInvestments", temp);
                          }}
                        />
                      }
                    />
                    <TextField
                      variant="standard"
                      type="text"
                      disabled={
                        suitabilityAnalysis.ownerOtherInvestments.indexOf(
                          "Other"
                        ) < 0
                          ? true
                          : false
                      }
                      value={suitabilityAnalysis.otherOwnerInvestmentField}
                      onChange={(e) => {
                        CommonContextUpdate(
                          "otherOwnerInvestmentField",
                          e.target.value
                        );
                      }}
                    />
                  </FormGroup>
                </Box>
                <Box className="seventh_question" style={{ margin: "10px 0" }}>
                  <Typography variant="span">
                    7. Please describe your risk tolerance:
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <RadioGroup
                    className="radio_group_shift_right"
                    value={suitabilityAnalysis.ownerRiskTolerance}
                    onClick={(e) => {
                      if (!e.target.checked) {
                        return;
                      }
                      if (!e.target.value) {
                        CommonContextUpdate("ownerRiskTolerance", "");
                        return;
                      }
                      if (
                        e.target.value != suitabilityAnalysis.ownerRiskTolerance
                      ) {
                        CommonContextUpdate(
                          "ownerRiskTolerance",
                          e.target.value
                        );
                      } else {
                        CommonContextUpdate("ownerRiskTolerance", "");

                        // CommonContextUpdate("typeOfAccount", "");
                      }
                    }}
                    // onChange={(e) => {
                    //   CommonContextUpdate("ownerRiskTolerance", e.target.value);
                    // }}
                    row={true}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Conservative"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Conservative"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Moderately Conservative"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Moderately Conservative"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Moderate"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Moderate"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Moderately Aggressive"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Moderately Aggressive"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Aggressive"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Aggressive"
                    />
                  </RadioGroup>
                </Box>
              </Box>
            ) : null}

            <Box
              className="suitability_section_head_root"
              onClick={() => {
                // setFlagFourthSection(!flagSecondPageFourthSection)
                SetApplicationFormFlagSectionData({
                  type: "Suitability Analysis",
                  section: "flagSecondPageFourthSection",
                  data: !flagSecondPageFourthSection,
                });
              }}
              sx={{ marginTop: 0 }}
            >
              <Box className="section_open_close">
                <Typography variant="span">4. The Product Purchase</Typography>
              </Box>
              <Box className="section_open_close">
                {flagSecondPageFourthSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagSecondPageFourthSection === true ? (
              <Box className="the_product_purchase" style={{ width: "80%" }}>
                <Box
                  className="reason_for_purchasing_product"
                  style={{ margin: "10px 0 0 0" }}
                >
                  <Typography variant="span">
                    Reason(s) for purchasing this product (Check all that apply)
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <FormGroup row={true} className="radio_group_shift_right">
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Asset accumulation"}
                      control={
                        <Checkbox
                          value={"Asset accumulation"}
                          checked={
                            suitabilityAnalysis.reasonForPurchasingProduct.indexOf(
                              "Asset accumulation"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.reasonForPurchasingProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "reasonForPurchasingProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Tax deferred growth"}
                      control={
                        <Checkbox
                          value={"Tax deferred growth"}
                          checked={
                            suitabilityAnalysis.reasonForPurchasingProduct.indexOf(
                              "Tax deferred growth"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.reasonForPurchasingProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "reasonForPurchasingProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Immediate income"}
                      control={
                        <Checkbox
                          value={"Immediate income"}
                          checked={
                            suitabilityAnalysis.reasonForPurchasingProduct.indexOf(
                              "Immediate income"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.reasonForPurchasingProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "reasonForPurchasingProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Future retirement income"}
                      control={
                        <Checkbox
                          value={"Future retirement income"}
                          checked={
                            suitabilityAnalysis.reasonForPurchasingProduct.indexOf(
                              "Future retirement income"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.reasonForPurchasingProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "reasonForPurchasingProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Guaranteed interest rate"}
                      control={
                        <Checkbox
                          value={"Guaranteed interest rate"}
                          checked={
                            suitabilityAnalysis.reasonForPurchasingProduct.indexOf(
                              "Guaranteed interest rate"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.reasonForPurchasingProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "reasonForPurchasingProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Transfer to heirs"}
                      control={
                        <Checkbox
                          value={"Transfer to heirs"}
                          checked={
                            suitabilityAnalysis.reasonForPurchasingProduct.indexOf(
                              "Transfer to heirs"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.reasonForPurchasingProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "reasonForPurchasingProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Safety of principal"}
                      control={
                        <Checkbox
                          value={"Safety of principal"}
                          checked={
                            suitabilityAnalysis.reasonForPurchasingProduct.indexOf(
                              "Safety of principal"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.reasonForPurchasingProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "reasonForPurchasingProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Other"}
                      control={
                        <Checkbox
                          value={"Other"}
                          checked={
                            suitabilityAnalysis.reasonForPurchasingProduct.indexOf(
                              "Other"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.reasonForPurchasingProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });

                              CommonContextUpdate(
                                "otherRasonForPurchasingThisProduct",
                                ""
                              );
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "reasonForPurchasingProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <TextField
                      variant="standard"
                      type="text"
                      disabled={
                        suitabilityAnalysis.reasonForPurchasingProduct.indexOf(
                          "Other"
                        ) < 0
                          ? true
                          : false
                      }
                      value={
                        suitabilityAnalysis.otherRasonForPurchasingThisProduct
                      }
                      onChange={(e) => {
                        CommonContextUpdate(
                          "otherRasonForPurchasingThisProduct",
                          e.target.value
                        );
                      }}
                    />
                  </FormGroup>
                </Box>
                <Box
                  className="first_question_sutability first_question1"
                  style={{ margin: "10px 0 10px 0" }}
                >
                  <Typography variant="span">
                    1. Do you plan to withdraw any portion of the funds in this
                    product during the surrender charge period?
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <RadioGroup
                    value={
                      suitabilityAnalysis.flagOwnerPlanToWithdrawFundInSurrenderPeriod
                    }
                    className="first_question_btn radio_group_shift_right"
                    onClick={(e) => {
                      if (!e.target.checked) {
                        return;
                      }
                      if (!e.target.value) {
                        CommonContextUpdate(
                          "explainOwnerPlanToWithdrawFundInSurrenderPeriod",
                          ""
                        );
                        CommonContextUpdate(
                          "flagOwnerPlanToWithdrawFundInSurrenderPeriod",
                          ""
                        );
                        return;
                      }
                      if (
                        e.target.value !=
                        suitabilityAnalysis.flagOwnerPlanToWithdrawFundInSurrenderPeriod
                      ) {
                        if (e.target.value === "No") {
                          CommonContextUpdate(
                            "explainOwnerPlanToWithdrawFundInSurrenderPeriod",
                            ""
                          );
                        }
                        CommonContextUpdate(
                          "flagOwnerPlanToWithdrawFundInSurrenderPeriod",
                          e.target.value
                        );
                      } else {
                        CommonContextUpdate(
                          "explainOwnerPlanToWithdrawFundInSurrenderPeriod",
                          ""
                        );
                        CommonContextUpdate(
                          "flagOwnerPlanToWithdrawFundInSurrenderPeriod",
                          ""
                        );
                        // CommonContextUpdate("typeOfAccount", "");
                      }
                    }}
                    // onChange={(e) => {
                    //   if (e.target.value === "No") {
                    //     CommonContextUpdate(
                    //       "explainOwnerPlanToWithdrawFundInSurrenderPeriod",
                    //       ""
                    //     );
                    //   }
                    //   CommonContextUpdate(
                    //     "flagOwnerPlanToWithdrawFundInSurrenderPeriod",
                    //     e.target.value
                    //   );
                    // }}
                    row={true}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Yes"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"No"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                  <TextField
                    className="radio_group_shift_right"
                    value={
                      suitabilityAnalysis.explainOwnerPlanToWithdrawFundInSurrenderPeriod
                    }
                    variant="standard"
                    onChange={(e) => {
                      CommonContextUpdate(
                        "explainOwnerPlanToWithdrawFundInSurrenderPeriod",
                        e.target.value
                      );
                    }}
                    label="If Yes, Please Explain"
                    disabled={
                      suitabilityAnalysis.flagOwnerPlanToWithdrawFundInSurrenderPeriod ===
                        "Yes"
                        ? false
                        : true
                    }
                  />
                </Box>
                <Box className="second_question" style={{ margin: "20px 0" }}>
                  <Typography variant="span">
                    2. When do you anticipate needing access to these funds?
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <Select
                    className="radio_group_shift_right"
                    disabled={disableAllInputs === true ? true : false}
                    placeholder="Select"
                    value={
                      suitabilityAnalysis.ownerAnticipateNeedingAccessToFunds
                    }
                    onChange={(e) => {
                      CommonContextUpdate(
                        "ownerAnticipateNeedingAccessToFunds",
                        e.target.value
                      );
                    }}
                    variant="standard"
                    style={{ width: "100%" }}
                  >
                    <MenuItem value={"0-2 years from now"}>
                      0-2 years from now
                    </MenuItem>
                    <MenuItem value={"3-4 years from now"}>
                      3-4 years from now
                    </MenuItem>
                    <MenuItem value={"5-6 years from now"}>
                      5-6 years from now
                    </MenuItem>
                    <MenuItem value={"7-8 years from now"}>
                      7-8 years from now
                    </MenuItem>
                    <MenuItem value={"9+ years from now"}>
                      9+ years from now
                    </MenuItem>
                  </Select>
                </Box>

                <Box
                  className="third_question"
                  style={{ marginTop: "20px", marginBottom: "0px" }}
                >
                  <Typography variant="span">
                    3. Source of funds used to purchase this product (Check all
                    that apply)
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <FormGroup row={true} className="radio_group_shift_right">
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"CD/Savings/Checking"}
                      control={
                        <Checkbox
                          value={"CD/Savings/Checking"}
                          checked={
                            suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct.indexOf(
                              "CD/Savings/Checking"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "sourceOfFundsToPurchaseThisProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Inheritance"}
                      control={
                        <Checkbox
                          value={"Inheritance"}
                          checked={
                            suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct.indexOf(
                              "Inheritance"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "sourceOfFundsToPurchaseThisProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Rollover/transfer from qualified account"}
                      control={
                        <Checkbox
                          value={"Rollover/transfer from qualified account"}
                          checked={
                            suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct.indexOf(
                              "Rollover/transfer from qualified account"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "sourceOfFundsToPurchaseThisProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Liquidation of assets"}
                      control={
                        <Checkbox
                          value={"Liquidation of assets"}
                          checked={
                            suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct.indexOf(
                              "Liquidation of assets"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "sourceOfFundsToPurchaseThisProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Death benefit proceeds"}
                      control={
                        <Checkbox
                          value={"Death benefit proceeds"}
                          checked={
                            suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct.indexOf(
                              "Death benefit proceeds"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "sourceOfFundsToPurchaseThisProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Qualified plan distribution"}
                      control={
                        <Checkbox
                          value={"Qualified plan distribution"}
                          checked={
                            suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct.indexOf(
                              "Qualified plan distribution"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "sourceOfFundsToPurchaseThisProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Cash value from existing annuity"}
                      control={
                        <Checkbox
                          value={"Cash value from existing annuity"}
                          checked={
                            suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct.indexOf(
                              "Cash value from existing annuity"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "sourceOfFundsToPurchaseThisProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Current income"}
                      control={
                        <Checkbox
                          value={"Current income"}
                          checked={
                            suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct.indexOf(
                              "Current income"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "sourceOfFundsToPurchaseThisProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      label={"Other"}
                      control={
                        <Checkbox
                          value={"Other"}
                          checked={
                            suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct.indexOf(
                              "Other"
                            ) < 0
                              ? false
                              : true
                          }
                          onChange={(e) => {
                            console.log(e.target.checked);
                            let temp =
                              suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct;
                            if (e.target.checked === false) {
                              temp = temp.filter((v) => {
                                if (v !== e.target.value) {
                                  return v;
                                }
                              });

                              CommonContextUpdate(
                                "otherSourceOfFundsToPurchaseThisProduct",
                                ""
                              );
                            } else {
                              temp.push(e.target.value);
                            }

                            console.log("context", temp);
                            CommonContextUpdate(
                              "sourceOfFundsToPurchaseThisProduct",
                              temp
                            );
                          }}
                        />
                      }
                    />
                    <TextField
                      variant="standard"
                      type="text"
                      disabled={
                        (suitabilityAnalysis.sourceOfFundsToPurchaseThisProduct.indexOf("Other") < 0 || disableAllInputs === true)
                          ? true
                          : false
                      }
                      value={
                        suitabilityAnalysis.otherSourceOfFundsToPurchaseThisProduct
                      }
                      onChange={(e) => {
                        CommonContextUpdate(
                          "otherSourceOfFundsToPurchaseThisProduct",
                          e.target.value
                        );
                      }}
                    />
                  </FormGroup>
                </Box>
              </Box>
            ) : null}

            <Box
              className=" suitability_section_head_root_top suitability_section_head_root"
              onClick={() => {
                // setFlagFifthSection(!flagSecondPageFifthSection)
                SetApplicationFormFlagSectionData({
                  type: "Suitability Analysis",
                  section: "flagSecondPageFifthSection",
                  data: !flagSecondPageFifthSection,
                });
              }}
              sx={{ marginTop: 0 }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  5. Existing Account Information
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagSecondPageFifthSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagSecondPageFifthSection === true ? (
              <Box
                className="existing_account_information"
                style={{ width: "80%" }}
              >
                <Box
                  className=" first_question_bottom first_question_sutability"
                  style={{ margin: "10px 0 10px 0" }}
                >
                  <Typography variant="span">
                    1. Do you plan to use funds from an existing life or annuity
                    policy to purchase this product?
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <RadioGroup
                    className="radio_group_shift_right_existing"
                    value={
                      suitabilityAnalysis.flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct
                    }
                    onChange={(e) => {
                      CommonContextUpdate(
                        "flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct",
                        e.target.value
                      );
                      if (e.target.value === "No (go to section 6)") {
                        CommonContextUpdate(
                          "howLongHasTheLifeInsuranceBeenInForce",
                          ""
                        );
                        CommonContextUpdate(
                          "flagSurrenderChargeOnExistingProduct",
                          ""
                        );
                        CommonContextUpdate(
                          "currentSurrenderChargeOnExistingProduct",
                          ""
                        );
                        CommonContextUpdate(
                          "expirationOfCurrentSurrenderCharge",
                          ""
                        );
                        CommonContextUpdate("typeOfExistingProduct", "");
                        CommonContextUpdate(
                          "interestRateForExistingProduct",
                          ""
                        );
                        CommonContextUpdate(
                          "flagTakingRequiredMinimumDistributionsFromExistingProduct",
                          ""
                        );
                        CommonContextUpdate(
                          "flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution",
                          ""
                        );
                        CommonContextUpdate(
                          "explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution",
                          ""
                        );
                      }
                    }}
                    row={true}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Yes (complete the rest of section 5)"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Yes (complete the rest of section 5)"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"No (go to section 6)"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="No (go to section 6)"
                    />
                  </RadioGroup>
                </Box>
                {suitabilityAnalysis.flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct ===
                  "Yes (complete the rest of section 5)" && (
                    <Fragment>
                      <Box
                        className="second_question"
                        style={{ margin: "20px 0" }}
                      >
                        <Typography
                          variant="span"
                          style={{ display: "flex", alignItems: "center" }}
                          className="second_question_1"
                        >
                          <span>
                            2. How long has the life insurance or annuity been in
                            force?
                            <span style={{ color: "red" }} color="red">
                              *
                            </span>
                          </span>
                          <TextField
                            type="text"
                            style={{ fontWeight: "normal", paddingLeft: "5px" }}
                            variant="standard"
                            className="second_question_text_field"
                            value={
                              suitabilityAnalysis.howLongHasTheLifeInsuranceBeenInForce
                            }
                            onChange={(e) => {
                              CommonContextUpdate(
                                "howLongHasTheLifeInsuranceBeenInForce",
                                e.target.value
                              );
                            }}
                          />
                          <span className="second_question_2">Years</span>
                        </Typography>
                      </Box>
                      <Box
                        className="third_question"
                        style={{ margin: "20px 0" }}
                      >
                        <Typography>
                          3. Is there a surrender charge on the existing product?
                          <span style={{ color: "red" }} color="red">
                            *
                          </span>
                        </Typography>
                        <RadioGroup
                          className="radio_group_shift_right_existing"
                          value={
                            suitabilityAnalysis.flagSurrenderChargeOnExistingProduct
                          }
                          onChange={(e) => {
                            if (e.target.value === "No") {
                              CommonContextUpdate(
                                "currentSurrenderChargeOnExistingProduct",
                                ""
                              );
                              CommonContextUpdate(
                                "expirationOfCurrentSurrenderCharge",
                                ""
                              );
                            }
                            CommonContextUpdate(
                              "flagSurrenderChargeOnExistingProduct",
                              e.target.value
                            );
                          }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Yes"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"No"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                        <Box
                          className="third_question_proper"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="span"
                            className="third_question_proper1 radio_group_shift_right_existing"
                          >
                            <span>If Yes, What is the current charge?</span>
                            <Input
                              variant="standard"
                              className="third_question_proper2"
                              type="text"
                              value={
                                suitabilityAnalysis.currentSurrenderChargeOnExistingProduct
                              }
                              onChange={(e) => {
                                CommonContextUpdate(
                                  "currentSurrenderChargeOnExistingProduct",
                                  e.target.value
                                );
                              }}
                              style={{ paddingLeft: "10px" }}
                              disabled={
                                suitabilityAnalysis.flagSurrenderChargeOnExistingProduct ===
                                  "Yes"
                                  ? false
                                  : true
                              }
                              endAdornment={
                                <InputAdornment position="end">%</InputAdornment>
                              }
                            />
                          </Typography>
                          <Typography variant="span" className="radio_group_shift_right_existing">
                            <span>When will it expire?</span>
                            <TextField
                              style={{ paddingLeft: "10px" }}
                              variant="standard"
                              type="text"
                              className="third_question_proper2"
                              value={
                                suitabilityAnalysis.expirationOfCurrentSurrenderCharge
                              }
                              onChange={(e) => {
                                CommonContextUpdate(
                                  "expirationOfCurrentSurrenderCharge",
                                  e.target.value
                                );
                              }}
                              disabled={
                                suitabilityAnalysis.flagSurrenderChargeOnExistingProduct ===
                                  "Yes"
                                  ? false
                                  : true
                              }
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        className="fourth_question"
                        style={{ margin: "20px 0" }}
                      >
                        <Typography variant="span">
                          4. What type is the existing product?
                          <span style={{ color: "red" }} color="red">
                            *
                          </span>
                        </Typography>
                        <RadioGroup
                          className="radio_group_shift_right_existing"
                          value={suitabilityAnalysis.typeOfExistingProduct}
                          onChange={(e) => {
                            CommonContextUpdate(
                              "typeOfExistingProduct",
                              e.target.value
                            );
                          }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Life Insurance"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Life Insurance"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Fixed Annuity"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Fixed Annuity"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Variable Annuity"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Variable Annuity"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Equity Indexed Annuity"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Equity Indexed Annuity"
                          />
                        </RadioGroup>
                      </Box>
                      <Box
                        className="fifth_question"
                        style={{ margin: "20px 0" }}
                      >
                        <Typography variant="span">
                          <span>
                            5. What interest rate is currently being credited to
                            the existing product?
                            <span style={{ color: "red" }} color="red">
                              *
                            </span>
                          </span>
                          <Input
                            type="text"
                            onChange={(e) => {
                              CommonContextUpdate(
                                "interestRateForExistingProduct",
                                e.target.value
                              );
                            }}
                            value={
                              suitabilityAnalysis.interestRateForExistingProduct
                            }
                            style={{ paddingLeft: "5px" }}
                            endAdornment={
                              isNaN(
                                suitabilityAnalysis.interestRateForExistingProduct
                              ) === false && (
                                <InputAdornment position="end">%</InputAdornment>
                              )
                            }
                          />
                        </Typography>
                      </Box>
                      <Typography variant="span" style={{ margin: "10px 0" }}>
                        Please describe any expected change(s) to this rate in the
                        additional information section below.
                      </Typography>
                      <Box
                        className="sixth_question"
                        style={{ margin: "20px 0" }}
                      >
                        <Typography variant="span">
                          6. If the existing product is a tax-qualified annuity,
                          are you taking Required Minimum Distributions from it?
                          <span style={{ color: "red" }} color="red">
                            *
                          </span>
                        </Typography>
                        <RadioGroup
                          className="radio_group_shift_right_existing"
                          value={
                            suitabilityAnalysis.flagTakingRequiredMinimumDistributionsFromExistingProduct
                          }
                          onChange={(e) => {
                            CommonContextUpdate(
                              "flagTakingRequiredMinimumDistributionsFromExistingProduct",
                              e.target.value
                            );
                          }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Yes"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"No"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="No"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Not Required due to age"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Not Required due to age"
                          />
                          {/* <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Not applicable"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Not applicable"
                          /> */}
                        </RadioGroup>
                      </Box>
                      <Box
                        className="seventh_question "
                        style={{ margin: "10px 0" }}
                      >
                        <Typography variant="span">
                          7. If the existing product is an annuity, are you taking
                          any other periodic distributions from the existing
                          annuity?
                          <span style={{ color: "red" }} color="red">
                            *
                          </span>
                        </Typography>
                        <RadioGroup
                          className="radio_group_shift_right_existing"
                          value={
                            suitabilityAnalysis.flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution
                          }
                          onChange={(e) => {
                            if (
                              e.target.value === "No" &&
                              suitabilityAnalysis.explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution !==
                              ""
                            ) {
                              CommonContextUpdate(
                                "explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution",
                                ""
                              );
                            }
                            CommonContextUpdate(
                              "flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution",
                              e.target.value
                            );
                          }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Yes"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"No"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="No"
                          />
                          {/* <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Not applicable"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Not applicable"
                          /> */}
                        </RadioGroup>
                        <TextField
                          className="radio_group_shift_right_existing"
                          label="If Yes, Please describe"
                          variant="standard"
                          value={
                            suitabilityAnalysis.explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution
                          }
                          onChange={(e) => {
                            CommonContextUpdate(
                              "explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution",
                              e.target.value
                            );
                          }}
                          disabled={
                            suitabilityAnalysis.flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution ===
                              "Yes"
                              ? false
                              : true
                          }
                        />
                      </Box>
                    </Fragment>
                  )}
              </Box>
            ) : null}

            <Box
              className="suitability_section_head_root"
              onClick={() => {
                // setFlagSixthSection(!flagSecondPageSixthSection)
                SetApplicationFormFlagSectionData({
                  type: "Suitability Analysis",
                  section: "flagSecondPageSixthSection",
                  data: !flagSecondPageSixthSection,
                });
              }}
              sx={{ marginTop: 0 }}
            >
              <Box className="section_open_close">
                <Typography variant="span">6. Other</Typography>
              </Box>
              <Box className="section_open_close">
                {flagSecondPageSixthSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagSecondPageSixthSection === true ? (
              <Box className="other" style={{ width: "80%" }}>
                <Typography style={{ marginTop: "10px" }}>
                  Which, if any, of the following persons assisted you in your
                  decision to purchase this product? (Check all that apply)
                </Typography>
                <FormGroup row={true} style={{ margin: "10px 0px 10px 15px" }}>
                  <FormControlLabel
                    disabled={disableAllInputs === true ? true : false}
                    label={"None"}
                    control={
                      <Checkbox
                        value={"None"}
                        checked={
                          suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct.indexOf(
                            "None"
                          ) < 0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          console.log(e.target.checked);
                          let temp =
                            suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct;
                          if (e.target.checked === false) {
                            temp = temp.filter((v) => {
                              if (v !== e.target.value) {
                                return v;
                              }
                            });
                          } else {
                            temp.push(e.target.value);
                          }

                          console.log("context", temp);
                          CommonContextUpdate(
                            "personsThatAssistedOwnerToPurchaseThisProduct",
                            temp
                          );
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    disabled={disableAllInputs === true ? true : false}
                    label={"Accountant"}
                    control={
                      <Checkbox
                        value={"Accountant"}
                        checked={
                          suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct.indexOf(
                            "Accountant"
                          ) < 0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          console.log(e.target.checked);
                          let temp =
                            suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct;
                          if (e.target.checked === false) {
                            temp = temp.filter((v) => {
                              if (v !== e.target.value) {
                                return v;
                              }
                            });
                          } else {
                            temp.push(e.target.value);
                          }

                          console.log("context", temp);
                          CommonContextUpdate(
                            "personsThatAssistedOwnerToPurchaseThisProduct",
                            temp
                          );
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    disabled={disableAllInputs === true ? true : false}
                    label={"Attorney"}
                    control={
                      <Checkbox
                        value={"Attorney"}
                        checked={
                          suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct.indexOf(
                            "Attorney"
                          ) < 0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          console.log(e.target.checked);
                          let temp =
                            suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct;
                          if (e.target.checked === false) {
                            temp = temp.filter((v) => {
                              if (v !== e.target.value) {
                                return v;
                              }
                            });
                          } else {
                            temp.push(e.target.value);
                          }

                          console.log("context", temp);
                          CommonContextUpdate(
                            "personsThatAssistedOwnerToPurchaseThisProduct",
                            temp
                          );
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    disabled={disableAllInputs === true ? true : false}
                    label={"Family Member"}
                    control={
                      <Checkbox
                        value={"Family Member"}
                        checked={
                          suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct.indexOf(
                            "Family Member"
                          ) < 0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          console.log(e.target.checked);
                          let temp =
                            suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct;
                          if (e.target.checked === false) {
                            temp = temp.filter((v) => {
                              if (v !== e.target.value) {
                                return v;
                              }
                            });
                          } else {
                            temp.push(e.target.value);
                          }

                          console.log("context", temp);
                          CommonContextUpdate(
                            "personsThatAssistedOwnerToPurchaseThisProduct",
                            temp
                          );
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    disabled={disableAllInputs === true ? true : false}
                    label={"Financial Planner"}
                    control={
                      <Checkbox
                        value={"Financial Planner"}
                        checked={
                          suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct.indexOf(
                            "Financial Planner"
                          ) < 0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          console.log(e.target.checked);
                          let temp =
                            suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct;
                          if (e.target.checked === false) {
                            temp = temp.filter((v) => {
                              if (v !== e.target.value) {
                                return v;
                              }
                            });
                          } else {
                            temp.push(e.target.value);
                          }

                          console.log("context", temp);
                          CommonContextUpdate(
                            "personsThatAssistedOwnerToPurchaseThisProduct",
                            temp
                          );
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    disabled={disableAllInputs === true ? true : false}
                    label={"Other (please specify)"}
                    control={
                      <Checkbox
                        value={"Other (please specify)"}
                        checked={
                          suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct.indexOf(
                            "Other (please specify)"
                          ) < 0
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          console.log(e.target.checked);
                          let temp =
                            suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct;
                          if (e.target.checked === false) {
                            temp = temp.filter((v) => {
                              if (v !== e.target.value) {
                                return v;
                              }
                            });
                            temp = temp.filter((v) => {
                              if (v === "None") return v;
                              else if (v === "Accountant") return v;
                              else if (v === "Attorney") return v;
                              else if (v === "Family Member") return v;
                              else if (v === "Financial Planner") {
                                return v;
                              }
                            });
                            CommonContextUpdate(
                              "otherPersonsThatAssistedOwnerToPurchaseThisProduct",
                              ""
                            );
                          } else {
                            temp.push(e.target.value);
                          }

                          console.log("context", temp);
                          CommonContextUpdate(
                            "personsThatAssistedOwnerToPurchaseThisProduct",
                            temp
                          );
                        }}
                      />
                    }
                  />
                  <TextField
                    variant="standard"
                    type="text"
                    disabled={
                      suitabilityAnalysis.personsThatAssistedOwnerToPurchaseThisProduct.indexOf(
                        "Other (please specify)"
                      ) < 0
                        ? true
                        : false
                    }
                    value={
                      suitabilityAnalysis.otherPersonsThatAssistedOwnerToPurchaseThisProduct
                    }
                    onChange={(e) => {
                      CommonContextUpdate(
                        "otherPersonsThatAssistedOwnerToPurchaseThisProduct",
                        e.target.value
                      );
                    }}
                  />
                </FormGroup>
                <TextField
                  className="label_field radio_group_shift_right"
                  variant="standard"
                  label="Please enter any additional relevant information to be considered in determining suitability of this product: "
                  value={
                    suitabilityAnalysis.additionalRelaventInformationForSuitabilityAnalysis
                  }
                  onChange={(e) => {
                    CommonContextUpdate(
                      "additionalRelaventInformationForSuitabilityAnalysis",
                      e.target.value
                    );
                  }}
                  style={{ width: "100%" }}
                />
              </Box>
            ) : null}

            <Box
              className="suitability_section_head_root"
              onClick={() => {
                SetApplicationFormFlagSectionData({
                  type: "Suitability Analysis",
                  section: "flagSecondPageSeventhSection",
                  data: !flagSecondPageSeventhSection,
                });
              }}
              sx={{ marginBottom: "10px", marginTop: 0 }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  7. Agent's Acknowledgment
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagSecondPageSeventhSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>

            {flagSecondPageSeventhSection === true ? (
              <Box className="other" style={{ width: "80%" }}>
                <Typography variant="span" style={{ margin: "20px 0" }}>
                  During the solicitation of this product, Did you use any sales
                  materials other than pre-printed product brochures, and other
                  material provided by the Company.
                  <span style={{ color: "red" }} color="red">
                    *
                  </span>
                </Typography>
                <RadioGroup
                  className="radio_group_shift_right"
                  value={suitabilityAnalysis.agentAcknowledgment}
                  onClick={(e) => {
                    // debugger;
                    if (!e.target.checked) {
                      return;
                    }
                    if (!e.target.value) {
                      CommonContextUpdate("agentAcknowledgment", "");

                      return;
                    }
                    if (
                      e.target.value != suitabilityAnalysis.agentAcknowledgment
                    ) {
                      CommonContextUpdate(
                        "agentAcknowledgment",
                        e.target.value
                      );
                    } else {
                      CommonContextUpdate("agentAcknowledgment", "");

                      // CommonContextUpdate("typeOfAccount", "");
                    }
                    console.log(
                      "1111",
                      suitabilityAnalysis.agentAcknowledgment
                    );
                  }}
                  // onChange={(e) => {
                  //   CommonContextUpdate("agentAcknowledgment", e.target.value);
                  // }}
                  row={true}
                >
                  <FormControlLabel
                    disabled={disableAllInputs}
                    control={
                      <Radio
                        value="Yes"
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                      />
                    }
                    label="Yes"
                  ></FormControlLabel>
                  <FormControlLabel
                    disabled={disableAllInputs}
                    control={
                      <Radio
                        value="No"
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                      />
                    }
                    label="No"
                  ></FormControlLabel>
                </RadioGroup>
              </Box>
            ) : null}

            <Box
              className="previous_next_btn"
              style={{
                margin: "25px 0",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                className="btn_prev"
                startIcon={<KeyboardDoubleArrowLeftIcon />}
                // onClick={() => {
                //     props.previousPage();
                // }}
                onClick={() => {
                  ConditionToShowReplacementForm() === true
                    ? annuitantState === "Oklahoma"
                      ? props.changePageIndex(-2)
                      : props.changePageIndex(1)
                    : props.changePageIndex(2);
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Previous
              </Button>
              <Button
                className="btn_next"
                endIcon={<DoubleArrowIcon />}
                onClick={() => {
                  if (ConditionToShowTransferExchangeForm()) {
                    props.nextPage();
                  } else {
                    props.eftPage();
                  }
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                {ConditionToShowTransferExchangeForm()
                  ? "Next - Transfer or Exchange Request"
                  : "Next - Safeguard Plus® Annuity Disclosure"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default SuitabilityAnalysis;
