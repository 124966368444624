import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Context as userLoaderContext } from './context/userLoaderContext';
import Loader from './components/loader/loader';
import { UserDetailAPI } from './api/userAPI';
import './app.css';
import { useSearchParams } from 'react-router-dom';
import Routes1 from "./routes.jsx"


const App = (props) => {

    const { state } = useContext(userLoaderContext);
    const { SetUser, SetFlagLoader, SetUserType } = useContext(userLoaderContext);
    const [userTypeState, setUserTypeState] = useState(null);

    const [flagAuthorised, setFlagAuthorised] = useState(null);
    const [searchParams] = useSearchParams();
    const location = useLocation();

    useEffect(() => {
        // SetFlagLoader(true);
        let domain = window.location.host;
        let protocol = window.location.protocol;
        // if (window.location.search.includes("public_url_id") === false) {
        if (searchParams.has("public_url_id") === false) {
            UserDetailAPI((res) => {
                console.log("Printing response of user detail", res);
                // SetFlagLoader(false);
                if (res.data.status === true) {
                    setFlagAuthorised(true);
                    let pathname = window.location.pathname;
                    let role = res.data.data.roles.map((v) => {
                        console.log("Printing each roles in user data", v.user_type);
                        return v.user_type;
                    });

                    console.log("Printing final role value", role);

                    if (role.indexOf("Agent") >= 0) {
                        SetUserType("Agent");


                        setUserTypeState("Agent");
                        if (pathname === '/register' || pathname === '/login') {
                            //  debugger
                            window.location.replace(`${protocol}//${domain}/myga_application_history`)
                        }
                        else {
                            setFlagAuthorised(true)
                            SetUser(res.data.data);
                        }
                    }
                    else {
                        console.log("Printing role", role);
                        // debugger;


                        SetUserType("Admin");
                        setUserTypeState("Admin ");
                        if (pathname === '/register' || pathname === '/login') {
                            window.location.replace(`${protocol}//${domain}/myga_application_history`)
                        }
                        else {
                            setFlagAuthorised(true)
                            SetUser(res.data.data);
                        }

                    }
                    // SetFlagLoader(false)
                    // else if(role.indexOf("Admin1") >= 0){
                    //     console.log("Printing role", role);
                    //     // debugger;
                    //     SetUserType("Admin1");
                    //     setUserTypeState("Admin1");
                    //     if(pathname === '/register' || pathname === '/login'){
                    //         window.location.replace(`${protocol}//${domain}/calculator-config`)
                    //     }
                    //     else{
                    //         SetUser(res.data.data);
                    //     }
                    // }
                }

            }, (err) => {
                let pathname = window.location.pathname;
                if (pathname.includes('/register') || pathname.includes("/forgot-password") || pathname.includes("/resetPassword")) {
                    setFlagAuthorised(false);
                    setUserTypeState("")
                }
                else {
                    // window.location.replace(`/login`);
                    if (pathname !== '/login') {
                        setFlagAuthorised(false);
                        window.location.replace(`${protocol}//${domain}/login`);
                    }
                    else {
                        setUserTypeState("")
                        setFlagAuthorised(false);
                    }
                }
                // SetFlagLoader(false);
                console.log("Got error for user detail", err);
            })
        }
    }, [])

    return (
        <Fragment>
            {
                state.flagLoader === true && <Loader />
            }
            {flagAuthorised != null && userTypeState != null && <Routes1
                userTypeState={userTypeState}
                // SetSearchParams={searchParams}
                flagAuthorised={flagAuthorised}
            />}
        </Fragment>
    )
};

export default App;