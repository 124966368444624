import React, { Fragment, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Context } from "../../../context/V1/newApplicationFormContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import "./annuityDisclosureForm.css";

const AnnuityDisclosureForm = (props) => {
  const { SetAnnuityDisclosureForm } = useContext(Context);
  const {
    annuityDisclosureForm,
    disableAllInputs,
    individualAnnuityApplication,
  } = useContext(Context).state;

  const CommonContextUpdate = (type, value) => {
    SetAnnuityDisclosureForm({
      type: type,
      value: value,
    });
  };

  const ConditionToShowTransferExchangeForm = () => {
    // if((individualAnnuityApplication.flagHaveExistingLifeInsurance === "Yes" || individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance === "Yes")){
    //     return true;
    // }
    // else{
    // return false;
    // }
  };

  useEffect(() => {
    console.log("Printing notice of replacement, ", annuityDisclosureForm);
  }, [annuityDisclosureForm]);

  return (
    <Fragment>
      <Box className="transfer_exchange_request_page_root">
        <Box className="application_form">
          <Box className="application_form_card">
            <Box className="application_form_head">
              <Box>
                <Typography variant="span">
                  Safeguard Plus® Annuity Disclosure
                </Typography>
              </Box>
              <Box className="application_form_head_bottom_line">
                <Divider />
              </Box>
            </Box>
            <Box className="section_head_root">
              <Box className="section_open_close">
                <Typography variant="span">
                  Please check the appropriate box:
                  <span style={{ color: "red" }} color="red">
                    *
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box className="disclosure_form_radio_button">
              <RadioGroup
                name="question_b"
                className="radio_group_shift_right"
                row={true}
                value={annuityDisclosureForm.flagAnnuityClosure}
                onChange={(e) => {
                  CommonContextUpdate("flagAnnuityClosure", e.target.value);
                }}
              >
                <FormControlLabel
                  disabled={disableAllInputs === true ? true : false}
                  control={
                    <Radio
                      value={"Qualified Annuity"}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  label="Qualified Annuity"
                />
                <FormControlLabel
                  disabled={disableAllInputs === true ? true : false}
                  control={
                    <Radio
                      value={"Non - Qualified Annuity"}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  label="Non - Qualified Annuity"
                />
              </RadioGroup>
            </Box>
            <Box className="annuity_disclosure_desc">
              <Typography className="annuity_disclosure_desc_1" variant="span">
                The Safeguard Plus Annuity is an individual fixed single-premium
                deferred annuity. You purchase the annuity with a single premium
                payment. Interest is earned during the accumulation phase and
                annuity benefit payments are deferred until the Maturity Date or
                upon Annuitization. Under current tax law: (a) the principal and
                earnings are not subject to income taxes until funds are
                withdrawn or distributed; and (b) a 10% IRS early-withdrawal
                penalty may apply to withdrawals or distributions prior to age
                591⁄2. Tax law is subject to change. Please consult your
                financial or tax professional for any exceptions to the
                early-withdrawal penalty.
              </Typography>
              <Typography className="annuity_disclosure_desc_2" variant="span">
                The main purposes of a deferred annuity are: (a) to save money
                for retirement; and (b) to receive retirement income for life.
                It is not meant for short-term financial goals.
              </Typography>
            </Box>

            {/* <Box className="previous_next_btn" style={{ margin: "25px 0", display: "flex", justifyContent: "center" }}>
                            <Button startIcon={<KeyboardDoubleArrowLeftIcon />} className="btn_prev" onClick={() => {
                                props.previousPage();
                            }} style={{ background: "#074a6e", color: "white", textTransform: "capitalize", margin: "0 10px" }}>

                                Previous
                            </Button>
                        </Box> */}
            <Box className="previous_next_btn" style={{ margin: "10px 0" }}>
              <Button
                startIcon={<KeyboardDoubleArrowLeftIcon />}
                onClick={() => {
                  if (ConditionToShowTransferExchangeForm() === false) {
                    props.suitabilityAnalysisPage();
                  } else {
                    props.previousPage();
                  }
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Previous
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default AnnuityDisclosureForm;
