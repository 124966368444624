import axiosInstance from "./axios";

const GetAnnuityApplicationAPI = async (onSuccess, onError) => {
    try {
        const res = await axiosInstance.get("/api/application", {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });

        onSuccess && onSuccess(res);
        console.log(res);
        // debugger;
    }
    catch (err) {
        onError && onError(err);
    }
};

const CopyApplicationFormAPI = async (data, onSuccess, onError) => {
    try {
        const res = await axiosInstance.post(`/api/application/clone-application-data`, data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(res);
    }
    catch (err) {
        onError && onError(err);
    }
}

const CopyApplicationFormV3API = async (data, onSuccess, onError) => {
    try {
        const res = await axiosInstance.post(`/api/application_v2/clone-application-data`, data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(res);
    }
    catch (err) {
        onError && onError(err);
    }
}

const GetSingleAnnuityApplicationAPI = async (data, onSuccess, onError) => {
    try {
        console.log(data);
        const res = await axiosInstance.get(`/api/application?${data.type}=${data.id}`, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });

        onSuccess && onSuccess(res);
        console.log(res);
        // debugger;
    }
    catch (err) {
        onError && onError(err);
    }
}

export { GetAnnuityApplicationAPI, GetSingleAnnuityApplicationAPI, CopyApplicationFormAPI, CopyApplicationFormV3API };