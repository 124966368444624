import React, { Fragment, useContext, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Context } from "../../../context/V1/newApplicationFormContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Context as ErrorValidationContext } from "../../../context/V1/errorValidationContext";
import "./replacementForm.css";

const ReplacementForm = (props) => {
  const { SetApplicationFormFlagSectionData } = useContext(
    ErrorValidationContext
  );
  const { flagReplacementPageFirstSection } = useContext(
    ErrorValidationContext
  ).state;
  const { SetNoticeOfReplacement } = useContext(Context);
  const { noticeOfReplacement, disableAllInputs } = useContext(Context).state;

  const CommonContextUpdate = (type, value) => {
    SetNoticeOfReplacement({
      type: type,
      value: value,
    });
  };

  useEffect(() => {
    console.log("Printing notice of replacement, ", noticeOfReplacement);
  }, [noticeOfReplacement]);

  return (
    <Fragment>
      <Box className="transfer_exchange_request_page_root">
        <Box className="application_form">
          <Box className="application_form_card">
            <Box className="application_form_head">
              <Box>
                <Typography variant="span">
                  Notice of Replacement of Life Insurance or Annuities
                </Typography>
              </Box>
              <Box className="application_form_head_bottom_line">
                <Divider />
              </Box>
            </Box>
            <Box
              className="section_head_root"
              onClick={() => {
                // debugger;
                SetApplicationFormFlagSectionData({
                  type: "Notice of Replacement",
                  section: "flagReplacementPageFirstSection",
                  data: !flagReplacementPageFirstSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  1. Important Notice: Replacement of Life Insurance or
                  Annuities
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagReplacementPageFirstSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagReplacementPageFirstSection === true ? (
              <Box className="important_notice_detail">
                <Typography
                  sx={{ marginTop: "20px" }}
                  className="notice_detail_desc"
                  variant="span"
                >
                  (This notice must be signed by the applicant(s) and broker,
                  with the original sent to Farmers Life Insurance Company and a
                  copy left with the applicant(s).)
                </Typography>
                <Typography className="notice_detail_desc" variant="span">
                  You are contemplating the purchase of a life insurance policy
                  or annuity contract. In some cases this purchase may involve
                  discontinuing or changing an existing policy or contract. If
                  so, a replacement is occurring. Financed purchases are also
                  considered replacements.
                </Typography>

                <Typography className="notice_detail_desc" variant="span">
                  A replacement occurs when a new policy or contract is
                  purchased and, in connection with the sale, you discontinue
                  making premium payments on the existing policy or contract, or
                  an existing policy or contract is surrendered, forfeited,
                  assigned to the replacing insurer, or otherwise terminated or
                  used in a financial purchase.
                </Typography>

                <Typography className="notice_detail_desc" variant="span">
                  A financed purchase occurs when the purchase of a new life
                  insurance policy involves the use of funds obtained by the
                  withdrawal or surrender of or by borrowing some of all of the
                  policy values, including accumulated dividends, of an existing
                  policy or contract to pay all or part of any premium or
                  payment due on the new policy. A financed purchase is a
                  replacement.
                </Typography>

                <Typography className="notice_detail_desc" variant="span">
                  You should carefully consider whether a replacement is in your
                  best interests. You will pay acquisition costs and there may
                  be surrender costs deducted from your policy or contract. You
                  may be able to make changes to your existing policy or
                  contract to meet your insurance needs at less cost. A financed
                  purchase will reduce the value of your existing policy and may
                  reduce the amount paid upon the death of the insured
                  individual.
                </Typography>

                <Typography className="notice_detail_desc" variant="span">
                  We want you to understand the effects of replacements before
                  you make your purchase decision and ask that you answer
                  <br />
                  the following questions and consider the questions on page 2
                  of this form.
                </Typography>
                <Box className="question_A_important_notice">
                  <Typography variant="span">
                    A. Are you considering discontinuing making premium
                    payments, surrendering, forfeiting, assigning to the insurer
                    or otherwise terminating your existing policy or contract?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <RadioGroup
                    name="question_a"
                    className="radio_group_shift_right"
                    row={true}
                    value={noticeOfReplacement.flagDiscontinuingPremiumPayment}
                    onChange={(e) => {
                      CommonContextUpdate(
                        "flagDiscontinuingPremiumPayment",
                        e.target.value
                      );
                    }}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Yes"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"No"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </Box>
                <Box className="question_B_important_notice">
                  <Typography variant="span">
                    B. Are you considering using funds from your existing
                    policies or contracts to pay premiums due on the new policy
                    or contract? <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <RadioGroup
                    name="question_b"
                    className="radio_group_shift_right"
                    row={true}
                    value={noticeOfReplacement.flagPayForPremiumPolicy}
                    onChange={(e) => {
                      CommonContextUpdate(
                        "flagPayForPremiumPolicy",
                        e.target.value
                      );
                    }}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Yes"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"No"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </Box>
                <Box className="question_C_important_notice">
                  <Typography variant="span">
                    C. If you answered “Yes” to either of the above questions,
                    list each existing policy or contract you are contemplating
                    replacing (include the name of the insurer, the insured or
                    annuitant, and the policy or contract number, if available)
                    and whether each policy or contract will be replaced or used
                    as a source of financing:{" "}
                    {(noticeOfReplacement.flagDiscontinuingPremiumPayment ===
                      "Yes" ||
                      noticeOfReplacement.flagPayForPremiumPolicy ===
                      "Yes") && <span style={{ color: "red" }}>*</span>}
                  </Typography>
                  {noticeOfReplacement.insurerPolicyDetailFieldArray.map(
                    (row, i, arr) => {
                      console.log(
                        "Printing row for beneficiaries primary",
                        row
                      );
                      let flagRequired = (noticeOfReplacement.flagDiscontinuingPremiumPayment === "Yes" || noticeOfReplacement.flagPayForPremiumPolicy === "Yes")
                      return (
                        <Box
                          className="important_notice_question_c_row radio_group_shift_right"
                          key={i}
                        >
                          <Box className="beneficiaries_section_row_1" key={i}>
                            <TextField
                              key={i}
                              sx={{ width: 110 }}
                              label="Insurer Name"
                              variant="standard"
                              className={flagRequired ? "" : "not_required"}
                              type="text"
                              disabled={
                                disableAllInputs === true ? true : false
                              }
                              value={row.insurerName}
                              onChange={(e) => {
                                let temp = arr;
                                console.log(temp);
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    v.insurerName = e.target.value;
                                  }
                                  return v;
                                });
                                SetNoticeOfReplacement({
                                  type: "insurerPolicyDetailFieldArray",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_2" key={i}>
                            <TextField
                              key={i}
                              label="Contract/Policy Number"
                              type="text"
                              variant="standard"
                              className={flagRequired ? "" : "not_required"}
                              disabled={
                                disableAllInputs === true ? true : false
                              }
                              value={row.contractOrPolicyNumber}
                              onChange={(e) => {
                                let temp = arr;
                                console.log(temp);
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    v.contractOrPolicyNumber = e.target.value;
                                  }
                                  return v;
                                });
                                SetNoticeOfReplacement({
                                  type: "insurerPolicyDetailFieldArray",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_3" key={i}>
                            <TextField
                              key={i}
                              label="Name of Insured or Annuitant"
                              type="text"
                              variant="standard"
                              className={flagRequired ? "" : "not_required"}
                              disabled={
                                disableAllInputs === true ? true : false
                              }
                              value={row.nameOfInsuredOrAnnuitant}
                              onChange={(e) => {
                                let temp = arr;
                                console.log(temp);
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    v.nameOfInsuredOrAnnuitant = e.target.value;
                                  }
                                  return v;
                                });
                                SetNoticeOfReplacement({
                                  type: "insurerPolicyDetailFieldArray",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                          <Box className="beneficiaries_section_row_3" key={i}>
                            <TextField
                              key={i}
                              label="Replaced (R)
                                        or Financing (F)"
                              type="text"
                              disabled={
                                disableAllInputs === true ? true : false
                              }
                              variant="standard"
                              className={flagRequired ? "" : "not_required"}
                              value={row.replacedOrFinancing}
                              onChange={(e) => {
                                let temp = arr;
                                console.log(temp);
                                temp = temp.filter((v) => {
                                  if (v?.id === row?.id) {
                                    v.replacedOrFinancing = e.target.value;
                                  }
                                  return v;
                                });
                                SetNoticeOfReplacement({
                                  type: "insurerPolicyDetailFieldArray",
                                  value: temp,
                                });
                              }}
                            />
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Box>
                <Box className="question_D_important_notice">
                  <Typography variant="span">
                    D. The existing policy or contract is being replace because{" "}
                    {(noticeOfReplacement.flagDiscontinuingPremiumPayment === "Yes" ||
                      noticeOfReplacement.flagPayForPremiumPolicy === "Yes")
                      && <span style={{ color: "red" }}>*</span>}
                  </Typography>
                  <TextField
                    variant="standard"
                    type="text"
                    className={(noticeOfReplacement.flagDiscontinuingPremiumPayment === "Yes" ||
                      noticeOfReplacement.flagPayForPremiumPolicy === "Yes")
                      ? "question_d_input_field" : "question_d_input_field not_required"}
                    label="Reason for replacement"
                    disabled={disableAllInputs === true ? true : false}
                    value={noticeOfReplacement.reasonForReplacement}
                    onChange={(e) => {
                      CommonContextUpdate(
                        "reasonForReplacement",
                        e.target.value
                      );
                    }}
                  />
                </Box>
              </Box>
            ) : null}
            {/* <Box className='section_head_root' onClick={() => {
                            SetApplicationFormFlagSectionData({
                                type: "Notice of Replacement",
                                section: "flagReplacementPageSecondSection",
                                data: !flagReplacementPageSecondSection
                            })
                        }} >
                            <Box className="section_open_close">
                                <Typography variant='span'>2. Broker</Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagReplacementPageSecondSection === true ? <KeyboardArrowUpIcon /> :
                                    <KeyboardArrowDownIcon />}
                            </Box>
                        </Box> */}

            {/* {flagReplacementPageSecondSection === true ?
                            // <Box className="broker_section req_fields">
                            //     <TextField
                            //         sx={{ width: "45%" }}
                            //         variant='standard'
                            //         type="text"
                            //         label="Broker Name "
                            //         disabled={disableAllInputs === true ? true : false}
                            //         value={noticeOfReplacement.brokerName}
                            //         onChange={e => {
                            //             CommonContextUpdate("brokerName", e.target.value);
                            //         }}
                            //     />
                            //     <TextField
                            //         sx={{ width: "45%" }}
                            //         variant='standard'
                            //         type="email"
                            //         label="Broker Email "
                            //         disabled={disableAllInputs === true ? true : false}
                            //         value={noticeOfReplacement.brokerEmail}
                            //         onChange={e => {
                            //             CommonContextUpdate("brokerEmail", e.target.value);
                            //         }}
                            //     />
                            // </Box> 
                            : null} */}

            <Box
              className="previous_next_btn"
              style={{
                margin: "25px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={<KeyboardDoubleArrowLeftIcon />}
                className="btn_prev"
                onClick={() => {
                  props.previousPage();
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Previous
              </Button>
              <Button
                endIcon={<DoubleArrowIcon />}
                className="btn_next"
                onClick={() => {
                  props.nextPage();
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Next - Suitability Analysis
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default ReplacementForm;
