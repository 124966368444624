import createDataContext from "../createDataContext";

// Reducer
const NewApplicationFormReducer = (state, action) => {
    switch (action.form) {
        case "individualAnnuityApplication": {
            return {
                ...state,
                individualAnnuityApplication: {
                    ...state.individualAnnuityApplication,
                    [action.type]: action.payload
                }
            }
        }

        case "suitabilityAnalysis": {
            return {
                ...state,
                suitabilityAnalysis: {
                    ...state.suitabilityAnalysis,
                    [action.type]: action.payload
                }
            }
        }

        case "transferOrExchangeRequest": {
            return {
                ...state,
                transferOrExchangeRequest: {
                    ...state.transferOrExchangeRequest,
                    [action.type]: action.payload
                }
            }
        }
        case "noticeOfReplacement": {
            return {
                ...state,
                noticeOfReplacement: {
                    ...state.noticeOfReplacement,
                    [action.type]: action.payload
                }
            }
        }
        // case "noticeOfReplacementOklahoma":{
        //     return {
        //         ...state,
        //         noticeOfReplacementOklahoma:{
        //             ...state.noticeOfReplacementOklahoma,
        //             [action.type]:action.payload
        //         }
        //     }
        // }
        // case "bankDetails": {
        //     return {
        //         ...state,
        //         bankDetails: {
        //             ...state.bankDetails,
        //             [action.type]: action.payload
        //         }
        //     }
        // }
        // debugger
        case "individualAnnuityApplicationWhole": {
            return {
                ...state,
                individualAnnuityApplication: action.payload

            }
        }

        case "suitabilityAnalysisWhole": {
            return {
                ...state,
                suitabilityAnalysis: action.payload
            }
        }

        case "noticeOfReplacementWhole": {
            return {
                ...state, noticeOfReplacement: action.payload
            }
        }

        case "transferExchangeRequestWhole": {
            // debugger
            return {
                ...state,
                transferOrExchangeRequest: action.payload
            }
        }

        // case "bankDetailsWhole": {
        //     return {
        //         ...state,
        //         bankDetails: action.payload
        //     }
        // }

        case "annuityDisclosureFormWhole": {
            return {
                ...state,
                annuityDisclosureForm: action.payload
            }
        }

        case "annuityDisclosureForm": {
            return {
                ...state,
                annuityDisclosureForm: {
                    [action.type]: action.payload
                }
            }
        }

        case "annuityDisclosureFormWhole": {
            return {
                ...state,
                annuityDisclosureFormWhole: action.payload
            }
        }

        case "annuityDisclosureForm": {
            return {
                ...state,
                annuityDisclosureForm: {
                    [action.type]: action.payload
                }
            }
        }

        case "disableAllInputs": {
            return {
                ...state,
                disableAllInputs: action.payload
            }
        }

        case "applicationId": {
            return {
                ...state,
                applicationId: action.payload
            }
        }

        case "transferOrExchangeRequestFlag": {
            // debugger
            return {
                ...state,
                transferOrExchangeRequestFlag: action.payload
            }
        }

        default:
            return state;
    }
}

// Function for Individual Annuity Application
const SetIndividualAnnuityApplication = dispatch => (data) => {
    // debugger
    console.log("set individual annuity app data", data);
    dispatch({
        type: data.type,
        payload: data.value,
        form: "individualAnnuityApplication"
    })
}

// Function for Suitability Analysis
const SetSuitabilityAnalysis = dispatch => (data) => {
    // debugger
    console.log("set suitability analysis data", data);
    dispatch({
        type: data.type,
        payload: data.value,
        form: "suitabilityAnalysis"
    })
}

//Function for Notice of Replacement
const SetNoticeOfReplacement = dispatch => (data) => {
    console.log("set notice of replacement", data);
    dispatch({
        type: data.type,
        payload: data.value,
        form: "noticeOfReplacement"
    })
}

//Function for Notice of Replacement
const SetNoticeOfReplacementOklahoma = dispatch => (data) => {
    console.log("set notice of replacement for oklahoma", data);
    dispatch({
        type: data.type,
        payload: data.value,
        form: "noticeOfReplacementOklahoma"
    })
}

// Function for Transfer or Exchange Request
const SetTransferOrExchangeRequest = dispatch => (data) => {
    console.log("set transfer or exchange request data", data);
    dispatch({
        type: data.type,
        payload: data.value,
        form: "transferOrExchangeRequest"
    })
}

// Function for Bank Details
// const SetBankDetails = dispatch => (data)=>{
//     console.log("set bank details data", data);
//     dispatch({
//         type: data.type,
//         payload: data.value,
//         form: "bankDetails"
//     })
// }

//  Function for Annuity disclosure form
const SetAnnuityDisclosureForm = dispatch => (data) => {
    console.log("set annuity disclosure form  data", data);
    dispatch({
        type: data.type,
        payload: data.value,
        form: "annuityDisclosureForm"
    })
}

// Function to set Whole Individual Annuity Application in One Function
const SetWholeIndividualAnnuityApplication = dispatch => (data) => {
    //   debugger
    console.log("set whole individual application", data);
    dispatch({
        form: 'individualAnnuityApplicationWhole',
        payload: data
    })
}

// Function to set Whole Individual Annuity Application in One Function
const SetWholeSuitabilityAnalysis = dispatch => (data) => {
    // debugger
    console.log("set whole suitability analysis", data);
    dispatch({
        form: 'suitabilityAnalysisWhole',
        payload: data
    })
}

// Function to set Whole notice of Replacement Application in One Function
const SetWholeNoticeOfReplacement = dispatch => (data) => {
    console.log("set whole notice of replacement", data);
    // debugger;
    dispatch({
        form: 'noticeOfReplacementWhole',
        payload: data
    })
}

// Function to set Whole Individual Annuity Application in One Function
const SetWholeTransferExchangeRequest = dispatch => (data) => {
    console.log("set whole transfer exchange request", data);
    dispatch({
        form: 'transferExchangeRequestWhole',
        payload: data
    })
}

// Function to set Whole Individual Annuity Application in One Function
// const SetWholeBankDetails = dispatch => (data)=>{
//     console.log("set whole bank details",data);
//     dispatch({
//         form: 'bankDetailsWhole',
//         payload: data
//     })
// }



// Function to set Whole Annuity Disclosure in One Function
const SetWholeAnnuityDisclosureForm = dispatch => (data) => {
    console.log("set  whole annuity disclosure form  data", data);
    dispatch({
        form: "annuityDisclosureFormWhole",
        payload: data,
    })
}

// Changing disable all input 
const SetDisableAllInputs = dispatch => (data) => {
    console.log("Printing value to be updated as disableAllInputs", data);
    dispatch({
        form: "disableAllInputs",
        payload: data
    })
}

// Changing application id for send this link to applicant api call
const SetApplicationId = dispatch => (data) => {
    console.log("Printing  application id", data);
    // debugger;
    dispatch({
        form: "applicationId",
        payload: data
    })
}
const updateTransferOrExchangeRequestFlag = dispatch => (data) => {
    console.log("updateTransferOrExchangeRequestFlag", data);
    // debugger;
    dispatch({
        form: "transferOrExchangeRequestFlag",
        payload: data
    })
}
const transferOrExchangeRequestFlag = dispatch => (data) => {
    console.log("transferOrExchangeRequestFlag", data);
    //  debugger;
    dispatch({
        form: "transferOrExchangeRequestFlag",
        payload: data
    })
}

// Export Context and Provider
export const { Context, Provider } = createDataContext(
    NewApplicationFormReducer,
    {
        SetIndividualAnnuityApplication, SetSuitabilityAnalysis, SetTransferOrExchangeRequest, SetNoticeOfReplacement, SetNoticeOfReplacementOklahoma,  // SetBankDetails,
        SetAnnuityDisclosureForm, SetWholeIndividualAnnuityApplication, SetWholeSuitabilityAnalysis, SetWholeNoticeOfReplacement, SetWholeTransferExchangeRequest,
        // SetWholeBankDetails,
        updateTransferOrExchangeRequestFlag,
        SetWholeAnnuityDisclosureForm, SetDisableAllInputs, SetApplicationId, transferOrExchangeRequestFlag
    },
    {
        individualAnnuityApplication: {
            annuitantFullName: "",
            annuitantSSN: "",
            annuitantGender: "",
            annuitantDOB: "",
            annuitantStreetAddress: "",
            annuitantZip: "",
            annuitantCity: "",
            annuitantState: "",
            annuitantPhone: "",
            annuitantEmail: "",
            ownerFullName: "",
            ownerSSN: "",
            ownerGender: "",
            ownerDOB: "",
            ownerStreetAddress: "",
            ownerZip: "",
            ownerCity: "",
            ownerState: "",
            ownerEmail: "",
            ownerRelationToAnnuitant: "",
            jointOwnerFullName: "",
            jointOwnerSSN: "",
            jointOwnerGender: "",
            jointOwnerDOB: "",
            jointOwnerStreetAddress: "",
            jointOwnerZip: "",
            jointOwnerCity: "",
            jointOwnerState: "",
            jointOwnerEmail: "",
            jointOwnerRelationToAnnuitant: "",
            planAppliedFor: [],
            beneficiariesPrimary: [
                {
                    id: 1,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 2,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 3,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 4,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 5,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 6,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                }
            ],
            beneficiariesContigent: [
                {
                    id: 1,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 2,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 3,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 4,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 5,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 6,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                }

            ],
            // flagNonQualifiedSelected: false,
            selectedNonQualifiedOptions: "",
            // flagQualifiedSelected: false,
            // selectedQualifiedOptions: "",
            selectedQualifiedOptionsGroup1: "",
            selectedQualifiedOptionsGroup2: "",
            otherSelectedQualifiedOptions: "",
            flagQualifiedOrNonQualifiedSelected: "",
            amountPaidWithApplication: "",
            expectedPremiumField: "",
            flagHaveExistingLifeInsurance: "",
            reasonOfApplicationToChangeLifeInsurance: "",
            applicationReplacementFieldsArray: [
                {
                    id: 1,
                    companyNameOfPolicyField: "",
                    policyNumberField: ""
                },
                {
                    id: 2,
                    companyNameOfPolicyField: "",
                    policyNumberField: ""
                },
                {
                    id: 3,
                    companyNameOfPolicyField: "",
                    policyNumberField: ""
                }
            ],
            flagSafeguardPlusAnnuityPeriod: ""
        },

        suitabilityAnalysis: {
            ownerFullName: "",
            ownerCurrentAge: "",
            ownerGender: "",
            ownerEmploymentStatus: "",
            ownerCurrentOccupation: "",
            ownerDependents: "",
            spouseFullName: "",
            spouseCurrentAge: "",
            spouseGender: "",
            spouseEmploymentStatus: "",
            spouseCurrentOccupation: "",
            spouseDependents: "",
            ownerAnnualHouseholdIncome: "",
            ownerSourceOfIncome: "",
            ownerIncomeTaxBracket: "",
            ownerTotalOutstandingDebt: "",
            ownerTotalAssets: "",
            ownerTotalLiquidAssets: "",
            flagOwnerIncomeCoverLivingAndMedicalExpenses: "",
            explainOwnerIncomeCoverLivingAndMedicalExpenses: "",
            flagOwnerExpectChangesInLivingExpenses: "",
            explainOwnerExpectChangesInLivingExpenses: "",
            flagOwnerAnticipateChangesInMedicalExpenses: "",
            explainOwnerAnticipateChangesInMedicalExpenses: "",
            flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod: "",
            explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod: "",
            flagOwnerHaveEmergencyFundForUnexpectedExpenses: "",
            explainOwnerHaveEmergencyFundForUnexpectedExpenses: "",
            ownerOtherInvestments: [],
            otherOwnerInvestmentField: "",
            ownerRiskTolerance: "",
            reasonForPurchasingProduct: [],
            otherRasonForPurchasingThisProduct: "",
            flagOwnerPlanToWithdrawFundInSurrenderPeriod: "",
            explainOwnerPlanToWithdrawFundInSurrenderPeriod: "",
            ownerAnticipateNeedingAccessToFunds: "",
            sourceOfFundsToPurchaseThisProduct: [],
            otherSourceOfFundsToPurchaseThisProduct: "",
            flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct: "",
            howLongHasTheLifeInsuranceBeenInForce: "",
            flagSurrenderChargeOnExistingProduct: "",
            currentSurrenderChargeOnExistingProduct: "",
            expirationOfCurrentSurrenderCharge: "",
            typeOfExistingProduct: "",
            interestRateForExistingProduct: "",
            flagTakingRequiredMinimumDistributionsFromExistingProduct: "",
            flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution: "",
            explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution: "",
            personsThatAssistedOwnerToPurchaseThisProduct: [],
            otherPersonsThatAssistedOwnerToPurchaseThisProduct: "",
            additionalRelaventInformationForSuitabilityAnalysis: "",
            agentAcknowledgment: ""
        },
        noticeOfReplacement: {
            flag_notice_replacement_for_oklahoma: "",
            flagDiscontinuingPremiumPayment: "",
            flagPayForPremiumPolicy: "",
            insurerPolicyDetailFieldArray: [
                {
                    id: 1,
                    insurerName: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    replacedOrFinancing: "",
                    amount: "",
                },
                {
                    id: 2,
                    insurerName: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    replacedOrFinancing: "",
                    amount: "",
                },
                {
                    id: 3,
                    insurerName: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    replacedOrFinancing: "",
                    amount: "",
                }
            ],
            reasonForReplacement: "",
            // brokerName:"",
            // brokerEmail: ""
        },
        // noticeOfReplacementOklahoma:{
        //     flagDiscontinuingPremiumPaymentOklahoma:"",
        //     flagPayForPremiumPolicyOklahoma:"",
        //     insurerPolicyDetailFieldArrayOklahoma:[
        //         {
        //             id:1,
        //             insurerName:"",
        //             contractOrPolicyNumber:"",
        //             nameOfInsuredOrAnnuitant:"",
        //             amount:"",
        //         },
        //         {
        //             id:2,
        //             insurerName:"",
        //             contractOrPolicyNumber:"",
        //             nameOfInsuredOrAnnuitant:"",
        //             amount:"",
        //         },                
        //         {
        //             id:3,
        //             insurerName:"",
        //             contractOrPolicyNumber:"",
        //             nameOfInsuredOrAnnuitant:"",
        //             amount:"",
        //         }
        //     ],
        //      reasonForReplacement:"",
        //     // brokerName:"",
        //     // brokerEmail: ""
        // },
        transferOrExchangeRequest: {
            ownerFullName: "",
            ownerSSN: "",
            ownerStreetAddress: "",
            ownerZip: "",
            ownerCity: "",
            ownerState: "",
            annuitantOrInsured: "",
            currentInsuranceCompany: "",
            currentInsuranceCompanyPhone: "",
            currentInsuranceCompanyAccountNumber: "",
            currentInsuranceCompanyStreetAddress: "",
            currentInsuranceCompanyZip: "",
            currentInsuranceCompanyCity: "",
            currentInsuranceCompanyState: "",
            typeOfAccount: "",
            otherTypeOfAccount: "",
            ownerExistingAnnuity: "",
            plans: "",
            nonQualifiedTransferOrExchangeExistingAccountAllOrPartial: "",
            nonQualifiedTransferOrExchangeExistingAccountPartialAmount: "",
            nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate: "",
            nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField: "",
            nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy: "",
            nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField: "",
            flagNonQualifiedOwnerWantWithdrawalToExchange: "",
            explainNonQualifiedOwnerWantWithdrawalToExchange: "",
            flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal: "",
            qualifiedTransferOrExchangeExistingAccountAllOrPartial: "",
            qualifiedTransferOrExchangeExistingAccountPartialAmount: "",
            qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate: "",
            qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField: "",
            qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy: "",
            qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField: "",
            qualifiedTypeOfTransfer: "",
            qualifiedQualifyingEventForDirectRolloverFunds: "",
            flagQualifiedOwnerReachedAge72OrMoreInThisYear: "",
            flagQualifiedOwnerSatisfiedRMDFromDistributingPlan: "",
            qualifiedInternalExchangeOfExistingAnnuityName: "",
            flagQualifiedOwnerWantRMDBeforeCompletingExchange: "",
            flagQualifiedOwnerWantToContinueExistingRMDSchedule: "",
            // witnessName: "",
            // witnessEmail: ""
        },
        // bankDetails: {
        //     routingNumber: "",
        //     accountNumber: "",
        //     bankName: "",
        //     bankAddress: ""
        // },
        annuityDisclosureForm: {
            flagAnnuityClosure: "",
        },
        annuityDisclosureForm: {
            flagAnnuityClosure: "",
        },
        disableAllInputs: true,
        applicationId: "",
        transferOrExchangeRequestFlag: false

    })