import Axios from "./axios";

export const ViewCalcIdDataAPI = async (data, onSuccess, onError) => {
    try {
        const responce = await Axios.get('/api/user/recentcalculatorhistory?calc_data_id=' + data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(responce);
    }
    catch (err) {
        onError && onError(err)
    }
}

export const NewViewCalcIdDataAPI = async (data, onSuccess, onError) => {
    try {
        const responce = await Axios.get('/api/calculator/get-myga-illustration-data?calc_data_id=' + data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(responce);
    }
    catch (err) {
        onError && onError(err)
    }
}