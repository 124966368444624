import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  InputAdornment,
} from "@mui/material";
import { Context } from "../../../context/V1/newApplicationFormContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Context as ErrorValidationContext } from "../../../context/V1/errorValidationContext";
import "./noticeOfReplacement.css";

const NoticeOfReplacement = (props) => {
  const { SetApplicationFormFlagSectionData } = useContext(
    ErrorValidationContext
  );
  const { flagReplacementPageFirstSection } = useContext(
    ErrorValidationContext
  ).state;
  const {
    SetNoticeOfReplacement,
    SetNoticeOfReplacementForOklahoma,
    NoticeOfReplacement,
  } = useContext(Context);
  const { noticeOfReplacement, disableAllInputs } = useContext(Context).state;
  const { flagFirstPageFirstSection } = useContext(
    ErrorValidationContext
  ).state;
  const [flagNotice, setFlagNotice] = useState();

  const CommonContextUpdate = (type, value) => {
    SetNoticeOfReplacement({
      type: type,
      value: value,
    });
    // SetNoticeOfReplacementForOklahoma({
    //     type: "flag_notice_replacement_for_oklahoma",
    //     value: value
    // })
  };

  useEffect(() => {
    console.log("Printing notice of replacement, ", noticeOfReplacement);
  }, [noticeOfReplacement]);

  return (
    <Fragment>
      <Box className="transfer_exchange_request_page_root">
        <Box className="application_form">
          <Box className="application_form_card">
            <Box className="application_form_head">
              <Box>
                <Typography variant="span">
                  Notice of Replacement of Life Insurance or Annuities for
                  Oklahoma
                </Typography>
              </Box>
              <Box className="application_form_head_bottom_line">
                <Divider />
              </Box>
            </Box>
            <Box
              className="section_head_root"
              onClick={() => {
                // debugger;
                SetApplicationFormFlagSectionData({
                  type: "Notice of Replacement for Oklahoma",
                  section: "flagReplacementPageFirstSection",
                  data: !flagReplacementPageFirstSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  1. Notice of Replacement of Life Insurance or Annuities{" "}
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagReplacementPageFirstSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagReplacementPageFirstSection === true && (
              <>
                <Box
                  className=" first_question"
                  style={{ textAlign: "justify" }}
                >
                  <Typography variant="span">
                    I have read the “NOTICE TO APPLICANTS REGARDING REPLACEMENT
                    OF LIFE INSURANCE OR ANNUITIES” which was furnished to me by
                    the agent taking the application for this policy.{" "}
                    <span style={{ color: "red" }} color="red">
                      *
                    </span>
                  </Typography>
                  <RadioGroup
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                    }}
                    name="first_question"
                    value={
                      noticeOfReplacement.flag_notice_replacement_for_oklahoma
                    }
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"Yes"}
                          onChange={() => {
                            // debugger;
                            CommonContextUpdate(
                              "flag_notice_replacement_for_oklahoma",
                              "Yes"
                            );
                          }}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          name="radio"
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"No"}
                          onChange={() => {
                            // debugger;
                            CommonContextUpdate(
                              "flag_notice_replacement_for_oklahoma",
                              "No"
                            );
                          }}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          name="radio"
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </Box>

                <Box className="important_notice_detail">
                  <Box
                    className="question_C_important_notice"
                    style={{ textAlign: "justify" }}
                  >
                    <Typography variant="span">
                      The signature of the owner shall be that of the insured
                      unless someone other than the insured is the owner of the
                      policy. If someone other than the insured is the owner of
                      the policy, the owner must sign. If the insured is under
                      eighteen (18) years of age, the parent is deemed to be the
                      owner of the policy.
                    </Typography>
                    <Typography style={{ paddingTop: "12px" }}>
                      <b>
                        The life insurance or annuity I intend to purchase from
                        Farmers Life Insurance Company may replace or alter
                        existing life insurance or annuity policy(ies).
                      </b>
                    </Typography>
                    {noticeOfReplacement.insurerPolicyDetailFieldArray.map(
                      (row, i, arr) => {
                        console.log(
                          "Printing row for beneficiaries primary",
                          row
                        );
                        return (
                          <Box
                            className="important_notice_question_c_row"
                            key={i}
                          >
                            <Box
                              className="beneficiaries_section_row_1"
                              key={i}
                            >
                              <TextField
                                key={i}
                                sx={{ width: 110 }}
                                label="Insurer Name"
                                variant="standard"
                                type="text"
                                disabled={
                                  disableAllInputs === true ? true : false
                                }
                                value={row.insurerName}
                                onChange={(e) => {
                                  let temp = arr;
                                  console.log("temp", temp);
                                  temp = temp.map((v) => {
                                    if (v?.id === row?.id) {
                                      v.insurerName = e.target.value;
                                      console.log("textfeild", v.insurerName);
                                    }
                                    return v;
                                  });
                                  SetNoticeOfReplacement({
                                    type: "insurerPolicyDetailFieldArray",
                                    value: temp,
                                  });
                                }}
                              />
                            </Box>
                            <Box
                              className="beneficiaries_section_row_2"
                              key={i}
                            >
                              <TextField
                                key={i}
                                label="Contract/Policy Number"
                                type="text"
                                variant="standard"
                                disabled={
                                  disableAllInputs === true ? true : false
                                }
                                value={row.contractOrPolicyNumber}
                                onChange={(e) => {
                                  let temp = arr;
                                  console.log(temp);
                                  temp = temp.map((v) => {
                                    if (v?.id === row?.id) {
                                      v.contractOrPolicyNumber = e.target.value;
                                    }
                                    return v;
                                  });
                                  SetNoticeOfReplacement({
                                    type: "insurerPolicyDetailFieldArray",
                                    value: temp,
                                  });
                                }}
                              />
                            </Box>
                            <Box
                              className="beneficiaries_section_row_3"
                              key={i}
                            >
                              <TextField
                                key={i}
                                label="Name of Insured or Annuitant"
                                type="text"
                                variant="standard"
                                disabled={
                                  disableAllInputs === true ? true : false
                                }
                                value={row.nameOfInsuredOrAnnuitant}
                                onChange={(e) => {
                                  let temp = arr;
                                  console.log(temp);
                                  temp = temp.map((v) => {
                                    if (v?.id === row?.id) {
                                      v.nameOfInsuredOrAnnuitant =
                                        e.target.value;
                                    }
                                    return v;
                                  });
                                  SetNoticeOfReplacement({
                                    type: "insurerPolicyDetailFieldArray",
                                    value: temp,
                                  });
                                }}
                              />
                            </Box>
                            <Box
                              className="beneficiaries_section_row_4"
                              key={i}
                            >
                              <TextField
                                key={i}
                                label="Amount"
                                type="number"
                                disabled={
                                  disableAllInputs === true ? true : false
                                }
                                variant="standard"
                                value={row.amount}
                                onChange={(e) => {
                                  let temp = arr;
                                  console.log(temp);
                                  temp = temp.map((v) => {
                                    if (v?.id === row?.id) {
                                      v.amount = e.target.value;
                                    }
                                    return v;
                                  });
                                  SetNoticeOfReplacement({
                                    type: "insurerPolicyDetailFieldArray",
                                    value: temp,
                                  });
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      className="right_space"
                                    >
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                </Box>
              </>
            )}
            <Box
              className="previous_next_btn"
              style={{
                margin: "25px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={<KeyboardDoubleArrowLeftIcon />}
                className="btn_prev"
                onClick={() => {
                  props.previousPage();
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Previous
              </Button>
              <Button
                endIcon={<DoubleArrowIcon />}
                className="btn_next"
                onClick={() => {
                  props.nextPage();
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Next - Suitability Analysis
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default NoticeOfReplacement;
