import {
  Grid,
  Dialog,
} from "@mui/material";


const Popup = (props) => {
  return (
    <>
      <Dialog
        open={props.open}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ marginTop: "5px" }}
        >
          {props.data}
        </Grid>
      </Dialog>
    </>
  );
};

export default Popup;
