import Axios from "./axios";

export const HistoryAPI = async (onSuccess, onError) => {
    try {
        const responce = await Axios.get('/api/user/calculatorhistory', {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(responce);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const NewHistoryAPI = async (onSuccess, onError) => {
    try {
        const responce = await Axios.get('/api/calculator/get-myga-illustration-list', {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(responce);
    }
    catch (err) {
        onError && onError(err)
    }
}