import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
  Input,
  InputAdornment,
  Button,
  FormControl,
  InputLabel,
  imageListClasses,
  Autocomplete,
  Link,
} from "@mui/material";
import { Context } from "../../../context/V1/newApplicationFormContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import "./transferExchangeRequest.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Context as ErrorValidationContext } from "../../../context/V1/errorValidationContext";

const TransferExchangeRequest = (props) => {
  const {
    SetTransferOrExchangeRequest,
    SetWholeTransferExchangeRequest,
    updateTransferOrExchangeRequestFlag,
    SetDisableAllInputs,
  } = useContext(Context);
  const {
    individualAnnuityApplication,
    transferOrExchangeRequest,
    disableAllInputs,
  } = useContext(Context).state;

  useEffect(() => {
    if (flagFill === true) {
      SetDisableAllInputs(true);
    }
  }, []);
  const { SetApplicationFormFlagSectionData } = useContext(
    ErrorValidationContext
  );
  const {
    flagFourthPageFirstSection,
    flagFourthPageSecondSection,
    flagFourthPageThirdSection,
  } = useContext(ErrorValidationContext).state;

  // const [flagThirdPageFirstSection, setFlagFirstSection] = useState(true);
  // const [flagThirdPageSecondSection, setFlagSecondSection] = useState(false);
  const [flagFill, setFlagFill] = useState(false);
  const [flagDelete, setFlagDelete] = useState(false);

  const [backupData, setBackupData] = useState({});
  const [transferExchangeValidationError, setTransferExchangeValidationError] = useState({
    flagZipValid: false,
    flagPhoneValid: false,
    flagCompanyZipValid: false,
  });

  const StatesName = [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Federated States Of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virgin Islands",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  useEffect(() => {
    // debugger;
    if (flagDelete) {
      SetWholeTransferExchangeRequest({
        ownerFullName: "",
        ownerSSN: "",
        ownerStreetAddress: "",
        ownerZip: "",
        ownerCity: "",
        ownerState: "",
        annuitantOrInsured: "",
        currentInsuranceCompany: "",
        currentInsuranceCompanyPhone: "",
        currentInsuranceCompanyAccountNumber: "",
        currentInsuranceCompanyStreetAddress: "",
        currentInsuranceCompanyZip: "",
        currentInsuranceCompanyCity: "",
        currentInsuranceCompanyState: "",
        typeOfAccount: "",
        otherTypeOfAccount: "",
        ownerExistingAnnuity: "",
        plans: "",
        nonQualifiedTransferOrExchangeExistingAccountAllOrPartial: "",
        nonQualifiedTransferOrExchangeExistingAccountPartialAmount: "",
        nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate:
          "",
        nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField: "",
        nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy:
          "",
        nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField:
          "",
        flagNonQualifiedOwnerWantWithdrawalToExchange: "",
        explainNonQualifiedOwnerWantWithdrawalToExchange: "",
        flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal: "",
        qualifiedTransferOrExchangeExistingAccountAllOrPartial: "",
        qualifiedTransferOrExchangeExistingAccountPartialAmount: "",
        qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate:
          "",
        qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField: "",
        qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy:
          "",
        qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField:
          "",
        qualifiedTypeOfTransfer: "",
        qualifiedQualifyingEventForDirectRolloverFunds: "",
        flagQualifiedOwnerReachedAge72OrMoreInThisYear: "",
        flagQualifiedOwnerSatisfiedRMDFromDistributingPlan: "",
        qualifiedInternalExchangeOfExistingAnnuityName: "",
        flagQualifiedOwnerWantRMDBeforeCompletingExchange: "",
        flagQualifiedOwnerWantToContinueExistingRMDSchedule: "",
      });

      setFlagDelete(false);
    }
  }, [flagDelete]);

  //    const changeFlagFill = () => {
  //     if (flagFill)
  //    }

  useEffect(() => {
    // intiial data saving for backup
    setBackupData({ ...transferOrExchangeRequest });
  }, []);

  // useEffect(() => {
  //   if (
  //     1 == 1
  //     // individualAnnuityApplication.flagHaveExistingLifeInsurance === "Yes" ||
  //     // individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance ===
  //     //   "Yes"
  //   ) {
  //     setFlagFill(true);
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (flagFill == false) {
      setTransferExchangeValidationError({
        flagZipValid: false,
        flagPhoneValid: false,
        flagCompanyZipValid: false
      })
    }
  }, [flagFill])

  useEffect(() => {
    console.log("context 77", transferOrExchangeRequest);
  }, [transferOrExchangeRequest]);

  useEffect(() => {
    let inputArray = document.querySelectorAll("input");
    if (disableAllInputs === true) {
      for (let i = 0; i < inputArray.length; i++) {
        console.log(
          "Printig all input for dsiable in transfer exchange request",
          inputArray[i]
        );
        inputArray[i].disabled = true;
      }
    }
    // else{
    //     for(let i=0;i<inputArray.length;i++){
    //         console.log("Printig all input for dsiable in transfer exchange request", inputArray[i]);
    //         inputArray[i].disabled = false;
    //     }
    // }
  }, [
    disableAllInputs,
    flagFourthPageFirstSection,
    flagFourthPageSecondSection,
  ]);

  const CommonContextUpdate = (type, value) => {
    SetTransferOrExchangeRequest({
      type: type,
      value: value,
    });
  };
  // useEffect(() => {
  //   if (
  //     1 === 1
  //     // individualAnnuityApplication.flagHaveExistingLifeInsurance === "Yes" &&
  //     // individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance ===
  //     //   "Yes"
  //   ) {
  //     setFlagFill(true);
  //     updateTransferOrExchangeRequestFlag(true);
  //   } else {
  //     updateTransferOrExchangeRequestFlag(false);
  //     if (flagFill) {
  //       setFlagFill(false);
  //     }
  //   }
  // }, [
  //   individualAnnuityApplication.flagHaveExistingLifeInsurance,
  //   individualAnnuityApplication.reasonOfApplicationToChangeLifeInsurance,
  // ]);

  return (
    <Fragment>
      <Box className="transfer_exchange_request_page_root">
        <Box className="application_form">
          <Box className="application_form_card">
            <Box className="application_form_head">
              <Box>
                <Typography variant="span">
                  Transfer or Exchange Request
                </Typography>
                {console.log(
                  "individualAnnuityApplication.flagHaveExistingLifeInsurance ...................................................",
                  individualAnnuityApplication.flagHaveExistingLifeInsurance
                )}
              </Box>
              {/* <Typography
                variant="text"
                className="btn_want"
                onClick={() => {
                  setFlagFill(!flagFill);
                  updateTransferOrExchangeRequestFlag(!flagFill);
                }}
              >
                {flagFill === false
                  ? "Fill this form"
                  : "Do not fill this form"}
              </Typography> */}
              <Box className="application_form_head_bottom_line">
                <Divider />
              </Box>
            </Box>
            <Box
              className="section_head_root"
              onClick={() => {
                // debugger;
                // setFlagFirstSection(!flagThirdPageFirstSection)
                SetApplicationFormFlagSectionData({
                  type: "Transfer or Exchange Request",
                  section: "flagFourthPageFirstSection",
                  data: !flagFourthPageFirstSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">1. Basic Information</Typography>
              </Box>
              <Box className="section_open_close">
                {flagFourthPageFirstSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFourthPageFirstSection === true ? (
              <Box className="basic_information" style={{ width: "80%" }}>
                <TextField
                  type="text"
                  //disabled={(flagFill === false ? true :false)}
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  variant="standard"
                  label="Owner Full Name"
                  className="Owner_Full_Name req_fields"
                  value={transferOrExchangeRequest.ownerFullName}
                  onChange={(e) => {
                    // if(flagFill === true) {
                    //     CommonContextUpdate("ownerFullName","")
                    // }
                    //    else{
                    CommonContextUpdate("ownerFullName", e.target.value);
                    // }
                  }}
                  style={{ width: "48%", margin: "10px 1% " }}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Owner Tax Id or SSN"
                  className="Owner_Tax_Id_or_SSN req_fields"
                  value={transferOrExchangeRequest.ownerSSN}
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    let targetLength = e.target.value.length;
                    let contextLength =
                      transferOrExchangeRequest.ownerSSN.length;

                    if (targetLength > contextLength) {
                      if (targetLength === 3 || targetLength === 6) {
                        let val = e.target.value + "-";
                        CommonContextUpdate("ownerSSN", val);
                      } else if (e.target.value.length <= 11) {
                        CommonContextUpdate("ownerSSN", e.target.value);
                      }
                      console.log(
                        "Printing value of e.target.value",
                        e.target.value
                      );
                      console.log(
                        "Printing value of ownerSSN",
                        transferOrExchangeRequest.ownerSSN
                      );
                    } else {
                      if (targetLength === 7 || targetLength === 4) {
                        CommonContextUpdate(
                          "ownerSSN",
                          e.target.value.slice(0, -1)
                        );
                      } else {
                        CommonContextUpdate("ownerSSN", e.target.value);
                      }
                    }
                  }}
                  onBlur={(e) => {
                    var regx = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
                    if (!regx.test(e.target.value)) {
                      CommonContextUpdate("ownerSSN", "");
                    }
                  }}
                  style={{ width: "48%", margin: "10px 1% " }}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Street Address"
                  className="Street_Address req_fields"
                  value={transferOrExchangeRequest.ownerStreetAddress}
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    CommonContextUpdate("ownerStreetAddress", e.target.value);
                  }}
                  style={{ width: "48%", margin: "10px 1% " }}
                />
                <TextField
                  type="number"
                  variant="standard"
                  error={transferExchangeValidationError?.flagZipValid ? true : false}
                  helperText={transferExchangeValidationError?.flagZipValid ? "ZIP number only contains 5 digits" : ""}
                  onBlur={() => {
                    let temp = { ...transferExchangeValidationError }
                    if (transferOrExchangeRequest.ownerZip?.length) {
                      temp.flagZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(transferOrExchangeRequest.ownerZip))
                      setTransferExchangeValidationError(temp)
                    } else {
                      temp.flagZipValid = false
                      setTransferExchangeValidationError(temp)
                    }
                  }}
                  label="ZIP"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={transferOrExchangeRequest.ownerZip}
                  onChange={(e) => {
                    if (e.target.value?.length == 5) {
                      let temp = { ...transferExchangeValidationError }
                      temp.flagZipValid = false
                      setTransferExchangeValidationError(temp)
                    }

                    let temp = { ...transferExchangeValidationError }
                    if (e.target.value?.length) {
                      temp.flagZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(e.target.value))
                      setTransferExchangeValidationError(temp)
                    } else {
                      temp.flagZipValid = false
                      setTransferExchangeValidationError(temp)
                    }
                    CommonContextUpdate("ownerZip", e.target.value);
                  }}
                  style={{ width: "48%", margin: "10px 1% " }}
                  className="req_fields"
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="City"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={transferOrExchangeRequest.ownerCity}
                  onChange={(e) => {
                    CommonContextUpdate("ownerCity", e.target.value);
                  }}
                  style={{ width: "48%", margin: "10px 1% " }}
                  className="req_fields"
                />
                {/* <TextField type='text' variant='standard' label='State'
                                    value={transferOrExchangeRequest.ownerState}
                                    onChange={e => {
                                        CommonContextUpdate("ownerState", e.target.value);
                                    }}
                                    style={{ width: "48%", margin: "10px 1% " }}
                                /> */}
                <FormControl
                  variant="standard"
                  style={{ width: "48%", margin: "10px 1%" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    State
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </InputLabel>
                  <Select
                    className="state_dropdown"
                    onChange={(e) => {
                      console.log(e.target.value);
                      CommonContextUpdate("ownerState", e.target.value);
                    }}
                    value={transferOrExchangeRequest.ownerState}
                    //disabled={(flagFill === false ? true :false)}
                    disabled={
                      disableAllInputs === true || flagFill === false
                        ? true
                        : false
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {StatesName.map((stateName) => {
                      return <MenuItem value={stateName}>{stateName}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <TextField
                  type="text"
                  variant="standard"
                  label="Annuitant/Insured (if other than owner)"
                  className="Annuitant_Insured not_required"
                  value={transferOrExchangeRequest.annuitantOrInsured}
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    CommonContextUpdate("annuitantOrInsured", e.target.value);
                  }}
                  style={{ width: "48%", margin: "10px 1% " }}
                />
                <br />
                <br />
                <TextField
                  type="text"
                  variant="standard"
                  label="Current Insurance Company/Trustee/Financial Institution"
                  className="Current_Insurance_Company req_fields"
                  value={transferOrExchangeRequest.currentInsuranceCompany}
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    CommonContextUpdate(
                      "currentInsuranceCompany",
                      e.target.value
                    );
                  }}
                  style={{ width: "98%", margin: "10px 1% " }}
                />
                <TextField
                  type="tel"
                  variant="standard"
                  label="Phone"
                  error={transferExchangeValidationError?.flagPhoneValid ? true : false}
                  helperText={transferExchangeValidationError?.flagPhoneValid ? "Invalid phone number" : ""}
                  onBlur={() => {
                    let temp = { ...transferExchangeValidationError }
                    if (transferOrExchangeRequest.currentInsuranceCompanyPhone?.length) {
                      temp.flagPhoneValid = !(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(transferOrExchangeRequest.currentInsuranceCompanyPhone))
                      setTransferExchangeValidationError(temp)
                    } else {
                      temp.flagPhoneValid = false
                      setTransferExchangeValidationError(temp)
                    }
                  }}
                  className="Phone req_fields"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={transferOrExchangeRequest.currentInsuranceCompanyPhone}
                  // onChange={(e) => {
                  //   CommonContextUpdate(
                  //     "currentInsuranceCompanyPhone",
                  //     e.target.value
                  //   );
                  // }}
                  onChange={(e) => {
                    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                    let temp = { ...transferExchangeValidationError }
                    if (onlyNums.length < 10) {
                      temp.flagPhoneValid = true
                      setTransferExchangeValidationError(temp)
                      CommonContextUpdate("currentInsuranceCompanyPhone", onlyNums);
                    } else if (onlyNums.length === 10) {
                      temp.flagPhoneValid = false
                      setTransferExchangeValidationError(temp)
                      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                      CommonContextUpdate("currentInsuranceCompanyPhone", number);
                    }
                  }}
                  style={{ width: "48%", margin: "10px 1% " }}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Account Number"
                  className="Account_Number req_fields"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={
                    transferOrExchangeRequest.currentInsuranceCompanyAccountNumber
                  }
                  onChange={(e) => {
                    CommonContextUpdate(
                      "currentInsuranceCompanyAccountNumber",
                      e.target.value
                    );
                  }}
                  style={{ width: "48%", margin: "10px 1% " }}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Street Address"
                  className="Street_Address req_fields"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={
                    transferOrExchangeRequest.currentInsuranceCompanyStreetAddress
                  }
                  onChange={(e) => {
                    CommonContextUpdate(
                      "currentInsuranceCompanyStreetAddress",
                      e.target.value
                    );
                  }}
                  style={{ width: "48%", margin: "10px 1% " }}
                />
                <TextField
                  type="number"
                  variant="standard"
                  label="ZIP"
                  error={transferExchangeValidationError?.flagCompanyZipValid ? true : false}
                  helperText={transferExchangeValidationError?.flagCompanyZipValid ? "ZIP number only contains 5 digits" : ""}
                  className="Zip req_fields"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  onBlur={() => {
                    let temp = { ...transferExchangeValidationError }
                    if (transferOrExchangeRequest.currentInsuranceCompanyZip?.length) {
                      temp.flagCompanyZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(transferOrExchangeRequest.currentInsuranceCompanyZip))
                      setTransferExchangeValidationError(temp)
                    } else {
                      temp.flagCompanyZipValid = false
                      setTransferExchangeValidationError(temp)
                    }
                  }}
                  value={transferOrExchangeRequest.currentInsuranceCompanyZip}
                  onChange={(e) => {
                    if (e.target.value?.length == 5) {
                      let temp = { ...transferExchangeValidationError }
                      temp.flagCompanyZipValid = false
                      setTransferExchangeValidationError(temp)
                    }

                    let temp = { ...transferExchangeValidationError }
                    if (e.target.value?.length) {
                      temp.flagCompanyZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(e.target.value))
                      setTransferExchangeValidationError(temp)
                    } else {
                      temp.flagCompanyZipValid = false
                      setTransferExchangeValidationError(temp)
                    }
                    CommonContextUpdate("currentInsuranceCompanyZip", e.target.value);
                  }}
                  style={{ width: "48%", margin: "10px 1% " }}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="City"
                  className="City req_fields"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={transferOrExchangeRequest.currentInsuranceCompanyCity}
                  onChange={(e) => {
                    CommonContextUpdate(
                      "currentInsuranceCompanyCity",
                      e.target.value
                    );
                  }}
                  style={{ width: "48%", margin: "10px 1% " }}
                />
                {/* <TextField type='text' variant='standard' label='State' className='State'
                                    value={transferOrExchangeRequest.currentInsuranceCompanyState}
                                    onChange={e => {
                                        CommonContextUpdate("currentInsuranceCompanyState", e.target.value);
                                    }}
                                    style={{ width: "48%", margin: "10px 1% " }}
                                /> */}
                <FormControl
                  variant="standard"
                  style={{ width: "48%", margin: "10px 1%" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    State
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </InputLabel>
                  <Select
                    className="state_dropdown"
                    onChange={(e) => {
                      CommonContextUpdate(
                        "currentInsuranceCompanyState",
                        e.target.value
                      );
                    }}
                    value={
                      transferOrExchangeRequest.currentInsuranceCompanyState
                    }
                    disabled={
                      disableAllInputs === true || flagFill === false
                        ? true
                        : false
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    {StatesName.map((stateName) => {
                      return <MenuItem value={stateName}>{stateName}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <Box className="type_of_account" style={{ margin: "15px 0" }}>
                  <Typography variant="span">
                    Type of Account:<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <RadioGroup
                    name="type_of_account_transfer"
                    className="radio_group_shift_right"
                    value={transferOrExchangeRequest.typeOfAccount}
                    onClick={(e) => {
                      if (!e.target.checked) {
                        return;
                      }
                      if (!e.target.value) {
                        CommonContextUpdate("otherTypeOfAccount", "");
                        CommonContextUpdate("typeOfAccount", "");
                        return;
                      }
                      if (
                        e.target.value !=
                        transferOrExchangeRequest.typeOfAccount
                      ) {
                        if (
                          e.target.value !== "Other" &&
                          transferOrExchangeRequest.otherTypeOfAccount !== ""
                        ) {
                          CommonContextUpdate("otherTypeOfAccount", "");
                        }
                        CommonContextUpdate("typeOfAccount", e.target.value);
                      } else {
                        CommonContextUpdate("otherTypeOfAccount", "");
                        CommonContextUpdate("typeOfAccount", "");
                      }
                    }}
                    row={true}
                  >
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"Annuity"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Annuity"
                    />
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"Life Insurance"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Life Insurance"
                    />
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"Bank/Savings Account"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Bank/Savings Account"
                    />
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"Other"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Other"
                    />
                    <TextField
                      variant="standard"
                      type="text"
                      value={transferOrExchangeRequest.otherTypeOfAccount}
                      className={
                        transferOrExchangeRequest.typeOfAccount !== "Other"
                          ? ""
                          : "re_fields"
                      }
                      placeholder={
                        transferOrExchangeRequest.typeOfAccount !== "Other"
                          ? ""
                          : "This field is required"
                      }
                      disabled={
                        (transferOrExchangeRequest.typeOfAccount !== "Other" || disableAllInputs === true)
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        CommonContextUpdate(
                          "otherTypeOfAccount",
                          e.target.value
                        );
                      }}
                    />
                  </RadioGroup>
                </Box>
                <Box className="existing_annuity_status">
                  <Typography variant="span">
                    My existing annuity/life contract/certificate is:<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <RadioGroup
                    name="plan_applied_for"
                    className="radio_group_shift_right"
                    value={transferOrExchangeRequest.ownerExistingAnnuity}
                    onClick={(e) => {
                      // CommonContextUpdate(
                      //   "ownerExistingAnnuity",
                      //   e.target.value
                      // )
                      if (!e.target.checked) {
                        return;
                      }
                      if (!e.target.value) {
                        CommonContextUpdate("ownerExistingAnnuity", "");

                        return;
                      }
                      if (
                        e.target.value !=
                        transferOrExchangeRequest.ownerExistingAnnuity
                      ) {
                        if (
                          e.target.value !== "Other" &&
                          transferOrExchangeRequest.ownerExistingAnnuity !== ""
                        ) {
                          CommonContextUpdate("ownerExistingAnnuity", "");
                        }
                        CommonContextUpdate(
                          "ownerExistingAnnuity",
                          e.target.value
                        );
                      } else {
                        CommonContextUpdate("ownerExistingAnnuity", "");
                      }
                    }}
                    row={true}
                  >
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"Enclosed"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Enclosed"
                    />
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"Not required to process this transaction"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Not required to process this transaction"
                    />
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"Lost or Destroyed"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Lost or Destroyed"
                    />
                  </RadioGroup>
                </Box>
              </Box>
            ) : null}

            <Box
              className="section_head_root"
              onClick={() => {
                // debugger;
                // setFlagSecondSection(!flagThirdPageSecondSection)
                SetApplicationFormFlagSectionData({
                  type: "Transfer or Exchange Request",
                  section: "flagFourthPageSecondSection",
                  data: !flagFourthPageSecondSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">2. Plans</Typography>
              </Box>
              <Box className="section_open_close">
                {flagFourthPageSecondSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFourthPageSecondSection === true ? (
              <Box className="plans_transfer_request" style={{ width: "80%" }}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={["Non-Qualified Plans", "Qualified Plans"]}
                    disabled={
                      disableAllInputs === true || flagFill === false
                        ? true
                        : false
                    }
                    value={transferOrExchangeRequest?.plans}
                    onChange={(e, value, reason) => {
                      if (value === "Qualified Plans") {
                        CommonContextUpdate(
                          "nonQualifiedTransferOrExchangeExistingAccountAllOrPartial",
                          ""
                        );
                        CommonContextUpdate(
                          "nonQualifiedTransferOrExchangeExistingAccountPartialAmount",
                          ""
                        );

                        CommonContextUpdate(
                          "nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate",
                          ""
                        );
                        CommonContextUpdate(
                          "nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                          ""
                        );

                        CommonContextUpdate(
                          "nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy",
                          ""
                        );
                        CommonContextUpdate(
                          "nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                          ""
                        );

                        CommonContextUpdate(
                          "flagNonQualifiedOwnerWantWithdrawalToExchange",
                          ""
                        );
                        CommonContextUpdate(
                          "explainNonQualifiedOwnerWantWithdrawalToExchange",
                          ""
                        );

                        CommonContextUpdate(
                          "flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal",
                          ""
                        );
                      } else if (value === "Non-Qualified Plans") {
                        CommonContextUpdate(
                          "qualifiedTransferOrExchangeExistingAccountAllOrPartial",
                          ""
                        );
                        CommonContextUpdate(
                          "qualifiedTransferOrExchangeExistingAccountPartialAmount",
                          ""
                        );

                        CommonContextUpdate(
                          "qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate",
                          ""
                        );
                        CommonContextUpdate(
                          "qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                          ""
                        );

                        CommonContextUpdate(
                          "qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy",
                          ""
                        );
                        CommonContextUpdate(
                          "qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                          ""
                        );

                        CommonContextUpdate("qualifiedTypeOfTransfer", "");

                        CommonContextUpdate(
                          "qualifiedQualifyingEventForDirectRolloverFunds",
                          ""
                        );

                        CommonContextUpdate(
                          "flagQualifiedOwnerReachedAge72OrMoreInThisYear",
                          ""
                        );

                        CommonContextUpdate(
                          "flagQualifiedOwnerSatisfiedRMDFromDistributingPlan",
                          ""
                        );

                        CommonContextUpdate(
                          "qualifiedInternalExchangeOfExistingAnnuityName",
                          ""
                        );

                        CommonContextUpdate(
                          "flagQualifiedOwnerWantRMDBeforeCompletingExchange",
                          ""
                        );

                        CommonContextUpdate(
                          "flagQualifiedOwnerWantToContinueExistingRMDSchedule",
                          ""
                        );
                      } else {
                        CommonContextUpdate(
                          "qualifiedTransferOrExchangeExistingAccountAllOrPartial",
                          ""
                        );
                        CommonContextUpdate(
                          "qualifiedTransferOrExchangeExistingAccountPartialAmount",
                          ""
                        );

                        CommonContextUpdate(
                          "qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate",
                          ""
                        );
                        CommonContextUpdate(
                          "qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                          ""
                        );

                        CommonContextUpdate(
                          "qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy",
                          ""
                        );
                        CommonContextUpdate(
                          "qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                          ""
                        );

                        CommonContextUpdate("qualifiedTypeOfTransfer", "");

                        CommonContextUpdate(
                          "qualifiedQualifyingEventForDirectRolloverFunds",
                          ""
                        );

                        CommonContextUpdate(
                          "flagQualifiedOwnerReachedAge72OrMoreInThisYear",
                          ""
                        );

                        CommonContextUpdate(
                          "flagQualifiedOwnerSatisfiedRMDFromDistributingPlan",
                          ""
                        );

                        CommonContextUpdate(
                          "qualifiedInternalExchangeOfExistingAnnuityName",
                          ""
                        );

                        CommonContextUpdate(
                          "flagQualifiedOwnerWantRMDBeforeCompletingExchange",
                          ""
                        );

                        CommonContextUpdate(
                          "flagQualifiedOwnerWantToContinueExistingRMDSchedule",
                          ""
                        );
                        CommonContextUpdate(
                          "nonQualifiedTransferOrExchangeExistingAccountAllOrPartial",
                          ""
                        );
                        CommonContextUpdate(
                          "nonQualifiedTransferOrExchangeExistingAccountPartialAmount",
                          ""
                        );

                        CommonContextUpdate(
                          "nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate",
                          ""
                        );
                        CommonContextUpdate(
                          "nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                          ""
                        );

                        CommonContextUpdate(
                          "nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy",
                          ""
                        );
                        CommonContextUpdate(
                          "nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                          ""
                        );

                        CommonContextUpdate(
                          "flagNonQualifiedOwnerWantWithdrawalToExchange",
                          ""
                        );
                        CommonContextUpdate(
                          "explainNonQualifiedOwnerWantWithdrawalToExchange",
                          ""
                        );

                        CommonContextUpdate(
                          "flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal",
                          ""
                        );
                      }
                      CommonContextUpdate("plans", value === null ? "" : value);
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          variant="standard"
                          label="Select Plan"
                          className="req_fields"
                          {...params}
                        />
                      );
                    }}
                  />
                </FormControl>
                {/* Non-Qualified plans details */}
                {transferOrExchangeRequest.plans === "Non-Qualified Plans" && (
                  <Box
                    className="non_qualified_plans"
                    style={{ margin: "10px 0" }}
                  >
                    {/* <Typography variant="span">
                      For Non-Qualified Plans (Complete this section)
                    </Typography> */}

                    <Box
                      className="transfer_existing_account_section"
                      style={{ margin: "15px 0" }}
                    >
                      <Typography variant="span">
                        Please Transfer/Exchange my existing account and process
                        as requested:<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Box
                        className="all_or_partial"
                        style={{
                          margin: "10px 0",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <RadioGroup
                          name="all_partial"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountAllOrPartial
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountPartialAmount",
                                ""
                              );
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountAllOrPartial",
                                ""
                              );
                              return;
                            }
                            if (
                              e.target.value !=
                              transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountAllOrPartial
                            ) {
                              if (
                                e.target.value === "All" &&
                                transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountPartialAmount !==
                                ""
                              ) {
                                CommonContextUpdate(
                                  "nonQualifiedTransferOrExchangeExistingAccountPartialAmount",
                                  ""
                                );
                              }
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountAllOrPartial",
                                e.target.value
                              );
                            } else {
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountAllOrPartial",
                                ""
                              );
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountPartialAmount",
                                ""
                              );
                              // CommonContextUpdate("typeOfAccount", "");
                            }
                          }}
                          // onChange={(e) => {
                          //   if (
                          //     e.target.value === "All" &&
                          //     transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountPartialAmount !==
                          //       ""
                          //   ) {
                          //     CommonContextUpdate(
                          //       "nonQualifiedTransferOrExchangeExistingAccountPartialAmount",
                          //       ""
                          //     );
                          //   }
                          //   CommonContextUpdate(
                          //     "nonQualifiedTransferOrExchangeExistingAccountAllOrPartial",
                          //     e.target.value
                          //   );
                          // }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"All"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="All"
                          />
                          <Typography
                            style={{ margin: "12px 15px 0 -5px" }}
                            variant="span"
                            className={disableAllInputs === true ? "non_qualified_or disableField" : "non_qualified_or"}
                          >
                            OR
                          </Typography>
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Partial"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Partial"
                          />
                          <Input
                            variant="standard"
                            type="text"
                            value={transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountPartialAmount.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            disabled={
                              transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountAllOrPartial !==
                                "Partial"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              console.log(
                                "Printing partial all for nonqualified",
                                e.target.value
                              );
                              console.log(e.target.value);
                              console.log(e.target.value.replaceAll(",", ""));
                              if (
                                isNaN(e.target.value.replaceAll(",", "")) ===
                                false
                              )
                                CommonContextUpdate(
                                  "nonQualifiedTransferOrExchangeExistingAccountPartialAmount",
                                  e.target.value.replaceAll(",", "")
                                );
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                          />
                        </RadioGroup>
                      </Box>
                      <Box
                        className="immediately_maturity_date"
                        style={{ margin: "10px 0" }}
                      >
                        <RadioGroup
                          name="all_partial"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate",
                                ""
                              );
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                                ""
                              );
                              return;
                            }
                            if (
                              e.target.value !=
                              transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate
                            ) {
                              if (
                                e.target.value === "Immediately" &&
                                transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField !==
                                ""
                              ) {
                                CommonContextUpdate(
                                  "nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                                  ""
                                );
                              }
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate",
                                e.target.value
                              );
                            } else {
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate",
                                ""
                              );
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                                ""
                              );
                              // CommonContextUpdate("typeOfAccount", "");
                            }
                          }}
                          // onChange={(e) => {
                          //   if (
                          //     e.target.value === "Immediately" &&
                          //     transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField !==
                          //       ""
                          //   ) {
                          //     CommonContextUpdate(
                          //       "nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                          //       ""
                          //     );
                          //   }
                          //   CommonContextUpdate(
                          //     "nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate",
                          //     e.target.value
                          //   );
                          // }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Immediately"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Immediately"
                          />
                          <Typography
                            style={{ margin: "12px 15px 0 -5px" }}
                            variant="span"
                            className={disableAllInputs === true ? "non_qualified_or disableField" : "non_qualified_or"}
                          >
                            OR
                          </Typography>
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Upon the maturity date of"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Upon the maturity date of"
                          />
                          <Input
                            variant="standard"
                            type="text"
                            value={
                              transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField
                            }
                            disabled={
                              transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate !==
                                "Upon the maturity date of"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                                e.target.value
                              );
                            }}
                          />
                        </RadioGroup>
                      </Box>
                      <Box
                        className="new_existing_account"
                        style={{ margin: "10px 0" }}
                      >
                        <RadioGroup
                          name="all_partial"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy",
                                ""
                              );
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                                ""
                              );
                              return;
                            }
                            if (
                              e.target.value !=
                              transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy
                            ) {
                              if (
                                e.target.value === "To New Policy/Account" &&
                                transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField !==
                                ""
                              ) {
                                CommonContextUpdate(
                                  "nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                                  ""
                                );
                              }
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy",
                                e.target.value
                              );
                            } else {
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy",
                                ""
                              );
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                                ""
                              );
                              // CommonContextUpdate("typeOfAccount", "");
                            }
                          }}
                          // onChange={(e) => {
                          //   if (
                          //     e.target.value === "To New Policy/Account" &&
                          //     transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField !==
                          //       ""
                          //   ) {
                          //     CommonContextUpdate(
                          //       "nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                          //       ""
                          //     );
                          //   }
                          //   CommonContextUpdate(
                          //     "nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy",
                          //     e.target.value
                          //   );
                          // }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"To New Policy/Account"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="To New Policy/Account"
                          />
                          <Typography
                            style={{ margin: "12px 15px 0 -5px" }}
                            variant="span"
                            className={disableAllInputs === true ? "non_qualified_or disableField" : "non_qualified_or"}
                          >
                            OR
                          </Typography>
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"To Existing Policy/Account"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="To Existing Policy/Account"
                          />
                          <Input
                            style={{ width: "250px" }}
                            variant="standard"
                            type="text"
                            value={
                              transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField
                            }
                            disabled={
                              transferOrExchangeRequest.nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy !==
                                "To Existing Policy/Account"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              CommonContextUpdate(
                                "nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                                e.target.value
                              );
                            }}
                            placeholder="Existing policy account # (if applicable)"
                          />
                        </RadioGroup>
                      </Box>
                    </Box>

                    <Typography variant="span">
                      If this is an internal exchange of an existing Farmers
                      annuity,
                    </Typography>

                    <Box
                      className="internal_exchange_of_existing_farmers_annuity"
                      style={{ margin: "10px 0" }}
                    >
                      <Box className="question_a radio_group_shift_right">
                        <Typography variant="span">
                          A. Do you want a withdrawal prior to the exchange?<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <RadioGroup
                          name="question_a"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.flagNonQualifiedOwnerWantWithdrawalToExchange
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate(
                                "flagNonQualifiedOwnerWantWithdrawalToExchange",
                                ""
                              );
                              CommonContextUpdate(
                                "explainNonQualifiedOwnerWantWithdrawalToExchange",
                                ""
                              );
                              return;
                            }
                            if (
                              e.target.value !=
                              transferOrExchangeRequest.flagNonQualifiedOwnerWantWithdrawalToExchange
                            ) {
                              if (
                                e.target.value === "No" &&
                                transferOrExchangeRequest.explainNonQualifiedOwnerWantWithdrawalToExchange !==
                                ""
                              ) {
                                CommonContextUpdate(
                                  "explainNonQualifiedOwnerWantWithdrawalToExchange",
                                  ""
                                );
                              }
                              CommonContextUpdate(
                                "flagNonQualifiedOwnerWantWithdrawalToExchange",
                                e.target.value
                              );
                            } else {
                              CommonContextUpdate(
                                "flagNonQualifiedOwnerWantWithdrawalToExchange",
                                ""
                              );
                              CommonContextUpdate(
                                "explainNonQualifiedOwnerWantWithdrawalToExchange",
                                ""
                              );
                              // CommonContextUpdate("typeOfAccount", "");
                            }
                          }}
                          // onChange={(e) => {
                          //   if (
                          //     e.target.value === "No" &&
                          //     transferOrExchangeRequest.explainNonQualifiedOwnerWantWithdrawalToExchange !==
                          //       ""
                          //   ) {
                          //     CommonContextUpdate(
                          //       "explainNonQualifiedOwnerWantWithdrawalToExchange",
                          //       ""
                          //     );
                          //   }
                          //   CommonContextUpdate(
                          //     "flagNonQualifiedOwnerWantWithdrawalToExchange",
                          //     e.target.value
                          //   );
                          // }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Yes"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"No"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                        <Input
                          variant="standard"
                          type="text"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.explainNonQualifiedOwnerWantWithdrawalToExchange
                          }
                          disabled={
                            transferOrExchangeRequest.flagNonQualifiedOwnerWantWithdrawalToExchange ===
                              "No"
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            CommonContextUpdate(
                              "explainNonQualifiedOwnerWantWithdrawalToExchange",
                              e.target.value
                            );
                          }}
                          placeholder="If Yes, amount of withdrawal"
                          style={{ width: "250px" }}
                        />
                      </Box>

                      <Box className="question_b radio_group_shift_right" style={{ margin: "10px 0" }}>
                        <Typography variant="span">
                          B. Do you want to continue an existing period withdraw
                          schedule?<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <RadioGroup
                          name="question_b"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate(
                                "flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal",
                                ""
                              );
                              return;
                            }
                            if (
                              e.target.value !=
                              transferOrExchangeRequest.flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal
                            ) {
                              CommonContextUpdate(
                                "flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal",
                                e.target.value
                              );
                            } else {
                              CommonContextUpdate(
                                "flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal",
                                ""
                              );

                              // CommonContextUpdate("typeOfAccount", "");
                            }
                          }}
                          // onChange={(e) =>
                          //   CommonContextUpdate(
                          //     "flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal",
                          //     e.target.value
                          //   )
                          // }
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Yes"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"No"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </Box>

                      <Typography
                        className="non_qualified_note_imp"
                        variant="span"
                      >
                        Note: If you request a withdrawal after the exchange is
                        complete, any distribution in excess of any penalty -
                        free withdrawal will be subject to the charges described
                        in the contract.
                      </Typography>
                    </Box>
                  </Box>
                )}

                {/* Qualified Plans Details */}
                {transferOrExchangeRequest.plans === "Qualified Plans" && (
                  <Box className="qualified_plans" style={{ margin: "10px 0" }}>
                    {/* <Typography variant="span">
                      For Qualified Plans (Complete this section)
                    </Typography> */}

                    <Box
                      className="transfer_exchange_existing_account"
                      style={{ margin: "15px 0" }}
                    >
                      <Typography variant="span">
                        Please Transfer/Exchange my existing account and process
                        as requested:<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Box
                        className="all_or_partial"
                        style={{
                          margin: "10px 0",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <RadioGroup
                          name="all_partial"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountAllOrPartial
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountPartialAmount",
                                ""
                              );
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountAllOrPartial",
                                ""
                              );
                              return;
                            }
                            if (
                              e.target.value !=
                              transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountAllOrPartial
                            ) {
                              if (
                                e.target.value === "All" &&
                                transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountPartialAmount !==
                                ""
                              ) {
                                CommonContextUpdate(
                                  "qualifiedTransferOrExchangeExistingAccountPartialAmount",
                                  ""
                                );
                              }
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountAllOrPartial",
                                e.target.value
                              );
                            } else {
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountAllOrPartial",
                                ""
                              );
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountPartialAmount",
                                ""
                              );
                              // CommonContextUpdate("typeOfAccount", "");
                            }
                          }}
                          // onChange={(e) => {
                          //   if (
                          //     e.target.value === "All" &&
                          //     transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountPartialAmount !==
                          //       ""
                          //   ) {
                          //     CommonContextUpdate(
                          //       "qualifiedTransferOrExchangeExistingAccountPartialAmount",
                          //       ""
                          //     );
                          //   }
                          //   CommonContextUpdate(
                          //     "qualifiedTransferOrExchangeExistingAccountAllOrPartial",
                          //     e.target.value
                          //   );
                          // }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"All"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="All"
                          />
                          <Typography
                            style={{ margin: "12px 15px 0 -5px" }}
                            variant="span"
                            className={disableAllInputs === true ? "non_qualified_or disableField" : "non_qualified_or"}
                          >
                            OR
                          </Typography>
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Partial"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Partial"
                          />
                          <Input
                            variant="standard"
                            type="text"
                            value={transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountPartialAmount.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            disabled={
                              transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountAllOrPartial !==
                                "Partial"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              console.log(e.target.value);
                              console.log(e.target.value.replaceAll(",", ""));
                              if (
                                isNaN(e.target.value.replaceAll(",", "")) ===
                                false
                              )
                                CommonContextUpdate(
                                  "qualifiedTransferOrExchangeExistingAccountPartialAmount",
                                  e.target.value.replaceAll(",", "")
                                );
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                          />
                        </RadioGroup>
                      </Box>
                      <Box
                        className="immediately_maturity_date"
                        style={{ margin: "10px 0" }}
                      >
                        <RadioGroup
                          name="all_partial"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate",
                                ""
                              );
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                                ""
                              );
                              return;
                            }
                            if (
                              e.target.value !=
                              transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate
                            ) {
                              if (
                                e.target.value === "Immediately" &&
                                transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField !==
                                ""
                              ) {
                                CommonContextUpdate(
                                  "qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                                  ""
                                );
                              }
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate",
                                e.target.value
                              );
                            } else {
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate",
                                ""
                              );
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                                ""
                              );
                              // CommonContextUpdate("typeOfAccount", "");
                            }
                          }}
                          // onChange={(e) => {
                          //   if (
                          //     e.target.value === "Immediately" &&
                          //     transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField !==
                          //       ""
                          //   ) {
                          //     CommonContextUpdate(
                          //       "qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                          //       ""
                          //     );
                          //   }
                          //   CommonContextUpdate(
                          //     "qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate",
                          //     e.target.value
                          //   );
                          // }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Immediately"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Immediately"
                          />
                          <Typography
                            style={{ margin: "12px 15px 0 -5px" }}
                            variant="span"
                            className={disableAllInputs === true ? "non_qualified_or disableField" : "non_qualified_or"}
                          >
                            OR
                          </Typography>
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Upon the maturity date of"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Upon the maturity date of"
                          />
                          <Input
                            variant="standard"
                            type="text"
                            value={
                              transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField
                            }
                            disabled={
                              transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate !==
                                "Upon the maturity date of"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField",
                                e.target.value
                              );
                            }}
                          />
                        </RadioGroup>
                      </Box>
                      <Box className="new_existing_account" style={{ margin: "10px 0" }}>
                        <RadioGroup
                          name="all_partial"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy",
                                ""
                              );
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                                ""
                              );
                              return;
                            }
                            if (
                              e.target.value !=
                              transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy
                            ) {
                              if (
                                e.target.value === "To New Policy/Account" &&
                                transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField !==
                                ""
                              ) {
                                CommonContextUpdate(
                                  "qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                                  ""
                                );
                              }
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy",
                                e.target.value
                              );
                            } else {
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy",
                                ""
                              );
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                                ""
                              );
                              // CommonContextUpdate("typeOfAccount", "");
                            }
                          }}
                          // onChange={(e) => {
                          //   if (
                          //     e.target.value === "To New Policy/Account" &&
                          //     transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField !==
                          //       ""
                          //   ) {
                          //     CommonContextUpdate(
                          //       "qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                          //       ""
                          //     );
                          //   }
                          //   CommonContextUpdate(
                          //     "qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy",
                          //     e.target.value
                          //   );
                          // }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"To New Policy/Account"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="To New Policy/Account"
                          />
                          <Typography
                            style={{ margin: "12px 15px 0 -5px" }}
                            variant="span"
                            className={disableAllInputs === true ? "non_qualified_or disableField" : "non_qualified_or"}
                          >
                            OR
                          </Typography>
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"To Existing Policy/Account"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="To Existing Policy/Account"
                          />
                          <Input
                            className="existing"
                            variant="standard"
                            type="text"
                            value={
                              transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField
                            }
                            disabled={
                              transferOrExchangeRequest.qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy !==
                                "To Existing Policy/Account"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              CommonContextUpdate(
                                "qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField",
                                e.target.value
                              );
                            }}
                            placeholder="Existing policy account # (if applicable)"
                          />
                        </RadioGroup>
                      </Box>
                    </Box>

                    <Box
                      className="type_of_transfer_rollover"
                      style={{ margin: "10px 0" }}
                    >
                      <Typography variant="span">
                        Type of Transfer or Rollover:<span style={{ color: "red" }}>*</span>
                      </Typography>

                      <RadioGroup
                        name="all_partial"
                        className="radio_group_shift_right"
                        value={
                          transferOrExchangeRequest.qualifiedTypeOfTransfer
                        }
                        onClick={(e) => {
                          if (!e.target.checked) {
                            return;
                          }
                          if (!e.target.value) {
                            CommonContextUpdate("qualifiedTypeOfTransfer", "");
                            return;
                          }
                          if (
                            e.target.value !=
                            transferOrExchangeRequest.qualifiedTypeOfTransfer
                          ) {
                            CommonContextUpdate(
                              "qualifiedTypeOfTransfer",
                              e.target.value
                            );
                          } else {
                            CommonContextUpdate("qualifiedTypeOfTransfer", "");
                            // CommonContextUpdate("typeOfAccount", "");
                          }
                        }}
                        // onChange={(e) =>
                        //   CommonContextUpdate(
                        //     "qualifiedTypeOfTransfer",
                        //     e.target.value
                        //   )
                        // }
                        row={true}
                      >
                        <FormControlLabel
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={"Trustee to Trustee Transfer"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Trustee to Trustee Transfer"
                        />
                        <FormControlLabel
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={"Conversion to Roth IRA"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Conversion to Roth IRA"
                        />
                        <FormControlLabel
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={
                                "Direct Rollover (Elligible Rollover Distribution)"
                              }
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Direct Rollover (Elligible Rollover Distribution)"
                        />
                      </RadioGroup>
                    </Box>

                    <Box
                      className="qualified_events_for_rollover_funds"
                      style={{ margin: "10px 0" }}
                    >
                      <Typography variant="span">
                        Qualifying Event for Direct Rollover of Funds:<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <RadioGroup
                        name="all_partial"
                        className="radio_group_shift_right"
                        value={
                          transferOrExchangeRequest.qualifiedQualifyingEventForDirectRolloverFunds
                        }
                        onClick={(e) => {
                          if (!e.target.checked) {
                            return;
                          }
                          if (!e.target.value) {
                            CommonContextUpdate(
                              "qualifiedQualifyingEventForDirectRolloverFunds",
                              ""
                            );
                            return;
                          }
                          if (
                            e.target.value !=
                            transferOrExchangeRequest.qualifiedQualifyingEventForDirectRolloverFunds
                          ) {
                            CommonContextUpdate(
                              "qualifiedQualifyingEventForDirectRolloverFunds",
                              e.target.value
                            );
                          } else {
                            CommonContextUpdate(
                              "qualifiedQualifyingEventForDirectRolloverFunds",
                              ""
                            );
                            // CommonContextUpdate("typeOfAccount", "");
                          }
                        }}
                        // onChange={(e) =>
                        //   CommonContextUpdate(
                        //     "qualifiedQualifyingEventForDirectRolloverFunds",
                        //     e.target.value
                        //   )
                        // }
                        row={true}
                      >
                        <FormControlLabel
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={"Age 59^1/2"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Age 59^1/2"
                        />
                        <FormControlLabel
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={"Disability"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Disability"
                        />
                        <FormControlLabel
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={"Death"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Death"
                        />
                        <FormControlLabel
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={"Internal Exchange"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Internal Exchange"
                        />
                        <FormControlLabel
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={"Plan Termination"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Plan Termination"
                        />
                        <FormControlLabel
                          disabled={disableAllInputs === true ? true : false}
                          control={
                            <Radio
                              value={"Severance of Employment"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Severance of Employment"
                        />
                      </RadioGroup>
                    </Box>

                    <Box className="rmd_info" style={{ margin: "10px 0" }}>
                      <Typography style={{ margin: "10px 0" }} variant="span">
                        Required Minimum Distribution (RMD) Information:
                      </Typography>

                      <Box className="question_a" style={{ margin: "10px 0px 10px 15px" }}>
                        <Typography variant="span">
                          A. Have you reached age 72 or older in this calendar
                          year?<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <RadioGroup
                          name="all_partial"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.flagQualifiedOwnerReachedAge72OrMoreInThisYear
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate(
                                "flagQualifiedOwnerReachedAge72OrMoreInThisYear",
                                ""
                              );
                              return;
                            }
                            if (
                              e.target.value !=
                              transferOrExchangeRequest.flagQualifiedOwnerReachedAge72OrMoreInThisYear
                            ) {
                              CommonContextUpdate(
                                "flagQualifiedOwnerReachedAge72OrMoreInThisYear",
                                e.target.value
                              );
                            } else {
                              CommonContextUpdate(
                                "flagQualifiedOwnerReachedAge72OrMoreInThisYear",
                                ""
                              );

                              // CommonContextUpdate("typeOfAccount", "");
                            }
                          }}
                          // onChange={(e) =>
                          //   CommonContextUpdate(
                          //     "flagQualifiedOwnerReachedAge72OrMoreInThisYear",
                          //     e.target.value
                          //   )
                          // }
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Yes"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"No"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </Box>

                      <Box className="question_b" style={{ margin: "10px 0px 10px 15px" }}>
                        <Typography variant="span">
                          B. Have you satisfied your RMD from the distributing
                          plan?<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <RadioGroup
                          name="all_partial"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.flagQualifiedOwnerSatisfiedRMDFromDistributingPlan
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate(
                                "flagQualifiedOwnerSatisfiedRMDFromDistributingPlan",
                                ""
                              );
                              return;
                            }
                            if (
                              e.target.value !=
                              transferOrExchangeRequest.flagQualifiedOwnerSatisfiedRMDFromDistributingPlan
                            ) {
                              CommonContextUpdate(
                                "flagQualifiedOwnerSatisfiedRMDFromDistributingPlan",
                                e.target.value
                              );
                            } else {
                              CommonContextUpdate(
                                "flagQualifiedOwnerSatisfiedRMDFromDistributingPlan",
                                ""
                              );

                              // CommonContextUpdate("typeOfAccount", "");
                            }
                          }}
                          // onChange={(e) =>
                          //   CommonContextUpdate(
                          //     "flagQualifiedOwnerSatisfiedRMDFromDistributingPlan",
                          //     e.target.value
                          //   )
                          // }
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Yes"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"No"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </Box>

                      <Box className="a_yes_b_no_imp radio_group_shift_right">
                        <Typography variant="span">
                          If A is “Yes” and B is “No”, then the transferring
                          company MUST make any RMD before transferring funds.{" "}
                        </Typography>
                      </Box>

                      <Box
                        className="internal_exchange_of_existing_account_field"
                        style={{ margin: "10px 0px 10px 15px" }}
                      >
                        <Typography
                          variant="span"
                          className="internal_exchange_1"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span>
                            If this is an internal exchange of an existing{" "}
                            <span
                              style={
                                transferOrExchangeRequest.flagQualifiedOwnerReachedAge72OrMoreInThisYear ===
                                  "Yes" &&
                                  transferOrExchangeRequest.flagQualifiedOwnerSatisfiedRMDFromDistributingPlan ===
                                  "No"
                                  ? { color: "red" }
                                  : { display: "none" }
                              }
                            >
                              *
                            </span>
                          </span>
                          <TextField
                            type="text"
                            variant="standard"
                            value={
                              transferOrExchangeRequest.qualifiedInternalExchangeOfExistingAnnuityName
                            }
                            onChange={(e) => {
                              CommonContextUpdate(
                                "qualifiedInternalExchangeOfExistingAnnuityName",
                                e.target.value
                              );
                            }}
                            style={{ paddingLeft: "5px", textAlign: "center" }}
                          />

                          <span className="internal_exchange_2">annuity.</span>
                        </Typography>
                      </Box>

                      <Box className="question_c" style={{ margin: "10px 0px 10px 15px" }}>
                        <Typography variant="span">
                          C. Do you want your RMD before completing the
                          exchange?
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </Typography>
                        <RadioGroup
                          name="all_partial"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.flagQualifiedOwnerWantRMDBeforeCompletingExchange
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate(
                                "flagQualifiedOwnerWantRMDBeforeCompletingExchange",
                                ""
                              );
                              return;
                            }
                            if (
                              e.target.value !=
                              transferOrExchangeRequest.flagQualifiedOwnerWantRMDBeforeCompletingExchange
                            ) {
                              CommonContextUpdate(
                                "flagQualifiedOwnerWantRMDBeforeCompletingExchange",
                                e.target.value
                              );
                            } else {
                              CommonContextUpdate(
                                "flagQualifiedOwnerWantRMDBeforeCompletingExchange",
                                ""
                              );

                              // CommonContextUpdate("typeOfAccount", "");
                            }
                          }}
                          // onChange={(e) =>
                          //   CommonContextUpdate(
                          //     "flagQualifiedOwnerWantRMDBeforeCompletingExchange",
                          //     e.target.value
                          //   )
                          // }
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Yes"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"No"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </Box>

                      <Box className="question_d" style={{ margin: "10px 0px 10px 15px" }}>
                        <Typography variant="span">
                          D. Do you want to continue your existing RMD schedule?{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </Typography>
                        <RadioGroup
                          name="all_partial"
                          className="radio_group_shift_right"
                          value={
                            transferOrExchangeRequest.flagQualifiedOwnerWantToContinueExistingRMDSchedule
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate(
                                "flagQualifiedOwnerWantToContinueExistingRMDSchedule",
                                ""
                              );
                              return;
                            }
                            if (
                              e.target.value !=
                              transferOrExchangeRequest.flagQualifiedOwnerWantToContinueExistingRMDSchedule
                            ) {
                              CommonContextUpdate(
                                "flagQualifiedOwnerWantToContinueExistingRMDSchedule",
                                e.target.value
                              );
                            } else {
                              CommonContextUpdate(
                                "flagQualifiedOwnerWantToContinueExistingRMDSchedule",
                                ""
                              );
                            }
                          }}
                          // onChange={(e) =>
                          //   CommonContextUpdate(
                          //     "flagQualifiedOwnerWantToContinueExistingRMDSchedule",
                          //     e.target.value
                          //   )
                          // }
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"Yes"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true ? true : false}
                            control={
                              <Radio
                                value={"No"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </Box>
                    </Box>

                    <Box className="imp_note">
                      <Typography variant="span">
                        Note: If you request an RMD after the exchange is
                        complete, any distribution in excess of any penalty-free
                        withdrawal will be subject to the charges described in
                        the contract.
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : null}

            {/* <Box className="section_head_root" onClick={() => {
                            SetApplicationFormFlagSectionData({
                                type: "Transfer or Exchange Request",
                                section: "flagFourthPageThirdSection",
                                data: !flagFourthPageThirdSection
                            })
                        }}>
                            <Box className="section_open_close">
                                <Typography variant="span">3. Witness Information</Typography>
                            </Box>

                            <Box className='section_open_close'>
                                {
                                    flagFourthPageThirdSection === true ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                                }
                            </Box>
                        </Box> */}

            {/* {
                            flagFourthPageThirdSection === true ? <Box className="basic_information" style={{width: "80%"}}>
                                <TextField
                                    type='text'
                                    variant='standard'
                                    label='Witness Full Name'
                                    className='Owner_Full_Name req_fields'
                                    value={transferOrExchangeRequest.witnessName}
                                    onChange={e => {
                                        CommonContextUpdate("witnessName", e.target.value);
                                    }}
                                   disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                                    style={{ width: "48%", margin: "10px 1% " }}
                                />
                                <TextField
                                    type='email'
                                    variant='standard'
                                    label='Witness Email'
                                    className='Owner_Full_Name req_fields'
                                    value={transferOrExchangeRequest.witnessEmail}
                                    onChange={e => {
                                        CommonContextUpdate("witnessEmail", e.target.value);
                                    }}
                                   disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                                    style={{ width: "48%", margin: "10px 1% " }}
                                />
                            </Box> : null
                        } */}

            <Box
              className="previous_next_btn"
              style={{
                margin: "25px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={<KeyboardDoubleArrowLeftIcon />}
                className="btn_prev"
                onClick={() => {
                  props.previousPage();
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Previous
              </Button>
              <Button
                endIcon={<DoubleArrowIcon />}
                className="btn_next"
                onClick={() => {
                  props.nextPage();
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Next - Safeguard Plus® Annuity Disclosure
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default TransferExchangeRequest;
